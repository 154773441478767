import useForm from 'lib/src/hooks/useForm';
import { UpdateGuestRequest, patchGuest } from '@actions/guests/patchGuest';
import { useDispatch, useSelector } from 'react-redux';
import { selectGuestIsPosting, selectGuestPostSuccess } from '@selectors/guest';
import Guest from '../../../../types/Guest';
import { useEffect, useMemo } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

const useUpdateGuest = (guest: Guest) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(selectGuestIsPosting);
    const postSuccess = useSelector(selectGuestPostSuccess);
    const initialFormData: UpdateGuestRequest = useMemo(
        () => ({
            id: guest.id,
            firstName: guest.firstName,
            middleName: guest.middleName,
            lastName: guest.lastName,
            nickname: guest.nickname,
            email: guest.email,
            address: guest.address,
            phoneNumber: guest.phoneNumber,
            specialRelations: guest.specialRelations,
            notes: guest.notes,
            ageCategory: guest.ageCategory,
            pictureS3Key: guest.pictureS3Key,
            guestInvitedTo: guest.guestInvitedTo,
            guestRSVP: guest.guestRSVP,
            cateringAttendanceCanape: guest.cateringAttendanceCanape,
            cateringAttendanceWeddingBreakfast: guest.cateringAttendanceWeddingBreakfast,
            cateringAttendanceEveningMeal: guest.cateringAttendanceEveningMeal,
            isVegan: guest.isVegan,
            isVegetarian: guest.isVegetarian,
            isGlutenFree: guest.isGlutenFree,
            isDairyFree: guest.isDairyFree,
            isNutFree: guest.isNutFree,
            isEggFree: guest.isEggFree,
            isOther: guest.isOther,
            otherDetails: guest.otherDetails,
            isStayingAtWeddingVenue: guest.isStayingAtWeddingVenue,
            accommodationAddress: guest.accommodationAddress,
            numberOfNights: guest.numberOfNights,
        }),
        [guest],
    );
    const [formData, handleChange, setFormData] = useForm<UpdateGuestRequest>(initialFormData);
    const prevProps = usePrevious({ guest, initialFormData });
    useEffect(() => {
        if (initialFormData !== prevProps.initialFormData) {
            // reset form on fetch
            setFormData(initialFormData);
        }
    }, [initialFormData, prevProps.initialFormData, setFormData]);

    const handleSubmit = () => {
        dispatch(patchGuest(formData));
    };

    return {
        formData,
        handleChange,
        handleSubmit,
        isPosting,
        postSuccess,
    };
};

export default useUpdateGuest;
