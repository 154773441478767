import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deletePlannerRequest = createAction('deletePlannerRequest');
export const deletePlannerSuccess = createAction<number>('deletePlannerSuccess');
export const deletePlannerFailure = createAction('deletePlannerFailure');

export const deletePlanner =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deletePlannerRequest());
        try {
            await api.delete(`wedding/planner/${id}`);
            dispatch(deletePlannerSuccess(id));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, deletePlannerFailure, e as APIError);
        }
    };
