import { Link } from 'react-router-dom';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';

export const navLinks: NavLink[] = [
    {
        title: 'Our Wedding',
        path: '/wedding',
    },
    {
        title: 'Guests',
        path: '/wedding/guests',
    },
    {
        title: 'Catering',
        path: '/wedding/catering',
    },
    {
        title: 'Table Planner',
        path: '/wedding/table-planner',
    },
    {
        title: 'Suppliers',
        path: '/wedding/suppliers',
    },
    {
        title: 'Timings',
        path: '/wedding/timings',
    },
    {
        title: 'Budget Breakdown',
        path: '/wedding/budget-breakdown',
    },
];

const Nav = () => {
    const { isLoggedIn } = useIsLoggedIn();

    if (!isLoggedIn) {
        return null;
    }

    return (
        <nav className="nav flex-row justify-center align-center">
            <div className="container flex-row align-center justify-between">
                {navLinks.map(link => {
                    const isActive = isNavItemActive(link);

                    return (
                        <Link
                            key={link.path}
                            to={link.path}
                            className={`nav-link ${isActive ? 'active' : ''}`}
                        >
                            {link.title}
                        </Link>
                    );
                })}
            </div>
        </nav>
    );
};

export function isNavItemActive(link: NavLink) {
    const { pathname } = window.location;
    if (link.path === '/wedding' && pathname !== '/wedding') {
        return false;
    }

    return window.location.pathname.startsWith(link.path);
}

interface NavLink {
    title: string;
    path: string;
}

export default Nav;
