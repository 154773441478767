import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Resource } from 'src/types/Resource';

export const fetchSingleResourceRequest = createAction('fetchSingleResourceRequest');
export const fetchSingleResourceSuccess = createAction<Resource>('fetchSingleResourceSuccess');
export const fetchSingleResourceFailure = createAction('fetchSingleResourceFailure');

export const fetchSingleResource =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleResourceRequest());
        try {
            const { data } = await api.get<Resource>(`resource/${id}`);

            dispatch(fetchSingleResourceSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleResourceFailure, e as APIError);
        }
    };
