import React, { FC } from 'react';

const SaveButton: FC<IToolbarProps> = ({ onSave }) => {
    return (
        <button type="button" className="button primary small float-left" onClick={onSave}>
            Save
        </button>
    );
};

interface IToolbarProps {
    onSave: () => void;
}

export default SaveButton;
