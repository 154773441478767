import React from 'react';
import Description from 'lib/src/components/typography/Description';
import { fabric } from 'fabric';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';
import { TableType } from 'lib/src/shared/enums/tableType';
import DraggableRoundTable from '@pages/tablePlanner/controls/controlPanel/components/DraggableRoundTable';
import DraggableRectangleTable from '@pages/tablePlanner/controls/controlPanel/components/DraggableRectangleTable';

const TableControls: React.FC<TableControlsProps> = ({ setDropItem }) => {
    return (
        <div className="table-planner-menu-section">
            <Description>Tables</Description>

            <div>
                <div className="table-planner-menu-grid">
                    <DraggableRoundTable size={152} numberOfChairs={8} setDropItem={setDropItem} />
                    <DraggableRoundTable size={168} numberOfChairs={10} setDropItem={setDropItem} />
                    <DraggableRoundTable size={183} numberOfChairs={12} setDropItem={setDropItem} />
                </div>
                <div className="table-planner-menu-grid">
                    <DraggableRectangleTable
                        setDropItem={setDropItem}
                        length={92}
                        width={183}
                        numberOfChairs={8}
                    />
                    <DraggableRectangleTable
                        setDropItem={setDropItem}
                        length={76}
                        width={183}
                        numberOfChairs={8}
                    />
                </div>
                <div className="table-planner-menu-grid">
                    <DraggableRoundTable
                        setDropItem={setDropItem}
                        size={100}
                        numberOfChairs={0}
                        tableType={TableType.Cake}
                        caption={'Cake table'}
                    />
                </div>
            </div>
        </div>
    );
};

interface TableControlsProps {
    canvas: fabric.Canvas;
    triggerRerender: () => void;
    selectedObject: fabric.Object | null;
    setDropItem: (item: DropItemConfig | null) => void;
}

export default TableControls;
