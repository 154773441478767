import { RootState } from '@reducers/index';
import Guest from '../../types/Guest';

export const selectGuestIsFetching = (state: RootState): boolean => state.guestReducer.isFetching;
export const selectGuestIsPosting = (state: RootState): boolean => state.guestReducer.isPosting;
export const selectGuestPostSuccess = (state: RootState): boolean => state.guestReducer.postSuccess;
export const selectGuestError = (state: RootState): string | null => state.guestReducer.error;
export const selectGuests = (state: RootState): Record<number, Guest> => state.guestReducer.guests;
export const selectGuestsArr = (state: RootState): Guest[] =>
    Object.values(state.guestReducer.guests);

export const selectGuestIsDeleting = (state: RootState): boolean => state.guestReducer.isDeleting;
export const selectGuestDeleteSuccess = (state: RootState): boolean =>
    state.guestReducer.deleteSuccess;

export const selectSupplierGuestsIsGenerating = (state: RootState): boolean =>
    state.guestReducer.isGeneratingSupplierGuests;
export const selectSupplierGuestsGenerateError = (state: RootState): string | null =>
    state.guestReducer.generateSupplierGuestsError;
export const selectSupplierGuestsGenerateSuccess = (state: RootState): boolean =>
    state.guestReducer.generateSupplierGuestsSuccess;

export const selectVenueGuestsIsGenerating = (state: RootState): boolean =>
    state.guestReducer.isGeneratingVenueGuests;
export const selectVenueGuestsGenerateError = (state: RootState): string | null =>
    state.guestReducer.generateVenueGuestsError;
export const selectVenueGuestsGenerateSuccess = (state: RootState): boolean =>
    state.guestReducer.generateVenueGuestsSuccess;
