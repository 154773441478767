import image1 from '../../_content/couple-placeholder-1.png';
import image2 from '../../_content/couple-placeholder-2.png';
import image3 from '../../_content/couple-placeholder-3.png';

const couples = [
    {
        id: 1,
        name: 'Elizabeth & Daniel',
        location: 'The Cotswolds',
        imageUrl: image1,
    },
    {
        id: 2,
        name: 'Joanna & Stephen',
        location: 'London',
        imageUrl: image2,
    },
    {
        id: 3,
        name: 'Mia & Jay',
        location: 'Birmingham',
        imageUrl: image3,
    },
];
const Couples = () => {
    return (
        <div className="couples-container">
            <div className="couples-container-inner">
                {couples.map(couple => {
                    return (
                        <div className="couple flex-column align-center" key={couple.id}>
                            <img src={couple.imageUrl} alt={couple.name} />
                            <h4>{couple.name}</h4>
                            <p>{couple.location}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Couples;
