import HeroCountdown from '@components/countdown/HeroCountdown';
import WeddingStartPlanning from './WeddingStartPlanning';

import champagneReception from '@content/weddingDetails/champagne-reception.jpg';
import setTable from '@content/weddingDetails/set-table.jpg';
import weddingCake from '@content/weddingDetails/wedding-cake.jpg';
import weddingDress from '@content/weddingDetails/wedding-dress.jpg';
import TestimonialsCarousel from '@components/home/TestimonialsCarousel';
import WeddingSuppliersCountdown from './WeddingSuppliersCountdown';
import { Link } from 'react-router-dom';
import WeddingBlogPosts from './WeddingBlogPosts';
import WeddingBeInspired from './WeddingBeInspired';

const weddingDetails = [
    {
        name: 'Guests',
        image: champagneReception,
        link: '/wedding/guests',
    },
    {
        name: 'Catering',
        image: weddingCake,
        link: '/wedding/catering',
    },
    {
        name: 'Table Planner',
        image: setTable,
        link: '/wedding/table-planner',
    },
    {
        name: 'Budget',
        image: weddingDress,
        link: '/wedding/budget-breakdown',
    },
];

const WeddingDetails = () => {
    return (
        <div id="wedding-details-page">
            <HeroCountdown />
            <WeddingSuppliersCountdown />
            <WeddingStartPlanning />
            <div className="flex-row justify-center wedding-details-image-links">
                {weddingDetails.map(detail => (
                    <Link
                        key={detail.name}
                        className="wedding-details-image flex-column justify-center"
                        style={{
                            backgroundImage: `url(${detail.image})`,
                        }}
                        to={detail.link}
                    >
                        <span className="button tertiary">{detail.name}</span>
                    </Link>
                ))}
            </div>
            <div className="flex-row justify-center wedding-details-quote-container">
                <h4 className="handwriting">"Romantic quotation will be shown here"</h4>
            </div>
            <TestimonialsCarousel />
            <WeddingBlogPosts />
            <WeddingBeInspired />
        </div>
    );
};

export default WeddingDetails;
