import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Wedding from '../../../types/Wedding';

export const fetchMyWeddingsRequest = createAction('fetchMyWeddingsRequest');
export const fetchMyWeddingsSuccess = createAction<Wedding[]>('fetchMyWeddingsSuccess');
export const fetchMyWeddingsFailure = createAction('fetchMyWeddingsFailure');

export const fetchMyWeddings =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchMyWeddingsRequest());
        try {
            const { data } = await api.get<Wedding[]>('wedding/user');
            dispatch(fetchMyWeddingsSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchMyWeddingsFailure, e as APIError);
        }
    };
