import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Profile from '../../../types/Profile';

export const fetchProfileRequest = createAction('fetchProfileRequest');
export const fetchProfileSuccess = createAction<Profile>('fetchProfileSuccess');
export const fetchProfileFailure = createAction('fetchProfileFailure');

export const fetchProfile =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchProfileRequest());
        try {
            const { data } = await api.get<Profile>('auth/profile');
            dispatch(fetchProfileSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchProfileFailure, e as APIError);
        }
    };
