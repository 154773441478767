import ActionButton from 'lib/src/components/button/ActionButton';
import Title from 'lib/src/components/typography/Title';
import { AccountTabOptions } from '@pages/account/Account';

const AccountTabs: React.FC<AccountTabsProps> = ({ tab, setTab }) => {
    return (
        <div className="account-page-tabs is-hidden flex-column">
            <Title>Our Account</Title>
            <ActionButton
                disabled={tab === AccountTabOptions.Profile}
                onClick={() => setTab(AccountTabOptions.Profile)}
            >
                Profile
            </ActionButton>
            {/*<ActionButton*/}
            {/*    disabled={tab === AccountTabOptions.AdditionalProfiles}*/}
            {/*    onClick={() => setTab(AccountTabOptions.AdditionalProfiles)}*/}
            {/*>*/}
            {/*    Additional Profiles*/}
            {/*</ActionButton>*/}
            {/* todo */}
            {/*<ActionButton*/}
            {/*    disabled={tab === AccountTabOptions.ChangePassword}*/}
            {/*    onClick={() => setTab(AccountTabOptions.ChangePassword)}*/}
            {/*>*/}
            {/*    Change Password*/}
            {/*</ActionButton>*/}
        </div>
    );
};

interface AccountTabsProps {
    tab: AccountTabOptions;
    setTab: (tab: AccountTabOptions) => void;
}

export default AccountTabs;
