import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchTimings } from '@actions/timings';
import {
    selectTimings,
    selectTimingsArr,
    selectTimingsFetchError,
    selectTimingsIsFetching,
} from '@selectors/timings';

const useTimings = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTimings());
    }, [dispatch]);
    const isFetching = useSelector(selectTimingsIsFetching);
    const timings = useSelector(selectTimings);
    const timingsArr = useSelector(selectTimingsArr);
    const error = useSelector(selectTimingsFetchError);

    return {
        isFetching,
        timings,
        timingsArr,
        error,
    };
};

export default useTimings;
