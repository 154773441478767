import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Meal, MealPatch } from 'src/types/Meal';

export const updateMealRequest = createAction('updateMealRequest');
export const updateMealSuccess = createAction<Meal>('updateMealSuccess');
export const updateMealFailure = createAction('updateMealFailure');

export const updateMeal =
    (postbody: MealPatch) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateMealRequest());

        try {
            const { data }: UpdateMealResponse = await api.patch(`meal`, postbody);

            dispatch(updateMealSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateMealFailure, e as APIError);
        }
    };

interface UpdateMealResponse {
    data: Meal;
}
