import { ChairType } from './../../../../../lib/src/shared/enums/chairType';
import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { TableType } from 'lib/src/shared/enums/tableType';
import TablePlannerResponse from '../../../types/TablePlannerResponse';

export const postTablePlannerRequest = createAction('postTablePlannerRequest');
export const postTablePlannerSuccess =
    createAction<TablePlannerResponse>('postTablePlannerSuccess');
export const postTablePlannerFailure = createAction('postTablePlannerFailure');

export const postTablePlanner =
    (postBody: PostTablePlannerRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postTablePlannerRequest());
        try {
            const { data } = await api.post<PostTablePlannerRequest, TablePlannerResponse>(
                'tableplanner',
                postBody,
            );
            dispatch(postTablePlannerSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postTablePlannerFailure, e as APIError);
        }
    };

export interface PostTablePlannerRequest {
    tables: TableRequest[];
    walls: WallRequest[];
    doors: DoorRequest[];
    windows: WindowRequest[];
}

export interface TableRequest {
    id: number;
    type: TableType;
    name: string;
    width: number;
    height: number;
    left: number;
    top: number;
    angle: number;
    chairs: ChairRequest[];
}
export interface ChairRequest {
    id: number;
    tableID: number;
    type: ChairType;
    left: number;
    top: number;
    angle: number;
    number: number;
}

export interface WallRequest {
    id: number;
    width: number;
    height: number;
    left: number;
    top: number;
    angle: number;
}

export interface DoorRequest {
    id: number;
    width: number;
    height: number;
    left: number;
    top: number;
    angle: number;
}

export interface WindowRequest {
    id: number;
    width: number;
    height: number;
    left: number;
    top: number;
    angle: number;
}
