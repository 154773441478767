import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchProfile, patchProfile, PatchProfileRequest } from '@actions/profile';
import {
    selectProfile,
    selectProfileError,
    selectProfileIsFetching,
    selectProfileIsPatching,
    selectProfilePatchSuccess,
} from '@selectors/profile';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

const useUpdateProfile = () => {
    const dispatch = useDispatch();
    const profile = useSelector(selectProfile);
    const isFetching = useSelector(selectProfileIsFetching);
    const error = useSelector(selectProfileError);
    const isPatching = useSelector(selectProfileIsPatching);
    const patchSuccess = useSelector(selectProfilePatchSuccess);
    const [showSuccess, setShowSuccess] = useState(false);
    const prevProps = usePrevious({ patchSuccess, profile });
    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const [formData, onChange, setForm] = useForm<PatchProfileRequest>({
        firstName: profile?.firstName ?? '',
        lastName: profile?.lastName ?? '',
        addressLine1: profile?.addressLine1 ?? '',
        addressLine2: profile?.addressLine2 ?? '',
        city: profile?.city ?? '',
        postCode: profile?.postCode ?? '',
        profilePictureS3Key: profile?.profilePictureS3Key ?? '',
        partnerFirstName: profile?.partnerFirstName ?? '',
        partnerLastName: profile?.partnerLastName ?? '',
        partnerCity: profile?.partnerCity ?? '',
        partnerPostCode: profile?.partnerPostCode ?? '',
        isPartnerAddressSame: profile?.isPartnerAddressSame ?? false,
        partnerAddressLine1: profile?.partnerAddressLine1 ?? '',
        partnerAddressLine2: profile?.partnerAddressLine2 ?? '',
        partnerProfilePictureS3Key: profile?.partnerProfilePictureS3Key ?? '',
    });

    useEffect(() => {
        if (profile && profile !== prevProps?.profile) {
            setForm(profile);
        }
    }, [profile, prevProps.profile, setForm]);

    const handleSubmit = () => {
        dispatch(patchProfile(formData));
    };
    const resetForm = () => {
        if (profile) {
            setForm(profile);
        }
    };

    const handleChange = <T>(name: keyof PatchProfileRequest, value: T) => {
        if (showSuccess) setShowSuccess(false);
        onChange(name, value);
    };

    useEffect(() => {
        if (patchSuccess && !prevProps.patchSuccess) {
            setShowSuccess(true);
        }
    }, [patchSuccess, prevProps.patchSuccess]);

    return {
        profile,
        isFetching,
        formData,
        handleChange,
        error,
        isPatching,
        patchSuccess,
        handleSubmit,
        resetForm,
        showSuccess,
    };
};

export default useUpdateProfile;
