import { Switch, Route } from 'react-router-dom';

import Home from '@pages/home/Home';
import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import DefaultRedirect from './DefaultRedirect';
import WeddingRoutes from './WeddingRoutes';
import AccountRoutes from './AccountRoutes';
import ResourcesRoutes from './ResourcesRoutes';

const Routes: React.FC = () => (
    <Switch>
        <Route exact path="/">
            <Home />
        </Route>
        <SubRouter path="/auth">
            <AuthRoutes />
        </SubRouter>
        <SubRouter path="/wedding" weddingAuth>
            <WeddingRoutes />
        </SubRouter>
        <SubRouter path="/resources-categories" auth>
            <ResourcesRoutes />
        </SubRouter>
        <SubRouter path="/account" auth>
            <AccountRoutes />
        </SubRouter>
        <DefaultRedirect to="/" />
    </Switch>
);

export default Routes;
