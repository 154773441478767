import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';
import { updateMeal } from '@actions/meal';
import { selectMealsError, selectMealsIsPosting, selectMealsPostSuccess } from '@selectors/meal';
import { customFloatInputValidation } from 'lib/src/utils/generic';

export default function useUpdateMeal(
    id: number,
    name: string,
    mealType: number,
    mealTime: number,
    costPerDish: number,
    isVegetarian: boolean,
    isVegan: boolean,
    isDairyFree: boolean,
    isGlutenFree: boolean,
    isNutFree: boolean,
    isEggFree: boolean,
    notes: string | null,
) {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/wedding/catering/menu/${id}`);
    }, [history, id]);

    const [formState, formChange] = useForm({
        id,
        name,
        mealType,
        mealTime,
        costPerDish,
        isVegetarian,
        isVegan,
        isDairyFree,
        isGlutenFree,
        isNutFree,
        isEggFree,
        notes,
    });

    const handleChange = (name: keyof typeof formState, val: any) => {
        if (name === 'costPerDish' && typeof val === 'string') {
            return formChange(name, customFloatInputValidation(val, 2));
        }
        return formChange(name, val);
    };

    const handleSubmit = useCallback(() => {
        dispatch(updateMeal(formState));
    }, [dispatch, formState]);

    const isPosting = useSelector(selectMealsIsPosting);
    const error = useSelector(selectMealsError);
    const postSuccess = useSelector(selectMealsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        handleSubmit,
        formState,
        handleChange,
        closeModal,
        isPosting,
        error,
    };
}
