import { IPanel } from '@pages/tablePlanner/TablePlanner';
import Table from 'lib/src/components/table/Table';
import Description from 'lib/src/components/typography/Description';
import React, { FC } from 'react';
import useGuestPanel, { GuestsSubsection, ToolFunction } from './hooks/useGuestPanel';
import GuestPanelTable from './GuestPanelTable';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

const GuestPanel: FC<IPanel> = ({ editor }) => {
    const {
        setSubsection,
        pressUnassign,
        isActive,
        toolFunction,
        subsections,
        subsection,
        tables,
        guestsByTable,
    } = useGuestPanel(editor.canvas);

    return (
        <div className="flex-column control-panel">
            <strong>Guest Panel</strong>
            <div className="section">
                <Description>Assign and unassign guests</Description>
                <div className="guest-search">
                    <div style={{ display: 'inline-flex' }}>
                        {subsections.map((x, i) => {
                            return (
                                <div
                                    className={`guests-subsection btn ${
                                        isActive(x) ? 'active' : ''
                                    }`}
                                    key={i}
                                    onClick={() => setSubsection(x)}
                                >
                                    <div className="image" />
                                    {x}
                                </div>
                            );
                        })}
                    </div>
                    {!Object.values(guestsByTable).length ? (
                        <strong>
                            There are no{' '}
                            {subsection === GuestsSubsection.AssignedGuests
                                ? 'assigned'
                                : 'unassigned'}{' '}
                            guests.
                        </strong>
                    ) : (
                        Object.entries(guestsByTable).map(([tableID, guests]) => {
                            const table = tables.find(table => table.id === +tableID);
                            return (
                                <React.Fragment key={tableID}>
                                    {table && (
                                        <strong style={{ textAlign: 'center', marginTop: '1em' }}>
                                            {table.name}
                                        </strong>
                                    )}
                                    <Table columns={GuestPanelTable} rows={guests} />
                                </React.Fragment>
                            );
                        })
                    )}
                </div>
                <ButtonRow>
                    <ActionButton
                        disabled={toolFunction === ToolFunction.Unassign}
                        onClick={pressUnassign}
                    >
                        Unassign guest
                    </ActionButton>
                </ButtonRow>
            </div>
        </div>
    );
};

export default React.memo(GuestPanel);
