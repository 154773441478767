import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';
import {
    selectSuppliersIsPosting,
    selectSuppliersPostError,
    selectSuppliersPostSuccess,
} from '@selectors/suppliers';
import { patchUpdateSupplier } from '@actions/suppliers';

export default function useUpdateSupplier(
    id: number,
    name: string,
    customType: string | null,
    supplierTypeID: number | null,
    email: string,
    contactInformation: string,
    notes: string,
    cost: number,
    deposit: number,
    paid: number,
    depositDueDate: string,
    depositPaid: boolean,
    remainingDueDate: string,
    remainingPaid: boolean,
) {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/wedding/suppliers/${id}`);
    }, [history, id]);

    const [formState, handleChange] = useForm({
        id,
        name,
        customType,
        supplierTypeID,
        email,
        contactInformation,
        notes,
        cost,
        deposit,
        paid,
        depositDueDate,
        depositPaid,
        remainingDueDate,
        remainingPaid,
    });

    const handleSubmit = useCallback(() => {
        const postBody = {
            ...formState,
            customType: customType === '' ? null : customType,
        };
        dispatch(patchUpdateSupplier(postBody));
    }, [customType, dispatch, formState]);

    const isPosting = useSelector(selectSuppliersIsPosting);
    const error = useSelector(selectSuppliersPostError);
    const postSuccess = useSelector(selectSuppliersPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        handleSubmit,
        formState,
        handleChange,
        closeModal,
        isPosting,
        error,
    };
}
