import Form from 'lib/src/components/form/Form';
import DatePicker from 'lib/src/components/form/DatePicker';
import { WeddingDetailsFormProps } from '../../../types/Wedding';
import Description from 'lib/src/components/typography/Description';
import TextInput from 'lib/src/components/form/TextInput';

import { NUMBERS_AND_DECIMALS } from 'lib/src/constants/regex';

const WeddingDateAndBudgetForm: React.FC<WeddingDetailsFormProps> = ({
    formData,
    handleChange,
    handleSubmit,
    isPosting,
    error,
    showSuccessMessage,
}) => {
    const { date, budget } = formData;
    const today = new Date();
    return (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            submitText="Save"
            omitCancel
            buttonAlignment="center"
        >
            <DatePicker
                label="Date"
                name="date"
                value={date ? new Date(date) : null}
                minDate={today}
                onChange={handleChange}
            />
            <TextInput
                name="budget"
                label="Budget (£)"
                value={(budget ?? '').toString()}
                onChange={handleChange}
                validationRegExp={NUMBERS_AND_DECIMALS}
            />
            {showSuccessMessage && (
                <Description className="form-generic-success">Changes saved!</Description>
            )}
            {error && <Description className="form-generic-error">{error}</Description>}
        </Form>
    );
};

export default WeddingDateAndBudgetForm;
