import { useParams } from 'react-router-dom';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import useCreateQuestion from './hooks/useCreateQuestion';

const CreateQuestionModal: React.FC<CreateQuestionProps> = ({ closeModal }) => {
    const { id } = useParams<{ id: string }>();
    const { form, handleSubmit, handleChange } = useCreateQuestion(closeModal, id);
    return (
        <Modal title="Add a question to the supplier">
            <Form onSubmit={handleSubmit} isPosting={false} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        name="questionText"
                        value={form.questionText}
                        onChange={handleChange}
                        label="Question's text"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="answerValue"
                        value={form.answerValue}
                        onChange={handleChange}
                        label="Answer"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface CreateQuestionProps {
    closeModal: () => void;
}

export default CreateQuestionModal;
