import { useState } from 'react';
import Profile from '@pages/account/tabs/profile/Profile';
import AdditionalProfiles from '@pages/account/tabs/additionalProfiles/AdditionalProfiles';
import AccountTabs from '@pages/account/AccountTabs';
import ChangePassword from '@pages/account/tabs/ChangePassword';

export enum AccountTabOptions {
    Profile = 'profile',
    AdditionalProfiles = 'additionalProfiles',
    ChangePassword = 'changePassword',
}

const tabComponents = {
    [AccountTabOptions.Profile]: Profile,
    [AccountTabOptions.AdditionalProfiles]: AdditionalProfiles,
    [AccountTabOptions.ChangePassword]: ChangePassword,
};

const Account = () => {
    const [tab, setTab] = useState(AccountTabOptions.Profile);
    const SelectedTabComponent = tabComponents[tab];
    return (
        <div id="account-page" className="page-padding section">
            <div className="account-page section-inner">
                <div className="account-page-inner flex-row flex flex-full width-12 justify-between">
                    <AccountTabs tab={tab} setTab={setTab} />
                    <SelectedTabComponent />
                </div>
            </div>
        </div>
    );
};

export default Account;
