import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from 'lib/src/hooks/useForm';
import { CloseModal } from 'src/types/shared/Functions';
import { createQuestion } from '@actions/questions';
import { selectQuestionsPostSuccess } from '@selectors/questions';
import usePrevious from 'lib/src/hooks/usePrevious';

export default function useCreateQuestion(closeModal: CloseModal, id: string) {
    const initialFormData: FormState = {
        supplierID: +id,
        questionText: '',
        answerValue: '',
    };

    const dispatch = useDispatch();
    const postSuccess = useSelector(selectQuestionsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm(initialFormData);

    const handleSubmit = useCallback(() => {
        dispatch(createQuestion(form));
    }, [dispatch, form]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [prevPostSuccess, closeModal, dispatch, postSuccess]);

    return {
        form,
        handleChange,
        handleSubmit,
    };
}

interface FormState {
    supplierID: number;
    questionText: string;
    answerValue: string;
}
