import { useDispatch, useSelector } from 'react-redux';

import { PostTimingRequest } from '@actions/timings/postTiming';
import { selectTiming, selectTimingsIsPosting } from '@selectors/timings';
import { selectSuppliersArr } from '@selectors/suppliers';
import { RootState } from '@reducers/index';
import { PatchTiming } from '@actions/timings';
import useForm from 'lib/src/hooks/useForm';

const useEditTiming = (editTimingID: number) => {
    const dispatch = useDispatch();
    const timing = useSelector((state: RootState) => selectTiming(state, editTimingID));
    const [form, handleChange] = useForm<PostTimingRequest>({
        name: timing.name,
        supplierID: timing.supplierID,
        day: timing.day,
        time: timing.time,
    });

    const suppliersArr = useSelector(selectSuppliersArr);
    const isPosting = useSelector(selectTimingsIsPosting);

    const handleSubmit = () => {
        dispatch(PatchTiming({ ...form, id: timing.id }));
    };

    const supplierOptions = suppliersArr.map(supplier => ({
        label: supplier.name,
        value: supplier.id,
    }));

    return { form, handleChange, handleSubmit, isPosting, supplierOptions };
};

export default useEditTiming;
