import LinkButton from 'lib/src/components/button/LinkButton';
import Title from 'lib/src/components/typography/Title';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';

const Hero = () => {
    const { isLoggedIn } = useIsLoggedIn();
    const getStartedLink = isLoggedIn ? '/wedding' : '/auth/register';
    return (
        <div className="hero">
            <div className="hero-upper flex-column justify-center">
                <div className="hero-upper-content flex-column align-start justify-center">
                    <Title>Usp text will be here</Title>

                    <LinkButton href={getStartedLink} source="secondary">
                        Get started
                    </LinkButton>
                </div>
            </div>
        </div>
    );
};

export default Hero;
