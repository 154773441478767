import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Meal, MealTime, MealType } from 'src/types/Meal';
import {
    deleteMealFailure,
    deleteMealRequest,
    deleteMealSuccess,
    fetchAllMealsFailure,
    fetchAllMealsRequest,
    fetchAllMealsSuccess,
    fetchAllMealTimesFailure,
    fetchAllMealTimesRequest,
    fetchAllMealTimesSuccess,
    fetchMealTypesFailure,
    fetchMealTypesRequest,
    fetchMealTypesSuccess,
    fetchSingleMealFailure,
    fetchSingleMealRequest,
    fetchSingleMealSuccess,
    generateMealPDFFailure,
    generateMealPDFRequest,
    generateMealPDFSuccess,
    postAssignMealFailure,
    postAssignMealRequest,
    postAssignMealSuccess,
    postCreateMealFailure,
    postCreateMealRequest,
    postCreateMealSuccess,
    updateMealFailure,
    updateMealRequest,
    updateMealSuccess,
} from '@actions/meal';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    unassignMealFailure,
    unassignMealRequest,
    unassignMealSuccess,
} from '@actions/meal/unassignMeal';
import MealNotes from '../../types/MealNotes';
import {
    fetchMealNotesFailure,
    fetchMealNotesRequest,
    fetchMealNotesSuccess,
} from '@actions/meal/fetchMealNotes';
import {
    patchMealNotesFailure,
    patchMealNotesRequest,
    patchMealNotesSuccess,
} from '@actions/meal/patchMealNotes';
import { logout } from '@actions/auth';

interface MealState {
    isPosting: boolean;
    isDeleting: boolean;
    isUnassigning: boolean;
    postSuccess: boolean;
    deleteSuccess: boolean;
    unassignSuccess: boolean;
    isFetching: boolean;
    isPatching: boolean;
    patchSuccess: boolean;
    error: string | null;
    isGenerating: boolean;
    generateSuccess: boolean;
    generateError: string | null;
    meals: { [key: number]: Meal };
    mealTypes: MealType[];
    mealTimes: MealTime[];
    mealNotes: MealNotes | null;
}

const initialState: MealState = {
    isPosting: false,
    isDeleting: false,
    isUnassigning: false,
    postSuccess: false,
    deleteSuccess: false,
    unassignSuccess: false,
    isFetching: false,
    isPatching: false,
    patchSuccess: false,
    error: null,
    isGenerating: false,
    generateSuccess: false,
    generateError: null,
    meals: {},
    mealTypes: [],
    mealTimes: [],
    mealNotes: null,
};

export default createReducer(initialState, {
    [fetchAllMealsRequest.type]: handleFetchRequest,
    [fetchAllMealsSuccess.type]: handleFetchAllMealsSuccess,
    [fetchAllMealsFailure.type]: handleFetchFailure,
    [fetchMealTypesRequest.type]: handleFetchRequest,
    [fetchMealTypesSuccess.type]: handleFetchMealTypesSuccess,
    [fetchMealTypesFailure.type]: handleFetchFailure,
    [fetchAllMealTimesRequest.type]: handleFetchRequest,
    [fetchAllMealTimesSuccess.type]: handleFetchAllMealTimesSuccess,
    [fetchAllMealTimesFailure.type]: handleFetchFailure,
    [fetchSingleMealRequest.type]: handleFetchRequest,
    [fetchSingleMealSuccess.type]: handleFetchSingleMealSuccess,
    [fetchSingleMealFailure.type]: handleFetchFailure,
    [fetchMealNotesRequest.type]: handleFetchRequest,
    [fetchMealNotesSuccess.type]: handleFetchMealNotesSuccess,
    [fetchMealNotesFailure.type]: handleFetchFailure,
    [postAssignMealRequest.type]: handlePostRequest,
    [postAssignMealSuccess.type]: handlePostAssignMealSuccess,
    [postAssignMealFailure.type]: handlePostFailure,
    [postCreateMealRequest.type]: handlePostRequest,
    [postCreateMealSuccess.type]: handlePostCreateMealSuccess,
    [postCreateMealFailure.type]: handlePostFailure,
    [patchMealNotesRequest.type]: handlePatchRequest,
    [patchMealNotesSuccess.type]: handlePatchMealNotesSuccess,
    [patchMealNotesFailure.type]: handlePatchFailure,
    [updateMealRequest.type]: handlePostRequest,
    [updateMealSuccess.type]: handleUpdateMealSuccess,
    [updateMealFailure.type]: handlePostFailure,
    [deleteMealRequest.type]: handleDeleteRequest,
    [deleteMealFailure.type]: handleDeleteError,
    [deleteMealSuccess.type]: handleDeleteSuccess,
    [unassignMealRequest.type]: handleUnassignRequest,
    [unassignMealFailure.type]: handleUnassignError,
    [unassignMealSuccess.type]: handleUnassignMealSuccess,
    [generateMealPDFRequest.type]: handleGenerateRequest,
    [generateMealPDFSuccess.type]: handleGenerateSuccess,
    [generateMealPDFFailure.type]: handleGenerateFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: MealState) {
    state.isFetching = true;
}

function handleFetchFailure(state: MealState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchAllMealsSuccess(state: MealState, action: PayloadAction<Meal[]>) {
    state.isFetching = false;
    state.meals = convertArrToObj(action.payload);
}

function handleFetchMealTypesSuccess(state: MealState, action: PayloadAction<MealType[]>) {
    state.isFetching = false;
    state.mealTypes = action.payload;
}

function handleFetchAllMealTimesSuccess(state: MealState, action: PayloadAction<MealTime[]>) {
    state.isFetching = false;
    state.mealTimes = action.payload;
}

function handleFetchSingleMealSuccess(state: MealState, action: PayloadAction<Meal>) {
    state.meals[action.payload.id] = action.payload;
    state.isFetching = false;
}
function handleFetchMealNotesSuccess(state: MealState, action: PayloadAction<MealNotes>) {
    state.mealNotes = action.payload;
    state.isFetching = false;
}

function handlePostRequest(state: MealState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostFailure(state: MealState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postSuccess = false;
    state.error = action.payload;
}

function handlePostAssignMealSuccess(state: MealState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handlePostCreateMealSuccess(state: MealState, action: PayloadAction<Meal>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.meals[action.payload.id] = action.payload;
}

function handleUpdateMealSuccess(state: MealState, action: PayloadAction<Meal>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.meals[action.payload.id] = action.payload;
}

function handlePatchRequest(state: MealState) {
    state.isPatching = true;
    state.patchSuccess = false;
    state.error = null;
}

function handlePatchMealNotesSuccess(state: MealState, action: PayloadAction<MealNotes>) {
    state.isPatching = false;
    state.patchSuccess = true;
    state.mealNotes = action.payload;
}

function handlePatchFailure(state: MealState, action: PayloadAction<string>) {
    state.isPatching = false;
    state.patchSuccess = false;
    state.error = action.payload;
}

function handleDeleteRequest(state: MealState) {
    state.isDeleting = true;
    state.deleteSuccess = false;
}

function handleDeleteError(state: MealState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isDeleting = false;
}

function handleDeleteSuccess(state: MealState, action: PayloadAction<number>) {
    state.isDeleting = false;
    state.deleteSuccess = true;
    delete state.meals[action.payload];
}
function handleUnassignRequest(state: MealState) {
    state.isUnassigning = true;
    state.unassignSuccess = false;
}

function handleUnassignError(state: MealState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isUnassigning = false;
}

function handleUnassignMealSuccess(state: MealState) {
    state.isUnassigning = false;
    state.unassignSuccess = true;
}

function handleGenerateRequest(state: MealState) {
    state.isGenerating = true;
    state.generateSuccess = false;
    state.generateError = null;
}

function handleGenerateSuccess(state: MealState) {
    state.isGenerating = false;
    state.generateSuccess = true;
}

function handleGenerateFailure(state: MealState, action: PayloadAction<string>) {
    state.isGenerating = false;
    state.generateError = action.payload;
}
