import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import React from 'react';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';
import { ChairType, chairTypeOptions } from 'lib/src/shared/enums/chairType';

const getImage = (type: ChairType) => {
    switch (type) {
        case ChairType.HighChair:
            return '/highchair.png';
        case ChairType.ChildChair:
            return '/childChair.png';
        case ChairType.WheelChair:
            return '/wheelchair.svg';
        default:
            return '/chair.png';
    }
};

const DraggableChair: React.FC<DraggableChairProps> = ({
    setDropItem,
    type = ChairType.Standard,
    caption = chairTypeOptions.find(x => x.value === type)?.label ?? 'Chair',
}) => {
    const src = getImage(type);

    return (
        <div className="drag-container">
            <div
                className="draggable chair"
                draggable={true}
                onDragStart={() => {
                    // todo highlight table drop zones
                    setDropItem({
                        type: PlannerObjectType.Chair,
                        chairType: type,
                    });
                }}
                onDragEnd={() => {
                    setDropItem(null);
                }}
            >
                <img src={src} alt="chair" style={{ height: 45, width: 45 }} />
            </div>
            <span className="drag-caption">{caption}</span>
        </div>
    );
};

interface DraggableChairProps {
    setDropItem: (item: DropItemConfig | null) => void;
    type: ChairType;
    caption?: string;
}

export default DraggableChair;
