import React from 'react';

import { QuestionCategory } from 'src/types/Question';

import Title from 'lib/src/components/typography/Title';
import Description from 'lib/src/components/typography/Description';
import SupplierQuestion from './SupplierQuestion';

const SupplierQuestionCategory: React.FC<SupplierQuestionCategoryProps> = ({ category }) => (
    <>
        <Title tag="h3">{category.name}</Title>
        <Description>{category.description}</Description>
        {[...category.questions]
            .sort((a, b) => a.ordinal - b.ordinal)
            .map(question => (
                <SupplierQuestion key={question.id} question={question} />
            ))}
    </>
);

interface SupplierQuestionCategoryProps {
    category: QuestionCategory;
}

export default SupplierQuestionCategory;
