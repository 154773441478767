import dayjs from 'dayjs';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import Table from 'lib/src/components/table/Table';
import { Column } from 'lib/src/types/table';
import { Meal, MealType } from 'src/types/Meal';

const MealsTable: React.FC<MealsTableProps> = ({ allMeals, mealTypes, isFetching }) => {
    const columns: Column<Meal>[] = [
        {
            key: 1,
            heading: 'Name',
            getValue: (row: Meal) => `${row?.name}`,
            getSort: (a: Meal, b: Meal) => a?.name.localeCompare(b?.name),
            searchable: true,
        },
        {
            key: 2,
            heading: 'Meal type',
            getValue: (row: Meal) =>
                `${
                    mealTypes?.find((mealType: MealType) => mealType?.value === row?.mealType)
                        ?.display
                }`,
            searchable: true,
        },
        {
            key: 3,
            heading: 'Cost per dish (£)',
            getValue: (row: Meal) => `£${row?.costPerDish}`,
            searchable: true,
        },
        {
            key: 4,
            heading: 'Created',
            getValue: (row: Meal) => dayjs(row?.createdOn).format('DD-MM-YYYY'),
            getSort: (a: Meal, b: Meal) =>
                new Date(b?.createdOn).getTime() - new Date(a?.createdOn).getTime(),
            searchable: true,
        },
        {
            key: 5,
            heading: '',
            getValue: (row: Meal) => (
                <ButtonRow alignment="right">
                    <LinkButton href={`/wedding/catering/menu/${row?.id}`}>View</LinkButton>
                </ButtonRow>
            ),
        },
    ];

    return (
        <Table
            extraClasses="tableWithBorder"
            columns={columns}
            rows={Object.values(allMeals)}
            isLoading={isFetching}
        />
    );
};

interface MealsTableProps {
    allMeals: Record<number, Meal>;
    mealTypes: MealType[];
    isFetching: boolean;
}

export default MealsTable;
