import CountdownNumber from './CountdownNumber';
import { formatNumber, useHeroCountdown } from './hooks/useCountdown';

const HeroCountdown = () => {
    const { firstName, partnerFirstName, weddingDate, months, weeks, days, hours, dateOfWedding } =
        useHeroCountdown();

    return (
        <div className="hero-countdown-container">
            <div className="hero-countdown">
                <img src="/images/happy-couple.jpg" alt="temp"></img>
                <div className="width-12 flex-row justify-center">
                    <div className="hero-countdown-description">
                        <span className="hero-countdown-wedding-title">
                            {firstName && partnerFirstName && (
                                <>
                                    {firstName} & {partnerFirstName}
                                </>
                            )}
                        </span>
                        {dateOfWedding && (
                            <>
                                <span className="hero-countdown-wedding-date">
                                    {weddingDate?.format('MMMM Do YYYY') ?? ''}
                                </span>
                                <div className="countdown-container">
                                    <CountdownNumber
                                        numbers={formatNumber(months)}
                                        title="Months"
                                    />
                                    <CountdownNumber numbers={formatNumber(weeks)} title="Weeks" />
                                    <CountdownNumber numbers={formatNumber(days)} title="Days" />
                                    <CountdownNumber numbers={formatNumber(hours)} title="Hours" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroCountdown;
