import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { SupplierType } from 'src/types/SupplierType';

export const fetchSupplierTypeRequest = createAction('fetchSupplierTypeRequest');
export const fetchSupplierTypeSuccess = createAction<SupplierType>('fetchSupplierTypeSuccess');
export const fetchSupplierTypeFailure = createAction('fetchSupplierTypeFailure');

export const fetchSupplierType =
    (supplierTypeID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSupplierTypeRequest());
        try {
            const { data } = await api.get<SupplierType>(`suppliertype/${supplierTypeID}`);
            dispatch(fetchSupplierTypeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSupplierTypeFailure, e as APIError);
        }
    };
