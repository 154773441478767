import { useState } from 'react';
import fileDownload from 'js-file-download';
import { api } from 'lib/src/utils/api';

const useDownloadPDF = () => {
    const [isFetching, setIsFetching] = useState(false);

    const handleDownload = async () => {
        setIsFetching(true);
        await api
            .get<Blob>(`tableplanner/Pdf`, 'blob')
            .then(res => new Blob([res.data], { type: 'application/pdf' }))
            .then(blob => fileDownload(blob, 'TablePlanner.pdf'));
        setIsFetching(false);
    };

    const handlePrint = async () => {
        setIsFetching(true);
        await api
            .get<Blob>(`tableplanner/Pdf`, 'blob')
            .then(res => new Blob([res.data], { type: 'application/pdf' }))
            .then(blob => {
                const fileURL = URL.createObjectURL(blob);
                const printWin = window.open(fileURL);
                printWin?.print();
            });
        setIsFetching(false);
    };

    return { handleDownload, handlePrint, isFetching };
};

export default useDownloadPDF;
