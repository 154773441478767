import React from 'react';
import dayjs from 'dayjs';

import SupplierCountdown from '@components/countdown/SupplierCountdown';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Title from 'lib/src/components/typography/Title';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import config from '../../../config';
import useWeddingSuppliersCountdown from './hooks/useWeddingSuppliersCountdown';
const { S3_URL } = config;

const WeddingSuppliersCountdown: React.FC = () => {
    const {
        suppliers,
        suppliersTypes,
        dateOfWedding,
        questionsTotal,
        notAnsweredQuestions,
        questionCategories,
        suppliersSortedByQuestionsDueDate,
    } = useWeddingSuppliersCountdown();

    return !!suppliers.length && dateOfWedding && dateOfWedding > dayjs() ? (
        <div className="suppliers-countdown-area">
            <div className="suppliers-countdown-wrapper">
                <FlexWrapper justify="between" align="end" extraClasses="heading-wrapper" gap={30}>
                    <Title tag="h3">Upcoming Supplier Questions</Title>
                    <p>
                        <strong>{questionsTotal - notAnsweredQuestions.length}</strong> of{' '}
                        <strong>{questionsTotal}</strong> questions answered
                    </p>
                </FlexWrapper>
                {dateOfWedding && (
                    <div className="suppliers-countdown-items">
                        {suppliersSortedByQuestionsDueDate.slice(0, 3).map(supplier => {
                            const suppliersType = suppliersTypes.find(
                                type => type.id === supplier?.supplierTypeID,
                            );

                            const questionsRequiredDaysBefore = questionCategories.find(
                                cat => cat.supplierID === supplier.id,
                            )?.requiredDaysBefore;

                            const questionsDeadlineDate = questionsRequiredDaysBefore
                                ? dateOfWedding.subtract(questionsRequiredDaysBefore, 'days')
                                : dateOfWedding;

                            return (
                                suppliersType && (
                                    <SupplierCountdown
                                        key={suppliersType.id}
                                        src={`${S3_URL}/${suppliersType.image}`}
                                        alt={suppliersType.name}
                                        title={suppliersType.name}
                                        description={suppliersType.description}
                                        date={questionsDeadlineDate}
                                    />
                                )
                            );
                        })}
                    </div>
                )}

                <ButtonRow>
                    <LinkButton extraClasses="small" href="/wedding/suppliers">
                        View all questions
                    </LinkButton>
                </ButtonRow>
            </div>
        </div>
    ) : null;
};

export default WeddingSuppliersCountdown;
