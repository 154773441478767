import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchQuestionsForSupplier } from '@actions/questions';
import {
    selectQuestionCategories,
    selectQuestionCategoriesArr,
    selectQuestionsFetchError,
    selectQuestionsIsFetching,
} from '@selectors/questions';

const useSupplierQuestionsFetch = (supplierID: number) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectQuestionsIsFetching);
    const fetchError = useSelector(selectQuestionsFetchError);
    const questionCategories = useSelector(selectQuestionCategories);
    const questionCategoriesArr = useSelector(selectQuestionCategoriesArr);

    useEffect(() => {
        dispatch(fetchQuestionsForSupplier(supplierID));
    }, [dispatch, supplierID]);

    return { isFetching, fetchError, questionCategories, questionCategoriesArr };
};

export default useSupplierQuestionsFetch;
