import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteGuestRequest = createAction('deleteGuestRequest');
export const deleteGuestSuccess = createAction<number>('deleteGuestSuccess');
export const deleteGuestFailure = createAction('deleteGuestFailure');

export const deleteGuest =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteGuestRequest());
        try {
            await api.delete(`guest/${id}`);
            dispatch(deleteGuestSuccess(id));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, deleteGuestFailure, e as APIError);
        }
    };
