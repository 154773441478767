import { useSelector } from 'react-redux';

import useFetchGuests from '@pages/guests/guestList/hooks/useFetchGuests';
import { selectAllMealsArr } from '@selectors/meal';
import LinkButton from 'lib/src/components/button/LinkButton';
import useAllMealTimesFetch from './hooks/useAllMealTimesFetch';
import useAllMealsFetch from './meals/hooks/useAllMealsFetch';
import MenuTable from './MenuTable';
import Description from 'lib/src/components/typography/Description';
import { GuestInvite } from 'lib/src/shared/enums/guestInvite';
import { GuestInviteRSVP } from 'lib/src/shared/enums/guestInviteRSVP';
import ActionButton from 'lib/src/components/button/ActionButton';
import Title from 'lib/src/components/typography/Title';
import useGenerateMealsPDF from './hooks/useGenerateMealsPDF';
import ErrorModal from 'lib/src/components/modal/ErrorModal';

const CateringDetails = () => {
    const { guests, isFetching } = useFetchGuests();
    const { mealTimes } = useAllMealTimesFetch();
    useAllMealsFetch();
    const allMeals = useSelector(selectAllMealsArr);

    const { handleGeneratePDF, isGenerating, generateError, showError, setShowError } =
        useGenerateMealsPDF();

    const guestCount = guests.length;
    const notYetInvitedCount = guests.filter(
        guest => guest.guestInvitedTo === GuestInvite.No,
    ).length;
    const fullDayCount = guests.filter(
        ({ guestInvitedTo }) => guestInvitedTo === GuestInvite.FullWedding,
    ).length;
    const eveningOnlyCount = guests.filter(
        ({ guestInvitedTo }) => guestInvitedTo === GuestInvite.EveningOnly,
    ).length;
    const dayOnlyCount = guests.filter(
        ({ guestInvitedTo }) => guestInvitedTo === GuestInvite.DayOnly,
    ).length;

    const attendingCount = guests.filter(
        ({ guestRSVP }) => guestRSVP !== GuestInviteRSVP.No,
    ).length;
    const notAttendingCount = guests.filter(
        ({ guestRSVP }) => guestRSVP === GuestInviteRSVP.No,
    ).length;
    const dayAttendingCount = guests.filter(
        ({ guestRSVP }) =>
            guestRSVP === GuestInviteRSVP.FullWedding || guestRSVP === GuestInviteRSVP.DayOnly,
    ).length;
    const eveningAttendingCount = guests.filter(
        ({ guestRSVP }) =>
            guestRSVP === GuestInviteRSVP.FullWedding || guestRSVP === GuestInviteRSVP.EveningOnly,
    ).length;

    const awaitingResponseCount = guests.filter(
        ({ guestRSVP }) => guestRSVP === GuestInviteRSVP.AwaitingResponse,
    ).length;

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <Title>Catering</Title>
                <div className="flex-column flex flex-full width-12 justify-between">
                    <Description>
                        Please set the meal choices of each guest below. You cannot set meals for a
                        guest that do not match their dietary requirements.
                    </Description>
                    <Description>
                        Guests: {guestCount} | Full Wedding: {fullDayCount} | Evening only:{' '}
                        {eveningOnlyCount} | Day only: {dayOnlyCount} | Not yet invited:{' '}
                        {notYetInvitedCount}
                    </Description>
                    <Description>
                        RSVP Total - Yes: {attendingCount} | No: {notAttendingCount} | Day:{' '}
                        {dayAttendingCount} | Evening: {eveningAttendingCount} | Awaiting Response:{' '}
                        {awaitingResponseCount}
                    </Description>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '10px' }}>
                            <LinkButton href="/wedding/catering/menu" icon="utensils">
                                Menu
                            </LinkButton>
                        </div>
                        <ActionButton isPosting={isGenerating} onClick={handleGeneratePDF}>
                            Export to PDF
                        </ActionButton>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <MenuTable
                            guests={guests}
                            mealTimes={mealTimes}
                            allMeals={allMeals}
                            isFetching={isFetching}
                        />
                    </div>
                </div>
            </div>

            {showError && (
                <ErrorModal
                    description={generateError || undefined}
                    closeModal={() => setShowError(false)}
                />
            )}
        </div>
    );
};

export default CateringDetails;
