import {DropdownOption} from "../../types/shared/DropdownOption";

export enum GuestInviteRSVP {
    AwaitingResponse = 0,
    FullWedding = 1,
    DayOnly = 2,
    EveningOnly = 3,
    No = 4
}

export const guestInviteRSVPOptions: DropdownOption<GuestInviteRSVP>[] = [
    {
        value: GuestInviteRSVP.AwaitingResponse,
        label: 'Awaiting Response',

    },
    {
        value: GuestInviteRSVP.FullWedding,
        label: 'Full Wedding',
    },
    {
        value: GuestInviteRSVP.DayOnly,
        label: 'Day Only',
    },
    {
        value: GuestInviteRSVP.EveningOnly,
        label: 'Evening Only',
    },
    {
        value: GuestInviteRSVP.No,
        label: 'Not attending',
    }
]
