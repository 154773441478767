import ActionButton from '../button/ActionButton';
import Description from '../typography/Description';
import Modal from './Modal';

const SuccessModal: React.FC<SuccessModalProps> = ({
    closeModal,
    title = 'Success',
    description = '',
}) => {
    return (
        <Modal title={title} size="small">
            <Description>{description}</Description>
            <ActionButton source="secondary" onClick={closeModal}>OK</ActionButton>
        </Modal>
    );
};
interface SuccessModalProps {
    closeModal: () => void;
    title?: string;
    description?: string;
}

export default SuccessModal;
