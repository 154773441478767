export enum PlannerObjectType {
    TableGroup = 1,
    Table = 1,
    TableText = 2,
    Chair = 3,
    Wall = 4,
    Window = 5,
    Door = 6,
    TableImmutable = 7,
}

export const getPlannerObjectName = (type: PlannerObjectType) => {
    switch (+type) {
        case PlannerObjectType.Table:
        case PlannerObjectType.TableText:
        case PlannerObjectType.TableImmutable:
            return 'Table';
        case PlannerObjectType.Chair:
            return 'Chair';
        case PlannerObjectType.Wall:
            return 'Wall';
        case PlannerObjectType.Window:
            return 'Window';
        case PlannerObjectType.Door:
            return 'Door';
        default:
            return 'Item';
    }
}

export interface IPosition {
    scaleX?: number;
    width: number;
    scaleY?: number;
    height: number;
    left: number;
    top: number;
    angle: number;
}

export const defaultProps: Record<string, IPosition> = {
    'table': {scaleX: 1, scaleY: 1, height: 200, width: 200, left: 200, top: 200, angle: 0 },
}

export const handleScale = <T extends IPosition>(objArr: T[]) => {
    return objArr.map(obj => ({ ...obj, scaleX: obj.width, scaleY: obj.height }));
};
