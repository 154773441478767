import Description from 'lib/src/components/typography/Description';
import Tooltip from '@components/tooltip/Tooltip';
import ActionButton from 'lib/src/components/button/ActionButton';
import { onAddChair, onRemoveChair } from '@pages/tablePlanner/controls/tables/chairs';
import {
    canChangeSelectionSides,
    getMaxChairsForTable,
    updateTableState,
} from '@pages/tablePlanner/controls/tables/generic';
import React from 'react';
import { fabric } from 'fabric';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import { ChairType, TableType } from 'lib/src/shared/enums';

const SelectedTableChairControls: React.FC<SelectedTableChairControlsProps> = ({
    canvas,
    selectedObject,
    triggerRerender,
}) => {
    //@ts-ignore
    const groupObjects: fabric.Object[] = selectedObject?._objects;
    const chairs = groupObjects?.filter(obj => obj.data?.isChair);
    const chairsThatCount = chairs?.filter(chair => chair.data.chairType !== ChairType.HighChair);
    const table = groupObjects?.find(obj => obj.data?.type === PlannerObjectType.Table);
    const maxChairs = table ? getMaxChairsForTable(table) : 0;
    const isMaxChairs = chairsThatCount?.length >= maxChairs;

    const selectionSides = canChangeSelectionSides(true, selectedObject);
    return (
        <div>
            <Description>Chairs: {chairs.length}</Description>
            <Tooltip
                side="top"
                text={`The recommended maximum number of chairs for this table is ${maxChairs}. (More chairs can still be added if desired)`}
                shouldOutput={isMaxChairs}
            >
                <ActionButton
                    type="button"
                    source={isMaxChairs ? 'secondary' : 'primary'}
                    onClick={() => onAddChair(true, selectedObject, canvas, triggerRerender)}
                >
                    Add Chair
                </ActionButton>
            </Tooltip>
            <ActionButton
                type="button"
                disabled={!chairs.length}
                onClick={() => onRemoveChair(true, selectedObject, canvas, triggerRerender)}
            >
                Remove Chair
            </ActionButton>
            {Object.values(selectionSides).some(x => x) && (
                <ActionButton
                    type="button"
                    onClick={() =>
                        updateTableState(
                            {
                                tableType: !!selectionSides.switchTableOneSides
                                    ? TableType.Head
                                    : TableType.Rectangle,
                            },
                            true,
                            selectedObject,
                            canvas,
                            triggerRerender,
                        )
                    }
                >
                    {selectionSides.switchTableOneSides
                        ? 'Make table one sided'
                        : 'Make table double sided'}
                </ActionButton>
            )}
        </div>
    );
};

interface SelectedTableChairControlsProps {
    canvas: fabric.Canvas;
    selectedObject: fabric.Object;
    triggerRerender: () => void;
}

export default SelectedTableChairControls;
