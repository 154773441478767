import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAllMeals } from '@actions/meal';

const useAllMealsFetch = (): void => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllMeals());
    }, [dispatch]);
};

export default useAllMealsFetch;
