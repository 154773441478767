import useForm from 'lib/src/hooks/useForm';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMealNotes } from '@actions/meal/fetchMealNotes';
import { MealNotesPatch, patchMealNotes } from '@actions/meal/patchMealNotes';
import {
    selectMealNotes,
    selectMealsIsFetching,
    selectMealsIsPatching,
    selectMealsPatchSuccess,
} from '@selectors/meal';
import usePrevious from 'lib/src/hooks/usePrevious';

const useMealNotesForm = () => {
    const mealNotes = useSelector(selectMealNotes);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [formState, handleChange, setForm] = useForm<MealNotesPatch>({
        canapeNotes: mealNotes?.canapeNotes || '',
        eveningMealNotes: mealNotes?.eveningMealNotes || '',
    });
    const isFetching = useSelector(selectMealsIsFetching);
    const isPatching = useSelector(selectMealsIsPatching);
    const patchSuccess = useSelector(selectMealsPatchSuccess);

    const prevProps = usePrevious({ mealNotes, isFetching, patchSuccess });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchMealNotes());
    }, [dispatch]);
    useEffect(() => {
        if (!isFetching && mealNotes) {
            setForm({
                canapeNotes: mealNotes.canapeNotes,
                eveningMealNotes: mealNotes.eveningMealNotes,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, mealNotes]);
    useEffect(() => {
        if (patchSuccess && !prevProps.patchSuccess && mealNotes) {
            setForm({
                canapeNotes: mealNotes.canapeNotes,
                eveningMealNotes: mealNotes.eveningMealNotes,
            });
            setShowSuccessModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patchSuccess, prevProps.patchSuccess, mealNotes]);

    const handleSubmit = () => {
        dispatch(patchMealNotes(formState));
    };
    const hideModal = () => setShowSuccessModal(false);

    return {
        formState,
        handleChange,
        isFetching,
        isPatching,
        handleSubmit,
        showSuccessModal,
        hideModal,
    };
};

export default useMealNotesForm;
