import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { SetQuestionAnswerRequest, Question } from 'src/types/Question';

export const postSetQuestionAnswerRequest = createAction('postSetQuestionAnswerRequest');
export const postSetQuestionAnswerSuccess = createAction<Question>('postSetQuestionAnswerSuccess');
export const postSetQuestionAnswerFailure = createAction('postSetQuestionAnswerFailure');

export const postSetQuestionAnswer =
    (postBody: SetQuestionAnswerRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postSetQuestionAnswerRequest());
        try {
            const { data } = await api.post<SetQuestionAnswerRequest, Question>(
                'question/answer',
                postBody,
            );
            dispatch(postSetQuestionAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postSetQuestionAnswerFailure, e as APIError);
        }
    };
