import {
    selectQuestionsFetchingEmail,
    selectQuestionsIsSendingEmail,
} from './../../../../redux/selectors/questions';
import { sendPDFExportToSupplier } from './../../../../redux/actions/questions/sendPDFExportToSupplier';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectQuestionsGenerateError, selectQuestionsIsGenerating } from '@selectors/questions';
import { generateSupplierQuestionsPDF } from '@actions/questions';

import usePrevious from 'lib/src/hooks/usePrevious';
import { Supplier } from 'src/types/Supplier';

const useGenerateSupplierQuestionsPDF = (supplier: Supplier) => {
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();
    const isGenerating = useSelector(selectQuestionsIsGenerating);
    const generateError = useSelector(selectQuestionsGenerateError);
    const isSendingEmail = useSelector(selectQuestionsIsSendingEmail);
    const emailSent = useSelector(selectQuestionsFetchingEmail);
    const prevGenerateError = usePrevious(generateError);

    useEffect(() => {
        if (generateError && !prevGenerateError) setShowError(true);
    }, [generateError, prevGenerateError]);

    const handleGeneratePDF = () => {
        dispatch(generateSupplierQuestionsPDF(supplier.id, supplier.name));
    };

    const handleSendPdfExportToSupplier = () => {
        dispatch(sendPDFExportToSupplier(supplier.id));
    };

    return {
        handleSendPdfExportToSupplier,
        handleGeneratePDF,
        isGenerating,
        isSendingEmail,
        generateError,
        showError,
        emailSent,
        setShowError,
    };
};

export default useGenerateSupplierQuestionsPDF;
