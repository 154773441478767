import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteMealRequest = createAction('deleteMealRequest');
export const deleteMealSuccess = createAction<number>('deleteMealSuccess');
export const deleteMealFailure = createAction('deleteMealFailure');

export const deleteMeal =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteMealRequest());
        try {
            await api.delete(`meal/${id}`);
            dispatch(deleteMealSuccess(id));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, deleteMealFailure, e as APIError);
        }
    };
