import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { MealTime } from 'src/types/Meal';

export const fetchAllMealTimesRequest = createAction('fetchAllMealTimesRequest');
export const fetchAllMealTimesSuccess = createAction<MealTime[]>('fetchAllMealTimesSuccess');
export const fetchAllMealTimesFailure = createAction('fetchAllMealTimesFailure');

export const fetchAllMealTimes =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllMealTimesRequest());
        try {
            const { data } = await api.get<MealTime[]>('meal/mealtimes');
            dispatch(fetchAllMealTimesSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchAllMealTimesFailure, e as APIError);
        }
    };
