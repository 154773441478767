import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBudget } from '@actions/budget';
import { selectBudget, selectBudgetError, selectBudgetIsFetching } from '@selectors/budget';

const useFetchBudgetBreakdown = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectBudgetIsFetching);
    const budget = useSelector(selectBudget);
    const budgetError = useSelector(selectBudgetError);

    useEffect(() => {
        dispatch(fetchBudget());
    }, [dispatch]);

    return {
        isFetching,
        budget,
        budgetError,
    };
};

export default useFetchBudgetBreakdown;
