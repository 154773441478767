import tablePlannerFlowers from '@content/table-planner-flowers.png';
import tablePlanner from '@content/table-planner.png';
import LinkButton from 'lib/src/components/button/LinkButton';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Title from 'lib/src/components/typography/Title';

const TablePlanner = () => {
    return (
        <div className="bg-secondary table-planner-banner">
            <img src={tablePlannerFlowers} alt="Flowers" className="flowers" />

            <FlexWrapper extraClasses="flex-column-pt" align="center">
                <img src={tablePlanner} alt="Table Planner" className="planner-image" />

                <FlexWrapper
                    extraClasses="align-center-pt"
                    direction="column"
                    justify="center"
                    align="start"
                >
                    <Title tag="h4">Table Planner</Title>

                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra
                        sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra
                        tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin enim
                        quis nunc euismod accumsan.
                    </p>

                    <LinkButton extraClasses="mt-32">Read More</LinkButton>
                </FlexWrapper>
            </FlexWrapper>
        </div>
    );
};

export default TablePlanner;
