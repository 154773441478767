import ActionButton from 'lib/src/components/button/ActionButton';
import { WeddingDetailsTabOptions } from '../../../types/Wedding';

const WeddingDetailsTabs: React.FC<WeddingDetailsTabsProps> = ({ tab, setTab }) => {
    return (
        <div className="wedding-details-planning-tabs flex-row justify-center">
            <ActionButton
                disabled={tab === WeddingDetailsTabOptions.DateAndBudget}
                onClick={() => setTab(WeddingDetailsTabOptions.DateAndBudget)}
            >
                Date & Budget
            </ActionButton>
            <ActionButton
                disabled={tab === WeddingDetailsTabOptions.Guests}
                onClick={() => setTab(WeddingDetailsTabOptions.Guests)}
            >
                Guests
            </ActionButton>
            <ActionButton
                disabled={tab === WeddingDetailsTabOptions.CeremonyAndReceptionLocation}
                onClick={() => setTab(WeddingDetailsTabOptions.CeremonyAndReceptionLocation)}
            >
                Ceremony & reception
            </ActionButton>
            <ActionButton
                disabled={tab === WeddingDetailsTabOptions.MealTimes}
                onClick={() => setTab(WeddingDetailsTabOptions.MealTimes)}
            >
                Meal Times
            </ActionButton>
        </div>
    );
};

interface WeddingDetailsTabsProps {
    tab: WeddingDetailsTabOptions;
    setTab: (tab: WeddingDetailsTabOptions) => void;
}

export default WeddingDetailsTabs;
