import useUpdateProfile from '@pages/account/tabs/profile/hooks/useUpdateProfile';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import config from '../../../../config';
import Title from 'lib/src/components/typography/Title';
import Checkbox from 'lib/src/components/form/Checkbox';
import Description from 'lib/src/components/typography/Description';
const { API_URL, S3_URL } = config;

const Profile = () => {
    const {
        isFetching,
        formData,
        handleChange,
        resetForm,
        isPatching,
        handleSubmit,
        showSuccess,
        error,
    } = useUpdateProfile();

    return (
        <div className="width-12 page-padding">
            <Title>Profile</Title>
            <Form onSubmit={handleSubmit} isPosting={isPatching || isFetching} onCancel={resetForm}>
                <TextInput
                    name={'firstName'}
                    value={formData.firstName ?? ''}
                    onChange={handleChange}
                    label={'First name'}
                />
                <TextInput
                    name={'lastName'}
                    value={formData.lastName ?? ''}
                    onChange={handleChange}
                    label={'Last name'}
                />
                <FilePicker
                    name="profilePictureS3Key"
                    label="Profile picture"
                    apiURL={API_URL}
                    storageURL={S3_URL}
                    value={formData.profilePictureS3Key ? [formData.profilePictureS3Key] : null}
                    onChange={(name, value) => handleChange(name, value?.[0] ?? null)}
                />
                <TextInput
                    name={'addressLine1'}
                    value={formData.addressLine1 ?? ''}
                    onChange={handleChange}
                    label={'Address line 1'}
                />
                <TextInput
                    name={'addressLine2'}
                    value={formData.addressLine2 ?? ''}
                    onChange={handleChange}
                    label={'Address line 2'}
                />
                <TextInput
                    name={'city'}
                    value={formData.city ?? ''}
                    onChange={handleChange}
                    label={'City'}
                />
                <TextInput
                    name={'postCode'}
                    value={formData.postCode ?? ''}
                    onChange={handleChange}
                    label={'Post code'}
                />
                <TextInput
                    name={'partnerFirstName'}
                    value={formData.partnerFirstName ?? ''}
                    onChange={handleChange}
                    label={'Partner first name'}
                />
                <TextInput
                    name={'partnerLastName'}
                    value={formData.partnerLastName ?? ''}
                    onChange={handleChange}
                    label={'Partner last name'}
                />
                <FilePicker
                    name="partnerProfilePictureS3Key"
                    label="Partner's Profile picture"
                    apiURL={API_URL}
                    storageURL={S3_URL}
                    value={
                        formData.partnerProfilePictureS3Key
                            ? [formData.partnerProfilePictureS3Key]
                            : null
                    }
                    onChange={(name, value) => handleChange(name, value?.[0] ?? null)}
                />
                <Checkbox
                    name={'isPartnerAddressSame'}
                    value={formData.isPartnerAddressSame}
                    onChange={handleChange}
                    label={'Is partner address same?'}
                />
                {!formData.isPartnerAddressSame && (
                    <>
                        <TextInput
                            name={'partnerAddressLine1'}
                            value={formData.partnerAddressLine1 ?? ''}
                            onChange={handleChange}
                            label={'Partner address line 1'}
                        />
                        <TextInput
                            name={'partnerAddressLine2'}
                            value={formData.partnerAddressLine2 ?? ''}
                            onChange={handleChange}
                            label={'Partner address line 2'}
                        />
                        <TextInput
                            name={'partnerCity'}
                            value={formData.partnerCity ?? ''}
                            onChange={handleChange}
                            label={'Partner city'}
                        />
                    </>
                )}
                {showSuccess && (
                    <Description className="form-generic-success">Changes saved!</Description>
                )}
                {error && <Description className="form-generic-error">{error}</Description>}
            </Form>
        </div>
    );
};

export default Profile;
