import { ToolFunction } from './../guestPanel/hooks/useGuestPanel';
import { AgeCategory } from 'lib/src/shared/enums/ageCategory';
import { GuestsForChairs } from '../../../../types/TablePlannerResponse';
import { postAssignGuestToChair } from '@actions/tablePlanner/postAssignGuest';
import { ChairType } from 'lib/src/shared/enums/chairType';
import { ToolType } from 'lib/src/shared/enums/toolType';
import { degToRad, getPositionFromAngledRadius, radToDeg } from '@pages/tablePlanner/utils';
import { fabric } from 'fabric';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import Chair from '../../../../types/Chair';
import Guest from 'src/types/Guest';
import { Dispatch } from 'react';
import { IGroupOptions, ITextboxOptions } from 'fabric/fabric-impl';
import { deleteAssignGuestToChair } from '@actions/tablePlanner/deleteAssignGuest';
import { TableType } from 'lib/src/shared/enums';
import { unassignGuestToChair } from '@actions/tablePlanner/unassignGuest';
import { batch } from 'react-redux';

// 18 inches in cm
export const chairSize = 45;
export const tableTextFontSize = 16;
export const chairTableMargin = 7.5;

const generateImage = (src: string) => {
    const imgElement = document.createElement('img');
    imgElement.src = src;
    return imgElement;
};

const getChairId = (chairId: number | null) => (!chairId ? 0 : chairId);
const getChairNumber = (index: number, chairType: ChairType): string => {
    if (chairType === ChairType.HighChair) {
        return '';
    }
    return `${index}`;
};

const images = {
    chair: generateImage('/chair.png'),
    childChair: generateImage('/childChair.png'),
    chairSelected: generateImage('/chairSelected.png'),
    highChair: generateImage('/highchair.png'),
    wheelChair: generateImage('/wheelchair.svg'),
};

const chairTypeToImage: Record<ChairType, HTMLImageElement> = {
    [ChairType.Standard]: images.chair,
    [ChairType.HighChair]: images.highChair,
    [ChairType.ChildChair]: images.childChair,
    [ChairType.WheelChair]: images.wheelChair,
    [ChairType.None]: images.chair,
};

const toolTypeToChairType: Record<ToolType, ChairType> = {
    [ToolType.StandardChair]: ChairType.Standard,
    [ToolType.HighChair]: ChairType.HighChair,
    [ToolType.ChildChair]: ChairType.ChildChair,
    [ToolType.WheelChair]: ChairType.WheelChair,
    [ToolType.None]: ChairType.None,
};

const textSettings: ITextboxOptions = {
    fontSize: 20,
    originX: 'center',
    originY: 'center',
    width: chairSize,
    textAlign: 'center',
    centeredRotation: true,
    selectable: false,
};

enum TextType {
    OccupantName = 1,
    TableName = 2,
    ChairNumber = 3,
}

const chairNameTextSettings: ITextboxOptions = {
    ...textSettings,
    data: {
        textType: TextType.OccupantName,
    },
};

const createChairImage = (chairType: ChairType, settings: any = {}, data: any = {}) =>
    new fabric.Image(chairTypeToImage[chairType], {
        ...settings,
        srcFromAttribute: true,
        originX: 'center',
        originY: 'center',
        dirty: true,
        data: {
            ...data,
            isTexture: true,
        },
        selectable: true,
    });

const createChairGroup = (children: any[], settings: IGroupOptions, data: any) =>
    new fabric.Group(children, {
        ...settings,
        originX: 'center',
        originY: 'center',
        centeredRotation: true,
        dirty: true,
        selectable: true,
        subTargetCheck: true,
        perPixelTargetFind: true,
        hoverCursor: 'pointer',
        data: {
            isChair: true,
            ...data,
        },
    });

// called on a table when creating, adding or removing chairs
export const getChairs = (
    numberOfChairs: number,
    table: fabric.Rect | fabric.Circle,
    chairs: fabric.Object[] | Chair[] = [],
    guestForChairs: GuestsForChairs | null = null,
    guests?: Record<number, Guest>,
    newChairType = ChairType.Standard,
): fabric.Object[] => {
    const newChairs = Array(numberOfChairs);
    const isRound = 'radius' in table;
    const chairIDs: number[] = chairs
        .map(chair => ('id' in chair ? chair.id : chair.data.id))
        .filter(Boolean);

    const getChair = (i: number): any | Chair | null => {
        //@ts-ignore
        return ('id' in (chairs[i] ?? {}) ? chairs[i] : chairs[i]?.data) || null;
    };

    if (isRound) {
        const angle = degToRad(360) / numberOfChairs;
        for (let i = 0; i < numberOfChairs; i++) {
            const fetchedChair = getChair(i);
            const chairType = (fetchedChair?.type as ChairType) ?? newChairType;
            const chairID = getChairId(chairIDs[i]);
            const radius = (table.radius ?? 0) + chairSize * 0.5 + chairTableMargin;
            const curChairAngle = (i + 1) * angle;
            const { x, y } = getPositionFromAngledRadius(curChairAngle, radius);

            const chair = createChairImage(chairType, {
                angle: radToDeg(curChairAngle) - 90,
                data: { isChair: true },
            });

            const guestID = guestForChairs ? guestForChairs[chairID] : null;
            const text = new fabric.Textbox(getChairNumber(i + 1, chairType), {
                ...chairNameTextSettings,
                angle: -(table.group?.angle ?? 0),
                underline: !!guestID,
            });

            newChairs[i] = createChairGroup(
                [chair, text],
                {
                    width: chairSize,
                    height: chairSize,
                    left: x,
                    top: y,
                },
                {
                    tableID: table.data.id,
                    groupID: table.data.groupID,
                    id: chairID,
                    number: i + 1,
                    guestID: guestID,
                    type: chairType,
                },
            );
        }
        return newChairs;
    }
    // chairs only on the long sides
    const sides = (() => {
        if (table?.data?.tableType === TableType.Head) {
            return 1;
        }
        if (numberOfChairs === 2) {
            return 2;
        }
        return 4;
    })();
    const chairsPerSide = Math.ceil(numberOfChairs / sides);
    for (let i = 0; i < numberOfChairs; i++) {
        const side = Math.floor(i / chairsPerSide);
        const fetchedChair = getChair(i);
        const chairType = (fetchedChair?.type as ChairType) ?? newChairType;
        const chairID = getChairId(chairIDs[i]);
        const width = table.width ?? 0;
        const height = table.height ?? 0;

        const angle = (360 / sides) * side;
        const isLeftRight = [90, 270].includes(angle);
        const distance = isLeftRight ? width : height;
        const position = getPositionFromAngledRadius(
            degToRad(angle + 90),
            distance * 0.5 + chairSize * 0.5,
        );
        const increment = (() => {
            const inc =
                chairSize * (i % chairsPerSide) - chairsPerSide * chairSize * 0.5 + chairSize * 0.5;
            return [90, 0].includes(angle) ? -inc : inc;
        })();

        const chairLeft = position.x + (isLeftRight ? 0 : increment);
        const chairTop = position.y + (isLeftRight ? increment : 0);

        const guestID = guestForChairs ? guestForChairs[chairID] : null;

        const chair = createChairImage(chairType, {
            angle,
        });
        const text = new fabric.Textbox(getChairNumber(i + 1, chairType), {
            ...chairNameTextSettings,
            angle: -(table.group?.angle ?? 0),
            underline: !!guestID,
        });
        const object = new fabric.Object();

        newChairs[i] = createChairGroup(
            [chair, text, object],
            {
                width: chairSize,
                height: chairSize,
                left: chairLeft,
                top: chairTop,
            },
            {
                tableID: table.data.id,
                groupID: table.data.groupID,
                guestID: guestID,
                id: chairID,
                number: i + 1,
                type: chairType,
            },
        );
    }
    return newChairs;
};

export const onAddChair = (
    isTableGroupSelected: boolean,
    selectedObject: any,
    canvas: fabric.Canvas,
    triggerRerender: () => void,
    newChairType = ChairType.Standard,
) => {
    if (!isTableGroupSelected) return;
    const group = selectedObject as fabric.Group;
    const items = group.getObjects();
    const table = items.find(item => item.data?.type === PlannerObjectType.Table);
    if (!table) return;
    const chairs = items.filter(item => item.data?.isChair);
    const newChairs = getChairs(chairs.length + 1, table, chairs, null, undefined, newChairType);
    group.remove(...chairs);
    group.add(...newChairs);
    canvas.renderAll();
    triggerRerender();
};

export const onRemoveChair = (
    isTableGroupSelected: boolean,
    selectedObject: any,
    canvas: fabric.Canvas,
    triggerRerender: () => void,
    chairID: number | null = null,
) => {
    if (!isTableGroupSelected) return;
    const group = selectedObject as fabric.Group;
    const items = group.getObjects();
    const table = items.find(item => item.data?.type === PlannerObjectType.Table);
    if (!table) return;
    const chairs = items.filter(
        ({ data }) => data?.isChair && (!chairID || data?.chairID !== chairID),
    );
    const newChairs = getChairs(chairs.length - 1, table, chairs);
    group.remove(...chairs);
    group.add(...newChairs);
    canvas.renderAll();
    triggerRerender();
};

interface IOnClickChairProps {
    e: fabric.IEvent<Event>;
    canvas: fabric.Canvas;
}

interface IOnClickChairTypeProps extends IOnClickChairProps {
    tool: ToolType;
}

interface IOnClickChairGuestProps extends IOnClickChairProps {
    selectedGuest: Guest | null;
    isPosting: boolean;
    dispatch: Dispatch<any>;
    toolFunction: ToolFunction;
    guests: Record<number, Guest>;
}

const validateChair = (e: fabric.IEvent<Event>, canvas: fabric.Canvas): fabric.Group | null => {
    // @ts-ignore
    if (!e.target?._objects) return null;
    const targetTable = e.target as fabric.Group;
    const chairs = targetTable._objects.filter(
        (x: fabric.Object): x is fabric.Group => x.data?.isChair,
    );
    if (!chairs.length) return null;

    const coords = canvas.getPointer(e.e, true);
    const { x, y } = canvas.restorePointerVpt(coords);
    const closestChair = chairs.reduce((prev, cur) => {
        const matrix = prev.calcTransformMatrix();

        const finalValues = fabric.util.qrDecompose(matrix);
        const prevX = finalValues.translateX;
        const prevY = finalValues.translateY;

        const matrix2 = cur.calcTransformMatrix();
        const finalValues2 = fabric.util.qrDecompose(matrix2);
        const curX = finalValues2.translateX;
        const curY = finalValues2.translateY;

        const prevDistance = Math.sqrt((prevX - x) ** 2 + (prevY - y) ** 2);
        const curDistance = Math.sqrt((curX - x) ** 2 + (curY - y) ** 2);
        return prevDistance < curDistance ? prev : cur;
    });

    return closestChair;
};

export const onClickChair = ({ e, canvas, tool }: IOnClickChairTypeProps) => {
    const val = validateChair(e, canvas);
    if (!val) return;
    //@ts-ignore
    val._element = images.chair;
    if (val?.group) {
        val.group.dirty = true;
    }
    val.dirty = true;

    switch (tool) {
        case ToolType.StandardChair:
        case ToolType.WheelChair:
        case ToolType.ChildChair:
        case ToolType.HighChair:
            const chairType = toolTypeToChairType[tool];
            // @ts-ignore
            const chair = val?._objects?.find(x => x?.data?.isTexture);
            val.data.type = chairType;
            if (chair) {
                // @ts-ignore
                chair._element = chairTypeToImage[chairType];
                chair.dirty = true;
            }
            break;
        case ToolType.None:
        default:
            return;
    }
};

const findAllTables = (canvas: fabric.Canvas) =>
    canvas._objects.filter(x => x?.data?.type === PlannerObjectType.Table);

const findAllChairs = (canvas: fabric.Canvas) =>
    findAllTables(canvas).flatMap(x => {
        //@ts-ignore
        return x?._objects?.filter(x => x?.data?.isChair) ?? [];
    });

const findUserOnChair = (canvas: fabric.Canvas, guest: Guest) =>
    findAllChairs(canvas).filter(x => x?.data?.guestID === guest.id);

export const onClickChairGuests = ({
    e,
    canvas,
    selectedGuest,
    toolFunction,
    isPosting,
    dispatch,
    guests,
}: IOnClickChairGuestProps) => {
    if (isPosting) return;

    const val = validateChair(e, canvas);
    if (!val) return;

    const text = val?._objects.find(
        (x: fabric.Object | fabric.Text): x is fabric.Text =>
            'text' in x && x?.data?.textType === TextType.OccupantName,
    );
    if (!text?.group) return;

    val.dirty = true;
    const isHighChair = val.data.type === ChairType.HighChair;
    if (!selectedGuest && toolFunction === ToolFunction.Assign) return;
    if (selectedGuest) {
        const isBaby = selectedGuest.ageCategory === AgeCategory.Baby;
        if (isHighChair && !isBaby) {
            window.alert('Only babies can be placed in high chairs');
            return;
        }
        if (!isHighChair && isBaby) {
            window.alert('You must put babies in high chairs');
            return;
        }

        const chairs = findUserOnChair(canvas, selectedGuest);

        // iterates through user's old chairs
        chairs.forEach(x => {
            x.dirty = true;
            const textObj = x._objects.find(
                (x: fabric.Object) => 'text' in x && x?.data?.textType === TextType.OccupantName,
            );

            x.data.guestID = null;
            x.group.dirty = true;
            textObj.dirty = true;
            textObj.group.dirty = true;
            if (!isHighChair) {
                textObj.set('text', `${textObj.group.data.number}`);
            }
            textObj.setSelectionStyles({ underline: false }, 0, textObj.text.length);
        });
        const oldGuest = guests[val.data?.guestID];
        val.data.guestID = selectedGuest?.id ?? null;
        batch(() => {
            dispatch(postAssignGuestToChair({ guest: selectedGuest, chairID: val.data.id }));
            if (!oldGuest) return;
            dispatch(unassignGuestToChair(val.data.id, oldGuest));
        });

        text.text = `${text.group?.data.number}`;
        text.setSelectionStyles({ underline: true }, 0, text.text.length);
        canvas.renderAll();
        return;
    }
    text.group.data.guestID = null;
    if (!isHighChair) {
        text.text = `${text.group?.data.number}`;
    } else {
        text.text = ' ';
    }
    text.setSelectionStyles({ underline: false }, 0, text.text!.length);
    dispatch(deleteAssignGuestToChair(text.group?.data.id));
    canvas.renderAll();
};
