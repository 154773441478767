import { patchGuestSuccess } from '@actions/guests/patchGuest';
import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { batch } from 'react-redux';
import Guest from 'src/types/Guest';

export const postAssignGuestToChairRequest = createAction('postAssignGuestToChairRequest');
export const postAssignGuestToChairSuccess = createAction<PostAssignGuestResponse>(
    'postAssignGuestToChairSuccess',
);
export const postAssignGuestToChairFailure = createAction('postAssignGuestToChairFailure');

export const postAssignGuestToChair =
    ({ guest, chairID }: IProps) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postAssignGuestToChairRequest());
        try {
            const { data } = await api.post<IPostAssignGuestRequest, number>(
                'tableplanner/assign',
                { guestID: guest.id, chairID },
            );
            batch(() => {
                dispatch(
                    postAssignGuestToChairSuccess({
                        chairID,
                        guestID: guest.id,
                        number: data,
                    }),
                );
                dispatch(patchGuestSuccess({ ...guest, chairID: chairID }));
            });
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postAssignGuestToChairFailure, e as APIError);
        }
    };

interface IProps {
    chairID: number;
    guest: Guest;
}
interface IPostAssignGuestRequest {
    guestID: number;
    chairID: number;
}

export type PostAssignGuestResponse = IPostAssignGuestRequest & {
    number: number;
};
