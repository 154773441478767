import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';
import { Question } from 'src/types/Question';
import { deleteQuestion } from '@actions/questions';
import {
    selectQuestionsIsPosting,
    selectQuestionsPostError,
    selectQuestionsPostSuccess,
} from '@selectors/questions';

export default function useDeleteQuestion(
    question: Question,
    supplierID: string,
    closeModal: () => void,
): UseDeleteQuestionReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteQuestion(question));
    }, [dispatch, question]);

    const isPosting = useSelector(selectQuestionsIsPosting);
    const error = useSelector(selectQuestionsPostError);
    const deleteSuccess = useSelector(selectQuestionsPostSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            closeModal();
        }
    }, [deleteSuccess, prevDeleteSuccess, history, supplierID, question.categoryID, closeModal]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteQuestionReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
