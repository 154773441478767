import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { QuestionCategory } from 'src/types/Question';

export const fetchQuestionsForSupplierRequest = createAction('fetchQuestionsForSupplierRequest');
export const fetchQuestionsForSupplierSuccess = createAction<QuestionCategory[]>(
    'fetchQuestionsForSupplierSuccess',
);
export const fetchQuestionsForSupplierFailure = createAction('fetchQuestionsForSupplierFailure');

export const fetchQuestionsForSupplier =
    (supplierID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchQuestionsForSupplierRequest());
        try {
            const { data } = await api.get<QuestionCategory[]>(`question/${supplierID}`);
            dispatch(fetchQuestionsForSupplierSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchQuestionsForSupplierFailure, e as APIError);
        }
    };
