import { RootState } from '@reducers/index';
import WeddingPlanner from 'src/types/WeddingPlanner';
import Wedding from '../../types/Wedding';

export const selectWeddingIsPosting = (state: RootState): boolean => state.weddingReducer.isPosting;
export const selectWeddingIsFetching = (state: RootState): boolean =>
    state.weddingReducer.isFetching;
export const selectWeddingPostSuccess = (state: RootState): boolean =>
    state.weddingReducer.postSuccess;
export const selectWeddingError = (state: RootState): string | null => state.weddingReducer.error;

export const selectWedding = (state: RootState): Wedding | null =>
    state.weddingReducer.selectedWedding;

export const selectWeddings = (state: RootState): Wedding[] =>
    Object.values(state.weddingReducer.weddings);

export const selectWeddingPlanners = (state: RootState): WeddingPlanner[] =>
    Object.values(state.weddingReducer.planners);

export const selectWeddingIsDeleting = (state: RootState): boolean =>
    state.weddingReducer.isDeleting;
export const selectWeddingDeleteSuccess = (state: RootState): boolean =>
    state.weddingReducer.deleteSuccess;
