import Couples from '@components/couples/Couples';
import Banner from '@components/home/Banner';
import Hero from '@components/home/Hero';
import LinkButton from 'lib/src/components/button/LinkButton';
import TablePlanner from '@components/home/TablePlanner';

import loveImage from '../../_content/love-image.png';
import weddingTent from '../../_content/wedding-tent.png';
import coupleInMedowImage from '../../_content/home/couple-in-medow.jpg';
import cupcakesImage from '../../_content/home/cupcakes.jpg';
import setTableImage from '../../_content/home/set-table.jpg';
import tableNumberImage from '../../_content/home/table-number.jpg';
import TestimonialsCarousel from '@components/home/TestimonialsCarousel';
import PricingTable from '@components/home/PricingTable';
import Title from 'lib/src/components/typography/Title';

const Home: React.FC = (): JSX.Element => {
    return (
        <div id="home-page">
            <Hero />

            <Title tag="h2">
                Plan your amazing wedding with wed, brought to you by the award-winning family run
                wedding and events catering company, Karen Rhodes
            </Title>

            <Couples />

            <Banner
                title="A bit about us"
                description={
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra
                        sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra
                        tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin enim
                        quis nunc euismod accumsan.
                    </p>
                }
                image={loveImage}
            />

            <div className="flex-row image-container display-none-pt">
                <img src={tableNumberImage} alt="Table Number" />
                <img src={coupleInMedowImage} alt="Couple in Medow" />
                <img src={setTableImage} alt="Set Table" />
                <img src={cupcakesImage} alt="Cupcakes" />
            </div>

            <Banner
                title="Over 30 years experience"
                description={
                    <div className="flex-column align-start align-center-pt">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra
                            sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra
                            tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin
                            enim quis nunc euismod accumsan.
                            <br />
                            <br />
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra
                            sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra
                            tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin
                            enim quis nunc euismod accumsan.
                        </p>

                        <LinkButton extraClasses="mt-32">Read More</LinkButton>
                    </div>
                }
                image={weddingTent}
                reverse
            />

            <TablePlanner />

            <TestimonialsCarousel />

            <PricingTable />
        </div>
    );
};

export default Home;
