import { grid } from './../utils';
import {
    selectGuestsForChairs,
    selectTablePlannerError,
    selectTablePlannerIsFetching,
    selectTablePlannerIsPosting,
    selectTablePlannerObjects,
    selectTablePlannerPostSuccess,
} from '@selectors/tablePlanner';
import { fetchGuestsForChairs } from '@actions/tablePlanner/fetchGuestsForChairs';
import { useFabricJSEditor } from 'fabricjs-react';
import { Canvas } from 'fabric/fabric-impl';
import { drawGrid } from '@pages/tablePlanner/utils';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import {
    ChairRequest,
    DoorRequest,
    postTablePlanner,
    PostTablePlannerRequest,
    TableRequest,
    WallRequest,
    WindowRequest,
} from '@actions/tablePlanner/postTablePlanner';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchTablePlanner } from '@actions/tablePlanner/fetchTablePlanner';
import usePrevious from 'lib/src/hooks/usePrevious';
import { onAddWindow } from '@pages/tablePlanner/controls/controlPanel/WindowControls';
import { onAddWall } from '@pages/tablePlanner/controls/controlPanel/WallControls';
import { onAddDoor } from '@pages/tablePlanner/controls/controlPanel/DoorControls';
import { TableType } from 'lib/src/shared/enums/tableType';
import { fabric } from 'fabric';
import { v4 } from 'uuid';
import Table from '../../../types/Table';
import { getChairs, tableTextFontSize } from '@pages/tablePlanner/controls/tables/chairs';
import { ChairType } from 'lib/src/shared/enums/chairType';
import { ChairProps } from 'src/types/Chair';
import { fetchGuests } from '@actions/guests/fetchGuests';
import { selectGuests } from '@selectors/guest';
import { chairNumbersSet } from '../controls/tables/chairNumbers';
import { removeEventListener } from '../TablePlanner';

const useTablePlanner = () => {
    const dispatch = useDispatch();
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        window.addEventListener('beforeunload', e => {
            e.preventDefault();
            e.returnValue = '';
        });
        batch(() => {
            dispatch(fetchTablePlanner());
            dispatch(fetchGuestsForChairs());
            dispatch(fetchGuests());
        });
    }, [dispatch]);
    const isFetching = useSelector(selectTablePlannerIsFetching);
    const isPosting = useSelector(selectTablePlannerIsPosting);
    const postSuccess = useSelector(selectTablePlannerPostSuccess);
    const error = useSelector(selectTablePlannerError);
    const guestsForChairs = useSelector(selectGuestsForChairs);
    const guests = useSelector(selectGuests);
    const { tables, chairs, windows, walls, doors } = useSelector(selectTablePlannerObjects);
    const prevProps = usePrevious({ isFetching, isPosting, postSuccess, error });
    useEffect(() => {
        if (!isFetching && prevProps.isFetching) {
            handleRefreshCanvas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, prevProps.isFetching]);
    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            setShowSuccessModal(true);
            handleRefreshCanvas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postSuccess, prevProps.postSuccess]);
    const { editor, onReady, selectedObjects } = useFabricJSEditor();
    const handleReady = (canvas: Canvas) => {
        onReady(canvas);
        canvas.setZoom(0.5);
        drawGrid(canvas);

        const snapListener = (event: fabric.IEvent<Event>) => onMoveSnap({ event, canvas });
        const rotationListener = (event: fabric.IEvent<Event>) => onRotate({ event, canvas });
        const scaleListener = (event: fabric.IEvent<Event>) => onScale({ event, canvas });
        removeEventListener(canvas, 'object:moving', snapListener);
        canvas.on('object:moving', snapListener);

        removeEventListener(canvas, 'object:rotating', rotationListener);
        canvas.on('object:rotating', rotationListener);

        removeEventListener(canvas, 'object:scaling', scaleListener);
        canvas.on('object:scaling', scaleListener);
    };

    useEffect(() => {
        return () => {
            if (editor) {
                const { canvas } = editor;
                removeEventListener(canvas, 'object:moving', 'snapListener');
                removeEventListener(canvas, 'object:rotating', 'rotationListener');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRefreshCanvas = () => {
        if (!editor) return;
        editor.canvas.clear();
        drawGrid(editor.canvas);
        Object.values(windows ?? []).forEach(window => onAddWindow(editor.canvas, window));
        Object.values(walls ?? []).forEach(wall => onAddWall(editor.canvas, wall));
        Object.values(doors ?? []).forEach(door => onAddDoor(editor.canvas, door));
        const chairsArray: ChairProps[] = Object.values(chairs ?? []).map(x => {
            return {
                ...x,
                data: {
                    id: x.id,
                    guestID: guestsForChairs[x.id],
                    type: x.type,
                    isChair: true,
                },
            };
        });
        Object.values(tables ?? []).forEach(table => {
            const group = createTableGroup(table);
            const tableObj =
                table.type === TableType.Rectangle || table.type === TableType.Head
                    ? addRectangleTableToGroup(table, group)
                    : addRoundTableToGroup(table, group);
            const curTableChairs: any[] = chairsArray
                .filter(chair => chair.tableID === table.id)
                .map(x => x);

            chairNumbersSet(
                table.id,
                curTableChairs.map(x => ({ number: x.id, chairType: x.data.type })),
            );

            const chairs = getChairs(
                curTableChairs.length,
                tableObj,
                curTableChairs,
                guestsForChairs,
                guests,
            );
            group.add(...chairs);
            editor.canvas.add(group);
        });
    };

    const handleSubmit = () => {
        if (!editor?.canvas) return;
        // @ts-ignore
        const objects: fabric.Object[] = editor?.canvas._objects;

        const tables = objects
            .filter(grp => grp.data?.type === PlannerObjectType.TableGroup)
            .map(grp => {
                let chairNumber = 1;
                // @ts-ignore ts-todo
                const table = grp._objects.find(
                    (obj: fabric.Object) =>
                        obj.data?.type === PlannerObjectType.Table ||
                        obj.data?.type === PlannerObjectType.TableImmutable,
                ) as fabric.Object;
                // @ts-ignore ts-todo
                const text = grp._objects.find(
                    (obj: fabric.Object) => obj.data?.type === PlannerObjectType.TableText,
                ) as fabric.Text;

                const chairs: ChairRequest[] = [];

                // Adding chairs
                // @ts-ignore ts-todo
                for (const chairGrp of grp?._objects ?? []) {
                    const data = chairGrp?.data ?? {};
                    if (data?.isChair) {
                        const [chair] = chairGrp._objects;
                        const { translateX, translateY, angle } = fabric.util.qrDecompose(
                            chair.calcTransformMatrix(),
                        );
                        const isHighChair = data.type === ChairType.HighChair;
                        const curChairNum = isHighChair ? -1 : chairNumber++;

                        chairs.push({
                            // ID is being manually set with chair numbering so we override to 0 here to prevent db conflicts
                            id: data.id || 0,
                            tableID: isNumber(data.tableID) ? data.tableID : 0,
                            angle,
                            top: translateY,
                            left: translateX,
                            number: curChairNum,
                            type: data.type ?? ChairType.Standard,
                        });
                    }
                }

                const tableReq: TableRequest = {
                    id: isNumber(table?.data?.id) ? table?.data?.id : 0,
                    type: table?.data?.tableType ?? 0,
                    name: text.text ?? '',
                    top: grp.top ?? 0,
                    left: grp.left ?? 0,
                    width: (table?.group?.scaleX ?? 1) * (table?.width ?? 300),
                    height: (table?.group?.scaleY ?? 1) * (table?.height ?? 200),
                    angle: grp.angle ?? 0,
                    chairs,
                };
                return tableReq;
            });

        const walls = objects
            .filter(o => o.data?.type === PlannerObjectType.Wall)
            .map((o: fabric.Object) => {
                const wallReq: WallRequest = {
                    id: isNumber(o.data.id) ? o.data.id : 0,
                    top: o.top ?? 0,
                    left: o.left ?? 0,
                    width: (o?.scaleX ?? 1) * (o?.width ?? 200),
                    height: (o?.scaleY ?? 1) * (o?.height ?? 200),
                    angle: o.angle ?? 0,
                };
                return wallReq;
            });

        const ifTrueFlip = (b: boolean | undefined, n: number): number => (b ? -n : n);
        const doors = objects
            .filter(o => o.data?.type === PlannerObjectType.Door)
            .map(o => {
                const doorReq: DoorRequest = {
                    id: isNumber(o.data.id) ? o.data.id : 0,
                    top: o.top ?? 0,
                    left: o.left ?? 0,
                    width: ifTrueFlip(o?.flipX, o?.scaleX ?? 1) * (o.width ?? 0),
                    height: ifTrueFlip(o?.flipY, o?.scaleY ?? 1) * (o.height ?? 0),
                    angle: o.angle ?? 0,
                };
                return doorReq;
            });
        const windows = objects
            .filter(o => o.data?.type === PlannerObjectType.Window)
            .map(o => {
                const windowReq: WindowRequest = {
                    id: isNumber(o.data.id) ? o.data.id : 0,
                    top: o.top ?? 0,
                    left: o.left ?? 0,
                    width: (o?.scaleX ?? 1) * (o.width ?? 0),
                    height: (o?.scaleY ?? 1) * (o.height ?? 0),
                    angle: o.angle ?? 0,
                };
                return windowReq;
            });

        const postbody: PostTablePlannerRequest = {
            tables,
            walls,
            doors,
            windows,
        };
        dispatch(postTablePlanner(postbody));
    };

    const hideModal = () => setShowSuccessModal(false);

    return {
        editor,
        onReady,
        selectedObjects,
        handleReady,
        handleSubmit,
        showSuccessModal,
        hideModal,
    };
};

const createTableGroup = (table: Table): fabric.Group => {
    const tableGroupWidth = table.width;
    const tableGroupHeight = table.height;
    const shouldCenterOrigin = [TableType.Cake, TableType.Round].includes(table.type);
    const group = new fabric.Group([], {
        selectable: true,
        evented: true,
        centeredRotation: true,
        width: tableGroupWidth * 1.5,
        height: tableGroupHeight * 1.5,
        originX: shouldCenterOrigin ? 'center' : 'left',
        originY: shouldCenterOrigin ? 'center' : 'top',
        objectCaching: false,
        left: table.left,
        top: table.top,
        subTargetCheck: true,
        perPixelTargetFind: true,
        snapAngle: 45,
        snapThreshold: 10,
        angle: table.angle,
        data: { id: v4(), type: PlannerObjectType.TableGroup },
    });

    group.setControlsVisibility({
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mt: false,
        mb: false,
        mr: false,
        ml: false,
    });
    return group;
};

const addRectangleTableToGroup = (table: Table, group: fabric.Group): fabric.Rect => {
    const tableLeft = -(table.width / 2);
    const tableTop = -(table.height / 2);
    const tableObj = new fabric.Rect({
        left: tableLeft,
        top: tableTop,
        width: table.width,
        height: table.height,
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 1,
        selectable: false,
        dirty: false,
        data: {
            groupID: group.data.id,
            id: table.id,
            type: PlannerObjectType.Table,
            tableType: table.type,
        },
    });
    group.add(tableObj);
    const text = new fabric.Textbox(table.name ?? 'Table', {
        fontSize: tableTextFontSize,
        width: table.width,
        height: table.height,
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        centeredRotation: true,
        stroke: '#000000',
        angle: -table.angle,
        selectable: false,
        dirty: false,
        data: {
            tableID: table.id,
            id: v4(),
            type: PlannerObjectType.TableText,
        },
    });
    group.add(text);
    return tableObj;
};

const addRoundTableToGroup = (table: Table, group: fabric.Group): fabric.Circle => {
    const radius = table.width / 2;
    const tableObj = new fabric.Circle({
        radius: table.width / 2,
        fill: '#fff',
        centeredRotation: true,
        originX: 'center',
        originY: 'center',
        stroke: '#000',
        strokeWidth: 1,
        dirty: false,
        selectable: false,
        data: {
            groupID: group.data.ID,
            id: table.id,
            type: PlannerObjectType.Table,
            tableType: table.type,
            name: table.name,
        },
    });
    group.add(tableObj);
    const text = new fabric.Textbox(table.name ?? 'Table', {
        fontSize: tableTextFontSize,
        originX: 'center',
        originY: 'center',
        centeredRotation: true,
        width: radius * 2,
        height: radius * 2,
        textAlign: 'center',
        stroke: '#000000',
        angle: -table.angle,
        selectable: false,
        dirty: false,
        data: {
            tableID: table.id,
            id: v4(),
            type: PlannerObjectType.TableText,
        },
    });
    group.add(text);
    return tableObj;
};

const isNumber = (value: any): value is number => typeof value === 'number';

interface IOnListener {
    event: fabric.IEvent<Event>;
    canvas: fabric.Canvas;
}

const onMoveSnap = ({ event }: IOnListener) => {
    const { e } = event;
    const { target } = event;
    if (!target?.left || !target?.top || (e as MouseEvent).altKey) return;
    if (
        Math.round((target.left / grid) * 4) % 4 === 0 &&
        Math.round((target.top / grid) * 4) % 4 === 0
    ) {
        target
            .set({
                left: Math.round(target.left / grid) * grid,
                top: Math.round(target.top / grid) * grid,
            })
            .setCoords();
    }
};
const isTextType = (str: string) => ['textbox', 'text'].includes(str);

const onScale = ({ event }: IOnListener) => {
    if (event?.target === undefined) return;
    const obj: any = event.target;

    if (obj.data?.type === PlannerObjectType.Wall) {
        const [text] = obj.getObjects('text') as fabric.Text[];
        const [line] = obj.getObjects('line') as fabric.Line[];
        if (text.group) {
            text.group.dirty = true;
        }

        if (text)
            text.set({
                scaleX: 1,
                scaleY: 1,
                flipX: obj.flipX,
                angle: obj.flipX ? obj.angle : -obj.angle,
                dirty: true,
                text: `${Math.round(obj.width * obj.scaleX * 10) / 10}cm`,
            });

        if (line) {
            line.set({
                width: (obj?.scaleX ?? 1) * (obj?.width ?? 0),
                left: -((obj?.width ?? 0) * 0.5),
            });
        }
        if (obj) {
            obj.set({
                width: (obj?.scaleX ?? 0) * (obj?.width ?? 0),
                height: (obj?.scaleY ?? 0) * (obj?.height ?? 0),
                scaleX: 1,
                scaleY: 1,
                objectCaching: false,
                dirty: true,
            });
        }
        return;
    }

    if (obj.type === 'polyline' && obj.name === 'door') {
        if (!obj.strokeWidthUnscaled && obj.strokeWidth) {
            obj.strokeWidthUnscaled = obj.strokeWidth;
        }
        if (obj.strokeWidthUnscaled) {
            obj.strokeWidth = obj.strokeWidthUnscaled / Math.max(obj.scaleX, obj.scaleY);
        }
        return;
    }
};

const onRotate = ({ event }: IOnListener) => {
    if (event?.target === undefined) return;
    const { angle } = event.target;

    const invertRotation = (o: fabric.Object) => {
        o.angle = -(angle ?? 0);
        o.dirty = true;
    };

    //@ts-ignore
    (event.target?._objects ?? []).forEach(x => {
        if (isTextType(x?.type ?? '')) {
            //@ts-ignore
            event.target.dirty = true;
            invertRotation(x);
        }

        if (x.data?.isChair) {
            x._objects.forEach((y: fabric.Object) => {
                if (isTextType(y?.type ?? '')) {
                    y.dirty = true;
                    invertRotation(y);
                }
            });
        }
    });
};

export default useTablePlanner;
