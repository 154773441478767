import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Title from 'lib/src/components/typography/Title';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import config from '../../../config';
import ContentBlock from '@components/layout/createHeader/contentBlock/ContentBlock';
import ContentRow from '@components/layout/createHeader/contentBlock/ContentRow';
import ContentItem from '@components/layout/createHeader/contentBlock/ContentItem';
import { Supplier } from 'src/types/Supplier';
import { RootState } from '@reducers/index';
import { selectSupplierType } from '@selectors/supplierTypes';
import { fetchSupplierType } from '@actions/supplierTypes';
import useSupplierQuestionsFetch from './hooks/useSupplierQuestionsFetch';
import { isEmpty } from 'lib/src/utils/generic';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import SupplierQuestionCategory from './SupplierQuestionCategory';
import { selectQuestionsPostError, selectQuestionsPostSuccess } from '@selectors/questions';
import usePrevious from 'lib/src/hooks/usePrevious';
import ErrorModal from 'lib/src/components/modal/ErrorModal';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import CreateQuestionModal from './CreateQuestionModal';
import ActionButton from 'lib/src/components/button/ActionButton';
import useGenerateSupplierQuestionsPDF from './hooks/useGenerateSupplierQuestionsPDF';
import { EmailSentType } from '@reducers/questions';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';

const { S3_URL } = config;

const SupplierPage: React.FC<SupplierProps> = ({ supplier }) => {
    const dispatch = useDispatch();
    const [showQuestionPostErrorModal, setShowQuestionPostErrorModal] = useState(false);
    const [showCreateQuestionModal, setShowCreateQuestionModal] = useState(false);
    const [successMessages, setSuccessMessages] = useState<string[]>([]);
    const questionPostSuccess = useSelector(selectQuestionsPostSuccess);
    const questionPostError = useSelector(selectQuestionsPostError);

    const {
        handleGeneratePDF,
        handleSendPdfExportToSupplier,
        isGenerating,
        isSendingEmail,
        generateError,
        emailSent,
        showError: showGenerateError,
        setShowError: setShowGenerateError,
    } = useGenerateSupplierQuestionsPDF(supplier);

    const prevProps = usePrevious({ questionPostSuccess, questionPostError });

    const supplierType = useSelector((state: RootState) =>
        selectSupplierType(state, supplier.supplierTypeID ? supplier.supplierTypeID : null),
    );

    const {
        id,
        name,
        email,
        supplierTypeName,
        contactInformation,
        notes,
        customType,
        cost,
        deposit,
        paid,
        depositDueDate,
        depositPaid,
        remainingDueDate,
        remainingPaid,
        createdOn,
    } = supplier;

    useEffect(() => {
        if (supplier.supplierTypeID) dispatch(fetchSupplierType(supplier.supplierTypeID));
    }, [dispatch, supplier.supplierTypeID]);

    useEffect(() => {
        if (questionPostError && !prevProps.questionPostError) {
            setShowQuestionPostErrorModal(true);
        }
    }, [questionPostError, prevProps.questionPostError]);

    useEffect(() => {
        if (questionPostSuccess && !prevProps.questionPostSuccess) {
            setSuccessMessages(state => [...state, `success-${Math.random()}`]);
            setTimeout(() => {
                setSuccessMessages(state => [...state].slice(1));
            }, 3000);
        }
    }, [questionPostSuccess, prevProps.questionPostSuccess, successMessages]);

    const { isFetching, fetchError, questionCategoriesArr } = useSupplierQuestionsFetch(
        supplier?.id,
    );

    return (
        <>
            <div className="page-padding section supplier">
                <div className="section-inner">
                    <div className="supplier-header">
                        <div>
                            <Title>{name}</Title>
                            {supplierType?.description && (
                                <p
                                    style={{ marginBottom: '10px' }}
                                >{`${supplierType.description}`}</p>
                            )}

                            {supplierType?.description && supplierType?.image && (
                                <img
                                    className="supplier-img"
                                    style={{ width: '500px' }}
                                    src={`${S3_URL}/${supplierType?.image}`}
                                    alt="Supplier"
                                />
                            )}
                        </div>

                        <div className="supplier-header-grid">
                            <ContentBlock>
                                <ContentRow>
                                    <ContentItem label="Name">
                                        <p>{`${name}`}</p>
                                    </ContentItem>
                                    <ContentItem label="Supplier type">
                                        <p>{`${
                                            supplierTypeName ? supplierTypeName : customType
                                        }`}</p>
                                    </ContentItem>
                                    <ContentItem label="Created on">
                                        <p>
                                            {createdOn
                                                ? `${new Date(createdOn)?.toLocaleDateString(
                                                      'en-UK',
                                                  )}`
                                                : ''}
                                        </p>
                                    </ContentItem>
                                </ContentRow>

                                <ContentRow>
                                    <ContentItem label="Contact information">
                                        <p>{`${contactInformation ? contactInformation : '-'}`}</p>
                                    </ContentItem>
                                    <ContentItem label="Notes">
                                        <p>{`${notes ? notes : '-'}`}</p>
                                    </ContentItem>
                                    <ContentItem label="Email">
                                        <p>{email}</p>
                                    </ContentItem>
                                </ContentRow>
                            </ContentBlock>
                            <ContentBlock>
                                <ContentRow>
                                    <ContentItem label="Cost">
                                        <p>{`${cost ? '£' + cost : '-'}`}</p>
                                    </ContentItem>
                                    <ContentItem label="Deposit">
                                        <p>{`${deposit ? '£' + deposit : '-'}`}</p>
                                    </ContentItem>
                                    <ContentItem label="Paid">
                                        <p>{`${paid ? '£' + paid : '£0'}`}</p>
                                    </ContentItem>
                                </ContentRow>

                                <ContentRow>
                                    <ContentItem label="Deposit Due Date">
                                        <p>{`${
                                            depositDueDate
                                                ? dayjs(depositDueDate).format('MMM D, YYYY')
                                                : '-'
                                        }`}</p>
                                    </ContentItem>
                                    <ContentItem label="Deposit Paid">
                                        <p>{`${depositPaid ? 'Yes' : 'No'}`}</p>
                                    </ContentItem>
                                    <ContentItem label="Remaining Due Date">
                                        <p>{`${
                                            remainingDueDate
                                                ? dayjs(remainingDueDate).format('MMM D, YYYY')
                                                : '-'
                                        }`}</p>
                                    </ContentItem>
                                </ContentRow>

                                <ContentRow>
                                    <ContentItem label="Remaining Paid">
                                        <p>{`${remainingPaid ? 'Yes' : 'No'}`}</p>
                                    </ContentItem>
                                    <ContentItem label="">
                                        <p></p>
                                    </ContentItem>
                                    <ContentItem label="">
                                        <p></p>
                                    </ContentItem>
                                </ContentRow>
                            </ContentBlock>
                        </div>
                    </div>

                    <div className="supplier-header-divider">
                        <ButtonRow alignment="left" extraStyle={{ marginBottom: '20px' }}>
                            <LinkButton source="secondary" href={`/wedding/suppliers/${id}/edit`}>
                                Edit
                            </LinkButton>
                            <LinkButton source="negative" href={`/wedding/suppliers/${id}/delete`}>
                                Delete
                            </LinkButton>
                        </ButtonRow>
                    </div>

                    <CreateHeader>
                        <Title tag="h2">Questions</Title>
                        <FlexWrapper gap={10} extraClasses="flex-column-lm">
                            <ActionButton
                                isPosting={isSendingEmail}
                                disabled={emailSent === EmailSentType.Success}
                                onClick={handleSendPdfExportToSupplier}
                            >
                                {emailSent === EmailSentType.NotSent
                                    ? 'Send PDF to supplier'
                                    : emailSent === EmailSentType.Success
                                    ? 'Email Sent'
                                    : 'Email Failed To Send'}
                            </ActionButton>
                            <ActionButton isPosting={isGenerating} onClick={handleGeneratePDF}>
                                Export to PDF
                            </ActionButton>
                            <ActionButton
                                source="secondary"
                                icon="plus"
                                onClick={() => setShowCreateQuestionModal(true)}
                            >
                                Add a question to the supplier
                            </ActionButton>
                        </FlexWrapper>
                    </CreateHeader>
                    <DataCheck
                        isEmpty={isEmpty(questionCategoriesArr)}
                        isLoading={isFetching}
                        error={fetchError}
                        noDataMessage="There are no questions to display"
                    >
                        {[...questionCategoriesArr]
                            .sort((a, b) => a.ordinal - b.ordinal)
                            .map(category => (
                                <SupplierQuestionCategory key={category?.id} category={category} />
                            ))}
                    </DataCheck>

                    {showQuestionPostErrorModal && (
                        <ErrorModal closeModal={() => setShowQuestionPostErrorModal(false)} />
                    )}

                    {showCreateQuestionModal && (
                        <CreateQuestionModal closeModal={() => setShowCreateQuestionModal(false)} />
                    )}

                    {showGenerateError && (
                        <ErrorModal
                            description={generateError || undefined}
                            closeModal={() => setShowGenerateError(false)}
                        />
                    )}

                    <div className="bottom-messages">
                        {successMessages.map(key => (
                            <p key={key} className="success">
                                <span className="bg"></span>
                                <span>Question updated successfully!</span>
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

interface SupplierProps {
    supplier: Supplier;
}

export default SupplierPage;
