import Guest, { AssignedGuest } from './../../../../../types/Guest';
import { ChairType } from 'lib/src/shared/enums/chairType';
import {
    selectTablePlannerIsPosting,
    selectToolFunction,
} from './../../../../../redux/selectors/tablePlanner';
import { setSelectedGuest, setToolFunction } from '@actions/tablePlanner/guests';
import {
    selectGuestsForChairs,
    selectSelectedGuest,
    selectTablePlannerObjects,
} from '@selectors/tablePlanner';
import { fetchGuests } from '@actions/guests/fetchGuests';
import { selectGuestIsFetching, selectGuests } from '@selectors/guest';
import { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { removeEventListener } from '@pages/tablePlanner/TablePlanner';
import { onClickChairGuests } from '../../tables/chairs';

export enum GuestsSubsection {
    UnassignedGuests = 'Unassigned Guests',
    AssignedGuests = 'Assigned Guests',
}

export enum ToolFunction {
    Assign = 1,
    Unassign = 2,
}

export const useGuestPanel = (canvas: fabric.Canvas) => {
    const dispatch = useDispatch();
    const [subsection, setSubsection] = useState<GuestsSubsection>(
        GuestsSubsection.UnassignedGuests,
    );

    const isActive = (s: GuestsSubsection) => subsection === s;
    const subsections = Object.values(GuestsSubsection);

    useEffect(() => {
        batch(() => {
            dispatch(fetchGuests());
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const guests = useSelector(selectGuests);
    const isFetching = useSelector(selectGuestIsFetching);
    const isPosting = useSelector(selectTablePlannerIsPosting);
    const guestsForChairs = useSelector(selectGuestsForChairs);
    const [assignedGuests, unassignedGuests] = useMemo(() => {
        const assigned = Object.values(guestsForChairs ?? [])
            .filter(x => !!x)
            .map(x => guests[x ?? 0] as AssignedGuest);
        const unassigned = Object.keys(guests ?? [])
            .filter(x => !assigned.find(a => a.id === +x))
            .map(x => guests[+x ?? 0]);
        return [assigned, unassigned];
    }, [guests, guestsForChairs]);

    const selectedGuest = useSelector(selectSelectedGuest);
    const toolFunction = useSelector(selectToolFunction);

    useEffect(() => {
        const chairListenerGuests = (e: fabric.IEvent<Event>) =>
            onClickChairGuests({
                e,
                canvas,
                selectedGuest,
                toolFunction,
                dispatch,
                isPosting,
                guests,
            });
        removeEventListener(canvas, 'mouse:down', chairListenerGuests);
        canvas.on('mouse:down', chairListenerGuests);

        return () => removeEventListener(canvas, 'mouse:down', chairListenerGuests);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGuest, toolFunction]);

    const pressUnassign = () => {
        batch(() => {
            dispatch(setToolFunction(ToolFunction.Unassign));
            dispatch(setSelectedGuest(null));
        });
    };

    const isAssignedMenu = subsection === GuestsSubsection.AssignedGuests;

    const tablePlannerObjects = useSelector(selectTablePlannerObjects);
    const { tables, chairs } = tablePlannerObjects;
    const guestsByTable = useMemo(() => {
        return (isAssignedMenu ? assignedGuests : unassignedGuests).reduce(
            (
                acc: Record<number | string, (Guest | AssignedGuest)[]>,
                guest: Guest | AssignedGuest,
            ) => {
                const chair = chairs.find(chair => chair.id === guest.chairID);
                const table = tables.find(table => table.id === chair?.tableID);
                const tableID = table?.id ?? '';
                if (!acc[tableID]) {
                    acc[tableID] = [];
                }

                if (chair) {
                    const guestOutput: AssignedGuest = {
                        ...guest,
                        chairNumber: chair?.number ?? null,
                        chairID: chair?.id ?? null,
                        tableID: tableID || null,
                        chairType: chair.type ?? ChairType.Standard,
                    };
                    acc[tableID].push(guestOutput);
                    return acc;
                }
                acc[tableID].push(guest);
                return acc;
            },
            {},
        );
    }, [assignedGuests, chairs, isAssignedMenu, tables, unassignedGuests]);

    return {
        subsection,
        setSubsection,
        pressUnassign,
        isActive,
        isFetching,
        toolFunction,
        subsections,
        tables,
        guestsByTable,
    };
};

export default useGuestPanel;
