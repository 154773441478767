import {DropdownOption} from "../../types/shared/DropdownOption";

export enum AgeCategory {
    Adult = 1,
    Child = 2,
    Baby = 3,
}

export const ageOptions: DropdownOption<AgeCategory>[] = [
    { value: AgeCategory.Adult, label: 'Adult' },
    { value: AgeCategory.Child, label: 'Child' },
    { value: AgeCategory.Baby, label: 'Baby' },
]
