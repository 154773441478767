import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { fetchSingleResource } from '@actions/resources';
import { RootState } from '@reducers/index';
import { getResourcesError, getResourcesIsFetching, getSingleResource } from '@selectors/resources';
import useFetchResources from './hooks/useFetchResources';
import BackButton from '@components/generic/backButton/BackButton';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import ResourcePage from './ResourcePage';

const ResourceContainer = () => {
    const history = useHistory();
    const { resourceID } = useParams<ParamTypes>();
    const dispatch = useDispatch();
    useFetchResources();

    useEffect(() => {
        dispatch(fetchSingleResource(+resourceID));
    }, [dispatch, resourceID]);

    const resource = useSelector((state: RootState) => getSingleResource(state, +resourceID));
    const isFetching = useSelector(getResourcesIsFetching);
    const error = useSelector(getResourcesError);

    return (
        <div>
            <div className="hero-back-button">
                <div className="hero-back-button-inner">
                    <BackButton onClick={() => history.goBack()} />
                </div>
            </div>
            <DataCheck isEmpty={!resource} isLoading={isFetching} error={error}>
                <ResourcePage resource={resource} />
            </DataCheck>
        </div>
    );
};

interface ParamTypes {
    id: string;
    resourceID: string;
}

export default ResourceContainer;
