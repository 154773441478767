import dayjs from 'dayjs';
import { Resource } from 'src/types/Resource';
import config from '../../config';

const { S3_URL } = config;

const ResourceCard: React.FC<ResourceProps> = ({ resource, resourceCategoryId }) => {
    return (
        <a
            href={`/resources-categories/${resourceCategoryId}/resource/${resource.id}`}
            key={resource.id}
            className="card-grid-card"
        >
            <div className="card-grid-card-img">
                <span className="card-grid-card-img-tag">
                    {resource.tags.length ? resource.tags[0].name : resource.category.name}
                </span>
                <img src={`${S3_URL}/${resource.image}`} alt="Article" />
            </div>

            <div className="card-grid-card-content">
                <span className="card-date">
                    {dayjs(resource.publishDate).format('MMMM D, YYYY')}
                </span>
                {/* <p className="card-description">{resource.name}</p>
                                  <p className="card-description">{resource.description}</p> */}
                <p className="card-description">
                    {resource.body?.length > 100
                        ? resource.body.substring(0, 100) + '...'
                        : resource.body}
                </p>
            </div>
        </a>
    );
};

interface ResourceProps {
    resourceCategoryId: number;
    resource: Resource;
}

export default ResourceCard;
