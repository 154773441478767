import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useHistory } from 'react-router-dom';
import { CloseModal } from 'src/types/shared/Functions';
import { selectMealsError, selectMealsIsPosting, selectMealsPostSuccess } from '@selectors/meal';
import { postCreateMeal } from '@actions/meal';
import { customFloatInputValidation } from 'lib/src/utils/generic';
import { MealPost } from '../../../../types/Meal';
import { MealTimeEnum } from 'lib/src/shared/enums/meal';

const initialFormData: MealPost = {
    name: '',
    mealType: null,
    costPerDish: 0,
    isVegetarian: false,
    isVegan: false,
    isDairyFree: false,
    isGlutenFree: false,
    isNutFree: false,
    isEggFree: false,
    notes: null,
    mealTime: MealTimeEnum.WeddingBreakfast,
};

export default function useCreateMeal(closeModal: CloseModal) {
    const dispatch = useDispatch();
    const history = useHistory();

    const isPosting = useSelector(selectMealsIsPosting);
    const postSuccess = useSelector(selectMealsPostSuccess);
    const error = useSelector(selectMealsError);

    const [form, formChange] = useForm(initialFormData);

    const handleChange = (name: keyof typeof form, val: any) => {
        if (name === 'costPerDish' && typeof val === 'string') {
            return formChange(name, customFloatInputValidation(val, 2));
        }
        return formChange(name, val);
    };

    const handleSubmit = useCallback(() => {
        dispatch(postCreateMeal(form));
        history.push('/wedding/catering/menu');
    }, [dispatch, form, history]);

    const prevPostSuccess = usePrevious(postSuccess);
    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [prevPostSuccess, postSuccess, closeModal]);

    return {
        isPosting,
        error,
        form,
        handleChange,
        handleSubmit,
    };
}
