import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPlanners } from '@actions/weddings/fetchPlanners';
import { selectWeddingIsFetching, selectWeddingPlanners } from '@selectors/wedding';

const useAdditionalProfiles = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectWeddingIsFetching);
    const profiles = useSelector(selectWeddingPlanners);
    useEffect(() => {
        dispatch(fetchPlanners());
    }, [dispatch]);
    return { isFetching, profiles };
};

export default useAdditionalProfiles;
