import { GuestInvite } from 'lib/src/shared/enums/guestInvite';
import Description from 'lib/src/components/typography/Description';

const AttendanceDescription: React.FC<AttendanceDescriptionProps> = ({ guestInvite }) => {
    let text = 'Not Yet invited';
    switch (guestInvite) {
        case GuestInvite.EveningOnly:
            text = 'Evening only';
            break;
        case GuestInvite.FullWedding:
            text = 'Full wedding';
            break;
        case GuestInvite.DayOnly:
            text = 'Day only';
            break;
    }
    return <Description>{text}</Description>;
};

interface AttendanceDescriptionProps {
    guestInvite: GuestInvite;
}

export default AttendanceDescription;
