import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import WeddingDetails from '@pages/wedding/weddingDetails/WeddingDetails';
import GuestList from '@pages/guests/guestList/GuestList';
import TablePlanner from '@pages/tablePlanner/TablePlanner';
import CateringDetails from '@pages/catering/CateringDetails';
import MenuPage from '@pages/catering/meals/MenuPage';
import MealContainer from '@pages/catering/meal/MealContainer';
import SuppliersContainer from '@pages/suppliers/suppliers/SuppliersContainer';
import SupplierContainer from '@pages/suppliers/supplier/SupplierContainer';
import Timings from '@pages/timings/Timings';
import BudgetBreakdown from '@pages/budget-breakdown/BudgetBreakdown';

const WeddingRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <WeddingDetails />
            </Route>
            <Route exact path={`${path}/guests`}>
                <GuestList />
            </Route>
            <Route exact path={`${path}/table-planner`}>
                <TablePlanner />
            </Route>
            <Route exact path={`${path}/catering`}>
                <CateringDetails />
            </Route>
            <Route exact path={`${path}/catering/menu`}>
                <MenuPage />
            </Route>
            <Route exact path={`${path}/catering/menu/:id`}>
                <MealContainer />
            </Route>
            <Route exact path={`${path}/catering/menu/:id/:action`}>
                <MealContainer />
            </Route>
            <Route exact path={`${path}/suppliers`}>
                <SuppliersContainer />
            </Route>
            <Route exact path={`${path}/suppliers/:id`}>
                <SupplierContainer />
            </Route>
            <Route exact path={`${path}/suppliers/:id/:action`}>
                <SupplierContainer />
            </Route>
            <Route exact path={`${path}/timings`}>
                <Timings />
            </Route>
            <Route exact path={`${path}/budget-breakdown`}>
                <BudgetBreakdown />
            </Route>
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default WeddingRoutes;
