import { fabric } from 'fabric';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import { TableType } from 'lib/src/shared/enums/tableType';
import { getChairs } from './chairs';

export const updateTableState = (
    props: { tableType: TableType },
    isTableGroupSelected: boolean,
    selectedObject: fabric.Object,
    canvas: fabric.Canvas,
    triggerRerender: () => void,
) => {
    if (!isTableGroupSelected) return;
    const group = selectedObject as fabric.Group;
    const items = group.getObjects();
    const table = items.find(item => item.data?.type === PlannerObjectType.Table);
    if (!table) return;
    table.data = { ...table.data, ...props };
    const chairs = items.filter(item => item.data?.isChair);
    const newChairs = getChairs(chairs.length, table, chairs);
    group.remove(...chairs);
    group.add(...newChairs);
    canvas.renderAll();
    triggerRerender();
};

export const isSelectionOccupiable = (
    isTableGroupSelected: boolean,
    selectedObject: fabric.Object,
): boolean => {
    if (!isTableGroupSelected) return false;
    const group = selectedObject as fabric.Group;
    const items = group.getObjects();
    const table = items.find(item => item.data?.type === PlannerObjectType.Table);
    return !!table;
};

export const canChangeSelectionSides = (
    isTableGroupSelected: boolean,
    selectedObject: fabric.Object | null,
) => {
    if (!isTableGroupSelected)
        return {
            switchTableOneSides: false,
            switchTableTwoSides: false,
        };
    const group = selectedObject as fabric.Group;
    const items = group.getObjects();
    const switchTableOneSides = items.find(item => TableType.Rectangle === item.data?.tableType);
    const switchTableTwoSides = items.find(item => TableType.Head === item.data?.tableType);
    return {
        switchTableOneSides: switchTableOneSides,
        switchTableTwoSides: switchTableTwoSides,
    };
};

export const getMaxChairsForTable = (selectedTable: fabric.Object): number => {
    const isRound = selectedTable.data.tableType === TableType.Round;
    if (!selectedTable.width) return 0;
    if (isRound) {
        if (selectedTable.width <= 100) return 2;
        if (selectedTable.width < 135) return 4;
        if (selectedTable.width >= 135 && selectedTable.width < 152) return 6;
        // 8, 10 & 12 come from client
        if (selectedTable.width >= 152 && selectedTable.width < 168) return 8;
        if (selectedTable.width >= 168 && selectedTable.width < 183) return 10;
        if (selectedTable.width >= 183 && selectedTable.width <= 201) return 12;
        return 12 + Math.floor((selectedTable.width - 201) / 20);
    } else {
        const width = selectedTable.width ?? 183;
        const length = selectedTable.height ?? 92;
        // client provided guidance
        if (width === 183 && length <= 76) return 6;
        if (width === 183 && length === 92) return 8;
        const longerSide = Math.max(width, length);
        const seatsOnLongerSides = Math.ceil(longerSide / 30);
        // const shorterSide = Math.min(width, length);
        // const seatsOnShorterSide = Math.ceil(shorterSide / 30);
        if (selectedTable.data.isOneSided) return Math.floor(seatsOnLongerSides / 2);
        return seatsOnLongerSides;
    }
};
