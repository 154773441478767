import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import useFetchResourcesCategories from './hooks/useFetchResourcesCategories';
import ResourcesCategories from './ResourcesCategories';

const ResourcesCategoriesContainer = () => {
    const { resourcesCategoriesArr, isFetching, error } = useFetchResourcesCategories();

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <DataCheck
                    isEmpty={!resourcesCategoriesArr.length}
                    isLoading={isFetching}
                    error={error}
                >
                    <ResourcesCategories resourcesCategoriesArr={resourcesCategoriesArr} />
                </DataCheck>
            </div>
        </div>
    );
};

export default ResourcesCategoriesContainer;
