import { Supplier } from 'src/types/Supplier';
import { Column } from 'lib/src/types/table';
import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Question } from 'src/types/Question';
import SupplierQuestion from '../supplier/SupplierQuestion';
import { useSelector } from 'react-redux';
import { selectQuestionCategories } from '@selectors/questions';
import { selectSuppliers } from '@selectors/suppliers';
import Title from 'lib/src/components/typography/Title';
import React, { Fragment } from 'react';

const SuppliersTable: React.FC<SuppliersProps> = ({
    suppliersArr,
    pressingQuestions,
    daysTillWedding,
    isFetching,
}) => {
    const categories = useSelector(selectQuestionCategories);
    const suppliers = useSelector(selectSuppliers);
    const pressingQuestionsBySupplierID = pressingQuestions.reduce<Record<number, Question[]>>(
        (acc, question) => {
            const supplierID = categories[question.categoryID].supplierID;
            const supplier = suppliers[supplierID];
            if (!supplier) return acc;
            if (!acc[supplierID]) acc[supplierID] = [];
            acc[supplierID].push(question);
            return acc;
        },
        {},
    );

    function sortByDueDate(a: Question, b: Question) {
        const aWithoutRequiredDaysBefore = a.requiredDaysBefore
            ? a.requiredDaysBefore
            : daysTillWedding;
        const bWithoutRequiredDaysBefore = b.requiredDaysBefore
            ? b.requiredDaysBefore
            : daysTillWedding;
        return bWithoutRequiredDaysBefore - aWithoutRequiredDaysBefore;
    }
    function getDueInText(question: Question) {
        const daysUntilDue = !question.requiredDaysBefore
            ? daysTillWedding
            : daysTillWedding - question.requiredDaysBefore;
        if (daysUntilDue < 0 || daysTillWedding < 0) return 'Overdue';
        return daysUntilDue === 0 ? 'Due today' : `Due in ${daysUntilDue} days`;
    }

    return (
        <>
            {!!pressingQuestions.length && (
                <>
                    <Title tag="h2">Questions due soon</Title>
                    {Object.entries(pressingQuestionsBySupplierID).map(
                        ([supplierID, questions]) => (
                            <Fragment key={supplierID}>
                                <Title tag="h3">{suppliers[+supplierID]?.name}</Title>
                                {questions.sort(sortByDueDate).map(question => (
                                    <div key={question.id} className="form-group-wrap">
                                        <SupplierQuestion question={question} />
                                        <div className="form-warning">{getDueInText(question)}</div>
                                    </div>
                                ))}
                            </Fragment>
                        ),
                    )}
                </>
            )}
            <div style={{ marginTop: '30px' }}>
                <Table
                    extraClasses="tableWithBorder"
                    columns={columns}
                    rows={suppliersArr}
                    isLoading={isFetching}
                />
            </div>
        </>
    );
};

const columns: Column<Supplier>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: (row: Supplier) => row.name,
        getSort: (a: Supplier, b: Supplier) => a.name.localeCompare(b.name),
        searchable: true,
    },
    {
        key: 2,
        heading: 'Supplier type',
        getValue: (row: Supplier) => (row.supplierTypeName ? row.supplierTypeName : row.customType),
        searchable: true,
    },
    {
        key: 3,
        heading: 'Email',
        getValue: (row: Supplier) => (row.email ? row.email : '-'),
        searchable: true,
    },
    {
        key: 5,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/wedding/suppliers/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

interface SuppliersProps {
    suppliersArr: Supplier[];
    pressingQuestions: Question[];
    daysTillWedding: number;
    isFetching: boolean;
}

export default SuppliersTable;
