import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchResourcesByCategory, fetchSingleResourcesCategory } from '@actions/resources';
import {
    getAllResourcesByCategory,
    getResourcesError,
    getResourcesIsFetching,
    getSingleResourcesCategory,
} from '@selectors/resources';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import ResourcesCategory from './ResourcesCategory';
import BackButton from '@components/generic/backButton/BackButton';
import { RootState } from '@reducers/index';

const ResourcesCategoryContainer = () => {
    const { id } = useParams<ParamTypes>();
    const dispatch = useDispatch();

    const resourcesByCategory = useSelector(getAllResourcesByCategory);
    const resourcesByCategoryArr = Object.values(resourcesByCategory);
    const resourcesCategory = useSelector((state: RootState) =>
        getSingleResourcesCategory(state, +id),
    );
    const isFetching = useSelector(getResourcesIsFetching);
    const error = useSelector(getResourcesError);

    useEffect(() => {
        dispatch(fetchResourcesByCategory([+id]));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchSingleResourcesCategory(+id));
    }, [dispatch, id]);

    return (
        <div className="page-padding">
            <div className="section">
                <div className="section-inner" style={{ marginBottom: '20px' }}>
                    <BackButton href="/resources-categories" />
                </div>
            </div>
            <DataCheck
                isEmpty={!resourcesByCategoryArr.length}
                isLoading={isFetching}
                error={error}
            >
                <ResourcesCategory
                    resourcesCategory={resourcesCategory}
                    resourcesByCategoryArr={resourcesByCategoryArr}
                    resourceCategoryId={+id}
                    isFetching={isFetching}
                    error={error}
                />
            </DataCheck>
        </div>
    );
};

interface ParamTypes {
    id: string;
}

export default ResourcesCategoryContainer;
