import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Resource } from 'src/types/Resource';

export const fetchResourcesBySearchTermRequest = createAction('fetchResourcesBySearchTermRequest');
export const fetchResourcesBySearchTermSuccess = createAction<Resource[]>(
    'fetchResourcesBySearchTermSuccess',
);
export const fetchResourcesBySearchTermFailure = createAction('fetchResourcesBySearchTermFailure');

export const fetchResourcesBySearchTerm =
    (term: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchResourcesBySearchTermRequest());
        try {
            const { data } = await api.get<Resource[]>(`resource/search?term=${term}`);

            dispatch(fetchResourcesBySearchTermSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchResourcesBySearchTermFailure, e as APIError);
        }
    };
