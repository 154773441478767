import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteAssignGuestToChairRequest = createAction('deleteAssignGuestToChairRequest');
export const deleteAssignGuestToChairSuccess = createAction<IDeleteAssignGuestResponse>(
    'deleteAssignGuestToChairSuccess',
);
export const deleteAssignGuestToChairFailure = createAction('deleteAssignGuestToChairFailure');

export const deleteAssignGuestToChair =
    (chairID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteAssignGuestToChairRequest());
        try {
            await api.delete(`tableplanner/assign?chair=${chairID}`);
            dispatch(deleteAssignGuestToChairSuccess({ chairID }));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, deleteAssignGuestToChairFailure, e as APIError);
        }
    };

export interface IDeleteAssignGuestResponse {
    chairID: number;
}
