import Description from 'lib/src/components/typography/Description';
import DraggableChair from '@pages/tablePlanner/controls/controlPanel/components/DraggableChair';
import { ChairType } from 'lib/src/shared/enums/chairType';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';

const ChairControls: React.FC<ChairControlsProps> = ({ setDropItem }) => {
    return (
        <div className="table-planner-menu-section">
            <Description>Chairs</Description>
            <div className="table-planner-menu-grid">
                <DraggableChair setDropItem={setDropItem} type={ChairType.Standard} />
                <DraggableChair setDropItem={setDropItem} type={ChairType.ChildChair} />
                <DraggableChair setDropItem={setDropItem} type={ChairType.HighChair} />
                <DraggableChair setDropItem={setDropItem} type={ChairType.WheelChair} />
            </div>
        </div>
    );
};

interface ChairControlsProps {
    setDropItem: (item: DropItemConfig | null) => void;
}

export default ChairControls;
