import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { CeremonyLocationType } from 'lib/src/shared/enums/ceremonyLocationType';
import { CeremonyType } from 'lib/src/shared/enums/ceremonyType';
import { ReceptionLocationType } from 'lib/src/shared/enums/receptionLocationType';
import Wedding from '../../../types/Wedding';

export const postUpdateWeddingRequest = createAction('postUpdateWeddingRequest');
export const postUpdateWeddingSuccess = createAction<Wedding>('postUpdateWeddingSuccess');
export const postUpdateWeddingFailure = createAction('postUpdateWeddingFailure');

export const postUpdateWedding =
    (postBody: UpdateWeddingRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postUpdateWeddingRequest());
        try {
            const { data } = await api.patch<UpdateWeddingRequest, Wedding>('wedding', postBody);
            dispatch(postUpdateWeddingSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postUpdateWeddingFailure, e as APIError);
        }
    };

export interface UpdateWeddingRequest {
    date: string | Date | null;
    budget: number | null;
    minDayGuests: number | null;
    maxDayGuests: number | null;
    minEveningGuests: number | null;
    maxEveningGuests: number | null;
    ceremonyLocation: string | null;
    ceremonyLocationType: CeremonyLocationType | null;
    ceremonyType: CeremonyType | null;
    receptionLocation: string | null;
    receptionLocationType: ReceptionLocationType | null;
    canapeTime: string | null;
    weddingBreakfastTime: string | null;
    eveningMealTime: string | null;
}
