import LinkButton from 'lib/src/components/button/LinkButton';
import Title from 'lib/src/components/typography/Title';

const PricingTable = () => {
    return (
        <div className="pricing-table flex-column align-center">
            <Title tag="h3">Our Pricing</Title>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam voluptatum
                quibusdam
            </p>

            <table>
                <tr>
                    <th>Service</th>
                    <th>Pricing Title 1</th>
                    <th>Pricing Title 2</th>
                    <th>Pricing Title 3</th>
                </tr>
                <tr>
                    <td>Service name</td>
                    <td>
                        <i className="fas fa-check"></i>
                    </td>
                    <td>
                        <i className="fas fa-check"></i>
                    </td>
                    <td>
                        <i className="fas fa-check"></i>
                    </td>
                </tr>
                <tr>
                    <td>Service name</td>
                    <td>
                        <i className="fas fa-check"></i>
                    </td>
                    <td>
                        <i className="fas fa-check"></i>
                    </td>
                    <td>
                        <i className="fas fa-check"></i>
                    </td>
                </tr>
            </table>

            <LinkButton>Find out more</LinkButton>
        </div>
    );
};

export default PricingTable;
