import React, { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Title from 'lib/src/components/typography/Title';
import Description from 'lib/src/components/typography/Description';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthError, selectAuthIsPosting, selectAuthPostSuccess } from '@selectors/auth';
import useForm from 'lib/src/hooks/useForm';
import LinkButton from 'lib/src/components/button/LinkButton';
import { postResetPassword } from '@actions/auth/postResetPassword';

const ResetPassword: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const [formData, handleChange] = useForm({ password: '', confirmPassword: '' });
    const { password, confirmPassword } = formData;
    const validateConfirmPassword = useCallback(
        (confirmPassword: string) => {
            if (formData.password !== confirmPassword) {
                return 'Passwords do not match.';
            }
        },
        [formData.password],
    );
    const handleSubmit = () => {
        if (code) {
            dispatch(postResetPassword({ code, password }));
        }
    };
    const postSuccess = useSelector(selectAuthPostSuccess);
    const isPosting = useSelector(selectAuthIsPosting);
    const error = useSelector(selectAuthError);
    const code = queryParams.get('code');

    return (
        <div id="reset-password-page" className="page-padding section">
            <div className="section-inner">
                <Title>Reset Password</Title>
                {postSuccess ? (
                    <>
                        <Description>You may now log in with your new password.</Description>
                        <LinkButton href="/auth/login">Log in</LinkButton>
                    </>
                ) : code ? (
                    <Form onSubmit={handleSubmit} isPosting={isPosting}>
                        <TextInput
                            name={'password'}
                            value={password}
                            onChange={handleChange}
                            label={'New password'}
                            type={'password'}
                            required
                        />
                        <TextInput
                            name={'confirmPassword'}
                            value={confirmPassword}
                            onChange={handleChange}
                            label={'Confirm password'}
                            type={'password'}
                            customValidate={validateConfirmPassword}
                            required
                        />
                        {error && (
                            <Description className="form-generic-error">
                                Something went wrong - has your link expired? Please try again.
                            </Description>
                        )}
                    </Form>
                ) : (
                    <Redirect to={'/auth/login'} />
                )}
            </div>
        </div>
    );
};

export default ResetPassword;
