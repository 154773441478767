import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import { HandleSubmit } from 'src/types/shared/Functions';
import { RegisterRequest } from '@actions/auth';
import React from 'react';
import PhoneInput from 'lib/src/components/form/PhoneInput';

const RegisterForm: React.FC<RegisterFormProps> = ({
    formState: {
        firstName,
        lastName,
        partnerFirstName,
        partnerLastName,
        email,
        password,
        confirmPassword,
        phoneNumber,
    },
    handleChange,
    handleSubmit,
    isPosting,
}): JSX.Element => (
    <Form onSubmit={handleSubmit} isPosting={isPosting} omitButtons className="register-form">
        <FormRow>
            <TextInput
                name="firstName"
                value={firstName}
                label="First name"
                onChange={handleChange}
                required
            />
            <TextInput
                name="lastName"
                value={lastName}
                label="Last name"
                onChange={handleChange}
                required
            />
        </FormRow>
        <FormRow>
            <TextInput
                name="partnerFirstName"
                value={partnerFirstName}
                label="Partner's first name"
                onChange={handleChange}
                required
            />
            <TextInput
                name="partnerLastName"
                value={partnerLastName}
                label="Partner's last name"
                onChange={handleChange}
                required
            />
        </FormRow>
        <FormRow>
            <TextInput
                name="email"
                type="email"
                value={email}
                label="Email address"
                onChange={handleChange}
                required
            />
        </FormRow>
        <FormRow>
            <TextInput
                name="password"
                value={password}
                label="Password"
                onChange={handleChange}
                type="password"
                required
            />
        </FormRow>
        <FormRow>
            <TextInput
                name="confirmPassword"
                value={confirmPassword}
                label="Confirm Password"
                onChange={handleChange}
                type="password"
                required
            />
        </FormRow>
        <FormRow>
            <PhoneInput
                name="phoneNumber"
                value={phoneNumber ?? ''}
                label="Phone number (optional)"
                onChange={handleChange}
            />
        </FormRow>
        <ButtonRow alignment="right">
            <ActionButton isPosting={isPosting}>Register</ActionButton>
        </ButtonRow>
    </Form>
);

interface RegisterFormProps {
    formState: RegisterRequest;
    handleChange: <T>(name: 'email' | 'password' | 'firstName' | 'lastName', value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
}

export default RegisterForm;
