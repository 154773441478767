import { WeddingDetailsTabOptions } from '../../../types/Wedding';
import WeddingDateAndBudgetForm from './WeddingDateAndBudgetForm';
import WeddingGuestsForm from './WeddingGuestsForm';
import WeddingCeremonyAndReceptionForm from './WeddingCeremonyAndReceptionForm';
import WeddingMealTimesForm from './WeddingMealTimesForm';
import WeddingDetailsTabs from './WeddingDetailsTabs';
import { useState } from 'react';
import useUpdateWedding from './hooks/useUpdateWedding';
import Title from 'lib/src/components/typography/Title';

const tabComponents = {
    [WeddingDetailsTabOptions.DateAndBudget]: WeddingDateAndBudgetForm,
    [WeddingDetailsTabOptions.Guests]: WeddingGuestsForm,
    [WeddingDetailsTabOptions.CeremonyAndReceptionLocation]: WeddingCeremonyAndReceptionForm,
    [WeddingDetailsTabOptions.MealTimes]: WeddingMealTimesForm,
};

const WeddingStartPlanning = () => {
    const { wedding, isPosting, formData, handleChange, handleSubmit, error, showSuccessMessage } =
        useUpdateWedding();
    const [tab, setTab] = useState(WeddingDetailsTabOptions.DateAndBudget);
    const SelectedTabComponent = tabComponents[tab];

    return (
        <div className="wedding-details-planning">
            <div className="wedding-details-planning-header flex-column align-center">
                <Title tag="h3">Start Planning</Title>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.
                </p>
            </div>

            <WeddingDetailsTabs tab={tab} setTab={setTab} />
            <div className="wedding-details-planning-content">
                <SelectedTabComponent
                    wedding={wedding}
                    isPosting={isPosting}
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    error={error}
                    showSuccessMessage={showSuccessMessage}
                />
            </div>
        </div>
    );
};

export default WeddingStartPlanning;
