import { isSelectionOccupiable } from '@pages/tablePlanner/controls/tables/generic';
import Description from 'lib/src/components/typography/Description';
import { chairSize, chairTableMargin, getChairs } from '@pages/tablePlanner/controls/tables/chairs';
import TextInput from 'lib/src/components/form/TextInput';
import React from 'react';
import { fabric } from 'fabric';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import SelectedTableChairControls from '@pages/tablePlanner/controls/SelectedTableChairControls';
import useForm from 'lib/src/hooks/useForm';
import FormRow from 'lib/src/components/form/FormRow';
import { customFloatInputValidation } from 'lib/src/utils/generic';
import { useSelector } from 'react-redux';
import { selectGuestsForChairs } from '@selectors/tablePlanner';
import { selectGuests } from '@selectors/guest';

const SelectedTableControls: React.FC<SelectedTableControlsProps> = ({
    tableGroup,
    canvas,
    triggerRerender,
}) => {
    //@ts-ignore
    const groupObjects: fabric.Object[] = tableGroup?._objects;
    let table = groupObjects?.find(obj => obj.data?.type === PlannerObjectType.Table) as
        | undefined
        | fabric.Circle
        | fabric.Rect;
    const [form, onChange] = useForm({
        diameter: table?.height ?? 0,
        width: table?.width ?? 0,
        length: table?.height ?? 0,
    });
    const guestsForChairs = useSelector(selectGuestsForChairs);
    const guests = useSelector(selectGuests);

    if (!table) return null;
    const chairs = groupObjects?.filter(obj => obj.data?.isChair);

    const isCircle = 'radius' in table;

    const onSubmit = () => {
        if (isCircle) {
            table = table as fabric.Circle;
            const diameter = +form.diameter;
            const tableGroupWidth = diameter + chairSize * 2 + chairTableMargin;
            const tableGroupHeight = diameter + chairSize * 2 + chairTableMargin;
            const tableRadius = diameter / 2;
            tableGroup.set({ height: tableGroupHeight, width: tableGroupWidth });
            table.set({
                radius: tableRadius,
                left: -tableRadius,
                top: -tableRadius,
            });
        } else {
            table = table as fabric.Rect;
            const width = +form.width;
            const length = +form.length;
            const tableGroupWidth = width + chairSize * 2 + chairTableMargin;
            const tableGroupHeight = length + chairSize * 2 + chairTableMargin;
            tableGroup.set({ height: tableGroupHeight, width: tableGroupWidth });

            table.set({
                width,
                height: length,
                left: -width / 2,
                top: -length / 2,
            });
        }
        const newChairs = getChairs(chairs.length, table, chairs, guestsForChairs, guests);
        tableGroup.remove(...chairs);
        tableGroup.add(...newChairs);
        tableGroup.dirty = true;
        table.dirty = true;
        canvas.renderAll();
        triggerRerender();
    };
    const customChange = (name: keyof typeof form, str: string) =>
        onChange(name, customFloatInputValidation(str));

    const text = groupObjects?.find(obj => obj.data?.type === PlannerObjectType.TableText) as
        | fabric.Textbox
        | undefined;

    const isOccupiable = isSelectionOccupiable(true, tableGroup);

    return (
        <div style={{ marginBottom: '1em', marginTop: '1em' }}>
            <FormRow>
                {isCircle && (
                    <TextInput
                        name={'diameter'}
                        value={isCircle ? form.diameter.toString() : ''}
                        label={'Area'}
                        disabled={!isCircle}
                        onChange={customChange}
                    />
                )}
                {!isCircle && (
                    <>
                        <TextInput
                            name={'width'}
                            label={'Width'}
                            value={isCircle ? '' : form.width.toString()}
                            disabled={isCircle}
                            onChange={customChange}
                        />
                        <TextInput
                            name={'length'}
                            label={'Length'}
                            value={isCircle ? '' : form.length.toString()}
                            disabled={isCircle}
                            onChange={customChange}
                        />{' '}
                    </>
                )}
            </FormRow>
            <FormRow>
                <button className="button primary" onClick={onSubmit}>
                    Resize
                </button>
            </FormRow>
            {text && (
                <div>
                    <Description>Table name:</Description>
                    <TextInput
                        name="tableName"
                        value={text.text ?? ''}
                        onChange={(_, value) => {
                            text.set({ text: value });
                            canvas.renderAll();
                            triggerRerender();
                        }}
                    />
                </div>
            )}
            {/* todo table resize controls*/}
            {isOccupiable && (
                <SelectedTableChairControls
                    selectedObject={tableGroup}
                    canvas={canvas}
                    triggerRerender={triggerRerender}
                />
            )}
        </div>
    );
};

interface SelectedTableControlsProps {
    canvas: fabric.Canvas;
    tableGroup: fabric.Group;
    triggerRerender: () => void;
}

export default SelectedTableControls;
