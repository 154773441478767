import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import useUpdateGuest from '@pages/guests/guestList/hooks/useUpdateGuest';
import TextInput from 'lib/src/components/form/TextInput';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import config from '../../../config';
import { useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';
import Checkbox from 'lib/src/components/form/Checkbox';
import Radio from 'lib/src/components/form/Radio';
import { ageOptions } from 'lib/src/shared/enums/ageCategory';
import Guest from '../../../types/Guest';
import TextArea from 'lib/src/components/form/TextArea';
import { attendanceOptions } from 'lib/src/shared/enums/attendanceResponse';
import FormRow from 'lib/src/components/form/FormRow';
import Description from 'lib/src/components/typography/Description';
import { guestInviteOptions } from 'lib/src/shared/enums/guestInvite';
import { guestInviteRSVPOptions } from 'lib/src/shared/enums/guestInviteRSVP';
import PhoneInput from 'lib/src/components/form/PhoneInput';
import NumberInput from 'lib/src/components/form/NumberInput';
const { API_URL, S3_URL } = config;
const UpdateGuestModal: React.FC<UpdateGuestModalProps> = ({ hideModal, guest }) => {
    const { formData, postSuccess, isPosting, handleChange, handleSubmit } = useUpdateGuest(guest);
    const prevProps = usePrevious({ postSuccess });
    useEffect(() => {
        // handle success
        if (postSuccess && !prevProps.postSuccess) {
            hideModal();
        }
    }, [postSuccess, prevProps.postSuccess, hideModal]);
    const {
        firstName,
        middleName,
        lastName,
        nickname,
        email,
        address,
        phoneNumber,
        notes,
        specialRelations,
        pictureS3Key,
        guestInvitedTo,
        guestRSVP,
        cateringAttendanceCanape,
        cateringAttendanceWeddingBreakfast,
        cateringAttendanceEveningMeal,
        ageCategory,
        isVegan,
        isVegetarian,
        isGlutenFree,
        isDairyFree,
        isNutFree,
        isEggFree,
        isOther,
        otherDetails,
        isStayingAtWeddingVenue,
        accommodationAddress,
        numberOfNights,
    } = formData;

    return (
        <Modal title="Update guest">
            <Form onSubmit={handleSubmit} onCancel={hideModal} isPosting={isPosting}>
                <FilePicker
                    name="pictureS3Key"
                    label="Profile picture"
                    apiURL={API_URL}
                    storageURL={S3_URL}
                    value={pictureS3Key ? [pictureS3Key] : null}
                    onChange={(name, value) => handleChange(name, value?.[0] ?? null)}
                />
                <FormRow>
                    <TextInput
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        label={'First name'}
                        required
                    />
                    <TextInput
                        name="middleName"
                        value={middleName ?? ''}
                        onChange={handleChange}
                        label={'Middle name'}
                    />
                    <TextInput
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        label={'Last name'}
                        required
                    />
                </FormRow>
                <TextInput
                    name="nickname"
                    value={nickname ?? ''}
                    onChange={handleChange}
                    label={'Nickname'}
                />
                <TextInput
                    name="email"
                    value={email}
                    onChange={handleChange}
                    label="Email"
                    type="email"
                />
                <PhoneInput
                    name="phoneNumber"
                    value={phoneNumber ?? ''}
                    onChange={handleChange}
                    label="Phone Number"
                />
                <TextArea name="address" value={address} onChange={handleChange} label="Address" />
                <TextInput
                    name="specialRelations"
                    value={specialRelations ?? ''}
                    onChange={handleChange}
                    label={'Special relations'}
                />

                <Radio
                    options={ageOptions}
                    name={'ageCategory'}
                    value={ageCategory}
                    onChange={handleChange}
                    label={'Age category'}
                />
                <Description>Dietary requirements</Description>
                <FormRow>
                    <Checkbox
                        name="isVegan"
                        value={isVegan}
                        onChange={handleChange}
                        label="Vegan"
                    />
                    <Checkbox
                        name="isVegetarian"
                        value={isVegetarian}
                        onChange={handleChange}
                        label="Vegetarian"
                    />
                    <Checkbox
                        name="isGlutenFree"
                        value={isGlutenFree}
                        onChange={handleChange}
                        label="Gluten free"
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="isDairyFree"
                        value={isDairyFree}
                        onChange={handleChange}
                        label="Dairy free"
                    />
                    <Checkbox
                        name="isNutFree"
                        value={isNutFree}
                        onChange={handleChange}
                        label="Nut free"
                    />
                    <Checkbox
                        name="isEggFree"
                        value={isEggFree}
                        onChange={handleChange}
                        label="Egg free"
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="isOther"
                        value={isOther}
                        onChange={handleChange}
                        label="Other Dietary requirement(s)"
                    />
                </FormRow>
                <FormRow>
                    {isOther && (
                        <TextInput
                            name="otherDetails"
                            value={otherDetails ?? ''}
                            onChange={handleChange}
                            label="Other Dietary requirement details"
                        />
                    )}
                </FormRow>
                <Radio
                    name="guestInvitedTo"
                    value={guestInvitedTo}
                    onChange={handleChange}
                    label="Invitation"
                    options={guestInviteOptions}
                />
                <Radio
                    name="guestRSVP"
                    value={guestRSVP}
                    onChange={handleChange}
                    label="RSVP"
                    options={guestInviteRSVPOptions}
                />
                <Radio
                    name="cateringAttendanceCanape"
                    value={cateringAttendanceCanape}
                    onChange={handleChange}
                    label="Catering attendance canapé"
                    options={attendanceOptions}
                />
                <Radio
                    name="cateringAttendanceWeddingBreakfast"
                    value={cateringAttendanceWeddingBreakfast}
                    onChange={handleChange}
                    label="Catering attendance wedding breakfast"
                    options={attendanceOptions}
                />
                <Radio
                    name="cateringAttendanceEveningMeal"
                    value={cateringAttendanceEveningMeal}
                    onChange={handleChange}
                    label="Catering attendance evening meal"
                    options={attendanceOptions}
                />
                <Checkbox
                    name={'isStayingAtWeddingVenue'}
                    value={isStayingAtWeddingVenue ?? false}
                    onChange={handleChange}
                    label={'Staying at wedding venue'}
                />
                {!isStayingAtWeddingVenue && (
                    <TextArea
                        name={'accommodationAddress'}
                        value={accommodationAddress ?? ''}
                        onChange={handleChange}
                        label={'Accommodation address'}
                    />
                )}

                <NumberInput
                    name={'numberOfNights'}
                    value={numberOfNights}
                    onChange={handleChange}
                    label={'Number of nights'}
                    min={0}
                />
                <TextArea name="notes" value={notes} onChange={handleChange} label={'Notes'} />
            </Form>
        </Modal>
    );
};

interface UpdateGuestModalProps {
    hideModal: () => void;
    guest: Guest;
}

export default UpdateGuestModal;
