import { useState } from 'react';

const Tooltip: React.FC<TooltipProps> = ({
    side = 'top',
    shouldOutput = true,
    extraContainerClasses = '',
    children = null,
    text = '',
}) => {
    const [show, setShow] = useState(false);
    if (!shouldOutput) return <>{children}</>;

    return (
        <div
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
            className={`tooltip-container ${extraContainerClasses}`}
        >
            <div>{children}</div>
            {show && (
                <div className={`tooltip ${side}`}>
                    <div className="tooltip-arrow" />
                    <div className="tooltip-label">{text}</div>
                </div>
            )}
        </div>
    );
};

interface TooltipProps {
    side?: 'top' | 'bottom' | 'left' | 'right';
    shouldOutput?: boolean;
    extraContainerClasses?: string;
    children: React.ReactNode;
    text?: string;
}

export default Tooltip;
