import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';
import { deleteSupplier } from '@actions/suppliers';
import {
    selectSuppliersDeleteError,
    selectSuppliersDeleteSuccess,
    selectSuppliersIsDeleting,
} from '@selectors/suppliers';

export default function useDeleteSupplier(id: number): UseDeleteSupplierReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteSupplier(id));
    }, [dispatch, id]);

    const isPosting = useSelector(selectSuppliersIsDeleting);
    const error = useSelector(selectSuppliersDeleteError);
    const deleteSuccess = useSelector(selectSuppliersDeleteSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace('/wedding/suppliers');
        }
    }, [deleteSuccess, prevDeleteSuccess, history]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteSupplierReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
