import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { selectWedding } from '@selectors/wedding';
import { selectSuppliersArr } from '@selectors/suppliers';
import { selectSupplierTypesArr } from '@selectors/supplierTypes';
import { selectQuestionCategoriesArr } from '@selectors/questions';
import { Question } from 'src/types/Question';
import { fetchWedding } from '@actions/weddings';
import { fetchSuppliers } from '@actions/suppliers';
import { fetchSupplierTypes } from '@actions/supplierTypes';
import { fetchQuestionsForAllSuppliers } from '@actions/questions';

const useWeddingSuppliersCountdown = () => {
    const dispatch = useDispatch();
    const wedding = useSelector(selectWedding);
    const suppliers = useSelector(selectSuppliersArr);
    const suppliersTypes = useSelector(selectSupplierTypesArr);
    const questionCategories = useSelector(selectQuestionCategoriesArr);

    const dateOfWedding = wedding?.date ? dayjs(wedding?.date) : null;

    const questionsTotal = questionCategories.reduce((acc, curr) => {
        return acc + curr.questions.length;
    }, 0);

    const allQuestions: Question[][] = [];
    questionCategories.forEach(item => {
        allQuestions.push(item.questions);
    });

    const notAnsweredQuestions = allQuestions
        .flat()
        .filter(
            question =>
                question.answerBool === null &&
                question.answerOptionIDs?.length === 0 &&
                question.answerValue === null &&
                question.answerOptionID === null,
        );

    const suppliersSortedByQuestionsDueDate = suppliers.sort((a, b) => {
        const aSupplier = questionCategories?.find(cat => cat.supplierID === a.id);
        const bSupplier = questionCategories?.find(cat => cat.supplierID === b.id);

        const aRequiredDaysBefore = aSupplier?.requiredDaysBefore
            ? aSupplier.requiredDaysBefore
            : 0;

        const bRequiredDaysBefore = bSupplier?.requiredDaysBefore
            ? bSupplier.requiredDaysBefore
            : 0;

        return bRequiredDaysBefore - aRequiredDaysBefore;
    });

    useEffect(() => {
        dispatch(fetchWedding());
        dispatch(fetchSuppliers());
        dispatch(fetchSupplierTypes());
        dispatch(fetchQuestionsForAllSuppliers());
    }, [dispatch]);

    return {
        suppliers,
        suppliersTypes,
        dateOfWedding,
        questionsTotal,
        notAnsweredQuestions,
        questionCategories,
        suppliersSortedByQuestionsDueDate,
    };
};

export default useWeddingSuppliersCountdown;
