/* eslint-disable react-hooks/exhaustive-deps */
import config from '../../../../config';
import { Column } from 'lib/src/types/table';
import { FC } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { selectSelectedGuest } from '@selectors/tablePlanner';
import { setSelectedGuest, setToolFunction } from '@actions/tablePlanner/guests';
import { ToolFunction } from './hooks/useGuestPanel';
import Guest, { AssignedGuest } from 'src/types/Guest';
import { AgeCategory } from 'lib/src/shared/enums';

const { S3_URL } = config;

export type AssignedGuestTableProps = AssignedGuest | Guest;

const GuestImage: FC<{ guest: AssignedGuestTableProps }> = ({ guest }) => {
    return guest.pictureS3Key ? (
        <img
            className="guest-table-img"
            style={{
                height: '3em',
                width: '3em',
                borderRadius: '50%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'black',
            }}
            src={`${S3_URL}/${guest.pictureS3Key}`}
            alt="Profile pic"
        />
    ) : (
        <div className="guest-table-img icon-container">
            <i className="fas fa-user" />
        </div>
    );
};

const SelectButton: FC<{ row: AssignedGuestTableProps }> = ({ row }) => {
    const dispatch = useDispatch();

    const selectedGuest = useSelector(selectSelectedGuest);
    const disabled = (selectedGuest?.id ?? -1) === row.id;

    const onClick = () => {
        batch(() => {
            dispatch(setSelectedGuest(row));
            dispatch(setToolFunction(ToolFunction.Assign));
        });
    };

    return (
        <button
            className="table-button"
            disabled={disabled}
            style={{ display: 'block' }}
            onClick={onClick}
        >
            Select
        </button>
    );
};

const columns: Column<AssignedGuestTableProps>[] = [
    {
        key: 1,
        heading: '',
        getValue: row => <GuestImage guest={row} />,
    },
    {
        key: 2,
        heading: '',
        searchable: true,
        getSort: (a, b) => a.lastName?.localeCompare(b.lastName),
        getValue: row => `${row?.firstName} ${row?.lastName}`,
    },
    {
        key: 3,
        heading: '',
        getValue: row => {
            const isBaby = row.ageCategory === AgeCategory.Baby;
            const chairNumber = isBaby
                ? 'High Chair'
                : row?.chairNumber && row.chairID
                ? `Chair: ${row?.chairNumber}`
                : '';
            return (
                <div className="button-container">
                    <SelectButton row={row} />
                    {!!chairNumber && <span style={{ whiteSpace: 'nowrap' }}>{chairNumber}</span>}
                </div>
            );
        },
    },
];

export default columns;
