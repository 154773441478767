import Guest from '../../../types/Guest';
import Tooltip from '@components/tooltip/Tooltip';

const DietaryRequirementIcon: React.FC<DietaryRequirementIconProps> = ({ guest }) => {
    const {
        isVegan,
        isVegetarian,
        isGlutenFree,
        isDairyFree,
        isNutFree,
        isEggFree,
        isOther,
        otherDetails,
    } = guest;
    let tooltipText = '';
    if (isVegan) tooltipText += ' Vegan,';
    if (isVegetarian) tooltipText += ' Vegetarian,';
    if (isGlutenFree) tooltipText += ' Gluten Free,';
    if (isDairyFree) tooltipText += ' Dairy Free,';
    if (isNutFree) tooltipText += ' Nut Free,';
    if (isEggFree) tooltipText += ' Egg Free,';
    if (isOther) tooltipText += isOther && `${otherDetails},`;

    tooltipText = tooltipText.slice(0, -1);

    const hasDietaryRequirement = !!tooltipText;
    const colour = hasDietaryRequirement ? 'orange' : 'green';
    const icon = hasDietaryRequirement ? 'exclamation' : 'times';
    return (
        <Tooltip
            text={tooltipText}
            shouldOutput={hasDietaryRequirement}
            side="left"
            extraContainerClasses="full"
        >
            <div className={`icon-container ${colour}`}>
                <i className={`fal fa-${icon} ${colour}`} />
            </div>
        </Tooltip>
    );
};
interface DietaryRequirementIconProps {
    guest: Guest;
}
export default DietaryRequirementIcon;
