import React from 'react';

import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Modal from 'lib/src/components/modal/Modal';
import useUpdateSupplier from './hooks/useUpdateSupplier';
import Select from 'lib/src/components/form/Select';
import { SupplierType } from 'src/types/SupplierType';
import NumberInput from 'lib/src/components/form/NumberInput';
import DatePicker from 'lib/src/components/form/DatePicker';
import Checkbox from 'lib/src/components/form/Checkbox';

const UpdateSupplierModal: React.FC<UpdateSupplierProps> = ({
    id,
    name,
    customType,
    supplierTypeID,
    email,
    contactInformation,
    notes,
    cost,
    deposit,
    paid,
    depositDueDate,
    depositPaid,
    remainingDueDate,
    remainingPaid,
    supplierTypes,
}) => {
    const { handleSubmit, handleChange, closeModal, isPosting, formState } = useUpdateSupplier(
        id,
        name,
        customType,
        supplierTypeID,
        email,
        contactInformation,
        notes,
        cost,
        deposit,
        paid,
        depositDueDate,
        depositPaid,
        remainingDueDate,
        remainingPaid,
    );

    const supplierTypesArr = Object.values(supplierTypes);
    let supplierTypesOptions = supplierTypesArr.map(item => ({
        value: item.id,
        label: item.name,
    }));

    supplierTypesOptions = [...supplierTypesOptions, { value: 0, label: 'Other' }];

    const eightyYearsBeforeNow = new Date(new Date().setFullYear(new Date().getFullYear() - 80));
    const tenYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

    return (
        <Modal title="Update supplier" size="small">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <Select
                        name="supplierTypeID"
                        value={formState.supplierTypeID === null ? 0 : formState.supplierTypeID}
                        options={supplierTypesOptions}
                        onChange={handleChange}
                        label="Choose supplier type"
                        search
                        required
                    />
                </FormRow>
                {formState.supplierTypeID === 0 && (
                    <FormRow>
                        <TextInput
                            name="customType"
                            value={formState.customType === null ? '' : formState.customType}
                            onChange={handleChange}
                            label="Custom type"
                            required
                        />
                    </FormRow>
                )}
                <FormRow>
                    <TextInput
                        name="name"
                        value={formState.name}
                        onChange={handleChange}
                        label="Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="email"
                        value={formState.email}
                        onChange={handleChange}
                        label="Email"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="contactInformation"
                        value={formState.contactInformation}
                        onChange={handleChange}
                        label="Contact information"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="notes"
                        value={formState.notes}
                        onChange={handleChange}
                        label="Notes"
                    />
                </FormRow>
                <FormRow>
                    <NumberInput
                        name="cost"
                        value={formState.cost}
                        onChange={handleChange}
                        label="Cost"
                        min={0}
                    />
                </FormRow>
                {!!formState.cost && (
                    <FormRow>
                        <NumberInput
                            name="deposit"
                            value={formState.deposit}
                            onChange={handleChange}
                            label="Deposit"
                            max={cost}
                            min={0}
                        />
                    </FormRow>
                )}
                <FormRow>
                    <NumberInput
                        name="paid"
                        value={formState.paid ? formState.paid : 0}
                        onChange={handleChange}
                        label="Paid"
                        min={0}
                    />
                </FormRow>
                {!!formState.deposit && (
                    <>
                        <FormRow>
                            <DatePicker
                                label="Deposit Due Date"
                                name="depositDueDate"
                                value={
                                    formState.depositDueDate
                                        ? new Date(formState.depositDueDate)
                                        : null
                                }
                                onChange={handleChange}
                                maxDate={
                                    formState.remainingDueDate
                                        ? new Date(formState.remainingDueDate)
                                        : tenYearsFromNow
                                }
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Checkbox
                                name="depositPaid"
                                value={formState.depositPaid}
                                onChange={handleChange}
                                label={'Is Deposit Paid'}
                            />
                        </FormRow>
                    </>
                )}
                {!!formState.cost && (
                    <>
                        <FormRow>
                            <DatePicker
                                label="Remaining Due Date"
                                name="remainingDueDate"
                                value={
                                    formState.remainingDueDate
                                        ? new Date(formState.remainingDueDate)
                                        : null
                                }
                                onChange={handleChange}
                                minDate={
                                    formState.depositDueDate
                                        ? new Date(formState.depositDueDate)
                                        : eightyYearsBeforeNow
                                }
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Checkbox
                                name="remainingPaid"
                                value={formState.remainingPaid}
                                onChange={handleChange}
                                label={'Is Remaining Paid'}
                            />
                        </FormRow>
                    </>
                )}
            </Form>
        </Modal>
    );
};

interface UpdateSupplierProps {
    id: number;
    name: string;
    customType: string | null;
    supplierTypeID: number | null;
    email: string;
    contactInformation: string;
    notes: string;
    supplierTypes: Record<number, SupplierType>;
    cost: number;
    deposit: number;
    paid: number;
    depositDueDate: string;
    depositPaid: boolean;
    remainingDueDate: string;
    remainingPaid: boolean;
}

export default UpdateSupplierModal;
