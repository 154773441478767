import { RootState } from '@reducers/index';
import { SupplierType } from 'src/types/SupplierType';

export const selectSupplierTypesIsFetching = (state: RootState): boolean =>
    state.supplierTypesReducer.isFetching;
export const selectSupplierTypesFetchError = (state: RootState): string | null =>
    state.supplierTypesReducer.fetchError;
export const selectSupplierTypes = (state: RootState): Record<number, SupplierType> =>
    state.supplierTypesReducer.supplierTypes;
export const selectSupplierTypesArr = (state: RootState): SupplierType[] =>
    Object.values(state.supplierTypesReducer.supplierTypes);
export const selectSupplierType = (
    state: RootState,
    supplierTypeID: number | null,
): SupplierType | null =>
    supplierTypeID ? state.supplierTypesReducer.supplierTypes[supplierTypeID] : null;
