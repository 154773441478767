import Title from 'lib/src/components/typography/Title';
import RegisterFormContainer from './RegisterFormContainer';

const Register: React.FC = (): JSX.Element => (
    <div id="register-page" className="page-padding section">
        <div className="section-inner">
            <Title>Register</Title>
            <RegisterFormContainer />
        </div>
    </div>
);

export default Register;
