import { useDispatch, useSelector } from 'react-redux';

import {
    selectMealsError,
    selectMealsIsPosting,
    selectMealsPostSuccess,
    selectMealsUnassignSuccess,
} from '@selectors/meal';
import { postAssignMeal } from '@actions/meal';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect } from 'react';
import { fetchGuest } from '@actions/guests/fetchGuest';
import { unassignMeal } from '@actions/meal/unassignMeal';

export default function useAssignMeal(
    guestID: number | null,
    mealTime: 'weddingBreakfastMealID' | 'eveningMealID' | 'canapeMealID',
    mealID: number | null,
) {
    const dispatch = useDispatch();
    const isPosting = useSelector(selectMealsIsPosting);
    const postSuccess = useSelector(selectMealsPostSuccess);
    const unassignSuccess = useSelector(selectMealsUnassignSuccess);
    const prevProps = usePrevious({ postSuccess, unassignSuccess });
    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess && guestID) {
            dispatch(fetchGuest(guestID));
        }
    }, [postSuccess, prevProps.postSuccess, dispatch, guestID]);
    useEffect(() => {
        if (unassignSuccess && !prevProps.unassignSuccess && guestID) {
            dispatch(fetchGuest(guestID));
        }
    }, [unassignSuccess, prevProps.unassignSuccess, dispatch, guestID]);
    const error = useSelector(selectMealsError);

    const handleChange = (newMealID: number | null) => {
        if (!guestID) return;
        if (!newMealID && !!mealID) dispatch(unassignMeal(guestID, mealID));
        else dispatch(postAssignMeal({ guestID, mealID: newMealID }));
    };

    const handleUnassign = () => {
        if (!guestID || !mealID) return;
        dispatch(unassignMeal(guestID, mealID));
    };

    return {
        isPosting,
        error,
        handleChange,
        handleUnassign,
    };
}
