import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteSupplierRequest = createAction('deleteSupplierRequest');
export const deleteSupplierSuccess = createAction<number>('deleteSupplierSuccess');
export const deleteSupplierFailure = createAction('deleteSupplierFailure');

export const deleteSupplier =
    (supplierID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteSupplierRequest());
        try {
            await api.delete(`supplier/${supplierID}`);
            dispatch(deleteSupplierSuccess(supplierID));
        } catch (e) {
            handleApiErrors(dispatch, deleteSupplierFailure, e as APIError);
        }
    };
