import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import useCreateMeal from './hooks/useCreateMeal';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';
import { MealTime, MealType } from 'src/types/Meal';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';
import TextArea from 'lib/src/components/form/TextArea';

const CreateMealModal: React.FC<CreateMealProps> = ({ closeModal, mealTypes, mealTimes }) => {
    const {
        form: {
            name,
            mealType,
            costPerDish,
            isVegetarian,
            isVegan,
            isDairyFree,
            isGlutenFree,
            isNutFree,
            isEggFree,
            notes,
            mealTime,
        },
        handleChange,
        handleSubmit,
        isPosting,
    } = useCreateMeal(closeModal);

    const mealTypesOptions = mealTypes.map(type => ({
        value: type.value,
        label: type.display,
    }));

    const mealTimesOptions = mealTimes.map((time: MealTime) => ({
        value: time.value,
        label: time.display,
    }));

    return (
        <Modal title="Create meal">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        type="name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        label="Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="mealType"
                        value={mealType}
                        options={mealTypesOptions}
                        onChange={handleChange}
                        label="Meal type"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="costPerDish"
                        value={`${costPerDish}`}
                        onChange={handleChange}
                        label="Cost per dish (£)"
                        required
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isVegetarian"
                        label="Vegetarian"
                        value={isVegetarian}
                        onChange={handleChange}
                    />
                    <ToggleSwitch
                        name="isVegan"
                        label="Vegan"
                        value={isVegan}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isDairyFree"
                        label="Dairy free"
                        value={isDairyFree}
                        onChange={handleChange}
                    />
                    <ToggleSwitch
                        name="isGlutenFree"
                        label="Gluten free"
                        value={isGlutenFree}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isNutFree"
                        label="Nut free"
                        value={isNutFree}
                        onChange={handleChange}
                    />
                    <ToggleSwitch
                        name="isEggFree"
                        value={isEggFree}
                        onChange={handleChange}
                        label="Egg free"
                    />
                </FormRow>

                <FormRow>
                    <TextArea
                        name="notes"
                        value={notes === null ? '' : notes}
                        onChange={handleChange}
                        label="Notes"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface CreateMealProps {
    closeModal: () => void;
    mealTypes: MealType[];
    mealTimes: MealTime[];
}

export default CreateMealModal;
