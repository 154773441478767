import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import MealNotes from '../../../types/MealNotes';

export const patchMealNotesRequest = createAction('patchMealNotesRequest');
export const patchMealNotesSuccess = createAction<MealNotes>('patchMealNotesSuccess');
export const patchMealNotesFailure = createAction('patchMealNotesFailure');

export const patchMealNotes =
    (postBody: MealNotesPatch) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(patchMealNotesRequest());
        try {
            const { data } = await api.post<MealNotesPatch, MealNotes>('meal/notes', postBody);
            dispatch(patchMealNotesSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, patchMealNotesFailure, e as APIError);
        }
    };

export interface MealNotesPatch {
    canapeNotes: string;
    eveningMealNotes: string;
}
