import { useSelector } from 'react-redux';

import { selectTimingsIsFetching } from '@selectors/timings';
import { TimingDayEnum } from 'lib/src/shared/enums';
import { selectSuppliers } from '@selectors/suppliers';
import { TimingsAndMeals } from './useTimingsAndMeals';

const useTimingsTables = (timingsAndMeals: TimingsAndMeals[]) => {
    const isFetching = useSelector(selectTimingsIsFetching);
    const suppliers = useSelector(selectSuppliers);

    const timingsByDay = timingsAndMeals.reduce((acc, timing) => {
        acc[timing.day] = [...(acc[timing.day] || []), timing];
        return acc;
    }, {} as Record<string, TimingsAndMeals[]>);

    const dayBeforeTimings = (timingsByDay[TimingDayEnum.DayBefore] ?? []).sort(sortByTiming);
    const dayOfTimings = (timingsByDay[TimingDayEnum.DayOf] ?? []).sort(sortByTiming);
    const dayAfterTimings = (timingsByDay[TimingDayEnum.DayAfter] ?? []).sort(sortByTiming);

    return { isFetching, dayBeforeTimings, dayOfTimings, dayAfterTimings, suppliers };
};

function sortByTiming(a: TimingsAndMeals, b: TimingsAndMeals) {
    return a.time.localeCompare(b.time);
}

export default useTimingsTables;
