import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Resource } from 'src/types/Resource';

export const fetchAllResourcesRequest = createAction('fetchAllResourcesRequest');
export const fetchAllResourcesSuccess = createAction<Resource[]>('fetchAllResourcesSuccess');
export const fetchAllResourcesFailure = createAction('fetchAllResourcesFailure');

export const fetchAllResources =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllResourcesRequest());
        try {
            const { data } = await api.get<Resource[]>('resource');
            dispatch(fetchAllResourcesSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchAllResourcesFailure, e as APIError);
        }
    };
