import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { CloseModal } from 'src/types/shared/Functions';
import {
    selectSuppliersIsPosting,
    selectSuppliersPostError,
    selectSuppliersPostSuccess,
} from '@selectors/suppliers';
import { postCreateSupplier } from '@actions/suppliers';

const initialFormData: FormState = {
    name: '',
    supplierTypeID: null,
    customType: null,
    email: '',
    contactInformation: '',
    notes: '',
    cost: null,
    deposit: null,
    paid: null,
    depositDueDate: null,
    depositPaid: false,
    remainingDueDate: null,
    remainingPaid: false,
};

export default function useAddSupplier(closeModal: CloseModal) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [form, handleChange] = useForm(initialFormData);

    const handleSubmit = useCallback(() => {
        const postBody = {
            ...form,
            supplierTypeID: form.supplierTypeID === 0 ? null : form.supplierTypeID,
        };

        dispatch(postCreateSupplier(postBody));
        history.push('/wedding/suppliers');
    }, [dispatch, history, form]);

    const isPosting = useSelector(selectSuppliersIsPosting);
    const error = useSelector(selectSuppliersPostError);
    const postSuccess = useSelector(selectSuppliersPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
}
interface FormState {
    name: string;
    supplierTypeID: null | number;
    customType: null | string;
    email: string;
    contactInformation: string;
    notes: string;
    cost: number | null;
    deposit: number | null;
    paid: number | null;
    depositDueDate: string | null;
    depositPaid: boolean;
    remainingDueDate: string | null;
    remainingPaid: boolean;
}
