import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { QuestionCategory } from 'src/types/Question';

export const fetchQuestionsForAllSuppliersRequest = createAction(
    'fetchQuestionsForAllSuppliersRequest',
);
export const fetchQuestionsForAllSuppliersSuccess = createAction<QuestionCategory[]>(
    'fetchQuestionsForAllSuppliersSuccess',
);
export const fetchQuestionsForAllSuppliersFailure = createAction(
    'fetchQuestionsForAllSuppliersFailure',
);

export const fetchQuestionsForAllSuppliers =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchQuestionsForAllSuppliersRequest());
        try {
            const { data } = await api.get<QuestionCategory[]>('question');
            dispatch(fetchQuestionsForAllSuppliersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchQuestionsForAllSuppliersFailure, e as APIError);
        }
    };
