import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Resource } from 'src/types/Resource';

export const fetchResourcesByCategoryRequest = createAction('fetchResourcesByCategoryRequest');
export const fetchResourcesByCategorySuccess = createAction<Resource[]>(
    'fetchResourcesByCategorySuccess',
);
export const fetchResourcesByCategoryFailure = createAction('fetchResourcesByCategoryFailure');

export const fetchResourcesByCategory =
    (ids: number[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const qs = '?' + ids.map((id, i) => `category=${encodeURIComponent(ids[i])}`).join('&');

        dispatch(fetchResourcesByCategoryRequest());
        try {
            const { data } = await api.get<Resource[]>(`resource/bycategory${qs}`);

            dispatch(fetchResourcesByCategorySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchResourcesByCategoryFailure, e as APIError);
        }
    };
