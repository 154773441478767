import React from 'react';

import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Modal from 'lib/src/components/modal/Modal';
import Select from 'lib/src/components/form/Select';
import useUpdateMeal from './hooks/useUpdateMeal';
import { MealTime, MealType } from 'src/types/Meal';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';
import TextArea from 'lib/src/components/form/TextArea';
import useDarkMode from 'lib/src/hooks/useDarkMode';

const UpdateMealModal: React.FC<UpdateMealProps> = ({
    id,
    name,
    mealType,
    mealTime,
    costPerDish,
    isVegetarian,
    isVegan,
    isDairyFree,
    isGlutenFree,
    isNutFree,
    notes,
    mealTypes,
    mealTimes,
    isEggFree,
}) => {
    const { handleSubmit, handleChange, closeModal, isPosting, formState } = useUpdateMeal(
        id,
        name,
        mealType,
        mealTime,
        costPerDish,
        isVegetarian,
        isVegan,
        isDairyFree,
        isGlutenFree,
        isNutFree,
        isEggFree,
        notes,
    );

    const mealTypesOptions = mealTypes.map(type => ({
        value: type.value,
        label: type.display,
    }));

    const mealTimesOptions = mealTimes.map((time: MealTime) => ({
        value: time.value,
        label: time.display,
    }));

    const [isDark] = useDarkMode();
    const textColour = isDark ? 'white' : 'black';

    return (
        <Modal title="Update meal" size="small">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        type="name"
                        name="name"
                        value={formState.name}
                        onChange={handleChange}
                        label="Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="mealType"
                        value={formState.mealType}
                        options={mealTypesOptions}
                        onChange={handleChange}
                        label="Meal type"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="costPerDish"
                        value={'' + formState.costPerDish}
                        onChange={handleChange}
                        label="Cost per dish (£)"
                        required
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isVegetarian"
                        label="Vegetarian"
                        value={formState.isVegetarian}
                        textColour={textColour}
                        onChange={handleChange}
                    />
                    <ToggleSwitch
                        name="isVegan"
                        label="Vegan"
                        value={formState.isVegan}
                        textColour={textColour}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isDairyFree"
                        label="Dairy free"
                        value={formState.isDairyFree}
                        textColour={textColour}
                        onChange={handleChange}
                    />
                    <ToggleSwitch
                        name="isGlutenFree"
                        label="Gluten free"
                        value={formState.isGlutenFree}
                        textColour={textColour}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <ToggleSwitch
                        name="isNutFree"
                        label="Nut free"
                        textColour={textColour}
                        value={formState.isNutFree}
                        onChange={handleChange}
                    />
                    <ToggleSwitch
                        name="isEggFree"
                        value={formState.isEggFree}
                        onChange={handleChange}
                        textColour={textColour}
                        label="Egg free"
                    />
                </FormRow>
                <FormRow>
                    {formState.notes === null ? (
                        <TextArea name="notes" value={''} onChange={handleChange} label="Notes" />
                    ) : (
                        <TextArea
                            name="notes"
                            value={formState.notes}
                            onChange={handleChange}
                            label="Notes"
                        />
                    )}
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateMealProps {
    id: number;
    name: string;
    mealType: number;
    mealTime: number;
    costPerDish: number;
    isVegetarian: boolean;
    isVegan: boolean;
    isDairyFree: boolean;
    isGlutenFree: boolean;
    isNutFree: boolean;
    notes: string | null;
    mealTypes: MealType[];
    mealTimes: MealTime[];
    isEggFree: boolean;
}

export default UpdateMealModal;
