import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    postLoginRequest,
    postLoginSuccess,
    postLoginFailure,
    postRegisterRequest,
    postRegisterSuccess,
    postRegisterFailure,
} from '@actions/auth';
import {
    postForgotPasswordFailure,
    postForgotPasswordRequest,
    postForgotPasswordSuccess,
} from '@actions/auth/postForgotPassword';
import {
    postResetPasswordFailure,
    postResetPasswordRequest,
    postResetPasswordSuccess,
} from '@actions/auth/postResetPassword';
import {
    postSelectWeddingFailure,
    postSelectWeddingRequest,
    postSelectWeddingSuccess,
} from '@actions/weddings/postSelectWedding';

interface AuthState {
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
}

const initialState: AuthState = {
    isPosting: false,
    postSuccess: false,
    error: null,
};

export default createReducer(initialState, {
    [postLoginRequest.type]: handlePostRequest,
    [postLoginSuccess.type]: handlePostSuccess,
    [postLoginFailure.type]: handleFailure,
    [postRegisterRequest.type]: handlePostRequest,
    [postRegisterSuccess.type]: handlePostSuccess,
    [postRegisterFailure.type]: handleFailure,
    [postSelectWeddingRequest.type]: handlePostRequest,
    [postSelectWeddingSuccess.type]: handlePostSuccess,
    [postSelectWeddingFailure.type]: handleFailure,
    [postForgotPasswordRequest.type]: handlePostRequest,
    [postForgotPasswordSuccess.type]: handlePostSuccess,
    [postForgotPasswordFailure.type]: handleFailure,
    [postResetPasswordRequest.type]: handlePostRequest,
    [postResetPasswordSuccess.type]: handlePostSuccess,
    [postResetPasswordFailure.type]: handleFailure,
});

function handlePostRequest(state: AuthState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: AuthState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFailure(state: AuthState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.error = action.payload;
}
