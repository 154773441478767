import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectAllMealTimes, selectMealsError, selectMealsIsFetching } from '@selectors/meal';
import { fetchAllMealTimes } from '@actions/meal';

const useAllMealTimesFetch = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectMealsIsFetching);
    const error = useSelector(selectMealsError);
    const mealTimes = useSelector(selectAllMealTimes);
    useEffect(() => {
        dispatch(fetchAllMealTimes());
    }, [dispatch]);

    return {
        isFetching,
        error,
        mealTimes: mealTimes,
    };
};

export default useAllMealTimesFetch;
