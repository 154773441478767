import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import fileDownload from 'js-file-download';

export const generateTimingsPDFRequest = createAction('generateTimingsPDFRequest');
export const generateTimingsPDFSuccess = createAction('generateTimingsPDFSuccess');
export const generateTimingsPDFFailure = createAction('generateTimingsPDFFailure');

export const generateTimingsPDF =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(generateTimingsPDFRequest());
        try {
            await api
                .get<Blob>('timing/pdf', 'blob')
                .then(res => new Blob([res.data], { type: 'application/pdf' }))
                .then(blob => fileDownload(blob, 'WeddingTimings.pdf'));
            dispatch(generateTimingsPDFSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, generateTimingsPDFFailure, e as APIError);
        }
    };
