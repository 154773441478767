import { AttendanceResponse } from 'lib/src/shared/enums/attendanceResponse';

const AttendanceIcon: React.FC<AttendanceIconProps> = ({ attendance }) => {
    let icon = 'question';
    let colour = 'orange';
    switch (attendance) {
        case AttendanceResponse.Yes:
            icon = 'check';
            colour = 'green';
            break;
        case AttendanceResponse.No:
            icon = 'times';
            colour = 'red';
            break;
    }
    return (
        <div className={`icon-container ${colour}`}>
            <i className={`fal fa-${icon} ${colour}`} />
        </div>
    );
};

interface AttendanceIconProps {
    attendance: AttendanceResponse;
}
export default AttendanceIcon;
