import { fetchBudgetFailure, fetchBudgetRequest, fetchBudgetSuccess } from '@actions/budget';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import BudgetFullResponse from 'src/types/Budget';
import { logout } from '@actions/auth';

interface BudgetState {
    isFetching: boolean;
    error: string | null;
    budget: BudgetFullResponse | null;
}

const initialState: BudgetState = {
    isFetching: false,
    error: null,
    budget: null,
};

export default createReducer(initialState, {
    [fetchBudgetRequest.type]: handleFetchRequest,
    [fetchBudgetSuccess.type]: handleFetchBudgetSuccess,
    [fetchBudgetFailure.type]: handleFetchFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: BudgetState) {
    state.isFetching = true;
}

function handleFetchBudgetSuccess(state: BudgetState, action: PayloadAction<BudgetFullResponse>) {
    state.isFetching = false;
    state.budget = action.payload;
}

function handleFetchFailure(state: BudgetState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}
