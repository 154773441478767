import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AssignMealPost } from 'src/types/Meal';

export const postAssignMealRequest = createAction('postAssignMealRequest');
export const postAssignMealSuccess = createAction('postAssignMealSuccess');
export const postAssignMealFailure = createAction('postAssignMealFailure');

export const postAssignMeal =
    (postBody: AssignMealPost) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postAssignMealRequest());
        try {
            await api.post<AssignMealPost, null>('meal/assign', postBody);
            dispatch(postAssignMealSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postAssignMealFailure, e as APIError);
        }
    };
