import Title from 'lib/src/components/typography/Title';
import LoginFormContainer from './LoginFormContainer';

const Login: React.FC = () => (
    <div id="login-page" className="page-padding section">
        <div className="section-inner">
            <Title>Login</Title>
            <LoginFormContainer />
        </div>
    </div>
);

export default Login;
