import { EmailSentType } from './../reducers/questions';
import { RootState } from '@reducers/index';
import { QuestionCategory } from 'src/types/Question';

export const selectQuestionsIsFetching = (state: RootState): boolean =>
    state.questionsReducer.isFetching;
export const selectQuestionsIsSendingEmail = (state: RootState): boolean =>
    state.questionsReducer.isSendingEmail;
export const selectQuestionsFetchingEmail = (state: RootState): EmailSentType =>
    state.questionsReducer.emailSent;
export const selectQuestionsFetchError = (state: RootState): string | null =>
    state.questionsReducer.fetchError;
export const selectQuestionsIsPosting = (state: RootState): boolean =>
    state.questionsReducer.isPosting;
export const selectQuestionsPostSuccess = (state: RootState): boolean =>
    state.questionsReducer.postSuccess;
export const selectQuestionsPostError = (state: RootState): string | null =>
    state.questionsReducer.postError;
export const selectQuestionCategories = (state: RootState): Record<number, QuestionCategory> =>
    state.questionsReducer.questionCategories;
export const selectQuestionCategoriesArr = (state: RootState): QuestionCategory[] =>
    Object.values(state.questionsReducer.questionCategories);
export const selectQuestionCategory = (state: RootState, categoryID: number): QuestionCategory =>
    state.questionsReducer.questionCategories[categoryID];
export const selectQuestionCategoriesForAllSuppliers = (
    state: RootState,
): Record<number, QuestionCategory> => state.questionsReducer.questionCategories;
export const selectQuestionCategoriesForAllSuppliersArr = (state: RootState): QuestionCategory[] =>
    Object.values(state.questionsReducer.questionCategories);

export const selectQuestionsIsGenerating = (state: RootState): boolean =>
    state.questionsReducer.isGenerating;
export const selectQuestionsGenerateError = (state: RootState): string | null =>
    state.questionsReducer.generateError;
export const selectQuestionsGenerateSuccess = (state: RootState): boolean =>
    state.questionsReducer.generateSuccess;
