import useForm from 'lib/src/hooks/useForm';
import { AddGuestRequest, postGuest } from '@actions/guests/postGuest';
import { AgeCategory } from 'lib/src/shared/enums/ageCategory';
import { useDispatch, useSelector } from 'react-redux';
import { selectGuestIsPosting, selectGuestPostSuccess } from '@selectors/guest';
import { AttendanceResponse } from 'lib/src/shared/enums/attendanceResponse';
import { GuestInvite } from 'lib/src/shared/enums/guestInvite';
import { GuestInviteRSVP } from 'lib/src/shared/enums/guestInviteRSVP';

const useAddGuest = () => {
    const dispatch = useDispatch();
    const isPosting = useSelector(selectGuestIsPosting);
    const postSuccess = useSelector(selectGuestPostSuccess);
    const [formData, handleChange] = useForm<AddGuestRequest>({
        firstName: '',
        middleName: '',
        lastName: '',
        nickname: '',
        email: '',
        address: '',
        phoneNumber: '',
        specialRelations: '',
        notes: '',
        ageCategory: AgeCategory.Adult,
        pictureS3Key: null,
        guestInvitedTo: GuestInvite.No,
        guestRSVP: GuestInviteRSVP.AwaitingResponse,
        cateringAttendanceCanape: AttendanceResponse.AwaitingReply,
        cateringAttendanceWeddingBreakfast: AttendanceResponse.AwaitingReply,
        cateringAttendanceEveningMeal: AttendanceResponse.AwaitingReply,
        isVegan: false,
        isVegetarian: false,
        isGlutenFree: false,
        isDairyFree: false,
        isNutFree: false,
        isEggFree: false,
        isOther: false,
        otherDetails: '',
        isStayingAtWeddingVenue: false,
        accommodationAddress: '',
        numberOfNights: null,
    });

    const handleSubmit = () => {
        dispatch(postGuest(formData));
    };

    return {
        formData,
        handleChange,
        handleSubmit,
        isPosting,
        postSuccess,
    };
};

export default useAddGuest;
