import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { CreateSupplierRequest, Supplier } from 'src/types/Supplier';

export const postCreateSupplierRequest = createAction('postCreateSupplierRequest');
export const postCreateSupplierSuccess = createAction<Supplier>('postCreateSupplierSuccess');
export const postCreateSupplierFailure = createAction('postCreateSupplierFailure');

export const postCreateSupplier =
    (postBody: CreateSupplierRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postCreateSupplierRequest());
        try {
            const { data } = await api.post<CreateSupplierRequest, Supplier>('supplier', postBody);
            dispatch(postCreateSupplierSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postCreateSupplierFailure, e as APIError);
        }
    };
