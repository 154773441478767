import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postSetQuestionAnswer } from '@actions/questions';
import { Question } from 'src/types/Question';
import { QuestionAnswerEnum } from 'lib/src/shared/enums/questions';
import { selectQuestionsIsPosting, selectQuestionsPostError } from '@selectors/questions';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

const useAnswerSupplierQuestion = (question: Question) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(selectQuestionsIsPosting);
    const prevIsPosting = usePrevious(isPosting);
    const [isQuestionPosting, setIsQuestionPosting] = useState(false);

    const { answerType, answerBool, answerOptionID, answerValue, answerOptionIDs } = question;

    const isAnswerString = [
        QuestionAnswerEnum.TextMultiLine,
        QuestionAnswerEnum.TextSingleLine,
        QuestionAnswerEnum.Numerical,
    ].includes(answerType);
    const isAnswerBoolean = answerType === QuestionAnswerEnum.Checkbox;
    const isAnswerID = answerType === QuestionAnswerEnum.Radio;
    const isAnswerIDs = answerType === QuestionAnswerEnum.CheckboxMulti;

    let initAnswerValue: string | number | boolean | number[] = isAnswerIDs
        ? []
        : isAnswerBoolean
        ? false
        : '';

    if (isAnswerBoolean && answerBool !== null) {
        initAnswerValue = answerBool;
    } else if (isAnswerID && answerOptionID !== null) {
        initAnswerValue = answerOptionID;
    } else if (isAnswerIDs && answerOptionIDs !== null) {
        initAnswerValue = answerOptionIDs;
    } else if (answerValue) {
        initAnswerValue = answerValue;
    }

    const [form, handleChange] = useForm({
        [`question-${question.id}`]: initAnswerValue,
    });

    const questionPostError = useSelector(selectQuestionsPostError);

    const curAnswerValue = form[`question-${question.id}`];
    const prevQuestionPostError = usePrevious(questionPostError);

    useEffect(() => {
        if (questionPostError && !prevQuestionPostError) {
            handleChange(`question-${question.id}`, initAnswerValue);
        }
    }, [questionPostError, prevQuestionPostError]); // eslint-disable-line

    useEffect(() => {
        if (!isPosting && prevIsPosting) setIsQuestionPosting(false);
    }, [isPosting, prevIsPosting]);

    const handleSubmit = useCallback(() => {
        const value = curAnswerValue.valueOf();
        const valueIsString = typeof value === 'string';
        const valueIsNumber = typeof value === 'number';
        const valueIsBoolean = typeof value === 'boolean';
        const valueIsArray = Array.isArray(value);
        dispatch(
            postSetQuestionAnswer({
                questionID: question.id,
                answerValue: isAnswerString && valueIsString ? value : null,
                answerBool: isAnswerBoolean && valueIsBoolean ? value : null,
                answerOptionID: isAnswerID && valueIsNumber ? value : null,
                answerOptionIDs: isAnswerIDs && valueIsArray ? value : null,
            }),
        );
        setIsQuestionPosting(true);
    }, [
        dispatch,
        question.id,
        isAnswerBoolean,
        isAnswerID,
        isAnswerString,
        isAnswerIDs,
        curAnswerValue,
    ]);

    const options = question.options.map(opt => ({ label: opt.value, value: opt.id }));

    const isAnswerDifferent = JSON.stringify(curAnswerValue) !== JSON.stringify(initAnswerValue);

    return {
        form,
        isPosting,
        isQuestionPosting,
        handleChange,
        handleSubmit,
        isAnswerDifferent,
        options,
    };
};

export default useAnswerSupplierQuestion;
