import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
    getAllResourcesCategories,
    getAllResourcesCategoriesArr,
    getResourcesError,
    getResourcesIsFetching,
} from '@selectors/resources';
import { fetchAllResourcesCategories } from '@actions/resources';

const useFetchResourcesCategories = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getResourcesIsFetching);
    const error = useSelector(getResourcesError);
    const resourcesCategories = useSelector(getAllResourcesCategories);
    const resourcesCategoriesArr = useSelector(getAllResourcesCategoriesArr);

    useEffect(() => {
        dispatch(fetchAllResourcesCategories());
    }, [dispatch]);

    return {
        isFetching,
        error,
        resourcesCategories,
        resourcesCategoriesArr,
    };
};

export default useFetchResourcesCategories;
