import React from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lib/src/utils/generic';
import { selectWedding, selectWeddingError, selectWeddingIsFetching } from '@selectors/wedding';

import useTimings from '@pages/timings/hooks/useTimings';
import useGenerateTimingsPDF from './hooks/useGenerateTimingsPDF';
import useFetchAllSuppliers from '@pages/suppliers/suppliers/hooks/useFetchAllSuppliers';
import useTimingsModals from './hooks/useTimingsModals';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';

import ErrorModal from 'lib/src/components/modal/ErrorModal';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import TimingsTables from './TimingsTables';
import AddTimingModal from './addTiming/AddTimingModal';
import EditTimingModal from './editTiming/EditTimingModal';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useTimingsAndMeals from './hooks/useTimingsAndMeals';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';

const Timings: React.FC = () => {
    const wedding = useSelector(selectWedding);
    const isFetchingWedding = useSelector(selectWeddingIsFetching);
    const weddingError = useSelector(selectWeddingError);
    const {
        timings,
        timingsArr,
        isFetching: isFetchingTimings,
        error: timingsError,
    } = useTimings();
    const { isFetching: isFetchingSuppliers, error: suppliersError } = useFetchAllSuppliers();
    const {
        handleGeneratePDF,
        isGenerating,
        generateError,
        showError: showGenerateError,
        setShowError: setShowGenerateError,
    } = useGenerateTimingsPDF();

    const {
        showAddModal,
        setShowAddModal,
        editTimingID,
        setEditTimingID,
        deleteTimingID,
        setDeleteTimingID,
        handleDelete,
        showErrorModal,
        setShowErrorModal,
        isPosting,
        postError,
    } = useTimingsModals();

    const timingsAndMeals = useTimingsAndMeals(timingsArr, wedding);

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <CreateHeader>
                    <Title>Timings</Title>
                    <FlexWrapper gap={10} extraClasses="flex-column-lm">
                        <ActionButton
                            onClick={() => setShowAddModal(true)}
                            source="secondary"
                            icon="plus"
                        >
                            Add Timing
                        </ActionButton>
                        <ActionButton onClick={handleGeneratePDF} isPosting={isGenerating}>
                            Export to PDF
                        </ActionButton>
                    </FlexWrapper>
                </CreateHeader>

                <DataCheck
                    isEmpty={isEmpty(timingsAndMeals) || isEmpty(wedding)}
                    isLoading={isFetchingTimings || isFetchingSuppliers || isFetchingWedding}
                    error={timingsError || suppliersError || weddingError}
                >
                    <TimingsTables
                        timingsAndMeals={timingsAndMeals}
                        setEditTimingID={setEditTimingID}
                        setDeleteTimingID={setDeleteTimingID}
                    />
                </DataCheck>

                {showAddModal && <AddTimingModal closeModal={() => setShowAddModal(false)} />}

                {editTimingID && (
                    <EditTimingModal
                        editTimingID={editTimingID}
                        closeModal={() => setEditTimingID(null)}
                    />
                )}

                {deleteTimingID && (
                    <ConfirmModal
                        title="Delete Timing"
                        description={`Are you sure you would like to delete the timing "${timings[deleteTimingID]?.name}"?`}
                        isPosting={isPosting}
                        handleSubmit={() => handleDelete(deleteTimingID)}
                        closeModal={() => setDeleteTimingID(null)}
                    />
                )}

                {showGenerateError && (
                    <ErrorModal
                        description={generateError || undefined}
                        closeModal={() => setShowGenerateError(false)}
                    />
                )}

                {showErrorModal && (
                    <ErrorModal
                        description={postError || undefined}
                        closeModal={() => setShowErrorModal(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default Timings;
