import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { ResourceCategory } from 'src/types/Resource';

export const fetchSingleResourcesCategoryRequest = createAction(
    'fetchSingleResourcesCategoryRequest',
);
export const fetchSingleResourcesCategorySuccess = createAction<ResourceCategory>(
    'fetchSingleResourcesCategorySuccess',
);
export const fetchSingleResourcesCategoryFailure = createAction(
    'fetchSingleResourcesCategoryFailure',
);

export const fetchSingleResourcesCategory =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleResourcesCategoryRequest());
        try {
            const { data } = await api.get<ResourceCategory>(`resource/category/${id}`);

            dispatch(fetchSingleResourcesCategorySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleResourcesCategoryFailure, e as APIError);
        }
    };
