import Description from 'lib/src/components/typography/Description';
import { GuestInviteRSVP } from 'lib/src/shared/enums/guestInviteRSVP';

const InviteDescription: React.FC<InviteDescriptionProps> = ({ guestInviteRSVP }) => {
    let text = 'Not yet replied';

    switch (guestInviteRSVP) {
        case GuestInviteRSVP.EveningOnly:
            text = 'Evening only';
            break;
        case GuestInviteRSVP.FullWedding:
            text = 'Full wedding';
            break;
        case GuestInviteRSVP.DayOnly:
            text = 'Day only';
            break;
        case GuestInviteRSVP.No:
            text = 'Not attending';
            break;
    }
    return <Description>{text}</Description>;
};

interface InviteDescriptionProps {
    guestInviteRSVP: GuestInviteRSVP;
}

export default InviteDescription;
