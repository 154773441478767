import { Link } from 'react-router-dom';

const LinkButton: React.FC<LinkButtonProps> = ({
    source = 'primary',
    href = '',
    icon,
    children,
    extraClasses,
    onClick,
}) => (
    <Link to={href} className={`button ${source} ${extraClasses}`} onClick={onClick}>
        {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
        <span className="text">{children}</span>
    </Link>
);

interface LinkButtonProps {
    children: React.ReactNode;
    source?: 'primary' | 'secondary' | 'tertiary' | 'positive' | 'negative' | 'transparent';
    href?: string;
    icon?: string;
    extraClasses?: string;
    onClick?: () => void;
}

export default LinkButton;
