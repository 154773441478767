import { useCallback, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import ExtPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { FormInputProps } from '../../types/shared/FormInputProps';
import useFieldValidation from '../../hooks/useFieldValidation';
import FormField from './FormField';
import TextInput from './TextInput';

const PhoneInput: React.FC<PhoneInputProps> = ({
    type,
    name,
    label,
    placeholder = 'Enter phone number',
    classes,
    value,
    onChange,
    disabled,
    required,
    customValidate = () => {},
}) => {
    const memoizedValidate = useCallback(_validate, [customValidate, required]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    function _validate(value: string) {
        const validateError = customValidate(value);
        if (required && !value) {
            return 'This is a required field.';
        } else if (validateError && validateError.length) {
            return validateError;
        } else if (!!value && !isValidPhoneNumber(value)) {
            return 'Please enter a valid phone number.';
        }
        return '';
    }

    useEffect(() => {
        memoizedValidate(value);
    }, [value, memoizedValidate]);

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <ExtPhoneInput
                className={`generic-input ${classes}`}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={value => onChange(name, value ?? '')}
                disabled={disabled}
                defaultCountry="GB"
                style={{ width: '100%' }}
            />
            {!!showError && error?.length && (
                <p className="error red-text text-accent-4">{error}</p>
            )}
        </FormField>
    );
};

interface PhoneInputProps extends FormInputProps<string> {
    placeholder?: string;
    label?: string;
    classes?: string;
    validationRegExp?: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
    minNumber?: number;
    maxNumber?: number;
}

export default PhoneInput;
