import { useDispatch, useSelector } from 'react-redux';

import { postTiming } from '@actions/timings/postTiming';
import { selectTimingsIsPosting } from '@selectors/timings';
import { selectSuppliersArr } from '@selectors/suppliers';
import { TimingDayEnum } from 'lib/src/shared/enums';

import useForm from 'lib/src/hooks/useForm';

const useAddTiming = () => {
    const dispatch = useDispatch();
    const [form, handleChange] = useForm<PostTimingForm>({
        name: '',
        supplierID: null,
        day: TimingDayEnum.DayOf,
        time: '',
    });

    const suppliersArr = useSelector(selectSuppliersArr);
    const isPosting = useSelector(selectTimingsIsPosting);

    const handleSubmit = () => {
        if (form.supplierID) {
            const formData = { ...form, supplierID: form.supplierID };
            dispatch(postTiming(formData));
        }
    };

    const supplierOptions = suppliersArr.map(supplier => ({
        label: supplier.name,
        value: supplier.id,
    }));

    return { form, handleChange, handleSubmit, isPosting, supplierOptions };
};

interface PostTimingForm {
    name: string;
    supplierID: number | null;
    day: TimingDayEnum;
    time: string;
}

export default useAddTiming;
