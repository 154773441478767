import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import Account from '@pages/account/Account';
// import WeddingSelection from '@pages/account/weddingSelection/WeddingSelection';

const AccountRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Account />
            </Route>
            {/*<Route path={`${path}/wedding-selection`}>*/}
            {/*    <WeddingSelection />*/}
            {/*</Route>*/}
            {/* todo addtional profiles */}
            {/* todo change password */}
            {/* todo notifications ? */}
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default AccountRoutes;
