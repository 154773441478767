import useUpdateWedding from '@pages/wedding/weddingDetails/hooks/useUpdateWedding';
import { CeremonyLocationType } from 'lib/src/shared/enums/ceremonyLocationType';
import { CeremonyType } from 'lib/src/shared/enums/ceremonyType';
import { ReceptionLocationType } from 'lib/src/shared/enums/receptionLocationType';

export default interface Wedding {
    id: number;
    createdByUserID: number;
    createdOn: Date;
    date: Date;
    budget: number | null;
    minDayGuests: number | null;
    maxDayGuests: number | null;
    minEveningGuests: number | null;
    maxEveningGuests: number | null;
    ceremonyLocation: string | null;
    ceremonyLocationType: CeremonyLocationType;
    ceremonyType: CeremonyType;
    receptionLocation: string | null;
    receptionLocationType: ReceptionLocationType;
    isDeleted: boolean;
    ownerFirstName: string | null;
    ownerLastName: string | null;
    ownerProfilePictureS3Key: string | null;
    partnerFirstName: string | null;
    partnerLastName: string | null;
    partnerProfilePictureS3Key: string | null;
    canapeTime: string | null;
    weddingBreakfastTime: string | null;
    eveningMealTime: string | null;
}

export interface WeddingDetailsFormProps extends ReturnType<typeof useUpdateWedding> {
    wedding: Wedding | null;
    isPosting: boolean;
}

export enum WeddingDetailsTabOptions {
    CeremonyAndReceptionLocation = 0,
    DateAndBudget = 1,
    Guests = 2,
    MealTimes = 3,
}
