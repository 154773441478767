import { useSelector } from 'react-redux';
import { useState } from 'react';

import {
    selectAllMeals,
    selectAllMealTimes,
    selectMealsError,
    selectMealsIsFetching,
    selectMealTypes,
} from '@selectors/meal';
import useAllMealsFetch from './hooks/useAllMealsFetch';
import MealsTable from './MealsTable';
import CreateMealModal from './CreateMealModal';
import useAllMealsTypesFetch from './hooks/useAllMealsTypesFetch';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import BackButton from '@components/generic/backButton/BackButton';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import useAllMealTimesFetch from '../hooks/useAllMealTimesFetch';
import MealNotesForm from '@pages/catering/meals/MealNotesForm';

const MenuPage = () => {
    useAllMealsFetch();
    useAllMealsTypesFetch();
    useAllMealTimesFetch();

    const allMeals = useSelector(selectAllMeals);
    const mealTypes = useSelector(selectMealTypes);
    const mealTimes = useSelector(selectAllMealTimes);
    const mealsIsFetching = useSelector(selectMealsIsFetching);
    const error = useSelector(selectMealsError);

    const [showCreateModal, setShowCreateModal] = useState(false);

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <BackButton href="/wedding/catering/" />
                <CreateHeader>
                    <Title>Menu</Title>
                    <ActionButton
                        source="secondary"
                        icon="plus"
                        onClick={() => setShowCreateModal(true)}
                    >
                        Create
                    </ActionButton>
                </CreateHeader>

                <DataCheck
                    isEmpty={!Object.values(allMeals).length || !mealTypes.length}
                    isLoading={mealsIsFetching}
                    error={error}
                >
                    <MealsTable
                        allMeals={allMeals}
                        mealTypes={mealTypes}
                        isFetching={mealsIsFetching}
                    />
                </DataCheck>
                <MealNotesForm />

                {showCreateModal && (
                    <CreateMealModal
                        closeModal={() => setShowCreateModal(false)}
                        mealTypes={mealTypes}
                        mealTimes={mealTimes}
                    />
                )}
            </div>
        </div>
    );
};

export default MenuPage;
