import React from 'react';
import dayjs from 'dayjs';

import Title from 'lib/src/components/typography/Title';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Meal, MealTime, MealType } from 'src/types/Meal';
import ContentBlock from '@components/layout/createHeader/contentBlock/ContentBlock';
import ContentRow from '@components/layout/createHeader/contentBlock/ContentRow';
import ContentItem from '@components/layout/createHeader/contentBlock/ContentItem';
import BackButton from '@components/generic/backButton/BackButton';

const MealPage: React.FC<MealProps> = ({ meal, mealTypes, mealTimes }) => {
    if (!meal) return null;

    const {
        id,
        name,
        mealType,
        createdOn,
        costPerDish,
        isVegetarian,
        isVegan,
        isDairyFree,
        isGlutenFree,
        isNutFree,
        isEggFree,
        notes,
        mealTime,
    } = meal;

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <BackButton href="/wedding/catering/menu/" />

                <Title>{name}</Title>

                <ContentBlock>
                    <ContentRow>
                        <ContentItem label="Name">
                            <p>{`${name}`}</p>
                        </ContentItem>
                        <ContentItem label="Meal type">
                            <p>
                                {`${
                                    mealTypes?.find((type: MealType) => type?.value === mealType)
                                        ?.display
                                }
                            `}
                            </p>
                        </ContentItem>
                        <ContentItem label="Meal time">
                            <p>
                                {`${
                                    mealTimes?.find((time: MealTime) => time?.value === mealTime)
                                        ?.display
                                }
                            `}
                            </p>
                        </ContentItem>
                    </ContentRow>

                    <ContentRow>
                        <ContentItem label="Vegetarian">
                            <p>{`${isVegetarian ? 'Yes' : 'No'}`}</p>
                        </ContentItem>
                        <ContentItem label="Vegan">
                            <p>{`${isVegan ? 'Yes' : 'No'}`}</p>
                        </ContentItem>
                        <ContentItem label="Dairy free">
                            <p>{`${isDairyFree ? 'Yes' : 'No'}`}</p>
                        </ContentItem>
                    </ContentRow>

                    <ContentRow>
                        <ContentItem label="Gluten free">
                            <p>{`${isGlutenFree ? 'Yes' : 'No'}`}</p>
                        </ContentItem>
                        <ContentItem label="Nut free">
                            <p>{`${isNutFree ? 'Yes' : 'No'}`}</p>
                        </ContentItem>
                        <ContentItem label="Egg free">
                            <p>{`${isEggFree ? 'Yes' : 'No'}`}</p>
                        </ContentItem>
                    </ContentRow>

                    <ContentRow>
                        <ContentItem label="Created on">
                            <p>{`${dayjs(createdOn).format('DD-MM-YYYY')}`}</p>
                        </ContentItem>
                        <ContentItem label="Cost per dish">
                            <p>{`£${costPerDish}`}</p>
                        </ContentItem>
                        <ContentItem label="Notes">
                            <p>{`${notes ? notes : '-'}`}</p>
                        </ContentItem>
                    </ContentRow>
                </ContentBlock>

                <ButtonRow alignment="left">
                    <LinkButton source="secondary" href={`/wedding/catering/menu/${id}/edit`}>
                        Edit
                    </LinkButton>
                    <LinkButton source="negative" href={`/wedding/catering/menu/${id}/delete`}>
                        Delete
                    </LinkButton>
                </ButtonRow>
            </div>
        </div>
    );
};

interface MealProps {
    meal: Meal | undefined;
    mealTypes: MealType[];
    mealTimes: MealTime[];
    isFetching: boolean;
}
export default MealPage;
