import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { UpdateSupplierRequest, Supplier } from 'src/types/Supplier';

export const patchUpdateSupplierRequest = createAction('patchUpdateSupplierRequest');
export const patchUpdateSupplierSuccess = createAction<Supplier>('patchUpdateSupplierSuccess');
export const patchUpdateSupplierFailure = createAction('patchUpdateSupplierFailure');

export const patchUpdateSupplier =
    (postBody: UpdateSupplierRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(patchUpdateSupplierRequest());
        try {
            const { data } = await api.patch<UpdateSupplierRequest, Supplier>('supplier', postBody);
            dispatch(patchUpdateSupplierSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, patchUpdateSupplierFailure, e as APIError);
        }
    };
