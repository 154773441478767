import ActionButton from 'lib/src/components/button/ActionButton';
import React, { FC, useEffect, useState } from 'react';
import { Sections } from '../TablePlanner';
import { fabric } from 'fabric';
import { PlannerCanvas } from '@pages/tablePlanner/controls/controlPanel/ControlPanel';

export const minZoom = 0.3;
export const maxZoom = 1.5;

const ToolbarSection: FC<IToolbarProps> = props => {
    const { onClickNext, onClickPrev, section } = props;
    const sections = Object.keys(Sections).filter(v => isNaN(Number(v)));
    const disableNext = section + 1 >= sections.length;
    const disablePrev = section <= 0;
    const sectionClamp = (sectionId: number) =>
        Math.max(0, Math.min(sections.length - 1, sectionId));
    const parseSectionName = (name: string) => {
        switch (name) {
            case 'Floorplan':
                return 'Floor Plan';
            case 'Guestseats':
                return 'Guest Seats';
            default:
                return name;
        }
    };
    return (
        <div className="toolbar">
            <ActionButton type="button" onClick={onClickPrev} disabled={disablePrev}>
                <i className="icon fal fa-fw fa-arrow-left" style={{ color: 'white' }} />
                Back to step {Math.max(sectionClamp(section), 1)}:{' '}
                {parseSectionName(sections[sectionClamp(section - 1)])}
            </ActionButton>
            <ActionButton type="button" onClick={onClickNext} disabled={disableNext}>
                <span>
                    Next step: {sectionClamp(section + 1) + 1}:{' '}
                    {parseSectionName(sections[sectionClamp(section + 1)])}
                </span>
                <i className="icon fal fa-fw fa-arrow-right" />
            </ActionButton>
        </div>
    );
};

const zoomOut = (canvas: fabric.Canvas | undefined, setZoom: (n: number) => void) => {
    if (!canvas) return;
    const zoom = canvas.getZoom();
    let newZoom = zoom - 0.1;
    if (newZoom < minZoom) newZoom = minZoom;
    setZoom(newZoom);
    canvas.setZoom(newZoom);
};

const zoomIn = (canvas: fabric.Canvas | undefined, setZoom: (n: number) => void) => {
    if (!canvas) return;
    const zoom = canvas.getZoom();
    let newZoom = zoom + 0.1;
    if (newZoom > maxZoom) newZoom = maxZoom;
    setZoom(newZoom);
    canvas.setZoom(newZoom);
};

const ToolbarZoom: FC<IToolbarProps> = ({ canvas }) => {
    const [zoom, setZoom] = useState<number>(canvas?.getZoom() ?? 0);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!canvas) return;
        let newZoom = event.target.valueAsNumber;
        if (!newZoom) return;
        if (newZoom > maxZoom) newZoom = maxZoom;
        if (newZoom < minZoom) newZoom = minZoom;
        setZoom(newZoom);
        canvas.setZoom(newZoom);
    };

    // initialise zooming and panning
    useEffect(() => {
        if (!canvas) return;
        canvas.on('mouse:wheel', function (this: fabric.Canvas, opt) {
            const delta = opt.e.deltaY;
            let zoom = canvas.getZoom();
            zoom *= 0.999 ** delta;
            zoom = Number(zoom.toFixed(2));
            if (zoom > maxZoom) zoom = maxZoom;
            if (zoom < minZoom) zoom = minZoom;
            document.dispatchEvent(new Event('zoom'));
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            // triggerRerender();
        });
        canvas.on('mouse:down', function (this: PlannerCanvas, opt) {
            const evt = opt.e;
            // todo panning without alt key
            if (evt.altKey) {
                this.isDragging = true;
                canvas.selection = false;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            }
        });
        canvas.on('mouse:move', function (this: PlannerCanvas, opt) {
            if (this.isDragging) {
                const e = opt.e;
                const vpt = this.viewportTransform ?? [];
                vpt[4] += e.clientX - this.lastPosX;
                vpt[5] += e.clientY - this.lastPosY;
                this.requestRenderAll();
                this.lastPosX = e.clientX;
                this.lastPosY = e.clientY;
            }
        });
        canvas.on('mouse:up', function (this: PlannerCanvas, opt) {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            this.setViewportTransform(this.viewportTransform ?? []);
            this.isDragging = false;
            canvas.selection = true;
        });
        return () => {
            canvas.off('mouse:wheel');
            canvas.off('mouse:down');
            canvas.off('mouse:move');
            canvas.off('mouse:up');
        };
    }, [canvas]);

    useEffect(() => {
        if (!canvas) return;
        setZoom(canvas.getZoom());
        const updateZoom = () => setZoom(canvas?.getZoom() ?? 0);
        document.addEventListener('zoom', updateZoom);

        return () => document.removeEventListener('zoom', updateZoom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas]);
    return (
        <div className="toolbar zoom">
            <strong>Unit of measurement = cm</strong>
            <div className="zoom-buttons">
                <button className="button primary" onClick={() => zoomOut(canvas, setZoom)}>
                    -
                </button>
                <input
                    type="range"
                    min={minZoom}
                    max={maxZoom}
                    step={0.05}
                    onChange={handleChange}
                    value={zoom}
                />
                <button className="button primary" onClick={() => zoomIn(canvas, setZoom)}>
                    +
                </button>
            </div>
        </div>
    );
};

const Toolbar: FC<IToolbarProps> = props => (
    <>
        <ToolbarZoom {...props} />
        <ToolbarSection {...props} />
    </>
);

interface IToolbarProps {
    onClickNext: () => void;
    onClickPrev: () => void;
    section: number;
    canvas: fabric.Canvas | undefined;
}

export default React.memo(Toolbar);
