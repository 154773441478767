import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { TimingDayEnum } from 'lib/src/shared/enums';
import { Timing } from '../../../types/Timing';

export const postTimingRequest = createAction('postTimingRequest');
export const postTimingSuccess = createAction<Timing>('postTimingSuccess');
export const postTimingFailure = createAction('postTimingFailure');

export const postTiming =
    (postBody: PostTimingRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postTimingRequest());
        try {
            const { data } = await api.post<PostTimingRequest, Timing>('timing', postBody);
            dispatch(postTimingSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postTimingFailure, e as APIError);
        }
    };

export interface PostTimingRequest {
    name: string;
    supplierID: number;
    time: string;
    day: TimingDayEnum;
}
