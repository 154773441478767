import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Guest from '../../../types/Guest';

export const fetchGuestsRequest = createAction('fetchGuestsRequest');
export const fetchGuestsSuccess = createAction<Guest[]>('fetchGuestsSuccess');
export const fetchGuestsFailure = createAction('fetchGuestsFailure');

export const fetchGuests =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchGuestsRequest());
        try {
            const { data } = await api.get<Guest[]>('guest');
            dispatch(fetchGuestsSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchGuestsFailure, e as APIError);
        }
    };
