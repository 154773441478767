import Routes from './routes';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import useGlobalRedirect from 'lib/src/hooks/useGlobalRedirect';
import useDarkMode from 'lib/src/hooks/useDarkMode';
import Nav from '@components/nav/Nav';

const App: React.FC = () => {
    useGlobalRedirect();
    const [darkMode] = useDarkMode();
    return (
        <div id="web-body" className="body" data-theme={darkMode ? 'light' : 'light'}>
            <Header />
            <Nav />
            <div id="web-wrapper">
                <div className="">
                    <Routes />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default App;
