import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';
import { deleteMeal } from '@actions/meal';
import { selectMealsDeleteSuccess, selectMealsError, selectMealsIsDeleting } from '@selectors/meal';

export default function useDeleteMeal(id: number): UseDeleteMealReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteMeal(id));
    }, [dispatch, id]);

    const isPosting = useSelector(selectMealsIsDeleting);
    const error = useSelector(selectMealsError);
    const deleteSuccess = useSelector(selectMealsDeleteSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace('/wedding/catering/menu/');
        }
    }, [deleteSuccess, prevDeleteSuccess, history]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteMealReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
