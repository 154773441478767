import React from 'react';
import { FormInputProps } from '../../types/shared/FormInputProps';
import ActionButton from '../button/ActionButton';
import ButtonRow from '../button/ButtonRow';
import FormField from '../form/FormField';
import FileUpload from './FileUpload';
import useFilePicker from './hooks/useFilePicker';

interface FilePickerProps extends FormInputProps<string[] | null> {
    maxFiles?: number;
    apiURL: string;
    buttonColor?: string | undefined;
    buttonExtraClasses?: string;
    storageURL: string;
    label?: string;
    acceptedTypes?: string;
    className?: string;
}

const FilePicker: React.FC<FilePickerProps> = ({
    name,
    label,
    required,
    customValidate,
    value,
    maxFiles = 1,
    apiURL,
    storageURL,
    onChange,
    acceptedTypes,
    className = '',
}) => {
    let source;

    const {
        inputRef,
        filePicker,
        error,
        handleAddFileClick,
        handleUpload,
        handleDelete,
        handleCancel,
        organizeS3KeysByType,
    } = useFilePicker({
        name,
        required,
        customValidate,
        value,
        source,
        maxFiles,
        apiURL,
        onChange,
        acceptedTypes,
    });

    const { fileS3Keys, progress, uploadingFileName, softError } = filePicker;

    return (
        <>
            <FormField name={name} label={label} required={required} error={error}>
                <div className={`file-drop-container size-lg-12 ${className}`}>
                    {fileS3Keys && fileS3Keys.length < maxFiles && (
                        <ButtonRow alignment="left">
                            <ActionButton onClick={handleAddFileClick}>Browse</ActionButton>
                        </ButtonRow>
                    )}

                    <FileUpload
                        name={name}
                        maxFiles={maxFiles}
                        fileS3Keys={fileS3Keys}
                        organizeS3KeysByType={organizeS3KeysByType}
                        inputRef={inputRef}
                        handleUpload={handleUpload}
                        handleDelete={handleDelete}
                        handleCancel={handleCancel}
                        progress={progress}
                        uploadingFileName={uploadingFileName}
                        storageURL={storageURL}
                    />
                </div>
            </FormField>
            {!!softError && <p className="form-error red-text text-accent-4">{softError}</p>}
        </>
    );
};

export default FilePicker;
