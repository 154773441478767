import { TimingDayEnum } from 'lib/src/shared/enums';
import { useMemo } from 'react';
import { Timing } from 'src/types/Timing';
import Wedding from 'src/types/Wedding';

const useTimingsAndMeals = (timings: Timing[], wedding: Wedding | null) => {
    const timingsData = useMemo((): TimingsAndMeals[] => {
        if (!wedding) return [];

        const timingsData = timings.map(({ id, name, supplierID, day, time }) => ({
            id,
            name,
            supplierID,
            day,
            time,
            canModify: true,
        }));

        const emptyMealData = {
            supplierID: 0,
            day: TimingDayEnum.DayOf,
            canModify: false,
        };

        const canapeMeal = {
            ...emptyMealData,
            id: 0,
            name: 'Canape Meal',
            time: wedding.canapeTime || '',
        };
        const weddingBreakfastMeal = {
            ...emptyMealData,
            id: -1,
            name: 'Wedding Breakfast Meal',
            time: wedding.weddingBreakfastTime || '',
        };
        const eveningMeal = {
            ...emptyMealData,
            id: -2,
            name: 'Evening Meal',
            time: wedding.eveningMealTime || '',
        };
        const meals = [canapeMeal, weddingBreakfastMeal, eveningMeal];

        return timingsData.concat(meals);
    }, [timings, wedding]);

    return timingsData;
};

export interface TimingsAndMeals {
    id: number;
    name: string;
    supplierID: number;
    day: TimingDayEnum;
    time: string;
    canModify: boolean;
}

export default useTimingsAndMeals;
