import { DropdownOption } from "../../types/shared/DropdownOption";

export enum ChairType {
    Standard = 0,
    HighChair = 1,
    ChildChair = 2,
    WheelChair = 3,
    None = 99,
}

export const chairTypeOptions: DropdownOption<ChairType>[] = [
    {
        value: ChairType.Standard,
        label: 'Standard',
    },
    {
        value: ChairType.HighChair,
        label: 'High Chair',
    },
    {
        value: ChairType.ChildChair,
        label: 'Child Chair',
    },
    {
        value: ChairType.WheelChair,
        label: 'Wheelchair',
    }
];
