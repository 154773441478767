import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectQuestionCategoriesForAllSuppliers,
    selectQuestionCategoriesForAllSuppliersArr,
    selectQuestionsFetchError,
    selectQuestionsIsFetching,
} from '@selectors/questions';
import { fetchQuestionsForAllSuppliers } from '@actions/questions/fetchQuestionsForAllSuppliers';
import useIsLoggedIn from '../../../hooks/useIsLoggedIn';

const useSupplierQuestionsForAllSuppliersFetch = () => {
    const { isLoggedIn } = useIsLoggedIn();
    const dispatch = useDispatch();
    const isFetching = useSelector(selectQuestionsIsFetching);
    const fetchError = useSelector(selectQuestionsFetchError);
    const questionCategoriesForAllSuppliers = useSelector(selectQuestionCategoriesForAllSuppliers);
    const questionCategoriesForAllSuppliersArr = useSelector(
        selectQuestionCategoriesForAllSuppliersArr,
    );

    useEffect(() => {
        if (isLoggedIn) dispatch(fetchQuestionsForAllSuppliers());
    }, [dispatch, isLoggedIn]);

    return {
        isFetching,
        fetchError,
        questionCategoriesForAllSuppliers,
        questionCategoriesForAllSuppliersArr,
    };
};

export default useSupplierQuestionsForAllSuppliersFetch;
