import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { GuestsForChairs } from 'src/types/TablePlannerResponse';

export const fetchGuestsForChairsRequest = createAction('fetchGuestsForChairsRequest');
export const fetchGuestsForChairsSuccess = createAction<GuestsForChairs>(
    'fetchGuestsForChairsSuccess',
);
export const fetchGuestsForChairsFailure = createAction('fetchGuestsForChairsFailure');

export const fetchGuestsForChairs =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchGuestsForChairsRequest());
        try {
            const { data } = await api.get<GuestsForChairs>('tableplanner/guestsforchairs');
            dispatch(fetchGuestsForChairsSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchGuestsForChairsFailure, e as APIError);
        }
    };
