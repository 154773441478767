import FlipNumbers from 'react-flip-numbers';

const CountdownNumber = ({ numbers, title }: CountdownNumberProps) => {
    return (
        <div className="countdown">
            <div className="numbers">
                <FlipNumbers
                    height={50}
                    width={50}
                    color="black"
                    background="white"
                    play
                    perspective={100}
                    numberStyle={{ fontSize: '12px' }}
                    numbers={numbers}
                />
                <div className="overlay">
                    <div className="overlay-box-left"></div>
                    <div className="overlay-box-right"></div>
                    <div className="overlay-box-across"></div>
                </div>
            </div>
            <span className="text">{title}</span>
        </div>
    );
};

interface CountdownNumberProps {
    numbers: string;
    title: string;
}

export default CountdownNumber;
