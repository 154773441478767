import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import TablePlannerResponse from '../../../types/TablePlannerResponse';

export const fetchTablePlannerRequest = createAction('fetchTablePlannerRequest');
export const fetchTablePlannerSuccess = createAction<TablePlannerResponse>(
    'fetchTablePlannerSuccess',
);
export const fetchTablePlannerFailure = createAction('fetchTablePlannerFailure');

export const fetchTablePlanner =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchTablePlannerRequest());
        try {
            const { data } = await api.get<TablePlannerResponse>('tableplanner');
            dispatch(fetchTablePlannerSuccess(data));
        } catch (e) {
            console.error({ e });
            handleApiErrors(dispatch, fetchTablePlannerFailure, e as APIError);
        }
    };
