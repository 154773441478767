// alignment options are: left, center, right
const Title: React.FC<TitleProps> = ({ children, alignment = '', className = '', tag = 'h1', ...props }) => {
    const Tag = tag;

    return (
        <Tag className={`page-title ${alignment} ${className}`} {...props}>
            {children}
        </Tag>
    );
};

interface TitleProps {
    children: React.ReactNode;
    alignment?: string;
    className?: string;
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export default Title;
