const ButtonRow: React.FC<ButtonRowProps> = ({ children, alignment = 'center', extraStyle }) => (
    <div className={`button-row ${alignment}`} style={extraStyle}>
        {children}
    </div>
);

interface ButtonRowProps {
    children: React.ReactNode;
    alignment?: string;
    extraStyle?: React.CSSProperties;
}

export default ButtonRow;
