import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';
import { SupplierType } from 'src/types/SupplierType';
import useAddSupplier from './hooks/useAddSupplier';
import NumberInput from 'lib/src/components/form/NumberInput';
import DatePicker from 'lib/src/components/form/DatePicker';
import Checkbox from 'lib/src/components/form/Checkbox';

const CreateCustomSuppliersModal: React.FC<CreateSupplierProps> = ({
    closeModal,
    supplierTypes,
}) => {
    const {
        form: {
            name,
            supplierTypeID,
            customType,
            email,
            contactInformation,
            notes,
            cost,
            deposit,
            paid,
            depositDueDate,
            depositPaid,
            remainingDueDate,
            remainingPaid,
        },
        handleChange,
        handleSubmit,
        isPosting,
    } = useAddSupplier(closeModal);

    const supplierTypesArr = Object.values(supplierTypes);
    let supplierTypesOptions = supplierTypesArr.map(item => ({
        value: item.id,
        label: item.name,
    }));

    supplierTypesOptions = [...supplierTypesOptions, { value: 0, label: 'Other' }];

    const eightyYearsBeforeNow = new Date(new Date().setFullYear(new Date().getFullYear() - 80));
    const tenYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

    return (
        <Modal title="Add supplier">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <Select
                    name="supplierTypeID"
                    value={supplierTypeID}
                    options={supplierTypesOptions}
                    onChange={handleChange}
                    label="Choose supplier type"
                    search
                    required
                />
                {supplierTypeID === 0 && (
                    <FormRow>
                        <TextInput
                            name="customType"
                            value={customType === null ? '' : customType}
                            onChange={handleChange}
                            label="Custom type"
                            required
                        />
                    </FormRow>
                )}
                <FormRow>
                    <TextInput
                        type="name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        label="Name"
                        required
                    />
                </FormRow>

                <FormRow>
                    <TextInput
                        name="email"
                        value={email}
                        onChange={handleChange}
                        label="Email"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="contactInformation"
                        value={contactInformation}
                        onChange={handleChange}
                        label="Contact information"
                    />
                </FormRow>
                <FormRow>
                    <TextInput name="notes" value={notes} onChange={handleChange} label="Notes" />
                </FormRow>
                <FormRow>
                    <NumberInput
                        name="cost"
                        value={cost}
                        onChange={handleChange}
                        label="Cost"
                        min={0}
                    />
                </FormRow>
                {!!cost && (
                    <FormRow>
                        <NumberInput
                            name="deposit"
                            value={deposit}
                            onChange={handleChange}
                            label="Deposit"
                            max={cost}
                            min={0}
                        />
                    </FormRow>
                )}
                <FormRow>
                    <NumberInput
                        name="paid"
                        value={paid ? paid : 0}
                        onChange={handleChange}
                        label="Paid"
                        min={0}
                    />
                </FormRow>
                {!!deposit && (
                    <>
                        <FormRow>
                            <DatePicker
                                label="Deposit Due Date"
                                name="depositDueDate"
                                value={depositDueDate ? new Date(depositDueDate) : null}
                                onChange={handleChange}
                                maxDate={
                                    remainingDueDate ? new Date(remainingDueDate) : tenYearsFromNow
                                }
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Checkbox
                                name="depositPaid"
                                value={depositPaid}
                                onChange={handleChange}
                                label={'Is Deposit Paid'}
                            />
                        </FormRow>
                    </>
                )}
                {!!cost && (
                    <>
                        <FormRow>
                            <DatePicker
                                label="Remaining Due Date"
                                name="remainingDueDate"
                                value={remainingDueDate ? new Date(remainingDueDate) : null}
                                onChange={handleChange}
                                minDate={
                                    depositDueDate ? new Date(depositDueDate) : eightyYearsBeforeNow
                                }
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Checkbox
                                name="remainingPaid"
                                value={remainingPaid}
                                onChange={handleChange}
                                label={'Is Remaining Paid'}
                            />
                        </FormRow>
                    </>
                )}
            </Form>
        </Modal>
    );
};

interface CreateSupplierProps {
    closeModal: () => void;
    supplierTypes: Record<number, SupplierType>;
}

export default CreateCustomSuppliersModal;
