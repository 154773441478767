import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import Wall from '../../../../types/Wall';
import DraggableWall from '@pages/tablePlanner/controls/controlPanel/components/DraggableWall';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';
import Description from 'lib/src/components/typography/Description';
import { tableTextFontSize } from '@pages/tablePlanner/controls/tables/chairs';

const WallControls: React.FC<WallControlsProps> = ({ setDropItem }) => {
    return (
        <div className="table-planner-menu-section">
            <Description>Walls</Description>
            <div className="table-planner-menu-grid">
                <DraggableWall setDropItem={setDropItem} length={200} />
            </div>
        </div>
    );
};

export const onAddWall = (
    canvas: fabric.Canvas,
    wall: Wall | null = null,
    width = wall?.width ?? 200,
    left = wall?.left ?? 100,
    top = wall?.top ?? 100,
    angle = wall?.angle ?? 0,
) => {
    const group = new fabric.Group([], {
        width,
        left,
        height: 10,
        top,
        angle,
        snapAngle: 15,
        data: {
            type: PlannerObjectType.Wall,
            id: wall?.id ?? v4(),
        },
        lockSkewingX: true,
        lockSkewingY: true,
        objectCaching: false,
    });

    const lineConfig: Partial<fabric.Line> = {
        stroke: 'black',
        strokeWidth: 5,
        left: -width * 0.5,
        top: -2.5,
        width,
        selectable: false,
        data: {
            type: PlannerObjectType.Wall,
            id: wall?.id ?? v4(),
        },
    };
    const line = new fabric.Line();
    const text = new fabric.Text(`${Math.round(width * 10) / 10}cm`, {
        top: -40,
        fontSize: tableTextFontSize,
        originX: 'center',
        originY: 'center',
        angle: -angle,
        lockSkewingX: true,
        lockSkewingY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockScalingFlip: true,
        objectCaching: false,
    });
    group.add(text);
    line.set(lineConfig);

    // hide non-length controls
    group.setControlsVisibility({
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mt: false,
        mb: false,
    });
    group.add(line);

    canvas.add(group);
};

interface WallControlsProps {
    setDropItem: (item: DropItemConfig | null) => void;
}

export default WallControls;
