import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const unassignMealRequest = createAction('unassignMealRequest');
export const unassignMealSuccess = createAction('unassignMealSuccess');
export const unassignMealFailure = createAction('unassignMealFailure');

export const unassignMeal =
    (guestID: number, mealID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(unassignMealRequest());
        try {
            await api.delete(`meal/assign?guest=${guestID}&meal=${mealID}`);
            dispatch(unassignMealSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, unassignMealFailure, e as APIError);
        }
    };
