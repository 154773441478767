import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Timing } from 'src/types/Timing';

export const fetchTimingsRequest = createAction('fetchTimingsRequest');
export const fetchTimingsSuccess = createAction<Timing[]>('fetchTimingsSuccess');
export const fetchTimingsFailure = createAction('fetchTimingsFailure');

export const fetchTimings =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchTimingsRequest());
        try {
            const { data } = await api.get<Timing[]>('timing');
            dispatch(fetchTimingsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchTimingsFailure, e as APIError);
        }
    };
