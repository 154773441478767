import { useDispatch, useSelector } from 'react-redux';

import { clearFormError, showFieldErrors } from '../../redux/actions/fieldErrors';
import { getFieldErrors, getFormError } from '../../redux/selectors/fieldErrors';
import { isEmpty } from '../../utils/generic';

import ActionButton from '../button/ActionButton';
import ButtonRow from '../button/ButtonRow';
import { useEffect } from 'react';

const Form: React.FC<FormProps> = ({
    children,
    onSubmit,
    onCancel = () => {},
    omitButtons = false,
    omitCancel = false,
    isPosting = false,
    isDisabled = false,
    submitText = 'Submit',
    cancelText = 'Cancel',
    className = 'form',
    specificFieldErrors = [],
    buttonAlignment = 'right',
}) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError);
    useEffect(() => {
        return () => {
            dispatch(clearFormError());
        };
    });

    return (
        <form className={className} onSubmit={_handleSubmit}>
            {children}
            {!!formError && <p className="form-generic-error">{formError}</p>}
            {!omitButtons && (
                <ButtonRow alignment={buttonAlignment}>
                    {!omitCancel && (
                        <ActionButton
                            source="secondary"
                            type="button"
                            onClick={_handleCancel}
                            disabled={isPosting}
                        >
                            {cancelText}
                        </ActionButton>
                    )}
                    <ActionButton isPosting={isPosting} disabled={isDisabled}>
                        {submitText}
                    </ActionButton>
                </ButtonRow>
            )}
        </form>
    );

    function _handleCancel(e: React.MouseEvent) {
        e.preventDefault();

        if (!isPosting) onCancel();
    }

    function _handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!isEmpty(specificFieldErrors)) {
            if (Object.keys(fieldErrors).some(err => specificFieldErrors.includes(err))) {
                dispatch(showFieldErrors());
            } else if (!isPosting) {
                dispatch(clearFormError());
                onSubmit();
            }

            return;
        }

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

interface FormProps {
    children?: React.ReactNode;
    className?: string;
    isPosting?: boolean;
    isDisabled?: boolean;
    omitButtons?: boolean;
    omitCancel?: boolean;
    onSubmit: () => void;
    onCancel?: () => void;
    submitText?: string;
    cancelText?: string;
    specificFieldErrors?: string[];
    buttonAlignment?: 'left' | 'center' | 'right';
}

export default Form;
