import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Title from 'lib/src/components/typography/Title';
import { ResourceCategory } from 'src/types/Resource';
import ActionButton from 'lib/src/components/button/ActionButton';
import Form from 'lib/src/components/form/Form';
import { fetchResourcesBySearchTerm, fetchResourcesByTag } from '@actions/resources';
import {
    getAllResourcesBySearchTermArr,
    getAllResourcesByTagArr,
    getResourcesBySearchTermFetchSuccess,
    getResourcesByTagFetchSuccess,
    getResourcesIsFetching,
} from '@selectors/resources';
import usePrevious from 'lib/src/hooks/usePrevious';
import useFetchAllTags from './hooks/useFetchAllTags';
import ResourceCard from '@components/resources/ResourceCard';

const ResourcesCategories: React.FC<ResourcesCategoriesProps> = ({ resourcesCategoriesArr }) => {
    const dispatch = useDispatch();
    const { tagsArr } = useFetchAllTags();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const prevSelectedTags = usePrevious(selectedTags);
    const isFetching = useSelector(getResourcesIsFetching);
    const resourcesBySearchTermFetchSuccess = useSelector(getResourcesBySearchTermFetchSuccess);
    const prevResourcesBySearchTermFetchSuccess = usePrevious(resourcesBySearchTermFetchSuccess);
    const resourcesBySearchTermArr = useSelector(getAllResourcesBySearchTermArr);

    const resourcesByTagFetchSuccess = useSelector(getResourcesByTagFetchSuccess);
    const prevResourcesByTagFetchSuccess = usePrevious(resourcesByTagFetchSuccess);
    const resourcesByTagArr = useSelector(getAllResourcesByTagArr);

    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchTerm((e.target as HTMLInputElement)?.value);
    };

    const handleSelectTag = (id: number) => {
        if (selectedTags.includes(id)) {
            setSelectedTags(state => [
                ...state.slice(0, state.indexOf(id)),
                ...state.slice(state.indexOf(id) + 1),
            ]);
        } else {
            setSelectedTags(state => [...state, id]);
        }
    };

    useEffect(() => {
        if (selectedTags !== prevSelectedTags) {
            dispatch(fetchResourcesByTag(selectedTags));
        }
    }, [
        dispatch,
        prevResourcesByTagFetchSuccess,
        prevSelectedTags,
        resourcesByTagFetchSuccess,
        selectedTags,
    ]);

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <Title>Resources</Title>

                <div className="card-grid-buttons">
                    {tagsArr.map(tag => (
                        <button
                            key={tag.id}
                            className={`card-grid-button ${
                                selectedTags.includes(tag.id) ? 'is-active' : ''
                            }`}
                            onClick={() => handleSelectTag(tag.id)}
                        >
                            <div>{tag.name}</div>
                        </button>
                    ))}
                </div>

                <Form
                    onSubmit={() => dispatch(fetchResourcesBySearchTerm(searchTerm))}
                    omitButtons={true}
                    isPosting={isFetching}
                >
                    <label className="form-label" htmlFor="searchTerm">
                        Search an article
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '5px',
                            marginBottom: '20px',
                        }}
                    >
                        <input
                            id="searchTerm"
                            className="form-search"
                            name="searchTerm"
                            value={searchTerm}
                            onChange={(e: SyntheticEvent<HTMLInputElement>) => handleChange(e)}
                            style={{ width: '85%' }}
                        />
                        <button
                            type="button"
                            style={{
                                position: 'relative',
                                right: '40px',
                                backgroundColor: 'transparent',
                            }}
                            onClick={() => setSearchTerm('')}
                        >
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <ActionButton
                            type="submit"
                            source="primary"
                            icon="search"
                            isPosting={isFetching}
                        >
                            Search
                        </ActionButton>
                    </div>
                </Form>

                {resourcesBySearchTermFetchSuccess && !prevResourcesBySearchTermFetchSuccess ? (
                    <div className="card-grid-inner">
                        {resourcesBySearchTermArr.map(resource => (
                            <ResourceCard
                                resource={resource}
                                key={resource.id}
                                resourceCategoryId={resource.category.id}
                            />
                        ))}
                    </div>
                ) : resourcesByTagFetchSuccess &&
                  !prevResourcesByTagFetchSuccess &&
                  resourcesByTagArr.length ? (
                    <div className="card-grid-inner">
                        {resourcesByTagArr.map(resource => (
                            <ResourceCard
                                resource={resource}
                                key={resource.id}
                                resourceCategoryId={resource.category.id}
                            />
                        ))}
                    </div>
                ) : (
                    <>
                        <h3 className="resource-categories-title">Resources categories</h3>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto, 1fr)',
                                marginTop: '5px',
                            }}
                        >
                            {resourcesCategoriesArr.map(resourcesCategory => (
                                <div key={resourcesCategory.id}>
                                    <a
                                        href={`/resources-categories/${resourcesCategory.id}`}
                                        className="resource-link"
                                    >
                                        {resourcesCategory.name}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

interface ResourcesCategoriesProps {
    resourcesCategoriesArr: ResourceCategory[];
}

export default ResourcesCategories;
