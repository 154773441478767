import { fabric } from 'fabric';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import { TableType } from 'lib/src/shared/enums/tableType';
import { v4 } from 'uuid';
import { getChairs, tableTextFontSize, chairSize, chairTableMargin } from './chairs';

const imgElement = document.createElement('img');
imgElement.src = '/chair.png';

export const onAddRoundTable = (
    {
        size = 152,
        numberOfChairs = 6,
        isOccupiable = true,
        name = 'Table',
        color = 'white',
        left = 100,
        top = 100,
        tableType = TableType.Round,
    }: Partial<IRoundTableProps>,
    canvas: fabric.Canvas,
) => {
    const tableGroupWidth = size + chairSize * 2 + chairTableMargin;
    const tableGroupHeight = size + chairSize * 2 + chairTableMargin;
    const tableGroupID = v4();
    const tableGroup = new fabric.Group([], {
        selectable: true,
        width: tableGroupWidth,
        height: tableGroupHeight,
        left,
        top,
        snapAngle: 11.25,
        snapThreshold: 10,
        subTargetCheck: true,
        originX: 'center',
        originY: 'center',
        data: {
            id: tableGroupID,
            type: PlannerObjectType.TableGroup,
        },
    });

    const tableRadius = size / 2;
    const tableID = v4();
    const tableTop = -tableRadius;
    const tableLeft = -tableRadius;
    const table = new fabric.Circle({
        radius: tableRadius,
        fill: color,
        left: tableLeft,
        top: tableTop,
        stroke: '#000000',
        angle: 0,
        selectable: false,
        dirty: true,
        data: {
            groupID: tableGroupID,
            id: tableID,
            type: isOccupiable ? PlannerObjectType.Table : PlannerObjectType.TableImmutable,
            tableType,
        },
    });
    tableGroup.add(table);
    const text = new fabric.Textbox(name, {
        fontSize: tableTextFontSize,
        width: tableRadius * 2,
        height: tableGroupHeight,
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        stroke: '#000000',
        angle: 0,
        selectable: false,
        dirty: true,
        data: {
            tableID,
            id: v4(),
            type: PlannerObjectType.TableText,
        },
    });
    tableGroup.add(text);
    tableGroup.setControlsVisibility({
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mt: false,
        mb: false,
        mr: false,
        ml: false,
    });

    if (isOccupiable) {
        const chairs = getChairs(numberOfChairs, table);
        tableGroup.add(...chairs);
    }

    canvas.add(tableGroup);
    tableGroup.dirty = true;
    setTimeout(() => {
        canvas.renderAll();
    }, 500);
};

export interface IRoundTableProps {
    size: number;
    numberOfChairs: number;
    isOccupiable: boolean;
    name: string;
    color: string;
    left?: number;
    top?: number;
    tableType?: TableType;
}

export default onAddRoundTable;
