import { patchGuestSuccess } from '@actions/guests/patchGuest';
import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { AppDispatch } from 'src/redux/store';
import Guest from 'src/types/Guest';

export const unassignGuestToChairSuccess = createAction<IUnassignGuestResponse>(
    'unassignGuestToChairSuccess',
);

export const unassignGuestToChair =
    (chairID: number, guest: Guest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        batch(() => {
            dispatch(unassignGuestToChairSuccess({ chairID }));
            dispatch(patchGuestSuccess({ ...guest, chairID: null }));
        });
    };

export interface IUnassignGuestResponse {
    chairID: number;
}
