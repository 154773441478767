import { useEffect, useState } from 'react';

import { WeddingDetailsFormProps } from '../../../types/Wedding';

import Form from 'lib/src/components/form/Form';
import TextArea from 'lib/src/components/form/TextArea';
import { CeremonyLocationType } from 'lib/src/shared/enums/ceremonyLocationType';
import Select from 'lib/src/components/form/Select';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';
import { CeremonyType } from 'lib/src/shared/enums/ceremonyType';
import { ReceptionLocationType } from 'lib/src/shared/enums/receptionLocationType';
import Description from 'lib/src/components/typography/Description';
import Checkbox from 'lib/src/components/form/Checkbox';
import usePrevious from 'lib/src/hooks/usePrevious';

const ceremonyLocationTypeOptions: DropdownOption<CeremonyLocationType>[] = [
    { value: CeremonyLocationType.Other, label: 'Other' },
    { value: CeremonyLocationType.Church, label: 'Church' },
    { value: CeremonyLocationType.Hotel, label: 'Hotel' },
];

const ceremonyTypeOptions: DropdownOption<CeremonyType>[] = [
    { value: CeremonyType.Celebrant, label: 'Celebrant' },
    { value: CeremonyType.Officiated, label: 'Officiated' },
];

const receptionLocationTypeOptions: DropdownOption<ReceptionLocationType>[] = [
    { value: ReceptionLocationType.Other, label: 'Other' },
    { value: ReceptionLocationType.Hotel, label: 'Hotel' },
    { value: ReceptionLocationType.Venue, label: 'Venue' },
    { value: ReceptionLocationType.Marquee, label: 'Marquee' },
    { value: ReceptionLocationType.Ceremony, label: 'Same as Ceremony' },
];

const receptionLocationTypeOptionsToDisplay: DropdownOption<ReceptionLocationType>[] = [
    { value: ReceptionLocationType.Other, label: 'Other' },
    { value: ReceptionLocationType.Hotel, label: 'Hotel' },
    { value: ReceptionLocationType.Venue, label: 'Venue' },
    { value: ReceptionLocationType.Marquee, label: 'Marquee' },
];

const WeddingCeremonyAndReceptionForm: React.FC<WeddingDetailsFormProps> = ({
    formData,
    error,
    isPosting,
    handleChange,
    handleSubmit,
    showSuccessMessage,
}) => {
    const {
        ceremonyLocation,
        ceremonyLocationType,
        ceremonyType,
        receptionLocation,
        receptionLocationType,
    } = formData;
    const [isReceptionLocationTheSame, setIsReceptionLocationTheSame] = useState(
        receptionLocationType ? false : true,
    );
    const prevIsReceptionLocationTheSame = usePrevious(isReceptionLocationTheSame);

    useEffect(() => {
        if (isReceptionLocationTheSame && !prevIsReceptionLocationTheSame) {
            handleChange('receptionLocationType', ReceptionLocationType.Ceremony);
        } else if (!isReceptionLocationTheSame && prevIsReceptionLocationTheSame) {
            handleChange('receptionLocationType', receptionLocationType);
        }
    }, [
        handleChange,
        isReceptionLocationTheSame,
        prevIsReceptionLocationTheSame,
        receptionLocationType,
    ]);

    return (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            submitText="Save"
            omitCancel
            buttonAlignment="center"
        >
            <TextArea
                onChange={handleChange}
                value={ceremonyLocation ?? ''}
                name="ceremonyLocation"
                label="Ceremony Location"
            />
            <Select
                value={ceremonyLocationType}
                name="ceremonyLocationType"
                label="Ceremony Location Type"
                options={ceremonyLocationTypeOptions}
                onChange={handleChange}
            />
            <Select
                value={ceremonyType}
                name="ceremonyType"
                label="Ceremony Type"
                options={ceremonyTypeOptions}
                onChange={handleChange}
            />
            <Checkbox
                value={isReceptionLocationTheSame}
                name="isReceptionLocationTheSame"
                onChange={() => setIsReceptionLocationTheSame(!isReceptionLocationTheSame)}
                label="Is reception location the same as ceremony?"
            />
            {!isReceptionLocationTheSame && (
                <>
                    <Select
                        value={receptionLocationType}
                        name="receptionLocationType"
                        label="Reception Location Type"
                        options={
                            !isReceptionLocationTheSame
                                ? receptionLocationTypeOptionsToDisplay
                                : receptionLocationTypeOptions
                        }
                        onChange={handleChange}
                        required
                    />
                    {receptionLocationType !== ReceptionLocationType.Ceremony && (
                        <TextArea
                            onChange={handleChange}
                            value={receptionLocation ?? ''}
                            name="receptionLocation"
                            label="Reception Location"
                        />
                    )}
                </>
            )}
            {showSuccessMessage && (
                <Description className="form-generic-success">Changes saved!</Description>
            )}
            {error && <Description className="form-generic-error">{error}</Description>}
        </Form>
    );
};

export default WeddingCeremonyAndReceptionForm;
