import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';

import Description from 'lib/src/components/typography/Description';
import { WeddingDetailsFormProps } from '../../../types/Wedding';

const WeddingGuestsForm: React.FC<WeddingDetailsFormProps> = ({
    formData,
    handleSubmit,
    handleChange,
    error,
    isPosting,
    showSuccessMessage,
}) => {
    const validateGuestNumber = (val: string) => {
        const isInt = /^\d+$/.test(val);
        if (!isInt) return 'Invalid number';
        const num = parseInt(val);
        if (num < 0) return 'Invalid number';
        return null;
    };
    const { minDayGuests, maxDayGuests, minEveningGuests, maxEveningGuests } = formData;
    return (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            submitText="Save"
            omitCancel
            buttonAlignment="center"
        >
            <TextInput
                type="number"
                label="Minimum day guests"
                name="minDayGuests"
                value={(minDayGuests ?? 2).toString()}
                // customValidate={validateGuestNumber}
                minNumber={0}
                onChange={(name, value) => {
                    handleChange(name, +value);
                }}
            />
            <TextInput
                type="number"
                label="Maximum day guests"
                name={'maxDayGuests'}
                value={(maxDayGuests ?? 2).toString()}
                customValidate={validateGuestNumber}
                minNumber={0}
                onChange={(name, value) => handleChange(name, +value)}
            />
            <TextInput
                type="number"
                label="Minimum evening guests"
                name={'minEveningGuests'}
                value={(minEveningGuests ?? 2).toString()}
                customValidate={validateGuestNumber}
                onChange={(name, value) => handleChange(name, +value)}
            />
            <TextInput
                type="number"
                label="Maximum evening guests"
                name={'maxEveningGuests'}
                value={(maxEveningGuests ?? 2).toString()}
                customValidate={validateGuestNumber}
                minNumber={0}
                onChange={(name, value) => handleChange(name, +value)}
            />
            {showSuccessMessage && (
                <Description className="form-generic-success">Changes saved!</Description>
            )}
            {error && <Description className="form-generic-error">{error}</Description>}
        </Form>
    );
};

export default WeddingGuestsForm;
