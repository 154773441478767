import Title from 'lib/src/components/typography/Title';
import { ReactElement } from 'react';

const Banner = ({ title, description, image, reverse }: Props) => {
    return (
        <div
            className={`banner flex-row ${
                reverse ? 'flex-row-reverse reverse' : ''
            } flex-column-pt align-center-pt`}
        >
            <div className="banner-image">
                <img src={image} alt="About Us" />

                <div className="background-box" />
            </div>

            <div className="flex-column justify-center banner-content align-center-pt">
                <Title tag="h4">{title}</Title>

                {description}
            </div>
        </div>
    );
};

interface Props {
    title: string;
    description: ReactElement;
    image?: string;
    reverse?: boolean;
}

export default Banner;
