import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const postForgotPasswordRequest = createAction('postForgotPasswordRequest');
export const postForgotPasswordSuccess = createAction('postForgotPasswordSuccess');
export const postForgotPasswordFailure = createAction('postForgotPasswordFailure');

export const postForgotPassword =
    (postBody: ForgotPasswordRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postForgotPasswordRequest());
        try {
            await api.post('auth/reset-password', postBody);
            dispatch(postForgotPasswordSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postForgotPasswordFailure, e as APIError);
        }
    };

interface ForgotPasswordRequest {
    email: string;
}
