export enum MealTimeEnum {
    WeddingBreakfast = 0,
    EveningMeal = 1,
    Canape = 2,
}

export enum MealTypeEnum {
    Adult = 1,
    Child = 2,
}
