import { RootState } from '@reducers/index';
import { Meal, MealTime, MealType } from 'src/types/Meal';
import MealNotes from '../../types/MealNotes';

export const selectAllMeals = (state: RootState): Record<number, Meal> => state.mealReducer.meals;
export const selectAllMealsArr = (state: RootState): Meal[] =>
    Object.values(state.mealReducer.meals);
export const selectMeal = (state: RootState, id: number): Meal => state.mealReducer.meals[id];
export const selectMealTypes = (state: RootState): MealType[] => state.mealReducer.mealTypes;
export const selectAllMealTimes = (state: RootState): MealTime[] => state.mealReducer.mealTimes;
export const selectMealNotes = (state: RootState): MealNotes | null => state.mealReducer.mealNotes;
export const selectMealsIsFetching = (state: RootState): boolean => state.mealReducer.isFetching;
export const selectMealsError = (state: RootState): string | null => state.mealReducer.error;

export const selectMealsIsPosting = (state: RootState): boolean => state.mealReducer.isPosting;
export const selectMealsPostSuccess = (state: RootState): boolean => state.mealReducer.postSuccess;

export const selectMealsIsPatching = (state: RootState): boolean => state.mealReducer.isPatching;
export const selectMealsPatchSuccess = (state: RootState): boolean =>
    state.mealReducer.patchSuccess;

export const selectMealsIsDeleting = (state: RootState): boolean => state.mealReducer.isDeleting;
export const selectMealsDeleteSuccess = (state: RootState): boolean =>
    state.mealReducer.deleteSuccess;
export const selectMealsUnassignSuccess = (state: RootState): boolean =>
    state.mealReducer.unassignSuccess;

export const selectMealsIsGenerating = (state: RootState): boolean =>
    state.mealReducer.isGenerating;
export const selectMealsGenerateError = (state: RootState): string | null =>
    state.mealReducer.generateError;
export const selectMealsGenerateSuccess = (state: RootState): boolean =>
    state.mealReducer.generateSuccess;
