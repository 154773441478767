export function isEmpty(item: any): boolean {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

function isObjEmpty(obj: Record<string, unknown>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj<T>(arr: T[], field: string = 'id'): Record<string, T> {
    return arr.reduce<Record<string, T>>((acc, item) => {
        // todo
        //@ts-ignore
        acc[item[field]] = item;
        return acc;
    }, {});
}

export const areArraysEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export function getFileName(src: any) {
    return src.match('[^/]*$')[0];
}

export function updateObj(origObj: any, key: string | number, newItem: any) {
    return {
        ...origObj,
        [key]: newItem,
    };
}

export function removeObjItem(obj: any, key: number | string) {
    const {
        [key]: removedItem, // eslint-disable-line
        ...rest
    } = obj;

    return rest;
}

// string and significant figures.
// e.g. ("52.325", 2) => 
export const customFloatInputValidation = (str: string, sf?: number): string => {
    let foundDots = false;
    let newStr = '';
    let counter = 0;
    for (const i of str.replaceAll(/[^0-9.]/g, '').split('')) {
        if (sf !== undefined) {
            if (foundDots && counter++ >= sf) {
                return newStr;
            }
        }
        
        if (i === '.') {
            if (!foundDots) {
                foundDots = true;
            } else continue;
        }
        newStr += i;
    }
    return newStr;
};
