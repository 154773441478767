import Guest from '../../../types/Guest';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useDeleteGuest from '@pages/guests/guestList/hooks/useDeleteGuest';
import { useEffect } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';

const DeleteGuestModal: React.FC<DeleteGuestModalProps> = ({ hideModal, guest }) => {
    const { isDeleting, handleSubmit, deleteSuccess } = useDeleteGuest(guest);

    const prevProps = usePrevious({ deleteSuccess });
    useEffect(() => {
        if (deleteSuccess && !prevProps.deleteSuccess) {
            hideModal();
        }
    });
    return (
        <ConfirmModal
            closeModal={hideModal}
            handleSubmit={handleSubmit}
            isPosting={isDeleting}
            title="Delete guest"
            description={`Are you sure you want to delete this guest (${guest.firstName} ${guest.lastName})? This action cannot be undone.`}
        />
    );
};

interface DeleteGuestModalProps {
    hideModal: () => void;
    guest: Guest;
}

export default DeleteGuestModal;
