import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const postResetPasswordRequest = createAction('postResetPasswordRequest');
export const postResetPasswordSuccess = createAction('postResetPasswordSuccess');
export const postResetPasswordFailure = createAction('postResetPasswordFailure');

export const postResetPassword =
    (postBody: ResetPasswordRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postResetPasswordRequest());
        try {
            await api.post(`auth/reset-password/${postBody.code}`, postBody);
            dispatch(postResetPasswordSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postResetPasswordFailure, e as APIError);
        }
    };

interface ResetPasswordRequest {
    password: string;
    code: string;
}
