import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import fileDownload from 'js-file-download';

export const generateSupplierGuestsPDFRequest = createAction('generateSupplierGuestsPDFRequest');
export const generateSupplierGuestsPDFSuccess = createAction('generateSupplierGuestsPDFSuccess');
export const generateSupplierGuestsPDFFailure = createAction('generateSupplierGuestsPDFFailure');

export const generateSupplierGuestsPDF =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(generateSupplierGuestsPDFRequest());
        try {
            await api
                .get<Blob>('guest/suppliers/pdf', 'blob')
                .then(res => new Blob([res.data], { type: 'application/pdf' }))
                .then(blob => fileDownload(blob, 'WeddingSupplierGuests.pdf'));
            dispatch(generateSupplierGuestsPDFSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, generateSupplierGuestsPDFFailure, e as APIError);
        }
    };
