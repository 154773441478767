import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Wedding from '../../../types/Wedding';

export const fetchWeddingRequest = createAction('fetchWeddingRequest');
export const fetchWeddingSuccess = createAction<Wedding>('fetchWeddingSuccess');
export const fetchWeddingFailure = createAction('fetchWeddingFailure');

export const fetchWedding =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchWeddingRequest());
        try {
            const { data } = await api.get<Wedding>('wedding');
            dispatch(fetchWeddingSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchWeddingFailure, e as APIError);
        }
    };
