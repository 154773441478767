import jwtDecode from 'jwt-decode';

interface JWT {
    nbf: number;
    exp: number;
    WeddingID?: string;
    IsWeddingOwner?: string;
}

export function validateJWT(token: string): boolean {
    try {
        if (!token) return false;
        return !!token;
    } catch {
        return false;
    }
}

export const getJwtToken = (): string | null => {
    const token = localStorage.getItem('jwt');
    return token;
};

export const clearJwtAndRefreshToken = (): void => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('refreshToken');
};

export const getDecodedJwtToken = (): JWT | null => {
    const jwtToken = getJwtToken();

    if (!jwtToken) return null;

    const isValid = validateJWT(jwtToken);

    if (!isValid) {
        return null;
    }

    try {
        const decoded = jwtDecode<JWT>(jwtToken);
        return decoded;
    }
    catch {
        return null;
    }
};

