import Title from 'lib/src/components/typography/Title';
import Description from 'lib/src/components/typography/Description';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import useFetchGuests from '@pages/guests/guestList/hooks/useFetchGuests';
import Table from 'lib/src/components/table/Table';
import AddGuestModal from '@pages/guests/guestList/AddGuestModal';
import { useState } from 'react';
import Guest from '../../../types/Guest';
import DietaryRequirementIcon from '@pages/guests/guestList/DietaryRequirementIcon';
import AttendanceIcon from '@pages/guests/guestList/AttendanceIcon';
import UpdateGuestModal from '@pages/guests/guestList/UpdateGuestModal';
import DeleteGuestModal from '@pages/guests/guestList/DeleteGuestModal';
import config from '../../../config';
import AttendanceDescription from '@pages/guests/guestList/AttendanceDescription';
import { GuestInvite } from 'lib/src/shared/enums/guestInvite';
import { GuestInviteRSVP } from 'lib/src/shared/enums/guestInviteRSVP';
import InviteDescription from '@pages/guests/guestList/InviteDescription';
import useGenerateSupplierGuestsPDF from './hooks/useGenerateSupplierGuestsPDF';
import useGenerateVenueGuestsPDF from './hooks/useGenerateVenueGuestsPDF';
import ErrorModal from 'lib/src/components/modal/ErrorModal';
import { selectWedding } from '@selectors/wedding';
import { useSelector } from 'react-redux';

const { S3_URL } = config;

const getFullName = (guest: Guest) =>
    `${guest.firstName ?? ''} ${guest.middleName ?? ''} ${guest.lastName ?? ''} ${
        guest.nickname ? `(${guest.nickname})` : ''
    }`;

const GuestList = () => {
    const { guests, isFetching } = useFetchGuests();
    const wedding = useSelector(selectWedding);
    const [showAddGuestModal, setShowAddGuestModal] = useState(false);
    const [showUpdateGuestModal, setShowUpdateGuestModal] = useState(false);
    const [showDeleteGuestModal, setShowDeleteGuestModal] = useState(false);
    const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);
    const columns = [
        {
            key: 1,
            heading: 'Profile pic',
            getValue: (row: Guest) =>
                row.pictureS3Key ? (
                    <img
                        className="guest-table-img"
                        style={{
                            height: '3em',
                            width: '3em',
                            borderRadius: '50%',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'black',
                        }}
                        src={`${S3_URL}/${row.pictureS3Key}`}
                        alt="Profile pic"
                    />
                ) : (
                    <div className="icon-container">
                        <i className="fas fa-user" />
                    </div>
                ),
        },
        {
            key: 2,
            heading: 'Full Name',
            getValue: (row: Guest) => getFullName(row),
            getSort: (a: Guest, b: Guest) => getFullName(a).localeCompare(getFullName(b)),
            searchable: true,
        },
        {
            key: 3,
            heading: 'Email',
            getValue: (row: Guest) => row.email,
            getSort: (a: Guest, b: Guest) => a.email.localeCompare(b.email),
            searchable: true,
        },
        {
            key: 4,
            heading: 'Dietary Requirements',
            getValue: (row: Guest) => (
                <div className="centered">
                    <DietaryRequirementIcon guest={row} />
                </div>
            ),
        },
        {
            key: 5,
            heading: 'Canapé',
            getValue: (row: Guest) => (
                <div className="centered">
                    <AttendanceIcon attendance={row.cateringAttendanceCanape} />
                </div>
            ),
        },
        {
            key: 6,
            heading: 'Wedding Breakfast',
            getValue: (row: Guest) => (
                <div className="centered">
                    <AttendanceIcon attendance={row.cateringAttendanceWeddingBreakfast} />
                </div>
            ),
        },
        {
            key: 7,
            heading: 'Evening Meal',
            getValue: (row: Guest) => (
                <div className="centered">
                    <AttendanceIcon attendance={row.cateringAttendanceEveningMeal} />
                </div>
            ),
        },
        {
            key: 8,
            heading: 'Invite',
            getValue: (row: Guest) => <AttendanceDescription guestInvite={row.guestInvitedTo} />,
        },
        {
            key: 9,
            heading: 'RSVP',
            getValue: (row: Guest) => <InviteDescription guestInviteRSVP={row.guestRSVP} />,
        },
        {
            key: 10,
            heading: 'Details',
            getValue: (row: Guest) => (
                <div className="guest-list-page-button-row">
                    <ActionButton
                        onClick={() => {
                            setShowUpdateGuestModal(true);
                            setSelectedGuest(row);
                        }}
                    >
                        Edit
                    </ActionButton>
                    <ActionButton
                        source="negative"
                        onClick={() => {
                            setShowDeleteGuestModal(true);
                            setSelectedGuest(row);
                        }}
                    >
                        Delete
                    </ActionButton>
                </div>
            ),
        },
    ];
    const guestCount = guests.length;
    const notYetInvitedCount = guests.filter(
        guest => guest.guestInvitedTo === GuestInvite.No,
    ).length;
    const fullDayCount = guests.filter(
        ({ guestInvitedTo }) => guestInvitedTo === GuestInvite.FullWedding,
    ).length;
    const eveningOnlyCount = guests.filter(
        ({ guestInvitedTo }) => guestInvitedTo === GuestInvite.EveningOnly,
    ).length;
    const dayOnlyCount = guests.filter(
        ({ guestInvitedTo }) => guestInvitedTo === GuestInvite.DayOnly,
    ).length;

    const attendingCount = guests.filter(
        ({ guestRSVP }) => guestRSVP !== GuestInviteRSVP.No,
    ).length;
    const notAttendingCount = guests.filter(
        ({ guestRSVP }) => guestRSVP === GuestInviteRSVP.No,
    ).length;
    const dayAttendingCount = guests.filter(
        ({ guestRSVP }) =>
            guestRSVP === GuestInviteRSVP.FullWedding || guestRSVP === GuestInviteRSVP.DayOnly,
    ).length;
    const eveningAttendingCount = guests.filter(
        ({ guestRSVP }) =>
            guestRSVP === GuestInviteRSVP.FullWedding || guestRSVP === GuestInviteRSVP.EveningOnly,
    ).length;

    const awaitingResponseCount = guests.filter(
        ({ guestRSVP }) => guestRSVP === GuestInviteRSVP.AwaitingResponse,
    ).length;

    const {
        handleGenerateSupplierGuestsPDF,
        isGeneratingSupplierGuests,
        generateSupplierGuestsError,
        showSupplierGuestsPDFError,
        setShowSupplierGuestsPDFError,
    } = useGenerateSupplierGuestsPDF();

    const {
        handleGenerateVenueGuestsPDF,
        isGeneratingVenueGuests,
        generateVenueGuestsError,
        showVenueGuestsPDFError,
        setShowVenueGuestsPDFError,
    } = useGenerateVenueGuestsPDF();

    const guestCopy = [];
    if (wedding?.minDayGuests) {
        guestCopy.push('Minimum Day Guests: ' + wedding.minDayGuests);
    }
    if (wedding?.maxDayGuests) {
        guestCopy.push('Maximum Day Guests: ' + wedding.maxDayGuests);
    }
    if (wedding?.minEveningGuests) {
        guestCopy.push('Minimum Evening Guests: ' + wedding.minEveningGuests);
    }
    if (wedding?.maxEveningGuests) {
        guestCopy.push('Maximum Evening Guests: ' + wedding.maxEveningGuests);
    }

    return (
        <>
            <div id="guest-list-page" className="page-padding section">
                <div className="section-inner">
                    <>
                        <Title>Guests</Title>
                        <Description>
                            Guests: {guestCount} | Full Wedding: {fullDayCount} | Evening only:{' '}
                            {eveningOnlyCount} | Day only: {dayOnlyCount} | Not yet invited:{' '}
                            {notYetInvitedCount}
                        </Description>
                        <Description>
                            RSVP Total - Yes: {attendingCount} | No: {notAttendingCount} | Day:{' '}
                            {dayAttendingCount} | Evening: {eveningAttendingCount} | Awaiting
                            Response: {awaitingResponseCount}
                        </Description>
                        <Description>{guestCopy.join(' | ')}</Description>
                    </>
                    <ButtonRow alignment="left">
                        <ActionButton onClick={() => setShowAddGuestModal(true)} source="secondary">
                            Add Guest
                        </ActionButton>
                        <ActionButton
                            isPosting={isGeneratingSupplierGuests}
                            onClick={handleGenerateSupplierGuestsPDF}
                        >
                            Export Supplier Guests to PDF
                        </ActionButton>
                        <ActionButton
                            isPosting={isGeneratingVenueGuests}
                            onClick={handleGenerateVenueGuestsPDF}
                        >
                            Export Venue Guests to PDF
                        </ActionButton>
                    </ButtonRow>
                    <div style={{ marginTop: '20px' }}>
                        <Table
                            extraClasses="tableWithBorder"
                            columns={columns}
                            rows={guests}
                            isLoading={isFetching}
                        />
                    </div>
                </div>
            </div>
            {showAddGuestModal && <AddGuestModal hideModal={() => setShowAddGuestModal(false)} />}
            {showUpdateGuestModal && selectedGuest && (
                <UpdateGuestModal
                    guest={selectedGuest}
                    hideModal={() => {
                        setShowUpdateGuestModal(false);
                        setSelectedGuest(null);
                    }}
                />
            )}{' '}
            {showDeleteGuestModal && selectedGuest && (
                <DeleteGuestModal
                    guest={selectedGuest}
                    hideModal={() => {
                        setShowDeleteGuestModal(false);
                        setSelectedGuest(null);
                    }}
                />
            )}
            {showSupplierGuestsPDFError && (
                <ErrorModal
                    description={generateSupplierGuestsError || undefined}
                    closeModal={() => setShowSupplierGuestsPDFError(false)}
                />
            )}
            {showVenueGuestsPDFError && (
                <ErrorModal
                    description={generateVenueGuestsError || undefined}
                    closeModal={() => setShowVenueGuestsPDFError(false)}
                />
            )}
        </>
    );
};

export default GuestList;
