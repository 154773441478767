import Title from 'lib/src/components/typography/Title';
import { FabricJSCanvas, FabricJSEditor } from 'fabricjs-react';
import ControlPanel from '@pages/tablePlanner/controls/controlPanel/ControlPanel';

import useTablePlanner from '@pages/tablePlanner/hooks/useTablePlanner';
import Toolbar from './controls/Toolbar';
import React, { FC, useEffect, useState } from 'react';
import StepBar from './controls/StepBar';
import GuestPanel from './controls/guestPanel/GuestPanel';
import { fabric } from 'fabric';
import { TableType } from 'lib/src/shared/enums/tableType';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import onAddRoundTable from '@pages/tablePlanner/controls/tables/roundTable';
import onAddRectangleTable from '@pages/tablePlanner/controls/tables/rectangleTable';
import { onAddWall } from '@pages/tablePlanner/controls/controlPanel/WallControls';
import { onAddWindow } from '@pages/tablePlanner/controls/controlPanel/WindowControls';
import { onAddDoor } from '@pages/tablePlanner/controls/controlPanel/DoorControls';
import { onAddChair } from '@pages/tablePlanner/controls/tables/chairs';
import { ChairType } from 'lib/src/shared/enums/chairType';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { getMaxChairsForTable } from '@pages/tablePlanner/controls/tables/generic';
import usePrevious from 'lib/src/hooks/usePrevious';
import PrintPanel from './controls/printPanel/PrintPanel';
import SuccessModal from 'lib/src/components/modal/SuccessModal';

export enum Sections {
    Floorplan,
    Guestseats,
    Print,
}
export type SectionType = {
    type: Sections;
    name: string;
};

export const SectionMenu: Record<Sections, string> = {
    [Sections.Floorplan]: 'Floor Plan',
    [Sections.Guestseats]: 'Guest Seats',
    [Sections.Print]: 'Print',
};

export const removeEventListener = (
    canvas: fabric.Canvas,
    eventListenerName: string,
    func: ((...a: any) => any) | string,
) => {
    const name = typeof func === 'function' ? func.name : func;
    // @ts-ignore
    if (!canvas.__eventListeners) return;
    // @ts-ignore
    canvas.__eventListeners[eventListenerName] = canvas.__eventListeners[eventListenerName]?.filter(
        (x: { name: string }) => x.name !== name,
    );
};

const GetPanel = (section: Sections): FC<IPanel> => {
    switch (section) {
        case Sections.Floorplan:
            return ControlPanel;
        case Sections.Guestseats:
            return GuestPanel;
        case Sections.Print:
            return PrintPanel;
        default:
            return () => <></>;
    }
};
interface ConfirmAddChairModalProps {
    handleSubmit: () => void;
    maxChairs: number;
}
const TablePlanner = () => {
    const { editor, selectedObjects, handleReady, handleSubmit, showSuccessModal, hideModal } =
        useTablePlanner();
    const [, setRerender] = useState(0);
    const [handleConfirmAddChair, setHandleConfirmAddChair] =
        useState<ConfirmAddChairModalProps | null>(null);

    const [section, setSection] = useState(Sections.Floorplan);
    const sections = Object.keys(Sections).filter(v => isNaN(Number(v)));
    const [dropItem, setDropItem] = useState<DropItemConfig | null>(null);
    const prevDropItem = usePrevious(dropItem);
    const prevSection = usePrevious(section);

    useEffect(() => {
        // disable moving elements on can
        // vas when section is not floorplan
        if (section !== prevSection) {
            if (section === Sections.Floorplan) {
                editor?.canvas?.forEachObject((obj: fabric.Object) => {
                    if (obj.data) obj.selectable = true;
                });
            } else {
                // deselect all objects
                editor?.canvas?.discardActiveObject();
                for (const obj of editor?.canvas?._objects || []) {
                    if (obj.data) {
                        obj.selectable = false;
                    }
                }
                editor?.canvas.requestRenderAll();
            }
        }
    }, [section, prevSection, editor]);

    const onClickNext = () => setSection(section + 1);
    const onClickPrev = () => setSection(section - 1);
    useEffect(() => {
        if (editor) {
            //@ts-ignore
            editor.canvas.lowerCanvasEl.id = 'table-planner-canvas';
            if (dropItem && dropItem !== prevDropItem) {
                editor.canvas.off('drop');
                const dropListener = ({ e }: any) => {
                    const zoom = editor.canvas?.getZoom();
                    const viewportTransform = editor.canvas?.viewportTransform;
                    const [, , , , xTransform, yTransform] = viewportTransform ?? [];
                    const x = (e.offsetX - xTransform) / zoom;
                    const y = (e.offsetY - yTransform) / zoom;

                    switch (dropItem?.type) {
                        case undefined:
                            break;
                        case PlannerObjectType.Table: {
                            if (
                                dropItem?.tableType === TableType.Round ||
                                dropItem?.tableType === TableType.Cake
                            ) {
                                const name =
                                    dropItem?.tableType === TableType.Round
                                        ? 'Round Table'
                                        : 'Cake Table';
                                const radius = (dropItem.height ?? 0) / 2;
                                const left = x - radius;
                                const top = y - radius;
                                onAddRoundTable(
                                    {
                                        size: dropItem.height,
                                        name,
                                        numberOfChairs: dropItem.numberOfChairs,
                                        left,
                                        top,
                                        isOccupiable: dropItem.tableType !== TableType.Cake,
                                        tableType: dropItem.tableType,
                                    },
                                    editor.canvas,
                                );
                            } else if (dropItem.tableType === TableType.Rectangle) {
                                const left = x - (dropItem.width ?? 0) / 2;
                                const top = y - (dropItem.height ?? 0) / 2;
                                onAddRectangleTable(
                                    dropItem.width,
                                    dropItem.height,
                                    dropItem.numberOfChairs,
                                    editor.canvas,
                                    left,
                                    top,
                                );
                            }

                            handleSubmit();
                            break;
                        }
                        case PlannerObjectType.Wall: {
                            onAddWall(editor.canvas, null, dropItem.width, x, y);
                            break;
                        }
                        case PlannerObjectType.Window: {
                            onAddWindow(editor.canvas, null, dropItem.width, x, y);
                            break;
                        }
                        case PlannerObjectType.Door: {
                            onAddDoor(
                                editor.canvas,
                                null,
                                dropItem.width,
                                dropItem.height,
                                dropItem.angle,
                                dropItem.flip,
                                x,
                                y,
                            );
                            break;
                        }
                        case PlannerObjectType.Chair: {
                            const tables: fabric.Object[] = editor.canvas._objects.filter(obj => {
                                if (obj.data?.type !== PlannerObjectType.TableGroup) return false;
                                // @ts-ignore ts-todo
                                const table = obj._objects.find(
                                    (x: fabric.Object) => x.data?.type === PlannerObjectType.Table,
                                );
                                return !!table && table.data.tableType !== TableType.Cake;
                            });
                            if (!tables.length) {
                                return;
                            }

                            const closestTable = tables.sort((a, b) => {
                                const centreA = a.getCenterPoint();
                                const centreB = b.getCenterPoint();
                                const xDiffA = Math.abs(x - centreA.x);
                                const yDiffA = Math.abs(y - centreA.y);
                                const xDiffB = Math.abs(x - centreB.x);
                                const yDiffB = Math.abs(y - centreB.y);
                                const aDiff = Math.pow(xDiffA + yDiffA, 2);
                                const bDiff = Math.pow(xDiffB + yDiffB, 2);
                                return aDiff - bDiff;
                            })[0];
                            const closestTableCentrePoint = closestTable.getCenterPoint();
                            const xDiff = Math.abs(x - closestTableCentrePoint.x);
                            const yDiff = Math.abs(y - closestTableCentrePoint.y);
                            const closestTableDistance = xDiff + yDiff;
                            const maximumDistance = 75 + (closestTable.height ?? 0) / 2;
                            if (closestTableDistance > maximumDistance) return;

                            // if (closestTableDistance > maximumDistance) {
                            //     // too far away
                            //     return;
                            // }
                            const handleAddChair = () => {
                                onAddChair(
                                    true,
                                    closestTable,
                                    editor.canvas,
                                    () => setRerender(_ => _ + 1),
                                    dropItem.chairType,
                                );
                            };
                            // @ts-ignore ts-todo
                            const table = closestTable._objects.find(
                                (x: fabric.Object) => x.data?.type === PlannerObjectType.Table,
                            );
                            if (table?.data?.tableType === TableType.Cake) return;
                            const maxChairs = getMaxChairsForTable(table);
                            // @ts-ignore ts-todo
                            const chairs: fabric.Object[] = closestTable._objects.filter(
                                (x: fabric.Object) =>
                                    x.data?.type === PlannerObjectType.Chair || x.data?.isChair,
                            );
                            const chairsThatCount = chairs.filter(
                                ({ data }) => data.chairType !== ChairType.HighChair,
                            );
                            if (
                                chairsThatCount.length >= maxChairs &&
                                dropItem.chairType !== ChairType.HighChair
                            ) {
                                setHandleConfirmAddChair({
                                    handleSubmit: handleAddChair,
                                    maxChairs,
                                });
                            } else {
                                handleAddChair();
                            }
                        }
                    }

                    editor?.canvas.renderAll();
                };
                editor?.canvas.on('drop', dropListener);
            }
        }
        return () => {
            editor?.canvas.off('drop');
        };
    }, [editor, dropItem, prevDropItem, editor?.canvas, handleSubmit]);

    const Sidepanel = GetPanel(section);

    return (
        <div id="table-planner-page" className="page-padding">
            <div className="table-planner-mobile-error">
                Table planner app is not available on mobile devices.
            </div>
            <div className="table-planner">
                <div className="table-planner-menu">
                    <Title>Table Planner</Title>
                    <br />
                    {!!editor?.canvas && (
                        <Sidepanel
                            editor={editor}
                            selectedObjects={selectedObjects}
                            handleSubmit={handleSubmit}
                            setDropItem={setDropItem}
                        />
                    )}
                </div>
                <div className="full-bar table-planner-menu">
                    <StepBar section={section} sections={sections} handleSubmit={handleSubmit} />
                    <div className="">Move camera: Alt + Left Click</div>
                    <FabricJSCanvas className="table-planner-canvas" onReady={handleReady} />
                    <Toolbar
                        onClickNext={onClickNext}
                        onClickPrev={onClickPrev}
                        section={section}
                        canvas={editor?.canvas}
                    />
                </div>
            </div>
            {!!handleConfirmAddChair && (
                <ConfirmModal
                    closeModal={() => setHandleConfirmAddChair(null)}
                    handleSubmit={() => {
                        handleConfirmAddChair.handleSubmit();
                        setHandleConfirmAddChair(null);
                    }}
                    title={`Add Chair`}
                    description={`We don't recommend adding more than ${handleConfirmAddChair.maxChairs} chairs to this table due to spacing.`}
                    confirmText="Add anyway"
                />
            )}
            {showSuccessModal && (
                <SuccessModal
                    title="Success"
                    description="Table plan successfully updated."
                    closeModal={hideModal}
                />
            )}
        </div>
    );
};

export interface IPanel {
    editor: FabricJSEditor;
    selectedObjects?: fabric.Object[];
    handleSubmit: () => void;
    setDropItem: (item: any | null) => void;
}

export interface DropItemConfig {
    type: PlannerObjectType;
    tableType?: TableType;
    height?: number;
    width?: number;
    numberOfChairs?: number;
    angle?: number;
    flip?: boolean;
    chairType?: ChairType;
}

export default TablePlanner;
