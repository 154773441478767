import {DropdownOption} from "../../types/shared/DropdownOption";

export enum GuestInvite {
    No = 0,
    FullWedding = 1,
    DayOnly = 2,
    EveningOnly = 3
}

export const guestInviteOptions: DropdownOption<GuestInvite>[] = [
    {
        value: GuestInvite.No,
        label: 'Not yet invited',

    },
    {
        value: GuestInvite.FullWedding,
        label: 'Full Wedding',
    },
    {
        value: GuestInvite.DayOnly,
        label: 'Day Only',
    },
    {
        value: GuestInvite.EveningOnly,
        label: 'Evening Only',
    },
    ]
