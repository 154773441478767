import { TimingDayEnum } from "../shared/enums";

export const TIMING_DAY_NAMES = {
    [TimingDayEnum.DayBefore]: 'Day before wedding',
    [TimingDayEnum.DayOf]: 'Day of wedding',
    [TimingDayEnum.DayAfter]: 'Day after wedding',
}

export const TIMING_DAY_DROPDOWN_OPTIONS = [
    {
        value: TimingDayEnum.DayBefore,
        label: TIMING_DAY_NAMES[TimingDayEnum.DayBefore],
    },
    {
        value: TimingDayEnum.DayOf,
        label: TIMING_DAY_NAMES[TimingDayEnum.DayOf],
    },
    {
        value: TimingDayEnum.DayAfter,
        label: TIMING_DAY_NAMES[TimingDayEnum.DayAfter],
    },
]