import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWedding, postUpdateWedding, UpdateWeddingRequest } from '@actions/weddings';
import {
    selectWedding,
    selectWeddingError,
    selectWeddingIsPosting,
    selectWeddingPostSuccess,
} from '@selectors/wedding';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';

const emptyWeddingRequest: UpdateWeddingRequest = {
    minDayGuests: null,
    maxDayGuests: null,
    minEveningGuests: null,
    maxEveningGuests: null,
    budget: null,
    ceremonyLocation: null,
    ceremonyLocationType: null,
    ceremonyType: null,
    receptionLocation: null,
    receptionLocationType: null,
    date: null,
    canapeTime: null,
    weddingBreakfastTime: null,
    eveningMealTime: null,
};

const useUpdateWedding = () => {
    const { isLoggedIn } = useIsLoggedIn();

    const dispatch = useDispatch();
    useEffect(() => {
        if (isLoggedIn) dispatch(fetchWedding());
    }, [dispatch, isLoggedIn]);

    const wedding = useSelector(selectWedding);
    const isPosting = useSelector(selectWeddingIsPosting);
    const postSuccess = useSelector(selectWeddingPostSuccess);
    const error = useSelector(selectWeddingError);

    const initialForm: UpdateWeddingRequest = useMemo(() => {
        if (!wedding) return emptyWeddingRequest;
        const { id, ...rest } = wedding;
        return rest;
    }, [wedding]);

    const [formData, onChange, resetForm] = useForm<UpdateWeddingRequest>(initialForm);
    const prevProps = usePrevious({ wedding, isPosting, error, postSuccess, initialForm });
    useEffect(() => {
        if (initialForm !== prevProps?.initialForm) {
            resetForm(initialForm);
        }
    }, [initialForm, prevProps.initialForm, resetForm]);

    const handleSubmit = () => {
        dispatch(postUpdateWedding(formData));
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            setShowSuccessMessage(true);
        }
    }, [postSuccess, prevProps.postSuccess, setShowSuccessMessage]);

    const handleChange = <T>(name: keyof UpdateWeddingRequest, value: T) => {
        if (showSuccessMessage) setShowSuccessMessage(false);
        onChange(name, value);
    };

    return { wedding, isPosting, error, formData, handleChange, handleSubmit, showSuccessMessage };
};

export default useUpdateWedding;
