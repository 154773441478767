import {DropdownOption} from "../../types/shared/DropdownOption";

export enum AttendanceResponse {
    AwaitingReply = 0,
    Yes = 1,
    No = 2,
}

export const attendanceOptions: DropdownOption<AttendanceResponse>[] = [
    {
        value: AttendanceResponse.AwaitingReply,
        label: 'Awaiting Reply',
    },
    {
        value: AttendanceResponse.Yes,
        label: 'Attending',

    },
    {
        value: AttendanceResponse.No,
        label: 'Not Attending',
    },
];
