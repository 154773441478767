import React, { ReactNode } from 'react';

const FlexWrapper: React.FC<FlexWrapperProps> = ({
    children,
    direction = 'row',
    wrap = 'nowrap',
    justify = 'start',
    align = 'stretch',
    gap = 0,
    width = 12,
    autoWidth = false,
    id = '',
    extraClasses = '',
}) => {
    const flexDirection = `flex-${direction}`;
    const flexWrap = `flex-${wrap}`;
    const flexJustify = `justify-${justify}`;
    const flexAlign = `align-${align}`;
    const blockWidth = autoWidth ? '' : `width-${width}`;

    return (
        <div
            id={id}
            className={`${flexDirection} ${flexWrap} ${flexJustify} ${flexAlign} ${blockWidth} ${extraClasses}`}
            style={{ gap }}
        >
            {children}
        </div>
    );
};

interface FlexWrapperProps {
    children: ReactNode;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    wrap?: 'wrap' | 'nowrap';
    justify?: 'start' | 'end' | 'center' | 'around' | 'between' | 'evenly';
    align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
    gap?: number;
    width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    autoWidth?: boolean;
    id?: string;
    extraClasses?: string;
}

export default FlexWrapper;
