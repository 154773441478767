import { useHistory } from 'react-router-dom';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useDeleteMeal from './hooks/useDeleteMeal';
import { Meal } from 'src/types/Meal';

const DeleteMealModal: React.FC<DeleteMealProps> = ({ meal }) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeleteMeal(meal?.id);

    return (
        <ConfirmModal
            title="Delete meal"
            description={`Are you sure you want to delete '${meal?.name}'?`}
            closeModal={() => history.replace(`/wedding/catering/menu/${meal?.id}`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteMealProps {
    meal: Meal;
}
export default DeleteMealModal;
