import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors, LoginResponse } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const postSelectWeddingRequest = createAction('postSelectWeddingRequest');
export const postSelectWeddingSuccess = createAction('postSelectWeddingSuccess');
export const postSelectWeddingFailure = createAction('postSelectWeddingFailure');

export const postSelectWedding =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postSelectWeddingRequest());
        try {
            const { data } = await api.post<null, LoginResponse>(`auth/selectWedding/${id}`, null);
            localStorage.setItem('jwt', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            dispatch(postSelectWeddingSuccess());
        } catch (e) {
            handleApiErrors(dispatch, postSelectWeddingFailure, e as APIError);
        }
    };
