import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import MealNotes from '../../../types/MealNotes';

export const fetchMealNotesRequest = createAction('fetchMealNotesRequest');
export const fetchMealNotesSuccess = createAction<MealNotes>('fetchMealNotesSuccess');
export const fetchMealNotesFailure = createAction('fetchMealNotesFailure');

export const fetchMealNotes =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchMealNotesRequest());
        try {
            const { data } = await api.get<MealNotes>('meal/notes');
            dispatch(fetchMealNotesSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchMealNotesFailure, e as APIError);
        }
    };
