import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import WeddingPlanner from '../../../types/WeddingPlanner';

export const fetchPlannersRequest = createAction('fetchPlannersRequest');
export const fetchPlannersSuccess = createAction<WeddingPlanner[]>('fetchPlannersSuccess');
export const fetchPlannersFailure = createAction('fetchPlannersFailure');

export const fetchPlanners =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchPlannersRequest());
        try {
            const { data } = await api.get<WeddingPlanner[]>('wedding/planner/fetch');
            dispatch(fetchPlannersSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchPlannersFailure, e as APIError);
        }
    };
