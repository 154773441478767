import Form from 'lib/src/components/form/Form';
import useAddAdditionalProfile from '@pages/account/tabs/additionalProfiles/hooks/useAddAdditionalProfile';
import TextInput from 'lib/src/components/form/TextInput';
import Modal from 'lib/src/components/modal/Modal';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect } from 'react';

const AddAdditionalProfileForm: React.FC<AddAdditionalProfileFormProps> = ({ hideModal }) => {
    const { handleSubmit, formData, handleChange, isPosting, postSuccess } =
        useAddAdditionalProfile();
    const prevProps = usePrevious({ postSuccess });
    useEffect(() => {
        // handle success
        if (postSuccess && !prevProps.postSuccess) {
            hideModal();
        }
    }, [postSuccess, prevProps.postSuccess, hideModal]);
    return (
        <Modal title="Add additional profile">
            <Form onSubmit={handleSubmit} onCancel={hideModal} isPosting={isPosting}>
                <TextInput
                    name={'firstName'}
                    value={formData.firstName}
                    onChange={handleChange}
                    label={'First Name'}
                    required
                />
                <TextInput
                    name={'lastName'}
                    value={formData.lastName}
                    onChange={handleChange}
                    label={'Last Name'}
                    required
                />
                <TextInput
                    name={'email'}
                    value={formData.email}
                    onChange={handleChange}
                    label={'Email'}
                    type="email"
                    required
                />
                <TextInput
                    name={'role'}
                    value={formData.role}
                    onChange={handleChange}
                    label={'Role in wedding'}
                />
            </Form>
        </Modal>
    );
};

interface AddAdditionalProfileFormProps {
    hideModal: () => void;
}

export default AddAdditionalProfileForm;
