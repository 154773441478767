import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    createQuestionFailure,
    createQuestionRequest,
    createQuestionSuccess,
    deleteQuestionFailure,
    deleteQuestionRequest,
    deleteQuestionSuccess,
    fetchQuestionsForSupplierFailure,
    fetchQuestionsForSupplierRequest,
    fetchQuestionsForSupplierSuccess,
    generateSupplierQuestionsPDFFailure,
    generateSupplierQuestionsPDFRequest,
    generateSupplierQuestionsPDFSuccess,
    postSetQuestionAnswerFailure,
    postSetQuestionAnswerRequest,
    postSetQuestionAnswerSuccess,
    QuestionWithSupplierID,
    updateQuestionFailure,
    updateQuestionRequest,
    updateQuestionSuccess,
} from '@actions/questions';
import { Question, QuestionCategory } from 'src/types/Question';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    fetchQuestionsForAllSuppliersFailure,
    fetchQuestionsForAllSuppliersRequest,
    fetchQuestionsForAllSuppliersSuccess,
} from '@actions/questions/fetchQuestionsForAllSuppliers';
import { logout } from '@actions/auth';
import {
    sendPDFExportToSupplierFailure,
    sendPDFExportToSupplierRequest,
    sendPDFExportToSupplierSuccess,
} from '@actions/questions/sendPDFExportToSupplier';

export enum EmailSentType {
    NotSent,
    Success,
    Failure,
}

interface QuestionsState {
    isFetching: boolean;
    emailSent: EmailSentType;
    fetchError: string | null;
    isPosting: boolean;
    isSendingEmail: boolean;
    postSuccess: boolean;
    postError: string | null;
    error: string | null;
    isGenerating: boolean;
    generateSuccess: boolean;
    generateError: string | null;
    questionCategories: Record<number, QuestionCategory>;
}

const initialState: QuestionsState = {
    isSendingEmail: false,
    emailSent: EmailSentType.NotSent,
    isFetching: false,
    fetchError: null,
    isPosting: false,
    postSuccess: false,
    postError: null,
    error: null,
    isGenerating: false,
    generateSuccess: false,
    generateError: null,
    questionCategories: {},
};

export default createReducer(initialState, {
    [fetchQuestionsForSupplierRequest.type]: handleFetchRequest,
    [fetchQuestionsForSupplierSuccess.type]: handleFetchQuestionsForSupplierSuccess,
    [fetchQuestionsForSupplierFailure.type]: handleFetchFailure,
    [fetchQuestionsForAllSuppliersRequest.type]: handleFetchRequest,
    [fetchQuestionsForAllSuppliersSuccess.type]: handleFetchQuestionsForAllSupplierSuccess,
    [fetchQuestionsForAllSuppliersFailure.type]: handleFetchFailure,
    [postSetQuestionAnswerRequest.type]: handlePostRequest,
    [postSetQuestionAnswerSuccess.type]: handlePostSetQuestionAnswerSuccess,
    [postSetQuestionAnswerFailure.type]: handlePostFailure,
    [createQuestionRequest.type]: handlePostRequest,
    [createQuestionSuccess.type]: handleCreateQuestionSuccess,
    [createQuestionFailure.type]: handlePostFailure,
    [updateQuestionRequest.type]: handlePostRequest,
    [updateQuestionSuccess.type]: handleUpdateQuestionSuccess,
    [updateQuestionFailure.type]: handlePostFailure,
    [deleteQuestionRequest.type]: handleDeleteRequest,
    [deleteQuestionFailure.type]: handleDeleteError,
    [deleteQuestionSuccess.type]: handleDeleteSuccess,
    [generateSupplierQuestionsPDFRequest.type]: handleGenerateRequest,
    [generateSupplierQuestionsPDFSuccess.type]: handleGenerateSuccess,
    [generateSupplierQuestionsPDFFailure.type]: handleGenerateFailure,
    [sendPDFExportToSupplierRequest.type]: handleSendingEmailRequest,
    [sendPDFExportToSupplierSuccess.type]: handleSendingEmailSuccess,
    [sendPDFExportToSupplierFailure.type]: handleSendingEmailFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: QuestionsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handlePostRequest(state: QuestionsState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handleSendingEmailRequest(state: QuestionsState) {
    state.isSendingEmail = true;
    state.emailSent = EmailSentType.NotSent;
}

function handleSendingEmailSuccess(state: QuestionsState) {
    state.isSendingEmail = false;
    state.emailSent = EmailSentType.Success;
}

function handleSendingEmailFailure(state: QuestionsState, action: PayloadAction<string>) {
    state.isSendingEmail = false;
    state.fetchError = action.payload;
    state.emailSent = EmailSentType.Failure;
}

function handleDeleteRequest(state: QuestionsState) {
    state.isPosting = true;
    state.postSuccess = false;
}
function handleFetchFailure(state: QuestionsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handlePostFailure(state: QuestionsState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleDeleteError(state: QuestionsState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}

function handleFetchQuestionsForSupplierSuccess(
    state: QuestionsState,
    action: PayloadAction<QuestionCategory[]>,
) {
    state.isFetching = false;
    state.questionCategories = convertArrToObj(action.payload);
}

function handleFetchQuestionsForAllSupplierSuccess(
    state: QuestionsState,
    action: PayloadAction<QuestionCategory[]>,
) {
    state.isFetching = false;
    state.questionCategories = convertArrToObj(action.payload);
}

function handleCreateQuestionSuccess(
    state: QuestionsState,
    action: PayloadAction<QuestionWithSupplierID>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    if (!state.questionCategories[action.payload.categoryID]) {
        // this can only be custom
        state.questionCategories[action.payload.categoryID] = {
            id: action.payload.categoryID,
            name: 'Custom',
            questions: [action.payload],
            description: '',
            requiredDaysBefore: null,
            ordinal: 0,
            supplierID: action.payload.supplierID,
        };
    } else {
        const curQuestions = state.questionCategories[action.payload.categoryID].questions;
        state.questionCategories[action.payload.categoryID].questions = [
            ...curQuestions,
            action.payload,
        ];
    }
}

function handlePostSetQuestionAnswerSuccess(
    state: QuestionsState,
    action: PayloadAction<Question>,
) {
    state.isPosting = false;
    state.postSuccess = true;

    const questions = state.questionCategories[action.payload.categoryID].questions;
    const indexOfQuestion = questions.map(question => question.id).indexOf(action.payload.id);
    questions[indexOfQuestion] = action.payload;

    state.questionCategories[action.payload.categoryID].questions = questions;
}

function handleUpdateQuestionSuccess(state: QuestionsState, action: PayloadAction<Question>) {
    state.isPosting = false;
    state.postSuccess = true;

    const questions = state.questionCategories[action.payload.categoryID].questions;
    const indexOfQuestion = questions.map(question => question.id).indexOf(action.payload.id);
    questions[indexOfQuestion] = action.payload;

    state.questionCategories[action.payload.categoryID].questions = questions;
}

function handleDeleteSuccess(state: QuestionsState, action: PayloadAction<Question>) {
    state.isPosting = false;
    state.postSuccess = true;

    const questions = state.questionCategories[action.payload.categoryID].questions.filter(
        question => question.id !== action.payload.id,
    );

    state.questionCategories[action.payload.categoryID].questions = questions;
}

function handleGenerateRequest(state: QuestionsState) {
    state.isGenerating = true;
    state.generateSuccess = false;
    state.generateError = null;
}

function handleGenerateSuccess(state: QuestionsState) {
    state.isGenerating = false;
    state.generateSuccess = true;
}

function handleGenerateFailure(state: QuestionsState, action: PayloadAction<string>) {
    state.isGenerating = false;
    state.generateError = action.payload;
}
