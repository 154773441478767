import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { SupplierType } from 'src/types/SupplierType';

export const fetchSupplierTypesRequest = createAction('fetchSupplierTypesRequest');
export const fetchSupplierTypesSuccess = createAction<SupplierType[]>('fetchSupplierTypesSuccess');
export const fetchSupplierTypesFailure = createAction('fetchSupplierTypesFailure');

export const fetchSupplierTypes =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSupplierTypesRequest());
        try {
            const { data } = await api.get<SupplierType[]>('suppliertype');
            dispatch(fetchSupplierTypesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSupplierTypesFailure, e as APIError);
        }
    };
