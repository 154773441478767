import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generateVenueGuestsPDF } from '@actions/guests';
import { selectVenueGuestsGenerateError, selectVenueGuestsIsGenerating } from '@selectors/guest';

import usePrevious from 'lib/src/hooks/usePrevious';

const useGenerateVenueGuestsPDF = () => {
    const [showVenueGuestsPDFError, setShowVenueGuestsPDFError] = useState(false);

    const dispatch = useDispatch();
    const isGeneratingVenueGuests = useSelector(selectVenueGuestsIsGenerating);
    const generateVenueGuestsError = useSelector(selectVenueGuestsGenerateError);
    const prevGenerateVenueGuestsError = usePrevious(generateVenueGuestsError);

    useEffect(() => {
        if (generateVenueGuestsError && !prevGenerateVenueGuestsError) {
            setShowVenueGuestsPDFError(true);
        }
    }, [generateVenueGuestsError, prevGenerateVenueGuestsError]);

    const handleGenerateVenueGuestsPDF = () => {
        dispatch(generateVenueGuestsPDF());
    };

    return {
        handleGenerateVenueGuestsPDF,
        isGeneratingVenueGuests,
        generateVenueGuestsError,
        showVenueGuestsPDFError,
        setShowVenueGuestsPDFError,
    };
};

export default useGenerateVenueGuestsPDF;
