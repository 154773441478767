import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Guest from '../../../types/Guest';

export const fetchGuestRequest = createAction('fetchGuestRequest');
export const fetchGuestSuccess = createAction<Guest>('fetchGuestSuccess');
export const fetchGuestFailure = createAction('fetchGuestFailure');

export const fetchGuest =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchGuestRequest());
        try {
            const { data } = await api.get<Guest>(`guest/single/${id}`);
            dispatch(fetchGuestSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchGuestFailure, e as APIError);
        }
    };
