import { RootState } from '@reducers/index';
import { Resource, ResourceCategory, ResourceTag } from 'src/types/Resource';

export const getAllResources = (state: RootState): Record<number, Resource> =>
    state.resourcesReducer.resources;
export const getAllResourcesArr = (state: RootState): Resource[] =>
    Object.values(state.resourcesReducer.resources);
export const getAllResourcesCategories = (state: RootState): Record<number, ResourceCategory> =>
    state.resourcesReducer.resourcesCategories;
export const getAllResourcesCategoriesArr = (state: RootState): ResourceCategory[] =>
    Object.values(state.resourcesReducer.resourcesCategories);
export const getAllTags = (state: RootState): Record<number, ResourceTag> =>
    state.resourcesReducer.tags;
export const getAllTagsArr = (state: RootState): ResourceTag[] =>
    Object.values(state.resourcesReducer.tags);
export const getAllResourcesByCategory = (state: RootState): Record<number, Resource> =>
    state.resourcesReducer.resourcesByCategory;
export const getAllResourcesBySearchTerm = (state: RootState): Record<number, Resource> =>
    state.resourcesReducer.resourcesBySearchTerm;
export const getAllResourcesBySearchTermArr = (state: RootState): Resource[] =>
    Object.values(state.resourcesReducer.resourcesBySearchTerm);
export const getAllResourcesByTag = (state: RootState): Record<number, Resource> =>
    state.resourcesReducer.resourcesByTag;
export const getAllResourcesByTagArr = (state: RootState): Resource[] =>
    Object.values(state.resourcesReducer.resourcesByTag);
export const getSingleResource = (state: RootState, id: number): Resource =>
    state.resourcesReducer.resources[id];
export const getSingleResourcesCategory = (state: RootState, id: number): ResourceCategory =>
    state.resourcesReducer.resourcesCategories[id];
export const getSingleTag = (state: RootState, id: number): ResourceTag =>
    state.resourcesReducer.tags[id];
export const getResourcesIsFetching = (state: RootState): boolean =>
    state.resourcesReducer.isFetching;
export const getResourcesError = (state: RootState): string | null => state.resourcesReducer.error;
export const getResourcesBySearchTermFetchSuccess = (state: RootState): boolean =>
    state.resourcesReducer.fetchResourcesBySearchTermSuccess;
export const getResourcesByTagFetchSuccess = (state: RootState): boolean =>
    state.resourcesReducer.fetchResourcesByTagSuccess;
