import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
    getAllResources,
    getAllResourcesArr,
    getResourcesError,
    getResourcesIsFetching,
} from '@selectors/resources';
import { fetchAllResources } from '@actions/resources';

const useFetchResources = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getResourcesIsFetching);
    const error = useSelector(getResourcesError);
    const resources = useSelector(getAllResources);
    const resourcesArr = useSelector(getAllResourcesArr);

    useEffect(() => {
        dispatch(fetchAllResources());
    }, [dispatch]);

    return {
        isFetching,
        error,
        resources,
        resourcesArr,
    };
};

export default useFetchResources;
