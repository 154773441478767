import React from 'react';

import InstagramSample from '@content/instagram-sample.jpg';

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Title from 'lib/src/components/typography/Title';

const WeddingBeInspired: React.FC = () => (
    <div className="landing-be-inspired padding-vertical-medium">
        <div className="container">
            <FlexWrapper align="center" direction="column">
                <Title tag="h3">Be Inspired</Title>
                <p className="sub-heading">
                    Follow us{' '}
                    <a
                        href="https://www.instagram.com/karenrhodesweddingsandevents"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        @karenrhodesweddingsandevents
                    </a>
                    . Lorem ipsum sont a la. Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit.
                </p>
            </FlexWrapper>
        </div>

        <div className="instagram-feed">
            <a className="post" href="https://www.instagram.com/karenrhodesweddingsandevents">
                <img alt="#" src={InstagramSample} />
            </a>
            <a className="post" href="https://www.instagram.com/karenrhodesweddingsandevents">
                <img alt="#" src={InstagramSample} />
            </a>
            <a className="post" href="https://www.instagram.com/karenrhodesweddingsandevents">
                <img alt="#" src={InstagramSample} />
            </a>
            <a className="post" href="https://www.instagram.com/karenrhodesweddingsandevents">
                <img alt="#" src={InstagramSample} />
            </a>
            <a className="post" href="https://www.instagram.com/karenrhodesweddingsandevents">
                <img alt="#" src={InstagramSample} />
            </a>
            <a className="post" href="https://www.instagram.com/karenrhodesweddingsandevents">
                <img alt="#" src={InstagramSample} />
            </a>
        </div>
    </div>
);

export default WeddingBeInspired;
