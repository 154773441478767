import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Question, QuestionPatch } from 'src/types/Question';

export const updateQuestionRequest = createAction('updateQuestionRequest');
export const updateQuestionSuccess = createAction<Question>('updateQuestionSuccess');
export const updateQuestionFailure = createAction('updateQuestionFailure');

export const updateQuestion =
    (postbody: QuestionPatch) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateQuestionRequest());

        try {
            const { data }: UpdateQuestionResponse = await api.patch(`question`, postbody);

            dispatch(updateQuestionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateQuestionFailure, e as APIError);
        }
    };

interface UpdateQuestionResponse {
    data: Question;
}
