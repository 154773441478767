import DietaryRequirementIcon from '@pages/guests/guestList/DietaryRequirementIcon';
import Table from 'lib/src/components/table/Table';
import { Column } from 'lib/src/types/table';
import Guest from 'src/types/Guest';
import { Meal, MealTime } from 'src/types/Meal';
import SelectMeal from './SelectMeal';
import { MealTimeEnum } from 'lib/src/shared/enums/meal';

const MenuTable: React.FC<MenuTableProps> = ({ guests, allMeals, isFetching }) => {
    const weddingBreakfastMeals = allMeals.filter(
        meal => meal.mealTime === MealTimeEnum.WeddingBreakfast,
    );

    // const eveningMeals = allMeals.filter(meal => meal.mealTime === MealTimeEnum.EveningMeal);
    // const canapeMeals = allMeals.filter(meal => meal.mealTime === MealTimeEnum.Canape);

    const columns: Column<Guest>[] = [
        {
            key: 1,
            heading: 'First Name',
            getValue: (row: Guest) => row.firstName,
            getSort: (a: Guest, b: Guest) => a.firstName.localeCompare(b.firstName),
            searchable: true,
        },
        {
            key: 2,
            heading: 'Middle Name',
            getValue: (row: Guest) => row.middleName,
            getSort: (a: Guest, b: Guest) => a.middleName.localeCompare(b.middleName),
            searchable: true,
        },
        {
            key: 3,
            heading: 'Last Name',
            getValue: (row: Guest) => row.lastName,
            getSort: (a: Guest, b: Guest) => a.lastName.localeCompare(b.lastName),
            searchable: true,
        },
        {
            key: 4,
            heading: 'Nickname',
            getValue: (row: Guest) => row.nickname,
            getSort: (a: Guest, b: Guest) => a.nickname.localeCompare(b.nickname),
            searchable: true,
        },
        {
            key: 5,
            heading: 'Dietary Requirements',
            getValue: (row: Guest) => (
                <div className="centered">
                    <DietaryRequirementIcon guest={row} />
                </div>
            ),
        },

        {
            key: 6,
            heading: 'Wedding Breakfast',
            getValue: (row: Guest) => (
                <SelectMeal
                    guest={row}
                    weddingBreakfastMealID={
                        row.meals?.find(meal => meal.mealTime === MealTimeEnum.WeddingBreakfast)?.id
                    }
                    meals={weddingBreakfastMeals}
                    mealTime="weddingBreakfastMealID"
                />
            ),
        },
    ];

    return (
        <Table
            extraClasses="tableWithBorder"
            columns={columns}
            rows={guests}
            isLoading={isFetching && !guests.length}
        />
    );
};

interface MenuTableProps {
    guests: Guest[];
    isFetching: boolean;
    mealTimes: MealTime[];
    allMeals: Meal[];
}

export default MenuTable;
