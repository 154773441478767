import { deleteTiming } from '@actions/timings';
import {
    selectTimingsIsPosting,
    selectTimingsPostError,
    selectTimingsPostSuccess,
} from '@selectors/timings';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useTimingsModals = () => {
    const dispatch = useDispatch();

    const [showAddModal, setShowAddModal] = useState(false);
    const [editTimingID, setEditTimingID] = useState<number | null>(null);
    const [deleteTimingID, setDeleteTimingID] = useState<number | null>(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const isPosting = useSelector(selectTimingsIsPosting);
    const postSuccess = useSelector(selectTimingsPostSuccess);
    const postError = useSelector(selectTimingsPostError);
    const prevProps = usePrevious({ postSuccess, postError });

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            setShowAddModal(false);
            setEditTimingID(null);
            setDeleteTimingID(null);
        }
    }, [postSuccess, prevProps.postSuccess]);

    useEffect(() => {
        if (postError && !prevProps.postError) {
            setShowAddModal(false);
            setEditTimingID(null);
            setDeleteTimingID(null);
            setShowErrorModal(true);
        }
    }, [postError, prevProps.postError]);

    const handleDelete = (timingID: number) => {
        dispatch(deleteTiming(timingID));
    };

    return {
        showAddModal,
        setShowAddModal,
        editTimingID,
        setEditTimingID,
        deleteTimingID,
        setDeleteTimingID,
        handleDelete,
        showErrorModal,
        setShowErrorModal,
        isPosting,
        postError,
    };
};

export type SetTimingID = React.Dispatch<React.SetStateAction<number | null>>;

export default useTimingsModals;
