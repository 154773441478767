import dayjs from 'dayjs';
import CountdownNumber from './CountdownNumber';
import useCountdown, { formatNumber } from './hooks/useCountdown';

const CountdownSmall = ({
    omitMonths = false,
    omitWeeks = false,
    omitDays = false,
    omitHours = true,
    date,
}: ICountdownSmallProps) => {
    const countdown = useCountdown(date);
    const { months, weeks, days, hours } = countdown;
    return (
        <div className="hero-countdown">
            <div className="width-12 flex-row justify-center">
                <div className="hero-countdown-description">
                    <div className="countdown-container">
                        {!omitMonths && (
                            <CountdownNumber numbers={formatNumber(months)} title="months" />
                        )}
                        {!omitWeeks && (
                            <CountdownNumber numbers={formatNumber(weeks)} title="weeks" />
                        )}
                        {!omitDays && <CountdownNumber numbers={formatNumber(days)} title="days" />}
                        {!omitHours && (
                            <CountdownNumber numbers={formatNumber(hours)} title="hours" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ICountdownSmallProps {
    omitMonths?: boolean;
    omitWeeks?: boolean;
    omitDays?: boolean;
    omitHours?: boolean;
    date: Date | dayjs.Dayjs;
}

export default CountdownSmall;
