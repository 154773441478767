import ActionButton from '../button/ActionButton';
import Form from '../form/Form';
import Description from '../typography/Description';
import Modal from './Modal';

const ErrorModal: React.FC<ErrorModalProps> = ({
    closeModal,
    title = 'Error',
    description = 'An error occurred, please try again.',
}) => {
    return (
        <Modal title={title} size="small">
            <Description>{description}</Description>
            <ActionButton onClick={closeModal}>Close</ActionButton>
        </Modal>
    );
};
interface ErrorModalProps {
    closeModal: () => void;
    title?: string;
    description?: string;
}

export default ErrorModal;
