import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generateMealPDF } from '@actions/meal';
import { selectMealsGenerateError, selectMealsIsGenerating } from '@selectors/meal';

import usePrevious from 'lib/src/hooks/usePrevious';

const useGenerateMealsPDF = () => {
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();
    const isGenerating = useSelector(selectMealsIsGenerating);
    const generateError = useSelector(selectMealsGenerateError);
    const prevGenerateError = usePrevious(generateError);

    useEffect(() => {
        if (generateError && !prevGenerateError) setShowError(true);
    }, [generateError, prevGenerateError]);

    const handleGeneratePDF = () => {
        dispatch(generateMealPDF());
    };

    return { handleGeneratePDF, isGenerating, generateError, showError, setShowError };
};

export default useGenerateMealsPDF;
