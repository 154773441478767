import React, { FC, ReactNode } from 'react';
import Description from '../../typography/Description';
import MessageStrip from '../../typography/MessageStrip';

const DataCheck: FC<DataCheckProps> = ({
    isLoading,
    noDataMessage = 'There is no data to display.',
    error,
    children,
    isEmpty,
    emptyClasses,
    errorClasses,
}) => {
    if (error)
        return (
            <MessageStrip>
                <Description className={errorClasses}>{error}</Description>
            </MessageStrip>
        );
    if (isLoading && isEmpty)
        return (
            <MessageStrip>
                <i className="fal fa-spinner fa-spin"></i>
            </MessageStrip>
        );
    if (isEmpty)
        return (
            <MessageStrip>
                <Description className={emptyClasses}>{noDataMessage}</Description>
            </MessageStrip>
        );

    return <>{children}</>;
};

interface DataCheckProps {
    children: ReactNode;
    isLoading?: boolean;
    error?: string | null;
    isEmpty?: boolean;
    emptyClasses?: string;
    errorClasses?: string;
    noDataMessage?: string;
}

export default DataCheck;
