import { ToolFunction } from './../../pages/tablePlanner/controls/guestPanel/hooks/useGuestPanel';
import { RootState } from '@reducers/index';
import Guest from 'src/types/Guest';
import { ChairIDForChairNumber, GuestsForChairs } from 'src/types/TablePlannerResponse';

export const selectTablePlannerIsFetching = (state: RootState) =>
    state.tablePlannerReducer.isFetching;
export const selectTablePlannerIsPosting = (state: RootState) =>
    state.tablePlannerReducer.isPosting;
export const selectTablePlannerPostSuccess = (state: RootState) =>
    state.tablePlannerReducer.postSuccess;
export const selectTablePlannerError = (state: RootState) => state.tablePlannerReducer.error;

export const selectTablePlannerObjects = (state: RootState) => {
    const { tables, chairs, walls, doors, windows, guestToChair } = state.tablePlannerReducer;
    return { tables, chairs, walls, doors, windows, guestToChair };
};

export const selectGuestsForChairs = (state: RootState): GuestsForChairs =>
    state.tablePlannerReducer.guestToChair;

export const selectGuestsForChair = (state: RootState, id: string): number | null =>
    state.tablePlannerReducer.guestToChair[id];

export const selectSelectedGuest = (state: RootState): Guest | null =>
    state.tablePlannerReducer.selectedGuest;

export const selectToolFunction = (state: RootState): ToolFunction =>
    state.tablePlannerReducer.toolFunction;

export const selectChairIDToChairNumber = (state: RootState): ChairIDForChairNumber =>
    state.tablePlannerReducer.chairIDToChairNumber;
