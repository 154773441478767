import Form from '../form/Form';
import Description from '../typography/Description';
import Modal from './Modal';
import React from "react";

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    closeModal,
    handleSubmit,
    error,
    isPosting,
    title = 'Confirm',
    description = 'Are you sure?',
    confirmText = 'Confirm',
}) => {
    return (
        <Modal title={title} size="small">
            <Description>{description}</Description>
            <Form submitText={confirmText} onSubmit={handleSubmit} onCancel={closeModal} isPosting={isPosting} />
        </Modal>
    );
};
interface ConfirmModalProps {
    closeModal: () => void;
    handleSubmit: () => void;
    error?: string | null;
    isPosting?: boolean;
    title?: string;
    description?: string;
    confirmText?: string;
}

export default React.memo(ConfirmModal);
