import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Guest from '../../../types/Guest';
import { AgeCategory } from 'lib/src/shared/enums/ageCategory';
import { AttendanceResponse } from 'lib/src/shared/enums/attendanceResponse';
import { GuestInvite } from 'lib/src/shared/enums/guestInvite';
import { GuestInviteRSVP } from 'lib/src/shared/enums/guestInviteRSVP';

export const postGuestRequest = createAction('postGuestRequest');
export const postGuestSuccess = createAction<Guest>('postGuestSuccess');
export const postGuestFailure = createAction('postGuestFailure');

export const postGuest =
    (postBody: AddGuestRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postGuestRequest());
        try {
            const { data } = await api.post<AddGuestRequest, Guest>('guest', postBody);
            dispatch(postGuestSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postGuestFailure, e as APIError);
        }
    };

export interface AddGuestRequest {
    firstName: string;
    middleName: string;
    lastName: string;
    nickname: string;
    email: string;
    address: string;
    phoneNumber: string;
    specialRelations: string;
    notes: string;
    ageCategory: AgeCategory;
    pictureS3Key: string | null;
    guestInvitedTo: GuestInvite;
    guestRSVP: GuestInviteRSVP;
    cateringAttendanceCanape: AttendanceResponse;
    cateringAttendanceWeddingBreakfast: AttendanceResponse;
    cateringAttendanceEveningMeal: AttendanceResponse;
    isVegan: boolean;
    isVegetarian: boolean;
    isGlutenFree: boolean;
    isDairyFree: boolean;
    isNutFree: boolean;
    isEggFree: boolean;
    isOther: boolean;
    otherDetails: string | null;
    isStayingAtWeddingVenue: boolean;
    accommodationAddress: string | null;
    numberOfNights: number | null;
}
