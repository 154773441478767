import React from 'react';
import Description from 'lib/src/components/typography/Description';
import Select from 'lib/src/components/form/Select';
import { TableType, tableTypeOptions } from 'lib/src/shared/enums/tableType';
import useForm from 'lib/src/hooks/useForm';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import onAddRoundTable from '../tables/roundTable';
import onAddRectangleTable from '../tables/rectangleTable';
import { customFloatInputValidation } from 'lib/src/utils/generic';

const initialForm = {
    shape: TableType.Rectangle,
    diameter: '0',
    length: '0',
    width: '0',
};

const defaultChairCount = 5;

const CustomTableControls: React.FC<ICustomTableControlsProps> = ({ canvas, handleSubmit }) => {
    const [form, onChange, resetData] = useForm<CustomTableForm>(initialForm);
    const isCircle = form.shape === TableType.Round;
    const clearForm = () => resetData(initialForm);
    const customChange = (name: keyof CustomTableForm, str: string) =>
        onChange(name, customFloatInputValidation(str));
    const onSubmit = () => {
        const { diameter, length, width, shape } = {
            diameter: +form.diameter,
            length: +form.length,
            width: +form.width,
            shape: form.shape,
        };
        if (shape === TableType.Round) {
            onAddRoundTable(
                {
                    size: diameter,
                    numberOfChairs: defaultChairCount,
                    isOccupiable: true,
                    name: 'Custom Table',
                    color: 'orange',
                },
                canvas,
            );
            return;
        }
        if (shape === TableType.Rectangle) {
            onAddRectangleTable(width, length, defaultChairCount, canvas);
            return;
        }
        handleSubmit();
    };

    return (
        <>
            <Description>Shape</Description>
            <FormRow>
                <Select
                    omitRemoval
                    name={'shape'}
                    value={form.shape}
                    options={tableTypeOptions}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow>
                {isCircle && (
                    <TextInput
                        name={'diameter'}
                        value={form.diameter}
                        label={'Area (cm)'}
                        disabled={!isCircle}
                        onChange={customChange}
                    />
                )}
                {!isCircle && (
                    <>
                        <TextInput
                            name={'width'}
                            label={'Width (cm)'}
                            value={form.width}
                            disabled={isCircle}
                            onChange={customChange}
                        />
                        <TextInput
                            name={'length'}
                            label={'Length (cm)'}
                            value={form.length}
                            disabled={isCircle}
                            onChange={customChange}
                        />
                    </>
                )}
            </FormRow>
            <div className="table-planner-menu-grid table-planner-menu-grid--2">
                <button className="button primary" onClick={clearForm}>
                    Clear
                </button>
                <button className="button primary" onClick={onSubmit}>
                    Add Table
                </button>
            </div>
        </>
    );
};

type CustomTableForm = typeof initialForm;

interface ICustomTableControlsProps {
    canvas: fabric.Canvas;
    handleSubmit: () => void;
}

export default CustomTableControls;
