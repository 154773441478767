import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import Profile from '../../types/Profile';
import {
    fetchProfileFailure,
    fetchProfileRequest,
    fetchProfileSuccess,
    patchProfileFailure,
    patchProfileRequest,
    patchProfileSuccess,
} from '@actions/profile';
import { logout } from '@actions/auth';

interface ProfileState {
    isPatching: boolean;
    patchSuccess: boolean;
    isDeleting: boolean;
    deleteSuccess: boolean;
    isFetching: boolean;
    error: string | null;
    profile: Profile | null;
}

const initialState: ProfileState = {
    isPatching: false,
    patchSuccess: false,
    isDeleting: false,
    deleteSuccess: false,
    isFetching: false,
    error: null,
    profile: null,
};

export default createReducer(initialState, {
    [patchProfileRequest.type]: handlePatchRequest,
    [patchProfileSuccess.type]: handlePatchSuccess,
    [patchProfileFailure.type]: handleFailure,
    [fetchProfileRequest.type]: handleFetchRequest,
    [fetchProfileSuccess.type]: handleFetchProfileSuccess,
    [fetchProfileFailure.type]: handleFetchFailure,
    [logout.type]: handleClearProfile,
});

function handleFetchRequest(state: ProfileState) {
    state.isFetching = true;
}

function handleFetchProfileSuccess(state: ProfileState, action: PayloadAction<Profile>) {
    state.isFetching = false;
    state.profile = action.payload;
}

function handleFetchFailure(state: ProfileState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePatchRequest(state: ProfileState) {
    state.isPatching = true;
    state.patchSuccess = false;
    state.error = null;
}

function handlePatchSuccess(state: ProfileState, action: PayloadAction<Profile>) {
    state.isPatching = false;
    state.patchSuccess = true;
    state.profile = action.payload;
}

function handleFailure(state: ProfileState, action: PayloadAction<string>) {
    state.isPatching = false;
    state.patchSuccess = false;
    state.error = action.payload;
}
function handleClearProfile(state: ProfileState) {
    state.profile = null;
    state.error = null;
    state.isPatching = false;
    state.patchSuccess = false;
    state.isDeleting = false;
    state.deleteSuccess = false;
    state.isFetching = false;
}
