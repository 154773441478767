import ActionButton from 'lib/src/components/button/ActionButton';
import usePrintPanel from './hooks/usePrintPanel';

const PrintPanel = () => {
    const { onClickPrint, onClickExport, isFetching } = usePrintPanel();

    return (
        <div className="flex-column print-panel">
            <strong>Print Panel</strong>
            <div className="table-planner-menu-grid table-planner-menu-grid--2">
                <ActionButton isPosting={isFetching} onClick={onClickPrint}>
                    Print
                </ActionButton>
                <ActionButton isPosting={isFetching} onClick={onClickExport}>
                    Export as .PDF
                </ActionButton>
            </div>
        </div>
    );
};

export default PrintPanel;
