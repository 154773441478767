import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Profile from '../../../types/Profile';

export const patchProfileRequest = createAction('patchProfileRequest');
export const patchProfileSuccess = createAction<Profile>('patchProfileSuccess');
export const patchProfileFailure = createAction('patchProfileFailure');

export const patchProfile =
    (postBody: PatchProfileRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(patchProfileRequest());
        try {
            const { data } = await api.patch<PatchProfileRequest, Profile>(
                'auth/profile',
                postBody,
            );
            dispatch(patchProfileSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, patchProfileFailure, e as APIError);
        }
    };

export interface PatchProfileRequest {
    firstName: string | null;
    lastName: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    city: string | null;
    postCode: string | null;
    profilePictureS3Key: string | null;
    partnerFirstName: string | null;
    partnerLastName: string | null;
    partnerAddressLine1: string | null;
    partnerAddressLine2: string | null;
    partnerCity: string | null;
    partnerPostCode: string | null;
    partnerProfilePictureS3Key: string | null;
    isPartnerAddressSame: boolean;
}
