import React from 'react';

import LinkButton from 'lib/src/components/button/LinkButton';
import ActionButton from 'lib/src/components/button/ActionButton';

const BackButton: React.FC<BackButtonProps> = ({ href, onClick }) => {
    return (
        <div style={{ marginBottom: '10px', width: '150px' }}>
            {href ? (
                <LinkButton href={href} icon="fa fa-chevron-double-left" source="secondary">
                    Back
                </LinkButton>
            ) : (
                <ActionButton onClick={onClick} icon="fa fa-chevron-double-left" source="secondary">
                    Back
                </ActionButton>
            )}
        </div>
    );
};

interface BackButtonProps {
    href?: string;
    onClick?: () => void;
}

export default BackButton;
