import { fabric } from 'fabric';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import { TableType } from 'lib/src/shared/enums/tableType';
import { v4 } from 'uuid';
import { getChairs, tableTextFontSize } from './chairs';

const onAddRectangleTable = (
    width = 183,
    length = 92,
    numberOfChairs = 6,
    canvas: fabric.Canvas,
    left = 50,
    top = 50,
) => {
    const tableGroupID = v4();
    const tableID = v4();

    const tableGroupWidth = width * 1.5;
    const tableGroupHeight = length * 2;
    const tableGroup = new fabric.Group([], {
        selectable: true,
        subTargetCheck: true,
        left,
        top,
        snapAngle: 11.25,
        width: tableGroupWidth,
        height: tableGroupHeight,
        data: { id: tableGroupID, type: PlannerObjectType.TableGroup },
    });

    const tableLeft = -(width / 2);
    const tableTop = -(length / 2);
    const table = new fabric.Rect({
        width: width,
        height: length,
        fill: 'white',
        left: tableLeft,
        top: tableTop,
        stroke: '#000000',
        angle: 0,
        selectable: false,
        data: {
            groupID: tableGroupID,
            id: tableID,
            type: PlannerObjectType.Table,
            tableType: TableType.Rectangle,
        },
    });
    tableGroup.add(table);

    const text = new fabric.Textbox('Table', {
        fontSize: tableTextFontSize,
        width: width,
        height: length,
        stroke: '#000000',
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        angle: 0,
        selectable: false,
        dirty: true,
        data: {
            tableID,
            id: v4(),
            type: PlannerObjectType.TableText,
        },
    });
    tableGroup.add(text);

    tableGroup.setControlsVisibility({
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mt: false,
        mb: false,
        mr: false,
        ml: false,
    });

    const chairs = getChairs(numberOfChairs, table);
    tableGroup.add(...chairs);

    canvas.add(tableGroup);
};

export default onAddRectangleTable;
