import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { SyntheticEvent, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import Logo from 'lib/src/_content/logos/logo-textless.svg';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';

import LinkButton from 'lib/src/components/button/LinkButton';
import ActionButton from 'lib/src/components/button/ActionButton';
import { clearJwtAndRefreshToken } from 'lib/src/utils/jwt';
import Tooltip from '@components/tooltip/Tooltip';
import { fetchWedding } from '@actions/weddings';
import { fetchProfile } from '@actions/profile';
import { selectProfile } from '@selectors/profile';
import { selectWeddings } from '@selectors/wedding';
import { fetchMyWeddings } from '@actions/weddings/fetchMyWeddings';
import { logout } from '@actions/auth';
import { isNavItemActive, navLinks } from '@components/nav/Nav';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import usePrevious from 'lib/src/hooks/usePrevious';

const Header = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const weddings = useSelector(selectWeddings);

    const currentPath = history.location.pathname;
    const prevPath = usePrevious(currentPath);

    const { isLoggedIn } = useIsLoggedIn();
    const profile = useSelector(selectProfile);
    const { weddingID } = useIsLoggedIn();
    const showAccountInfo = false;

    const wedding = weddings.find(item => item.id === weddingID);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchProfile());
            dispatch(fetchMyWeddings());
        }
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        if (!!weddingID) dispatch(fetchWedding());
    }, [weddingID, dispatch]);

    const closeNav = () => {
        document.querySelector('#web-body')?.classList.remove('is-locked');
        setIsOpen(false);
    };

    const closeNavDebounced = debounce(closeNav, 100);

    const openNav = () => {
        // lock body to prevent scrolling
        document.querySelector('#web-body')?.classList.add('is-locked');
        setIsOpen(true);
    };

    const toggleNavIsOpen = () => {
        if (isOpen) {
            closeNav();
        } else {
            openNav();
        }
    };

    // remove is-locked class if window is resized
    useEffect(() => {
        window.addEventListener('resize', closeNavDebounced);

        return () => {
            window.removeEventListener('resize', closeNavDebounced);
        };
    }, [closeNavDebounced]);

    useEffect(() => {
        if (prevPath !== currentPath) {
            closeNav();
        }
    }, [prevPath, currentPath]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <header className="header">
                <div className="container flex-row align-center">
                    <div className="logo">
                        <Link to="/">
                            <img alt="company logo" src={Logo} />
                        </Link>
                    </div>

                    {isOpen && <div className="nav-bg" onClick={closeNav} />}

                    <nav className={`profile ${isOpen ? 'is-open' : ''}`}>
                        <button
                            className="close-nav"
                            onClick={closeNav}
                            aria-label="Close the navigation"
                        >
                            <i className="fal fa-times"></i>
                        </button>

                        {isLoggedIn && (
                            <ul className="profile-mob-links">
                                {navLinks.map(link => {
                                    const isActive = isNavItemActive(link);

                                    return (
                                        <Link
                                            key={link.path}
                                            to={link.path}
                                            className={`nav-link ${isActive ? 'active' : ''}`}
                                            onClick={closeNav}
                                        >
                                            {link.title}
                                        </Link>
                                    );
                                })}
                            </ul>
                        )}

                        {isLoggedIn ? (
                            <div className="profile-inner">
                                <div className="header-end-links">
                                    <ActionButton source="secondary" onClick={handleLogout}>
                                        Logout
                                    </ActionButton>
                                    <Link
                                        to="/account"
                                        className="headers-icon-links"
                                        onClick={closeNav}
                                    >
                                        <Tooltip text="Account" side="bottom">
                                            <i className="fa-thin fa-user-vneck"></i>
                                        </Tooltip>
                                    </Link>
                                    <Link
                                        to="/resources-categories"
                                        className="headers-icon-links"
                                        onClick={closeNav}
                                    >
                                        <Tooltip text="Resources" side="bottom">
                                            <i className="fa-thin fa-book-open-cover"></i>
                                        </Tooltip>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <FlexWrapper gap={15} extraClasses="flex-column-lt">
                                <LinkButton href="/auth/login">Login</LinkButton>
                                <LinkButton source="secondary" href="/auth/register">
                                    Sign Up
                                </LinkButton>
                            </FlexWrapper>
                        )}
                    </nav>
                    <button className="header-hamburger" onClick={toggleNavIsOpen}>
                        <span className="header-hamburger-shape"></span>
                        <span className="header-hamburger-shape"></span>
                        <span className="header-hamburger-shape"></span>
                    </button>
                </div>
            </header>
            {showAccountInfo && weddings.length > 1 && isLoggedIn && wedding && profile && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'rgb(128, 128, 140)',
                        padding: '10px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '98%',
                        }}
                    >
                        <p
                            style={{
                                color: 'white',
                            }}
                        >
                            {!!wedding?.partnerFirstName && !!wedding?.partnerLastName
                                ? `You are logged in as ${profile?.firstName} ${profile?.lastName} and looking at
                        ${wedding?.ownerFirstName} ${wedding?.ownerLastName}
                        and ${wedding?.partnerFirstName} ${wedding?.partnerLastName}'s wedding.`
                                : `You are logged in as ${profile?.firstName} ${profile?.lastName} and looking at
                    ${wedding?.ownerFirstName} ${wedding?.ownerLastName}'s wedding.`}
                        </p>
                    </div>
                    <Tooltip side="left" text="Hide account info">
                        <div>
                            <button
                                style={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    fontSize: '18px',
                                }}
                                onClick={() => {}}
                                // onClick={() => setShowAccountInfo(false)}
                            >
                                <i className="fa fa-arrow-up"></i>
                            </button>
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );

    function handleLogout(e: SyntheticEvent) {
        e.preventDefault();
        clearJwtAndRefreshToken();
        dispatch(logout());
        closeNav();
        history.replace('/');
    }
};

export default Header;
