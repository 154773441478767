import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchSupplierTypes } from '@actions/supplierTypes';
import useIsLoggedIn from '../../../hooks/useIsLoggedIn';

const useAllSupplierTypesFetch = (): void => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useIsLoggedIn();
    useEffect(() => {
        if (isLoggedIn) dispatch(fetchSupplierTypes());
    }, [dispatch, isLoggedIn]);
};

export default useAllSupplierTypesFetch;
