import { Link } from 'react-router-dom';
import CountdownSmall from './CountdownSmall';
import dayjs from 'dayjs';

const SupplierCountdown = ({ src, alt, title, description, date }: SupplierCountdownProps) => {
    return (
        <Link className="suppliers-countdown" to="/wedding/suppliers">
            <img className="suppliers-countdown-image" alt={alt} src={src} />
            <span className="suppliers-countdown-title">{title}</span>
            <span className="suppliers-countdown-description">{description}</span>
            <CountdownSmall date={date} />
        </Link>
    );
};

interface SupplierCountdownProps {
    src: string;
    alt: string;
    title: string;
    description: string;
    date: Date | dayjs.Dayjs;
}

export default SupplierCountdown;
