import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const sendPDFExportToSupplierRequest = createAction('sendPDFExportToSupplierRequest');
export const sendPDFExportToSupplierSuccess = createAction('sendPDFExportToSupplierSuccess');
export const sendPDFExportToSupplierFailure = createAction('sendPDFExportToSupplierFailure');

export const sendPDFExportToSupplier =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(sendPDFExportToSupplierRequest());
        try {
            await api.get(`question/email/${id}`);
            dispatch(sendPDFExportToSupplierSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, sendPDFExportToSupplierFailure, e as APIError);
        }
    };
