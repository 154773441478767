import { useHistory } from 'react-router-dom';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useDeleteSupplier from './hooks/useDeleteSupplier';
import { Supplier } from 'src/types/Supplier';

const DeleteSupplierModal: React.FC<DeleteSupplierProps> = ({ supplier }) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeleteSupplier(supplier?.id);

    return (
        <ConfirmModal
            title="Delete supplier"
            description={`Are you sure you want to delete '${supplier?.name}'?`}
            closeModal={() => history.replace(`/wedding/suppliers/${supplier?.id}`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteSupplierProps {
    supplier: Supplier;
}
export default DeleteSupplierModal;
