import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { ResourceTag } from 'src/types/Resource';

export const fetchSingleTagRequest = createAction('fetchSingleTagRequest');
export const fetchSingleTagSuccess = createAction<ResourceTag>('fetchSingleTagSuccess');
export const fetchSingleTagFailure = createAction('fetchSingleTagFailure');

export const fetchSingleTag =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleTagRequest());
        try {
            const { data } = await api.get<ResourceTag>(`resource/tag${id}`);

            dispatch(fetchSingleTagSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleTagFailure, e as APIError);
        }
    };
