import { useParams } from 'react-router-dom';

import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { Question } from 'src/types/Question';
import useDeleteQuestion from './hooks/useDeleteQuestion';

const DeleteQuestionModal: React.FC<DeleteQuestionProps> = ({ closeModal, question }) => {
    const { id: supplierID } = useParams<{ id: string }>();

    const { handleSubmit, isPosting, error } = useDeleteQuestion(question, supplierID, closeModal);

    return (
        <ConfirmModal
            title="Delete question"
            description={`Are you sure you want to delete '${question.questionText}'?`}
            closeModal={() => closeModal()}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
        />
    );
};

interface DeleteQuestionProps {
    question: Question;
    closeModal: () => void;
}

export default DeleteQuestionModal;
