import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Meal, MealPost } from 'src/types/Meal';

export const postCreateMealRequest = createAction('postCreateMealRequest');
export const postCreateMealSuccess = createAction<Meal>('postCreateMealSuccess');
export const postCreateMealFailure = createAction('postCreateMealFailure');

export const postCreateMeal =
    (postBody: MealPost) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postCreateMealRequest());
        try {
            const { data } = await api.post<MealPost, Meal>('meal', postBody);
            dispatch(postCreateMealSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postCreateMealFailure, e as APIError);
        }
    };
