import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Timing } from '../../../types/Timing';
import { PostTimingRequest } from './postTiming';

export const patchTimingRequest = createAction('patchTimingRequest');
export const patchTimingSuccess = createAction<Timing>('patchTimingSuccess');
export const patchTimingFailure = createAction('patchTimingFailure');

export const PatchTiming =
    (postBody: PatchTimingRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(patchTimingRequest());
        try {
            const { data } = await api.patch<PatchTimingRequest, Timing>('timing', postBody);
            dispatch(patchTimingSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, patchTimingFailure, e as APIError);
        }
    };

export interface PatchTimingRequest extends PostTimingRequest {
    id: number;
}
