import WeddingPlanner from '../../../../types/WeddingPlanner';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { deletePlanner } from '@actions/weddings/deletePlanner';
import { selectWeddingDeleteSuccess, selectWeddingIsDeleting } from '@selectors/wedding';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect } from 'react';

const DeleteAdditionalProfileModal: React.FC<DeleteAdditionalProfileModalProps> = ({
    hideModal,
    profile,
}) => {
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectWeddingIsDeleting);
    const deleteSuccess = useSelector(selectWeddingDeleteSuccess);
    const prevProps = usePrevious({ deleteSuccess });
    const handleSubmit = () => {
        dispatch(deletePlanner(profile.userID));
    };

    useEffect(() => {
        if (deleteSuccess && !prevProps.deleteSuccess) {
            hideModal();
        }
    }, [deleteSuccess, prevProps.deleteSuccess, hideModal]);
    return (
        <ConfirmModal
            handleSubmit={handleSubmit}
            closeModal={hideModal}
            title="Confirm Deletion"
            isPosting={isDeleting}
            description="Are you sure you want to delete this profile?"
        />
    );
};

interface DeleteAdditionalProfileModalProps {
    profile: WeddingPlanner;
    hideModal: () => void;
}

export default DeleteAdditionalProfileModal;
