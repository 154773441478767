import Select from 'lib/src/components/form/Select';
import useAssignMeal from './hooks/useAssignMeal';
import { Meal } from '../../types/Meal';
import Guest from '../../types/Guest';
import { AgeCategory } from 'lib/src/shared/enums/ageCategory';
import { MealTypeEnum } from 'lib/src/shared/enums/meal';
import Tooltip from '@components/tooltip/Tooltip';

const SelectMeal: React.FC<SelectMealProps> = ({
    guest,
    meals,
    mealTime,
    weddingBreakfastMealID,
    eveningMealID,
    canapeMealID,
}) => {
    const isDisabled = guest.ageCategory === AgeCategory.Baby;
    const mealIDs = { weddingBreakfastMealID, eveningMealID, canapeMealID };
    const mealID = mealIDs[mealTime] ?? null;
    const { handleChange } = useAssignMeal(guest.id, mealTime, mealID);

    const mealsOptions = meals
        .filter(meal => {
            if (mealID === meal.id) return true;
            if (guest.isEggFree && !meal.isEggFree) return false;
            if (guest.isVegetarian && !meal.isVegetarian) return false;
            if (guest.isVegan && !meal.isVegan) return false;
            if (guest.isDairyFree && !meal.isDairyFree) return false;
            if (guest.isGlutenFree && !meal.isGlutenFree) return false;
            if (guest.isNutFree && !meal.isNutFree) return false;
            if (guest.ageCategory === AgeCategory.Adult && meal.mealType === MealTypeEnum.Child) {
                return false;
            }
            if (guest.ageCategory === AgeCategory.Child && meal.mealType === MealTypeEnum.Adult) {
                return false;
            }

            return true;
        })
        .map(meal => ({ label: meal.name, value: meal.id }));

    const disabledMealsOptions = meals
        .filter(meal => {
            if (guest.isEggFree && !meal.isEggFree) return true;
            if (guest.isVegetarian && !meal.isVegetarian) return true;
            if (guest.isVegan && !meal.isVegan) return true;
            if (guest.isDairyFree && !meal.isDairyFree) return true;
            if (guest.isGlutenFree && !meal.isGlutenFree) return true;
            if (guest.isNutFree && !meal.isNutFree) return true;
            if (guest.ageCategory === AgeCategory.Adult && meal.mealType === MealTypeEnum.Child) {
                return true;
            }
            if (guest.ageCategory === AgeCategory.Child && meal.mealType === MealTypeEnum.Adult) {
                return true;
            }

            return false;
        })
        .map(meal => ({ label: meal.name, value: meal.id }));

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip shouldOutput={isDisabled} text="This guest is a baby" side="top">
                <div style={{ width: '200px' }}>
                    <Select
                        name="mealID"
                        options={mealsOptions}
                        value={mealID}
                        onChange={(_, value) => handleChange(value)}
                        search
                        disabled={isDisabled}
                        disabledOptions={disabledMealsOptions}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

interface SelectMealProps {
    guest: Guest;
    meals: Meal[];
    mealTime: 'weddingBreakfastMealID' | 'eveningMealID' | 'canapeMealID';
    weddingBreakfastMealID?: number | undefined;
    eveningMealID?: number | undefined;
    canapeMealID?: number | undefined;
}

export default SelectMeal;
