import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { Question, QuestionPost } from 'src/types/Question';

export const createQuestionRequest = createAction('createQuestionRequest');
export const createQuestionSuccess = createAction<QuestionWithSupplierID>('createQuestionSuccess');
export const createQuestionFailure = createAction('createQuestionFailure');

export const createQuestion =
    (postbody: QuestionPost) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(createQuestionRequest());
        try {
            const { data }: { data: Question } = await api.post(`question`, postbody);

            dispatch(createQuestionSuccess({ ...data, supplierID: postbody.supplierID }));
        } catch (e) {
            handleApiErrors(dispatch, createQuestionFailure, e as APIError);
        }
    };

export interface QuestionWithSupplierID extends Question {
    supplierID: number;
}
