import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import { Resource, ResourceCategory, ResourceTag } from 'src/types/Resource';
import {
    fetchAllResourcesCategoriesFailure,
    fetchAllResourcesCategoriesRequest,
    fetchAllResourcesCategoriesSuccess,
    fetchAllResourcesFailure,
    fetchAllResourcesRequest,
    fetchAllResourcesSuccess,
    fetchAllTagsFailure,
    fetchAllTagsRequest,
    fetchAllTagsSuccess,
    fetchResourcesByCategoryFailure,
    fetchResourcesByCategoryRequest,
    fetchResourcesByCategorySuccess,
    fetchResourcesBySearchTermFailure,
    fetchResourcesBySearchTermRequest,
    fetchResourcesBySearchTermSuccess,
    fetchResourcesByTagFailure,
    fetchResourcesByTagRequest,
    fetchResourcesByTagSuccess,
    fetchSingleResourceFailure,
    fetchSingleResourceRequest,
    fetchSingleResourcesCategoryFailure,
    fetchSingleResourcesCategoryRequest,
    fetchSingleResourcesCategorySuccess,
    fetchSingleResourceSuccess,
    fetchSingleTagFailure,
    fetchSingleTagRequest,
    fetchSingleTagSuccess,
} from '@actions/resources';

interface ResourcesState {
    isFetching: boolean;
    error: string | null;
    fetchResourcesBySearchTermSuccess: boolean;
    fetchResourcesByTagSuccess: boolean;
    resources: { [key: number]: Resource };
    resourcesCategories: { [key: number]: ResourceCategory };
    tags: { [key: number]: ResourceTag };
    resourcesByCategory: { [key: number]: Resource };
    resourcesBySearchTerm: { [key: number]: Resource };
    resourcesByTag: { [key: number]: Resource };
}

const initialState: ResourcesState = {
    isFetching: false,
    error: null,
    fetchResourcesBySearchTermSuccess: false,
    fetchResourcesByTagSuccess: false,
    resources: {},
    resourcesCategories: {},
    tags: {},
    resourcesByCategory: {},
    resourcesBySearchTerm: {},
    resourcesByTag: {},
};

export default createReducer(initialState, {
    [fetchAllResourcesRequest.type]: handleFetchRequest,
    [fetchAllResourcesSuccess.type]: handleFetchAllResourcesSuccess,
    [fetchAllResourcesFailure.type]: handleFetchFailure,
    [fetchAllResourcesCategoriesRequest.type]: handleFetchRequest,
    [fetchAllResourcesCategoriesSuccess.type]: handleFetchAllResourcesCategoriesSuccess,
    [fetchAllResourcesCategoriesFailure.type]: handleFetchFailure,
    [fetchAllTagsRequest.type]: handleFetchRequest,
    [fetchAllTagsSuccess.type]: handleFetchAllTagsSuccess,
    [fetchAllTagsFailure.type]: handleFetchFailure,
    [fetchResourcesByCategoryRequest.type]: handleFetchRequest,
    [fetchResourcesByCategorySuccess.type]: handleFetchResourcesByCategorySuccess,
    [fetchResourcesByCategoryFailure.type]: handleFetchFailure,
    [fetchResourcesBySearchTermRequest.type]: handleFetchRequest,
    [fetchResourcesBySearchTermSuccess.type]: handleFetchResourcesBySearchTermSuccess,
    [fetchResourcesBySearchTermFailure.type]: handleFetchFailure,
    [fetchResourcesByTagRequest.type]: handleFetchRequest,
    [fetchResourcesByTagSuccess.type]: handleFetchResourcesByTagSuccess,
    [fetchResourcesByTagFailure.type]: handleFetchFailure,
    [fetchSingleResourceRequest.type]: handleFetchRequest,
    [fetchSingleResourceSuccess.type]: handleFetchSingleResourceSuccess,
    [fetchSingleResourceFailure.type]: handleFetchFailure,
    [fetchSingleResourcesCategoryRequest.type]: handleFetchRequest,
    [fetchSingleResourcesCategorySuccess.type]: handleFetchSingleResourcesCategorySuccess,
    [fetchSingleResourcesCategoryFailure.type]: handleFetchFailure,
    [fetchSingleTagRequest.type]: handleFetchRequest,
    [fetchSingleTagSuccess.type]: handleFetchSingleTagSuccess,
    [fetchSingleTagFailure.type]: handleFetchFailure,
});

function handleFetchRequest(state: ResourcesState) {
    state.isFetching = true;
    state.fetchResourcesBySearchTermSuccess = false;
    state.fetchResourcesByTagSuccess = false;
}

function handleFetchFailure(state: ResourcesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchResourcesBySearchTermSuccess = false;
    state.fetchResourcesByTagSuccess = false;
    state.error = action.payload;
}

function handleFetchAllResourcesSuccess(state: ResourcesState, action: PayloadAction<Resource[]>) {
    state.isFetching = false;
    state.resources = convertArrToObj(action.payload);
}

function handleFetchAllResourcesCategoriesSuccess(
    state: ResourcesState,
    action: PayloadAction<ResourceCategory[]>,
) {
    state.isFetching = false;
    state.resourcesCategories = convertArrToObj(action.payload);
}

function handleFetchResourcesByCategorySuccess(
    state: ResourcesState,
    action: PayloadAction<Resource[]>,
) {
    state.isFetching = false;
    state.resourcesByCategory = convertArrToObj(action.payload);
}

function handleFetchAllTagsSuccess(state: ResourcesState, action: PayloadAction<ResourceTag[]>) {
    state.isFetching = false;
    state.tags = convertArrToObj(action.payload);
}

function handleFetchResourcesBySearchTermSuccess(
    state: ResourcesState,
    action: PayloadAction<Resource[]>,
) {
    state.isFetching = false;
    state.fetchResourcesBySearchTermSuccess = true;
    state.resourcesBySearchTerm = convertArrToObj(action.payload);
}

function handleFetchResourcesByTagSuccess(
    state: ResourcesState,
    action: PayloadAction<Resource[]>,
) {
    state.isFetching = false;
    state.fetchResourcesByTagSuccess = true;
    state.resourcesByTag = convertArrToObj(action.payload);
}

function handleFetchSingleResourcesCategorySuccess(
    state: ResourcesState,
    action: PayloadAction<Resource>,
) {
    state.isFetching = false;
    state.resources[action.payload.id] = action.payload;
}

function handleFetchSingleResourceSuccess(
    state: ResourcesState,
    action: PayloadAction<ResourceCategory>,
) {
    state.isFetching = false;
    state.resourcesCategories[action.payload.id] = action.payload;
}

function handleFetchSingleTagSuccess(state: ResourcesState, action: PayloadAction<ResourceTag>) {
    state.isFetching = false;
    state.tags[action.payload.id] = action.payload;
}
