import { RootState } from '@reducers/index';
import { Timing } from 'src/types/Timing';

export const selectTimingsIsFetching = (state: RootState): boolean =>
    state.timingsReducer.isFetching;
export const selectTimingsFetchError = (state: RootState): string | null =>
    state.timingsReducer.fetchError;

export const selectTimingsIsPosting = (state: RootState): boolean => state.timingsReducer.isPosting;
export const selectTimingsPostError = (state: RootState): string | null =>
    state.timingsReducer.postError;
export const selectTimingsPostSuccess = (state: RootState): boolean =>
    state.timingsReducer.postSuccess;
export const selectTimings = (state: RootState): Record<number, Timing> =>
    state.timingsReducer.timings;
export const selectTimingsArr = (state: RootState): Timing[] =>
    Object.values(state.timingsReducer.timings);
export const selectTiming = (state: RootState, timingID: number): Timing =>
    state.timingsReducer.timings[timingID];

export const selectTimingsIsGenerating = (state: RootState): boolean =>
    state.timingsReducer.isGenerating;
export const selectTimingsGenerateError = (state: RootState): string | null =>
    state.timingsReducer.generateError;
export const selectTimingsGenerateSuccess = (state: RootState): boolean =>
    state.timingsReducer.generateSuccess;
