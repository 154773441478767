import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { ResourceTag } from 'src/types/Resource';

export const fetchAllTagsRequest = createAction('fetchAllTagsRequest');
export const fetchAllTagsSuccess = createAction<ResourceTag[]>('fetchAllTagsSuccess');
export const fetchAllTagsFailure = createAction('fetchAllTagsFailure');

export const fetchAllTags =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllTagsRequest());
        try {
            const { data } = await api.get<ResourceTag[]>('resource/tag');
            dispatch(fetchAllTagsSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchAllTagsFailure, e as APIError);
        }
    };
