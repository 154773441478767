import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
    getAllTags,
    getAllTagsArr,
    getResourcesError,
    getResourcesIsFetching,
} from '@selectors/resources';
import { fetchAllTags } from '@actions/resources';

const useFetchAllTags = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getResourcesIsFetching);
    const error = useSelector(getResourcesError);
    const tags = useSelector(getAllTags);
    const tagsArr = useSelector(getAllTagsArr);

    useEffect(() => {
        dispatch(fetchAllTags());
    }, [dispatch]);

    return {
        isFetching,
        error,
        tags,
        tagsArr,
    };
};

export default useFetchAllTags;
