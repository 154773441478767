import React from 'react';

import { SetTimingID } from './hooks/useTimingsModals';
import { Column } from 'lib/src/types/table';
import { TimingsAndMeals } from './hooks/useTimingsAndMeals';

import useTimingsTables from './hooks/useTimingsTables';

import Title from 'lib/src/components/typography/Title';
import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import LinkButton from 'lib/src/components/button/LinkButton';

const TimingsTables: React.FC<TimingsTablesProps> = ({
    timingsAndMeals,
    setEditTimingID,
    setDeleteTimingID,
}) => {
    const { isFetching, dayBeforeTimings, dayOfTimings, dayAfterTimings, suppliers } =
        useTimingsTables(timingsAndMeals);

    const timingColumns: Column<TimingsAndMeals>[] = [
        {
            key: 1,
            heading: 'Supplier',
            getValue: ({ supplierID, canModify }) => {
                const supplier = suppliers[supplierID];
                if (!canModify) return 'N/A';
                if (!supplier) return '--';
                return supplier.name;
            },
            searchable: true,
        },
        {
            key: 2,
            heading: 'Name',
            getValue: ({ name }) => name,
            searchable: true,
        },
        { key: 3, heading: 'Time', getValue: ({ time }) => time },
        {
            key: 4,
            heading: '',
            getValue: ({ id, canModify }) => {
                if (!canModify)
                    return (
                        <ButtonRow alignment="right">
                            <LinkButton href="/wedding">Edit in Our Wedding</LinkButton>
                        </ButtonRow>
                    );
                return (
                    <ButtonRow alignment="right">
                        <ActionButton source="secondary" onClick={() => setEditTimingID(id)}>
                            Edit
                        </ActionButton>
                        <ActionButton source="negative" onClick={() => setDeleteTimingID(id)}>
                            Delete
                        </ActionButton>
                    </ButtonRow>
                );
            },
        },
    ];

    return (
        <>
            <Title tag="h2">Day before</Title>
            <Table
                extraClasses="tableWithBorder"
                isLoading={isFetching}
                columns={timingColumns}
                rows={dayBeforeTimings}
            />
            <Title tag="h2">Day of</Title>
            <Table
                extraClasses="tableWithBorder"
                isLoading={isFetching}
                columns={timingColumns}
                rows={dayOfTimings}
            />
            <Title tag="h2">Day after</Title>
            <Table
                extraClasses="tableWithBorder"
                isLoading={isFetching}
                columns={timingColumns}
                rows={dayAfterTimings}
            />
        </>
    );
};

interface TimingsTablesProps {
    timingsAndMeals: TimingsAndMeals[];
    setEditTimingID: SetTimingID;
    setDeleteTimingID: SetTimingID;
}

export default TimingsTables;
