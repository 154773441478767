import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import ResourcesCategoriesContainer from '@pages/resources/ResourcesCategoriesContainer';
import ResourcesCategoryContainer from '@pages/resources/ResourcesCategoryContainer';
import ResourceContainer from '@pages/resources/ResourceContainer';

const ResourcesRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <ResourcesCategoriesContainer />
            </Route>
            <Route exact path={`${path}/:id`}>
                <ResourcesCategoryContainer />
            </Route>
            <Route exact path={`${path}/:id/resource/:resourceID`}>
                <ResourceContainer />
            </Route>
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default ResourcesRoutes;
