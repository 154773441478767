import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

// Register
export const postRegisterRequest = createAction('postRegisterRequest');
export const postRegisterSuccess = createAction('postRegisterSuccess');
export const postRegisterFailure = createAction('postRegisterFailure');

export const postRegister =
    (postBody: RegisterRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postRegisterRequest());
        try {
            const { data } = await api.post<RegisterRequest, RegisterResponse>(
                'auth/register',
                postBody,
            );

            localStorage.setItem('jwt', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);

            dispatch(postRegisterSuccess());
        } catch (e) {
            handleApiErrors(dispatch, postRegisterFailure, e as APIError);
        }
    };

export interface RegisterRequest {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber?: string;
    partnerFirstName: string;
    partnerLastName: string;
}

export interface RegisterResponse {
    token: string;
    refreshToken: string;
}
