import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '@reducers/index';
import { fetchSupplier } from '@actions/suppliers';
import {
    selectSupplier,
    selectSuppliersFetchError,
    selectSuppliersIsFetching,
} from '@selectors/suppliers';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import SupplierPage from './SupplierPage';
import UpdateSupplierModal from './UpdateSupplierModal';
import useAllSupplierTypesFetch from '../hooks/useAllSupplierTypesFetch';
import { selectSupplierTypes } from '@selectors/supplierTypes';
import DeleteSupplierModal from './DeleteSupplierModal';

const SupplierContainer: React.FC = () => {
    const { id, action } = useParams<ParamTypes>();
    const dispatch = useDispatch();

    useAllSupplierTypesFetch();
    useEffect(() => {
        dispatch(fetchSupplier(+id));
    }, [id, dispatch]);

    const supplier = useSelector((state: RootState) => selectSupplier(state, +id));
    const isFetching = useSelector(selectSuppliersIsFetching);
    const error = useSelector(selectSuppliersFetchError);
    const supplierTypes = useSelector(selectSupplierTypes);

    return (
        <>
            <DataCheck isEmpty={!supplier} isLoading={isFetching} error={error}>
                <SupplierPage supplier={supplier} />
            </DataCheck>
            {action === 'delete' && <DeleteSupplierModal supplier={supplier} />}
            {!!supplier && action === 'edit' && (
                <UpdateSupplierModal
                    id={supplier.id}
                    name={supplier.name}
                    supplierTypeID={supplier.supplierTypeID}
                    customType={supplier.customType}
                    email={supplier.email}
                    contactInformation={supplier.contactInformation}
                    notes={supplier.notes}
                    cost={supplier.cost}
                    deposit={supplier.deposit}
                    paid={supplier.paid}
                    depositDueDate={supplier.depositDueDate}
                    depositPaid={supplier.depositPaid}
                    remainingDueDate={supplier.remainingDueDate}
                    remainingPaid={supplier.remainingPaid}
                    supplierTypes={supplierTypes}
                />
            )}
        </>
    );
};

interface ParamTypes {
    id: string;
    action: string;
}

export default SupplierContainer;
