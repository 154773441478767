import { useEffect, useState } from 'react';
import Title from 'lib/src/components/typography/Title';
import { Resource, ResourceCategory } from 'src/types/Resource';
import useFetchAllTags from './hooks/useFetchAllTags';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResourcesByTag } from '@actions/resources';
import { getAllResourcesByTagArr, getResourcesByTagFetchSuccess } from '@selectors/resources';
import ResourceCard from '@components/resources/ResourceCard';

const Resources: React.FC<ResourcesProps> = ({
    resourceCategoryId,
    resourcesByCategoryArr,
    resourcesCategory,
}) => {
    const dispatch = useDispatch();
    const { tagsArr } = useFetchAllTags();

    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const prevSelectedTags = usePrevious(selectedTags);

    const resourcesByTagFetchSuccess = useSelector(getResourcesByTagFetchSuccess);
    const prevResourcesByTagFetchSuccess = usePrevious(resourcesByTagFetchSuccess);
    const resourcesByTagArr = useSelector(getAllResourcesByTagArr);

    const handleSelectTag = (id: number) => {
        if (selectedTags.includes(id)) {
            setSelectedTags(state => [
                ...state.slice(0, state.indexOf(id)),
                ...state.slice(state.indexOf(id) + 1),
            ]);
        } else {
            setSelectedTags(state => [...state, id]);
        }
    };

    useEffect(() => {
        if (selectedTags !== prevSelectedTags) {
            dispatch(fetchResourcesByTag(selectedTags));
        }
    }, [
        dispatch,
        prevResourcesByTagFetchSuccess,
        prevSelectedTags,
        resourcesByTagFetchSuccess,
        selectedTags,
        tagsArr,
    ]);

    return (
        <div className="card-grid">
            <div className="card-grid-buttons">
                {tagsArr.map(tag => (
                    <button
                        key={tag.id}
                        className={`card-grid-button ${
                            selectedTags.includes(tag.id) ? 'is-active' : ''
                        }`}
                        onClick={() => handleSelectTag(tag.id)}
                    >
                        <div>{tag.name}</div>
                    </button>
                ))}
            </div>

            <Title>{resourcesCategory?.name}</Title>
            <Title>{resourcesCategory?.description}</Title>
            <div className="card-grid-inner">
                {!selectedTags.length
                    ? resourcesByCategoryArr.map(resource => (
                          <ResourceCard
                              resource={resource}
                              resourceCategoryId={resourceCategoryId}
                          />
                      ))
                    : resourcesByTagArr
                          .filter(item => item.category.id === resourceCategoryId)
                          .map(resource => (
                              <ResourceCard
                                  resource={resource}
                                  resourceCategoryId={resourceCategoryId}
                              />
                          ))}
            </div>
        </div>
    );
};

interface ResourcesProps {
    resourceCategoryId: number;
    resourcesByCategoryArr: Resource[];
    resourcesCategory: ResourceCategory;
}

export default Resources;
