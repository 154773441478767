import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { ResourceCategory } from 'src/types/Resource';

export const fetchAllResourcesCategoriesRequest = createAction(
    'fetchAllResourcesCategoriesRequest',
);
export const fetchAllResourcesCategoriesSuccess = createAction<ResourceCategory[]>(
    'fetchAllResourcesCategoriesSuccess',
);
export const fetchAllResourcesCategoriesFailure = createAction(
    'fetchAllResourcesCategoriesFailure',
);

export const fetchAllResourcesCategories =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllResourcesCategoriesRequest());
        try {
            const { data } = await api.get<ResourceCategory[]>('resource/category');
            dispatch(fetchAllResourcesCategoriesSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchAllResourcesCategoriesFailure, e as APIError);
        }
    };
