import { fabric } from 'fabric';
import WallControls from '@pages/tablePlanner/controls/controlPanel/WallControls';
import ActionButton from 'lib/src/components/button/ActionButton';
import TableControls from '@pages/tablePlanner/controls/controlPanel/TableControls';
import DoorControls from './DoorControls';
import CustomTableControls from './CustomTableControls';
import WindowControls from './WindowControls';
import { IPanel } from '@pages/tablePlanner/TablePlanner';
import { toolTypeOptions } from 'lib/src/shared/enums/toolType';
import useControlPanel from './hooks/useControlPanel';
import ChairControls from '@pages/tablePlanner/controls/controlPanel/ChairControls';
import { getPlannerObjectName, PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import SelectedTableControls from '@pages/tablePlanner/controls/controlPanel/SelectedTableControls';
import CustomRoomControls from '@pages/tablePlanner/controls/controlPanel/CustomRoomControls';
import React from 'react';
import Description from 'lib/src/components/typography/Description';

const ControlPanel: React.FC<IPanel> = ({ editor, selectedObjects, handleSubmit, setDropItem }) => {
    const { canvas } = editor;

    const { triggerRerender, selectedObject, setTool, removeAllElements, tool } = useControlPanel(
        editor,
        selectedObjects,
    );
    const isObjectSelected = !!selectedObject;
    const areMultipleObjectsSelected = (selectedObjects?.length ?? 0) > 1;
    const selectedObjectName = areMultipleObjectsSelected
        ? 'Item(s)'
        : isObjectSelected
        ? getPlannerObjectName(selectedObject?.data?.type)
        : '';

    const isTableGroupSelected = selectedObject?.data?.type === PlannerObjectType.TableGroup;
    return (
        <>
            <div className="flex-column control-panel">
                {isObjectSelected && (
                    <section key={selectedObject.data?.id}>
                        <ActionButton type="button" onClick={() => editor.deleteSelected()}>
                            Delete selected {selectedObjectName}
                        </ActionButton>
                        {isTableGroupSelected && (
                            <SelectedTableControls
                                canvas={canvas}
                                tableGroup={selectedObject as fabric.Group}
                                triggerRerender={triggerRerender}
                            />
                        )}
                    </section>
                )}
                <section>
                    <DoorControls setDropItem={setDropItem} />
                    <WindowControls setDropItem={setDropItem} />
                    <WallControls setDropItem={setDropItem} />
                </section>
                <section>
                    <ChairControls setDropItem={setDropItem} />
                    <TableControls
                        canvas={canvas}
                        triggerRerender={triggerRerender}
                        selectedObject={selectedObject}
                        setDropItem={setDropItem}
                    />
                </section>
                <strong>Add a custom table</strong>
                <section>
                    <CustomTableControls handleSubmit={handleSubmit} canvas={canvas} />
                    <CustomRoomControls canvas={canvas} />
                </section>
                <strong>Chair Selection</strong>
                <Description>
                    You can use this to edit the type of an existing chair.
                    <br />
                    Tap the new chair type, then the chair you wish to edit.
                </Description>
                <section>
                    <div className="table-planner-menu-grid table-planner-menu-grid--2">
                        {toolTypeOptions.map(({ value, label }, key) => (
                            <ActionButton
                                type="button"
                                key={key}
                                onClick={() => setTool(value)}
                                disabled={tool === value}
                            >
                                {label}
                            </ActionButton>
                        ))}
                    </div>
                </section>
            </div>

            <div className="flex-column control-panel">
                <section>
                    <strong>Tools</strong>
                    <div className="table-planner-menu-grid table-planner-menu-grid--1">
                        <ActionButton type="button" onClick={handleSubmit}>
                            Save
                        </ActionButton>
                    </div>
                    <div className="table-planner-menu-grid table-planner-menu-grid--1">
                        <ActionButton type="button" onClick={() => removeAllElements(editor)}>
                            Clear Table Plan
                        </ActionButton>
                    </div>
                </section>
            </div>
        </>
    );
};

export interface PlannerCanvas extends fabric.Canvas {
    isDragging: boolean;
    lastPosX: number;
    lastPosY: number;
}

export default React.memo(ControlPanel);
