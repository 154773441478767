import Form from 'lib/src/components/form/Form';
import Description from 'lib/src/components/typography/Description';
import TimePickerInput from 'lib/src/components/form/TimePickerInput';
import { WeddingDetailsFormProps } from '../../../types/Wedding';

const WeddingMealTimesForm: React.FC<WeddingDetailsFormProps> = ({
    formData,
    error,
    isPosting,
    handleChange,
    handleSubmit,
    showSuccessMessage,
}) => {
    return (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            submitText="Save"
            omitCancel
            buttonAlignment="center"
        >
            <TimePickerInput
                label="Canape Time"
                name="canapeTime"
                value={formData.canapeTime}
                onChange={handleChange}
                disableClear
                required
            />
            <TimePickerInput
                label="Wedding Breakfast Time"
                name="weddingBreakfastTime"
                value={formData.weddingBreakfastTime}
                onChange={handleChange}
                disableClear
                required
            />
            <TimePickerInput
                label="Evening Meal Time"
                name="eveningMealTime"
                value={formData.eveningMealTime}
                onChange={handleChange}
                disableClear
                required
            />
            {showSuccessMessage && (
                <Description className="form-generic-success">Changes saved!</Description>
            )}
            {error && <Description className="form-generic-error">{error}</Description>}
        </Form>
    );
};

export default WeddingMealTimesForm;
