import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    deleteSupplierFailure,
    deleteSupplierRequest,
    deleteSupplierSuccess,
    fetchSupplierFailure,
    fetchSupplierRequest,
    fetchSuppliersFailure,
    fetchSuppliersRequest,
    fetchSuppliersSuccess,
    fetchSupplierSuccess,
    patchUpdateSupplierFailure,
    patchUpdateSupplierRequest,
    patchUpdateSupplierSuccess,
    postCreateSupplierFailure,
    postCreateSupplierRequest,
    postCreateSupplierSuccess,
} from '@actions/suppliers';
import { Supplier } from 'src/types/Supplier';
import { convertArrToObj } from 'lib/src/utils/generic';
import { logout } from '@actions/auth';

interface SuppliersState {
    isFetching: boolean;
    fetchError: string | null;
    isPosting: boolean;
    isDeleting: boolean;
    postSuccess: boolean;
    deleteSuccess: boolean;
    deleteError: string | null;
    postError: string | null;
    suppliers: Record<number, Supplier>;
}

const initialState: SuppliersState = {
    isFetching: false,
    fetchError: null,
    isPosting: false,
    isDeleting: false,
    postSuccess: false,
    deleteSuccess: false,
    deleteError: null,
    postError: null,
    suppliers: {},
};

export default createReducer(initialState, {
    [fetchSuppliersRequest.type]: handleFetchRequest,
    [fetchSuppliersSuccess.type]: handleFetchSuppliersSuccess,
    [fetchSuppliersFailure.type]: handleFetchFailure,
    [fetchSupplierRequest.type]: handleFetchRequest,
    [fetchSupplierSuccess.type]: handleFetchSupplierSuccess,
    [fetchSupplierFailure.type]: handleFetchFailure,
    [postCreateSupplierRequest.type]: handlePostRequest,
    [postCreateSupplierSuccess.type]: handlePostSupplierSuccess,
    [postCreateSupplierFailure.type]: handlePostFailure,
    [patchUpdateSupplierRequest.type]: handlePostRequest,
    [patchUpdateSupplierSuccess.type]: handlePostSupplierSuccess,
    [patchUpdateSupplierFailure.type]: handlePostFailure,
    [deleteSupplierRequest.type]: handleDeleteRequest,
    [deleteSupplierSuccess.type]: handleDeleteSupplierSuccess,
    [deleteSupplierFailure.type]: handleDeleteFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: SuppliersState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handlePostRequest(state: SuppliersState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handleDeleteRequest(state: SuppliersState) {
    state.isDeleting = true;
    state.deleteError = null;
    state.deleteSuccess = false;
}

function handleFetchFailure(state: SuppliersState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handlePostFailure(state: SuppliersState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postSuccess = false;
    state.postError = action.payload;
}

function handleDeleteFailure(state: SuppliersState, action: PayloadAction<string>) {
    state.isDeleting = false;
    state.deleteSuccess = false;
    state.deleteError = action.payload;
}

function handleFetchSuppliersSuccess(state: SuppliersState, action: PayloadAction<Supplier[]>) {
    state.isFetching = false;
    state.suppliers = convertArrToObj(action.payload);
}

function handleFetchSupplierSuccess(state: SuppliersState, action: PayloadAction<Supplier>) {
    state.isFetching = false;
    state.suppliers[action.payload.id] = action.payload;
}

function handlePostSupplierSuccess(state: SuppliersState, action: PayloadAction<Supplier>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.suppliers[action.payload.id] = action.payload;
}

function handleDeleteSupplierSuccess(state: SuppliersState, action: PayloadAction<number>) {
    state.isDeleting = false;
    state.deleteSuccess = true;
    delete state.suppliers[action.payload];
}
