import { useEffect } from 'react';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { postRegister, RegisterRequest } from '@actions/auth/postRegister';
import { selectAuthIsPosting, selectAuthPostSuccess } from '@selectors/auth';

import RegisterForm from './RegisterForm';
import useIsLoggedIn from '../../../hooks/useIsLoggedIn';

const RegisterFormContainer: React.FC = (): JSX.Element => {
    const { isLoggedIn } = useIsLoggedIn();
    const dispatch = useDispatch();
    const history = useHistory();
    const isPosting = useSelector(selectAuthIsPosting);
    const postSuccess = useSelector(selectAuthPostSuccess);

    const [formState, handleChange] = useForm<RegisterRequest>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        partnerFirstName: '',
        partnerLastName: '',
    });

    const prevPostSuccess = usePrevious(postSuccess);
    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            history.push('/wedding');
        }
    }, [postSuccess, prevPostSuccess, history]);

    if (isLoggedIn) return <Redirect to={'/wedding'} />;
    return (
        <RegisterForm
            handleChange={handleChange}
            formState={formState}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
        />
    );

    function handleSubmit() {
        dispatch(postRegister(formState));
    }
};

export default RegisterFormContainer;
