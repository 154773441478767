import { Redirect, Route, useLocation } from 'react-router-dom';
import useIsLoggedIn from '../hooks/useIsLoggedIn';

const SubRouter: React.FC<SubRouterProps> = ({ path, children, auth, weddingAuth }) => {
    const location = useLocation();
    const { isLoggedIn, hasWedding } = useIsLoggedIn();

    if ((auth || weddingAuth) && !isLoggedIn) {
        return <Redirect to="/auth/login" from={location.pathname} />;
    }

    if (weddingAuth && !hasWedding) {
        return <Redirect to="/account/wedding-selection" from={location.pathname} />;
    }
    return <Route path={path}>{children}</Route>;
};

interface SubRouterProps {
    children: React.ReactNode;
    path: string;
    auth?: boolean;
    weddingAuth?: boolean;
    exact?: boolean;
}
export default SubRouter;
