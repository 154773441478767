import useMealNotesForm from '@pages/catering/meals/hooks/useMealNotesForm';
import Form from 'lib/src/components/form/Form';
import TextArea from 'lib/src/components/form/TextArea';
import React from 'react';
import SuccessModal from 'lib/src/components/modal/SuccessModal';

const MealNotesForm = () => {
    const {
        formState,
        handleChange,
        handleSubmit,
        isFetching,
        isPatching,
        hideModal,
        showSuccessModal,
    } = useMealNotesForm();
    return (
        <>
            <Form onSubmit={handleSubmit} isPosting={isPatching} isDisabled={isFetching} omitCancel>
                <TextArea
                    name={'canapeNotes'}
                    value={formState.canapeNotes ?? ''}
                    onChange={handleChange}
                    label={'Canapé options'}
                />
                <TextArea
                    name={'eveningMealNotes'}
                    value={formState.eveningMealNotes ?? ''}
                    onChange={handleChange}
                    label={'Evening Meal options'}
                />
                {showSuccessModal && (
                    <SuccessModal
                        title="Success"
                        description={'Meal notes updated'}
                        closeModal={hideModal}
                    />
                )}
            </Form>
        </>
    );
};

export default MealNotesForm;
