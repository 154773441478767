import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteTimingRequest = createAction('deleteTimingRequest');
export const deleteTimingSuccess = createAction<number>('deleteTimingSuccess');
export const deleteTimingFailure = createAction('deleteTimingFailure');

export const deleteTiming =
    (timingID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteTimingRequest());
        try {
            await api.delete(`timing/${timingID}`);
            dispatch(deleteTimingSuccess(timingID));
        } catch (e) {
            handleApiErrors(dispatch, deleteTimingFailure, e as APIError);
        }
    };
