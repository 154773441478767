import { ChairType } from 'lib/src/shared/enums';

interface ChairNumberInfo {
    number: number;
    chairType: ChairType;
}
const chairNumbers: Record<number, ChairNumberInfo[]> = {};

export const chairNumbersSet = (tableID: number, arr: ChairNumberInfo[]) => {
    chairNumbers[tableID] = arr;
};
const assignNewChairNumber = (tableID: number) => {
    if (!chairNumbers[tableID]?.length) return 1;
    const numbers = chairNumbers[tableID].map(x => x.number);
    return Math.max(...numbers) + 1;
};

export const chairNumbersInsert = (tableID: number, index: number, chairType: ChairType) => {
    const numbers = chairNumbers[tableID] ?? [];
    const newNum = assignNewChairNumber(tableID);
    chairNumbers[tableID] = numbers
        .slice(0, index)
        .concat({ number: newNum, chairType })
        .concat(numbers.slice(index));
    return newNum;
};

export const getChairNumbersLength = (tableID: number) =>
    chairNumbers[tableID]?.filter(x => x.chairType !== ChairType.HighChair).length ?? 0;

export const chairNumbersGet = (tableID: number, chairID: number, chairType: ChairType): string => {
    const isHighChair = chairType === ChairType.HighChair;
    if (chairID < 0 || isHighChair) return '';
    if (!chairNumbers[tableID]) chairNumbers[tableID] = [];
    const index = chairNumbers[tableID].findIndex(x => x.number === chairID);
    const prevIndex = chairNumbers[tableID].findIndex(x => x.number === chairID - 1);
    if (index === -1) {
        const inserted = chairNumbersInsert(tableID, prevIndex, chairType);
        return chairNumbersGet(tableID, inserted, chairType);
    }
    return '' + (index + 1);
};

export default chairNumbers;
