import {
    IUnassignGuestResponse,
    unassignGuestToChairSuccess,
} from './../actions/tablePlanner/unassignGuest';
import { ChairIDForChairNumber } from './../../types/TablePlannerResponse';
import { ToolFunction } from './../../pages/tablePlanner/controls/guestPanel/hooks/useGuestPanel';
import { PostAssignGuestResponse } from './../actions/tablePlanner/postAssignGuest';
import { setSelectedGuestAction, setToolFunctionAction } from './../actions/tablePlanner/guests';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    postTablePlannerFailure,
    postTablePlannerRequest,
    postTablePlannerSuccess,
} from '@actions/tablePlanner/postTablePlanner';
import {
    fetchTablePlannerFailure,
    fetchTablePlannerRequest,
    fetchTablePlannerSuccess,
} from '@actions/tablePlanner/fetchTablePlanner';
import TablePlannerResponse, { GuestsForChairs } from '../../types/TablePlannerResponse';
import Table from '../../types/Table';
import Wall from '../../types/Wall';
import Chair from '../../types/Chair';
import Door from '../../types/Door';
import Window from '../../types/Window';
import {
    fetchGuestsForChairsFailure,
    fetchGuestsForChairsRequest,
    fetchGuestsForChairsSuccess,
} from '@actions/tablePlanner/fetchGuestsForChairs';
import Guest from 'src/types/Guest';
import {
    postAssignGuestToChairFailure,
    postAssignGuestToChairRequest,
    postAssignGuestToChairSuccess,
} from '@actions/tablePlanner/postAssignGuest';
import {
    deleteAssignGuestToChairFailure,
    deleteAssignGuestToChairRequest,
    deleteAssignGuestToChairSuccess,
    IDeleteAssignGuestResponse,
} from '@actions/tablePlanner/deleteAssignGuest';
import { logout } from '@actions/auth';

interface TablePlannerState {
    isPosting: boolean;
    postSuccess: boolean;
    isFetching: boolean;
    error: string | null;
    tables: Table[];
    chairs: Chair[];
    walls: Wall[];
    doors: Door[];
    windows: Window[];
    guestToChair: GuestsForChairs;
    chairIDToChairNumber: ChairIDForChairNumber;
    selectedGuest: Guest | null;
    toolFunction: ToolFunction;
}

const initialState: TablePlannerState = {
    isPosting: false,
    postSuccess: false,
    isFetching: false,
    error: null,
    tables: [],
    chairs: [],
    walls: [],
    doors: [],
    windows: [],
    guestToChair: {},
    chairIDToChairNumber: {},
    selectedGuest: null,
    toolFunction: ToolFunction.Assign,
};

export default createReducer(initialState, {
    [fetchTablePlannerRequest.type]: handleFetchRequest,
    [fetchTablePlannerSuccess.type]: handleFetchSuccess,
    [fetchTablePlannerFailure.type]: handleFetchFailure,
    [postTablePlannerRequest.type]: handlePostRequest,
    [postTablePlannerSuccess.type]: handlePostSuccess,
    [postTablePlannerFailure.type]: handlePostFailure,
    [fetchGuestsForChairsRequest.type]: handleFetchRequest,
    [fetchGuestsForChairsSuccess.type]: handleFetchGuestsForChairsSuccess,
    [fetchGuestsForChairsFailure.type]: handleFetchFailure,
    [setSelectedGuestAction.type]: handleSetSelectedGuest,
    [setToolFunctionAction.type]: handleSetToolFunctionAction,
    [postAssignGuestToChairRequest.type]: handlePostRequest,
    [postAssignGuestToChairSuccess.type]: handlePostAssignGuestToChairSuccess,
    [postAssignGuestToChairFailure.type]: handlePostFailure,
    [deleteAssignGuestToChairRequest.type]: handlePostRequest,
    [deleteAssignGuestToChairSuccess.type]: handleDeleteAssignGuestToChairSuccess,
    [deleteAssignGuestToChairFailure.type]: handlePostFailure,
    [unassignGuestToChairSuccess.type]: handleUnassignGuestToChairSuccess,
    [logout.type]: () => initialState,
});

function handleUnassignGuestToChairSuccess(
    state: TablePlannerState,
    action: PayloadAction<IUnassignGuestResponse>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    if (state.guestToChair[action.payload.chairID]) {
        state.guestToChair[action.payload.chairID] = null;
    }
}

function handleDeleteAssignGuestToChairSuccess(
    state: TablePlannerState,
    action: PayloadAction<IDeleteAssignGuestResponse>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    if (state.guestToChair[action.payload.chairID]) {
        state.guestToChair[action.payload.chairID] = null;
    }
}

function handlePostAssignGuestToChairSuccess(
    state: TablePlannerState,
    action: PayloadAction<PostAssignGuestResponse>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    if (state.guestToChair[action.payload.chairID]) {
        state.guestToChair[action.payload.chairID] = null;
    }
    state.chairIDToChairNumber[action.payload.chairID] = action.payload.number;
    const oldChair = Object.entries(state.guestToChair ?? []).find(
        ([k, v]) => v === action.payload.guestID,
    );
    if (oldChair) {
        state.guestToChair[oldChair[0]] = null;
    }
    state.guestToChair[action.payload.chairID] = action.payload.guestID;
}

function handleSetSelectedGuest(state: TablePlannerState, action: PayloadAction<Guest | null>) {
    state.selectedGuest = action.payload;
}

function handleSetToolFunctionAction(
    state: TablePlannerState,
    action: PayloadAction<ToolFunction>,
) {
    state.toolFunction = action.payload;
}

function handleFetchRequest(state: TablePlannerState) {
    state.isFetching = true;
}

function handleFetchSuccess(state: TablePlannerState, action: PayloadAction<TablePlannerResponse>) {
    state.isFetching = false;
    const { tables, walls, doors, windows } = action.payload;
    state.tables = tables;
    state.walls = walls;
    state.doors = doors;
    state.windows = windows;
    state.chairs = tables.flatMap(x => x.chairs);
    tables
        .flatMap(x => x.chairs.flatMap(x => ({ number: x.number, chairID: x.id })))
        .forEach(x => {
            state.chairIDToChairNumber[x.chairID] = x.number;
        });
}

function handleFetchGuestsForChairsSuccess(
    state: TablePlannerState,
    action: PayloadAction<GuestsForChairs>,
) {
    state.isFetching = false;
    state.guestToChair = action.payload;
}

function handleFetchFailure(state: TablePlannerState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state: TablePlannerState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: TablePlannerState, action: PayloadAction<TablePlannerResponse>) {
    state.isPosting = false;
    state.postSuccess = true;
    const { tables, walls, doors, windows } = action.payload;
    state.tables = tables;
    state.walls = walls;
    state.doors = doors;
    state.windows = windows;
    state.chairs = tables.flatMap(x => x.chairs);
}

function handlePostFailure(state: TablePlannerState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postSuccess = false;
    state.error = action.payload;
}
