import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import Guest from '../../types/Guest';
import { convertArrToObj } from 'lib/src/utils/generic';

import {
    deleteGuestFailure,
    deleteGuestRequest,
    deleteGuestSuccess,
    fetchGuestFailure,
    fetchGuestRequest,
    fetchGuestsFailure,
    fetchGuestsRequest,
    fetchGuestsSuccess,
    fetchGuestSuccess,
    generateSupplierGuestsPDFFailure,
    generateSupplierGuestsPDFRequest,
    generateSupplierGuestsPDFSuccess,
    generateVenueGuestsPDFFailure,
    generateVenueGuestsPDFRequest,
    generateVenueGuestsPDFSuccess,
    patchGuestFailure,
    patchGuestRequest,
    patchGuestSuccess,
    postGuestFailure,
    postGuestRequest,
    postGuestSuccess,
} from '@actions/guests';
import { logout } from '@actions/auth';

interface GuestState {
    isPosting: boolean;
    postSuccess: boolean;
    isFetching: boolean;
    isDeleting: boolean;
    deleteSuccess: boolean;
    error: string | null;
    isGeneratingSupplierGuests: boolean;
    generateSupplierGuestsSuccess: boolean;
    generateSupplierGuestsError: string | null;
    isGeneratingVenueGuests: boolean;
    generateVenueGuestsSuccess: boolean;
    generateVenueGuestsError: string | null;
    guests: Record<number, Guest>;
}

const initialState: GuestState = {
    isPosting: false,
    postSuccess: false,
    isFetching: false,
    isDeleting: false,
    deleteSuccess: false,
    error: null,
    isGeneratingSupplierGuests: false,
    generateSupplierGuestsSuccess: false,
    generateSupplierGuestsError: null,
    isGeneratingVenueGuests: false,
    generateVenueGuestsSuccess: false,
    generateVenueGuestsError: null,
    guests: {},
};

export default createReducer(initialState, {
    [fetchGuestsRequest.type]: handleFetchRequest,
    [fetchGuestsSuccess.type]: handleFetchSuccess,
    [fetchGuestsFailure.type]: handleFetchFailure,
    [fetchGuestRequest.type]: handleFetchRequest,
    [fetchGuestFailure.type]: handleFetchFailure,
    [fetchGuestSuccess.type]: handleFetchSingleSuccess,
    [postGuestRequest.type]: handlePostRequest,
    [postGuestSuccess.type]: handlePostSuccess,
    [postGuestFailure.type]: handlePostFailure,
    [deleteGuestRequest.type]: handleDeleteRequest,
    [deleteGuestSuccess.type]: handleDeleteSuccess,
    [deleteGuestFailure.type]: handleDeleteFailure,
    [patchGuestRequest.type]: handlePostRequest,
    [patchGuestSuccess.type]: handlePostSuccess,
    [patchGuestFailure.type]: handlePostFailure,
    [generateSupplierGuestsPDFRequest.type]: handleGenerateSupplierGuestsRequest,
    [generateSupplierGuestsPDFSuccess.type]: handleGenerateSupplierGuestsSuccess,
    [generateSupplierGuestsPDFFailure.type]: handleGenerateSupplierGuestsFailure,
    [generateVenueGuestsPDFRequest.type]: handleGenerateVenueGuestsRequest,
    [generateVenueGuestsPDFSuccess.type]: handleGenerateVenueGuestsSuccess,
    [generateVenueGuestsPDFFailure.type]: handleGenerateVenueGuestsFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: GuestState) {
    state.isFetching = true;
}

function handleFetchSuccess(state: GuestState, action: PayloadAction<Guest[]>) {
    state.isFetching = false;
    state.guests = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state: GuestState, action: PayloadAction<Guest>) {
    state.isFetching = false;
    state.guests[action.payload.id] = action.payload;
}

function handleFetchFailure(state: GuestState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state: GuestState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: GuestState, action: PayloadAction<Guest>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.guests[action.payload.id] = action.payload;
}

function handlePostFailure(state: GuestState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postSuccess = false;
    state.error = action.payload;
}

function handleDeleteRequest(state: GuestState) {
    state.isDeleting = true;
    state.deleteSuccess = false;
}

function handleDeleteSuccess(state: GuestState, action: PayloadAction<number>) {
    state.isDeleting = false;
    state.deleteSuccess = true;
    delete state.guests[action.payload];
}

function handleDeleteFailure(state: GuestState, action: PayloadAction<string>) {
    state.isDeleting = false;
    state.deleteSuccess = false;
    state.error = action.payload;
}

function handleGenerateSupplierGuestsRequest(state: GuestState) {
    state.isGeneratingSupplierGuests = true;
    state.generateSupplierGuestsSuccess = false;
    state.generateSupplierGuestsError = null;
}

function handleGenerateSupplierGuestsSuccess(state: GuestState) {
    state.isGeneratingSupplierGuests = false;
    state.generateSupplierGuestsSuccess = true;
}

function handleGenerateSupplierGuestsFailure(state: GuestState, action: PayloadAction<string>) {
    state.isGeneratingSupplierGuests = false;
    state.generateSupplierGuestsError = action.payload;
}

function handleGenerateVenueGuestsRequest(state: GuestState) {
    state.isGeneratingVenueGuests = true;
    state.generateVenueGuestsSuccess = false;
    state.generateVenueGuestsError = null;
}

function handleGenerateVenueGuestsSuccess(state: GuestState) {
    state.isGeneratingVenueGuests = false;
    state.generateVenueGuestsSuccess = true;
}

function handleGenerateVenueGuestsFailure(state: GuestState, action: PayloadAction<string>) {
    state.isGeneratingVenueGuests = false;
    state.generateVenueGuestsError = action.payload;
}
