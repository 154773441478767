import WeddingPlanner from '../../../../types/WeddingPlanner';
import ActionButton from 'lib/src/components/button/ActionButton';
import Description from 'lib/src/components/typography/Description';

const AdditionalProfileItem: React.FC<AdditionalProfileItemProps> = ({
    profile,
    showDeleteProfileModal,
    isWeddingOwner,
}) => {
    return (
        <div
            style={{ border: '1px solid black', margin: '1em', padding: '0.5em', maxWidth: '25%' }}
        >
            <Description>
                {profile.firstName} {profile.lastName}
            </Description>
            <Description>Role: {profile.role}</Description>
            {isWeddingOwner && (
                <ActionButton source="negative" onClick={showDeleteProfileModal}>
                    Delete
                </ActionButton>
            )}
        </div>
    );
};

interface AdditionalProfileItemProps {
    profile: WeddingPlanner;
    showDeleteProfileModal: () => void;
    isWeddingOwner: boolean;
}

export default AdditionalProfileItem;
