import { RootState } from '@reducers/index';
import { Supplier } from 'src/types/Supplier';

export const selectSuppliersIsFetching = (state: RootState): boolean =>
    state.suppliersReducer.isFetching;
export const selectSuppliersFetchError = (state: RootState): string | null =>
    state.suppliersReducer.fetchError;
export const selectSuppliersIsPosting = (state: RootState): boolean =>
    state.suppliersReducer.isPosting;
export const selectSuppliersIsDeleting = (state: RootState): boolean =>
    state.suppliersReducer.isDeleting;
export const selectSuppliersPostSuccess = (state: RootState): boolean =>
    state.suppliersReducer.postSuccess;
export const selectSuppliersDeleteSuccess = (state: RootState): boolean =>
    state.suppliersReducer.deleteSuccess;
export const selectSuppliersPostError = (state: RootState): string | null =>
    state.suppliersReducer.postError;
export const selectSuppliersDeleteError = (state: RootState): string | null =>
    state.suppliersReducer.deleteError;
export const selectSuppliers = (state: RootState): Record<number, Supplier> =>
    state.suppliersReducer.suppliers;
export const selectSuppliersArr = (state: RootState): Supplier[] =>
    Object.values(state.suppliersReducer.suppliers);
export const selectSupplier = (state: RootState, supplierID: number): Supplier =>
    state.suppliersReducer.suppliers[supplierID];
