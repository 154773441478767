import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import { selectSupplierTypes } from '@selectors/supplierTypes';
import ActionButton from 'lib/src/components/button/ActionButton';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import Title from 'lib/src/components/typography/Title';
import useAllSupplierTypesFetch from '../hooks/useAllSupplierTypesFetch';
import useFetchAllSuppliers from './hooks/useFetchAllSuppliers';
import SuppliersTable from './SuppliersTable';
import AddSuppliersModal from './AddSuppliersModal';
import useSupplierQuestionsForAllSuppliersFetch from '../hooks/useSupplierQuestionsForAllSuppliersFetch';
import { Question } from 'src/types/Question';
import { fetchWedding } from '@actions/weddings';
import { selectWedding } from '@selectors/wedding';

const SuppliersContainer = () => {
    const dispatch = useDispatch();
    useAllSupplierTypesFetch();
    const { questionCategoriesForAllSuppliersArr } = useSupplierQuestionsForAllSuppliersFetch();
    useEffect(() => {
        dispatch(fetchWedding());
    }, [dispatch]);

    const { suppliersArr, isFetching, error } = useFetchAllSuppliers();
    const activeSuppliers = suppliersArr.filter(supplier => supplier.isDeleted === false);
    const supplierTypes = useSelector(selectSupplierTypes);
    const wedding = useSelector(selectWedding);
    const weddingDate = wedding?.date;
    const pressingDaysBefore = 60;
    const daysTillWedding = dayjs(weddingDate).diff(dayjs(), 'days');

    const [showCreateModal, setShowCreateModal] = useState(false);

    const allQuestions: Question[][] = [];
    questionCategoriesForAllSuppliersArr.forEach(item => {
        allQuestions.push(item.questions);
    });
    const notAnsweredQuestions = allQuestions
        .flat()
        .filter(
            question =>
                question.answerBool === null &&
                question.answerOptionIDs?.length === 0 &&
                question.answerValue === null &&
                question.answerOptionID === null,
        );

    const pressingQuestions = notAnsweredQuestions.filter(
        item =>
            daysTillWedding <=
            pressingDaysBefore + (item.requiredDaysBefore ? item.requiredDaysBefore : 0),
    );

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <CreateHeader>
                    <Title>Suppliers</Title>
                    <div>
                        <ActionButton
                            source="secondary"
                            icon="plus"
                            onClick={() => setShowCreateModal(true)}
                        >
                            Add supplier
                        </ActionButton>
                    </div>
                </CreateHeader>
                <DataCheck isEmpty={!suppliersArr.length} isLoading={isFetching} error={error}>
                    <SuppliersTable
                        suppliersArr={activeSuppliers}
                        pressingQuestions={pressingQuestions}
                        daysTillWedding={daysTillWedding}
                        isFetching={isFetching}
                    />
                </DataCheck>

                {showCreateModal && (
                    <AddSuppliersModal
                        supplierTypes={supplierTypes}
                        closeModal={() => setShowCreateModal(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default SuppliersContainer;
