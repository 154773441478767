import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { MealType } from 'src/types/Meal';

export const fetchMealTypesRequest = createAction('fetchMealTypesRequest');
export const fetchMealTypesSuccess = createAction<MealType[]>('fetchMealTypesSuccess');
export const fetchMealTypesFailure = createAction('fetchMealTypesFailure');

export const fetchMealTypes =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchMealTypesRequest());
        try {
            const { data } = await api.get<MealType[]>('meal/mealtypes');
            dispatch(fetchMealTypesSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchMealTypesFailure, e as APIError);
        }
    };
