import Title from 'lib/src/components/typography/Title';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import useForm from 'lib/src/hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { postForgotPassword } from '@actions/auth/postForgotPassword';
import { selectAuthError, selectAuthIsPosting, selectAuthPostSuccess } from '@selectors/auth';
import Description from 'lib/src/components/typography/Description';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect, useState } from 'react';

const ForgotPassword = () => {
    const [formData, handleChange] = useForm({ email: '' });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const dispatch = useDispatch();
    const isPosting = useSelector(selectAuthIsPosting);
    const postSuccess = useSelector(selectAuthPostSuccess);
    const error = useSelector(selectAuthError);
    const { email } = formData;
    const handleSubmit = () => {
        dispatch(postForgotPassword(formData));
    };

    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) setShowSuccessMessage(true);
    }, [postSuccess, prevPostSuccess]);

    return (
        <div id="login-page" className="page-padding section">
            <div className="section-inner">
                <Title>Forgot password?</Title>
                {showSuccessMessage ? (
                    <Description>
                        Please check your e-mail for a link to finish resetting your password.
                    </Description>
                ) : (
                    <>
                        <Form onSubmit={handleSubmit} isPosting={isPosting}>
                            <TextInput
                                name={'email'}
                                value={email}
                                onChange={handleChange}
                                label={'Email address'}
                                type={'email'}
                                required
                            />
                        </Form>

                        {error && <Description>{error}</Description>}
                    </>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;
