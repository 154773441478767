import { FabricJSEditor } from 'fabricjs-react';

enum Direction {
    N,
    S,
    E,
    W,
}

type SafeObject = fabric.Object & { top: number; left: number };

const moveObjects = (editor: FabricJSEditor, direction: Direction) => {
    const objects = editor.canvas.getActiveObjects();
    for (const object of objects as SafeObject[]) {
        if (!object || !('top' in object) || !('left' in object)) return;
        object.dirty = true;
        switch (direction) {
            case Direction.N:
                object.top -= 1;
                continue;
            case Direction.S:
                object.top += 1;
                continue;
            case Direction.E:
                object.left += 1;
                continue;
            case Direction.W:
                object.left -= 1;
                continue;
        }
    }

    editor.canvas.renderAll();
};

export const applyKeyboardListeners = (editor: FabricJSEditor) => {
    document.addEventListener('keydown', (btn: KeyboardEvent) => {
        if (btn.key === 'Delete') editor.deleteSelected();
        if (btn.key === 'ArrowRight') {
            moveObjects(editor, Direction.E);
        }
        if (btn.key === 'ArrowLeft') {
            moveObjects(editor, Direction.W);
        }
        if (btn.key === 'ArrowUp') {
            moveObjects(editor, Direction.N);
        }
        if (btn.key === 'ArrowDown') {
            moveObjects(editor, Direction.S);
        }
    });
};

export const bindKeyboardControls = () => {};
