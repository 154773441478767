import { DropdownOption } from "../../types/shared/DropdownOption";

export enum TableType {
    Rectangle = 0,
    Round = 1,
    Cake = 2,
    Head = 3,
}

export const tableTypeOptions: DropdownOption<TableType>[] = [
    {
        value: TableType.Rectangle,
        label: 'Rectangle',
    },
    {
        value: TableType.Round,
        label: 'Round',
    }
];
