import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import { TableType } from 'lib/src/shared/enums/tableType';
import React from 'react';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';

const DraggableRectangleTable: React.FC<DraggableRectangleTableProps> = ({
    setDropItem,
    length,
    width,
    numberOfChairs,
    caption = `Up to ${numberOfChairs} chairs`,
}) => {
    return (
        <div className="drag-container">
            <div
                className="draggable rectangle"
                draggable={true}
                onDragStart={() => {
                    setDropItem({
                        type: PlannerObjectType.Table,
                        tableType: TableType.Rectangle,
                        height: length,
                        width: width,
                        numberOfChairs,
                    });
                }}
                onDragEnd={() => {
                    setDropItem(null);
                }}
            >
                <span className="draggable-text" unselectable="on">
                    {width} * {length}cm
                </span>
            </div>
            <span className="drag-caption">{caption}</span>
        </div>
    );
};

interface DraggableRectangleTableProps {
    setDropItem: (item: DropItemConfig | null) => void;
    length: number;
    width: number;
    numberOfChairs: number;
    caption?: string;
}

export default DraggableRectangleTable;
