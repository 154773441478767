import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Title from 'lib/src/components/typography/Title';
import React from 'react';
import { Link } from 'react-router-dom';
import BlogSample1 from '@content/blog-sample-1.jpg';
import BlogSample2 from '@content/blog-sample-2.jpg';
import BlogSample3 from '@content/blog-sample-3.jpg';

const WeddingBlogPosts: React.FC = () => {
    return (
        <div className="landing-blog-posts padding-vertical-medium">
            <div className="bg" />
            <div className="container">
                <FlexWrapper justify="center" extraClasses="heading-wrapper" gap={30}>
                    <Title tag="h3">The latest from our blog</Title>
                </FlexWrapper>

                <div className="posts">
                    <Link className="post" to="/resources-categories/14/resource/25">
                        <img alt="#" src={BlogSample1} />
                        <p>Blog title will be shown here</p>
                    </Link>
                    <Link className="post" to="/resources-categories/14/resource/25">
                        <img alt="#" src={BlogSample2} />
                        <p>Blog title will be shown here</p>
                    </Link>
                    <Link className="post" to="/resources-categories/14/resource/25">
                        <img alt="#" src={BlogSample3} />
                        <p>Blog title will be shown here</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default WeddingBlogPosts;
