import { fabric } from 'fabric';

export const grid = 100;
export const gridSize = 10000;

const textSettings: fabric.ITextboxOptions = {
    fontSize: 16,
    objectCaching: true,
    dirty: false,
    selectable: false,
};

const lineSettings: fabric.ILineOptions = {
    stroke: '#ccc',
    selectable: false,
    strokeWidth: 1,
    dirty: false,
};

export const drawGrid = (canvas: fabric.Canvas) => {
    const margin = 100;
    for (let i = 0; i <= gridSize / grid; i++) {
        const startPoint = margin;
        const endPoint = gridSize + margin;
        const offset = margin + i * grid;
        const gridGroup = new fabric.Group(
            [
                new fabric.Line([offset, startPoint, offset, endPoint], lineSettings),
                new fabric.Line([startPoint, offset, endPoint, offset], lineSettings),
                new fabric.Text(`${i * 100}`, {
                    top: startPoint - 15,
                    left: offset,
                    originX: 'left',
                    originY: 'top',
                    ...textSettings,
                }),
                new fabric.Text(`${i * 100}`, {
                    top: offset,
                    left: startPoint - 5,
                    width: 20,
                    originX: 'right',
                    originY: 'top',
                    ...textSettings,
                }),
            ],
            {
                objectCaching: false,
                dirty: false,
                selectable: false,
                hoverCursor: 'unset',
                // subTargetCheck: true,
            },
        );
        canvas.add(gridGroup);
        canvas.sendToBack(gridGroup);
    }
};

// Maths
export interface IVector2 {
    x: number;
    y: number;
}

export const radToDeg = (angleRadians: number) => (angleRadians * 180) / Math.PI;
export const degToRad = (angleDegrees: number) => (angleDegrees * Math.PI) / 180;
export const getPositionFromAngledRadius = (angleRadians: number, radius: number): IVector2 => ({
    x: Math.cos(angleRadians) * radius,
    y: Math.sin(angleRadians) * radius,
});
export const addVectors = ({ x, y }: IVector2, addition: IVector2): IVector2 => ({
    x: x + addition.x,
    y: y + addition.y,
});
