import React, { FC } from 'react';
import SaveButton from './SaveButton';
import { Sections } from '@pages/tablePlanner/TablePlanner';

const StepBar: FC<IToolbarProps> = ({ section, sections, handleSubmit }) => {
    const isActive = (n: number) => section === n;
    return (
        <div className="step-bar">
            {section === Sections.Floorplan && <SaveButton onSave={handleSubmit} />}

            {sections.map((_, i) => (
                <div className={`step-button ${isActive(i) ? 'active' : ''}`} key={i}>
                    Step {i + 1}
                </div>
            ))}
        </div>
    );
};

interface IToolbarProps {
    section: Sections;
    sections: string[];
    handleSubmit: () => void;
}

export default React.memo(StepBar);
