import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Supplier } from 'src/types/Supplier';

export const fetchSuppliersRequest = createAction('fetchSuppliersRequest');
export const fetchSuppliersSuccess = createAction<Supplier[]>('fetchSuppliersSuccess');
export const fetchSuppliersFailure = createAction('fetchSuppliersFailure');

export const fetchSuppliers =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSuppliersRequest());
        try {
            const { data } = await api.get<Supplier[]>('supplier');
            dispatch(fetchSuppliersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSuppliersFailure, e as APIError);
        }
    };
