import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Question } from 'src/types/Question';

export const deleteQuestionRequest = createAction('deleteQuestionRequest');
export const deleteQuestionSuccess = createAction<Question>('deleteQuestionSuccess');
export const deleteQuestionFailure = createAction('deleteQuestionFailure');

export const deleteQuestion =
    (question: Question) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteQuestionRequest());

        try {
            await api.delete(`question/${question.id}`);

            dispatch(deleteQuestionSuccess(question));
        } catch (e) {
            handleApiErrors(dispatch, deleteQuestionFailure, e as APIError);
        }
    };
