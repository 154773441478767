import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Meal } from 'src/types/Meal';

export const fetchAllMealsRequest = createAction('fetchAllMealsRequest');
export const fetchAllMealsSuccess = createAction<Meal[]>('fetchAllMealsSuccess');
export const fetchAllMealsFailure = createAction('fetchAllMealsFailure');

export const fetchAllMeals =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllMealsRequest());
        try {
            const { data } = await api.get<Meal[]>('meal');
            dispatch(fetchAllMealsSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchAllMealsFailure, e as APIError);
        }
    };
