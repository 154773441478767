import fb from '../../_content/icons/facebook.svg';
import ig from '../../_content/icons/instagram.svg';
import pt from '../../_content/icons/pinterest.svg';

const Footer = (): JSX.Element => {
    return (
        <footer className="footer">
            <div className="footer-upper">
                <div className="footer-upper-inner">
                    <div className="footer-upper-contact flex-4">
                        <a
                            href="https://www.karenrhodes.co.uk/contact-us/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Contact Us
                        </a>
                        <a
                            href="https://www.karenrhodes.co.uk/our-story/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            About Us
                        </a>
                        <a href="/blog">Blog</a>
                    </div>

                    <div className="footer-upper-header flex-4 flex-row justify-center">
                        <p>Your online wedding planner</p>
                    </div>

                    <div className="footer-upper-socials flex-4">
                        <a href="https://pin.it/5vEWsSp">
                            <img alt="pinterest" src={pt} />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100088800910481">
                            <img alt="facebook" src={fb} />
                        </a>
                        <a href="https://www.instagram.com/wedonline2023/">
                            <img alt="instagram" src={ig} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-lower">
                <div className="footer-lower-inner">
                    <span>© 2023 Web. All rights reserved</span>
                    <nav>
                        <ul>
                            <li>
                                <a
                                    href="https://www.karenrhodes.co.uk/terms-conditions/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms & Conditions
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.karenrhodes.co.uk/privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy & Cookie Policy
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
