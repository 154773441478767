import React from 'react';

import Table from 'lib/src/components/table/Table';
import { Column } from 'lib/src/types/table';
import Budget from 'src/types/Budget';
import BudgetFullResponse from 'src/types/Budget';
import ContentBlock from '@components/layout/createHeader/contentBlock/ContentBlock';
import ContentRow from '@components/layout/createHeader/contentBlock/ContentRow';
import ContentItem from '@components/layout/createHeader/contentBlock/ContentItem';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';

const BudgetTable: React.FC<MealProps> = ({ budget, budgetData, isFetching }) => {
    const data = budgetData.map((item, i) => ({ ...item, id: i + 1 }));

    const budgetBreakdownsColumns: Column<Budget>[] = [
        {
            key: 1,
            heading: 'Supplier',
            getValue: (row: Budget) => row.supplier,
        },
        {
            key: 2,
            heading: 'Cost',
            getValue: (row: Budget) => (row.cost ? row.cost : '-'),
        },
        {
            key: 3,
            heading: 'Deposit',
            getValue: (row: Budget) => (row.deposit ? row.deposit : '-'),
        },
        {
            key: 4,
            heading: 'Paid',
            getValue: (row: Budget) => (row.paid ? row.paid : 0),
        },
        {
            key: 5,
            heading: 'Status',
            getValue: (row: Budget) =>
                row.note ? <p className={`budget-note ${row.noteColour}`}>{row.note}</p> : '-',
        },
    ];

    return (
        <>
            <Table
                isLoading={isFetching}
                columns={budgetBreakdownsColumns}
                rows={data}
                extraClasses="tableWithBorder budget"
            />

            <CreateHeader>
                <Title>Overview</Title>
            </CreateHeader>

            <div style={{ marginTop: '20px' }}>
                <ContentBlock>
                    <ContentRow>
                        <ContentItem label="Wedding Budget">
                            <p>{`${budget.weddingBudget ? budget.weddingBudget : '0'}`}</p>
                        </ContentItem>
                    </ContentRow>
                    <ContentRow>
                        <ContentItem label="Total Cost">
                            <p>{`${budget.totalCost}`}</p>
                        </ContentItem>
                    </ContentRow>
                    <ContentRow>
                        <ContentItem label="Total Paid">
                            <p>{`${budget.totalPaid}`}</p>
                        </ContentItem>
                    </ContentRow>
                    <ContentRow>
                        <ContentItem label="Remaining To Pay">
                            <p>{`${budget.remainingToPay}`}</p>
                        </ContentItem>
                    </ContentRow>
                    <ContentRow>
                        <ContentItem label="Remaining Budget">
                            <p>{`${budget.remainingBudget ? budget.remainingBudget : '0'}`}</p>
                        </ContentItem>
                    </ContentRow>
                </ContentBlock>
            </div>
        </>
    );
};

interface MealProps {
    budget: BudgetFullResponse;
    budgetData: Budget[];
    isFetching: boolean;
}

export default BudgetTable;
