import React from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { CheckboxOption } from '../../types/shared/CheckboxOption';
import { FormInputProps } from '../../types/shared/FormInputProps';

import FormField from './FormField';

const MultiCheckbox: React.FC<MultiCheckboxProps> = ({
    name,
    disabled = false,
    value = [],
    options = [],
    onChange,
    label = '',
    required,
    customValidate,
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        customValidate,
        value,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            {options.map(opt => {
                const isChecked = value.includes(opt.value);
                const optName = `${opt.label}-${opt.value}`;

                return (
                    <div key={opt.value} className="form-checkbox">
                        <input
                            type="checkbox"
                            name={optName}
                            id={optName}
                            checked={isChecked}
                            onChange={() => handleChange(opt.value)}
                            disabled={disabled}
                        />
                        <label className={`content ${disabled ? 'disabled' : ''}`} htmlFor={optName}>
                            <div className="outer-box">
                                <i className={`inner-box far fa-check ${isChecked ? 'active' : ''}`}></i>
                            </div>
                            <p>{opt.label}</p>
                        </label>
                    </div>
                )
            })}
        </FormField>
    );

    function handleChange(val: number) {
        let newVal = value;

        if (value.includes(val)) {
            newVal = newVal.filter(v => v !== val);
        } else {
            newVal = [...newVal, val];
        }
        onChange(name, newVal);
        showError();
    }
};

interface MultiCheckboxProps extends FormInputProps<number[]> {
    options: CheckboxOption<number>[];
    label?: string;
}

export default MultiCheckbox;
