import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
    selectSuppliersArr,
    selectSuppliersFetchError,
    selectSuppliersIsFetching,
} from '@selectors/suppliers';
import { fetchSuppliers } from '@actions/suppliers';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';

const useFetchAllSuppliers = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectSuppliersIsFetching);
    const error = useSelector(selectSuppliersFetchError);
    const suppliers = useSelector(selectSuppliersArr);
    const suppliersArr = useSelector(selectSuppliersArr);
    const { isLoggedIn } = useIsLoggedIn();

    useEffect(() => {
        if (isLoggedIn) dispatch(fetchSuppliers());
    }, [dispatch, isLoggedIn]);

    return {
        isFetching,
        error,
        suppliers,
        suppliersArr,
    };
};

export default useFetchAllSuppliers;
