import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import BudgetFullResponse from 'src/types/Budget';

export const fetchBudgetRequest = createAction('fetchBudgetRequest');
export const fetchBudgetSuccess = createAction<BudgetFullResponse>('fetchBudgetSuccess');
export const fetchBudgetFailure = createAction('fetchBudgetFailure');

export const fetchBudget =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchBudgetRequest());
        try {
            const { data } = await api.get<BudgetFullResponse>(`budget`);
            dispatch(fetchBudgetSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, fetchBudgetFailure, e as APIError);
        }
    };
