import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import WeddingPlanner from '../../../types/WeddingPlanner';

export const postAddPlannerRequest = createAction('postAddPlannerRequest');
export const postAddPlannerSuccess = createAction<WeddingPlanner>('postAddPlannerSuccess');
export const postAddPlannerFailure = createAction('postAddPlannerFailure');

export const postAddPlanner =
    (postBody: AddPlannerRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postAddPlannerRequest());
        try {
            const { data } = await api.post<AddPlannerRequest, WeddingPlanner>(
                'auth/registerPlanner',
                postBody,
            );
            dispatch(postAddPlannerSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, postAddPlannerFailure, e as APIError);
        }
    };

export interface AddPlannerRequest {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}
