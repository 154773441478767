import { applyKeyboardListeners } from './../../../keyboard';
import { ToolType } from 'lib/src/shared/enums/toolType';
import { v4 } from 'uuid';
import { drawGrid } from '@pages/tablePlanner/utils';
import { FabricJSEditor } from 'fabricjs-react';
import { useEffect, useState } from 'react';
import { onClickChair } from '../../tables/chairs';
import { removeEventListener } from '@pages/tablePlanner/TablePlanner';
import { fabric } from 'fabric';

const useControlPanel = (editor: FabricJSEditor, selectedObjects: fabric.Object[] | undefined) => {
    const { canvas } = editor;
    // used to update component when canvas updates
    const [, setUnique] = useState('');
    const triggerRerender = () => setUnique(v4());
    const [tool, setTool] = useState(ToolType.None);

    useEffect(() => {}, [canvas]);
    useEffect(() => {
        applyKeyboardListeners(editor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const chairListener = (e: fabric.IEvent<Event>) => onClickChair({ e, canvas, tool });
        removeEventListener(canvas, 'mouse:down', chairListener);
        canvas.on('mouse:down', chairListener);
        return () => removeEventListener(canvas, 'mouse:down', chairListener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tool]);

    const selectedObject = selectedObjects?.[0] ?? null;

    const removeAllElements = (e: FabricJSEditor) => {
        e.deleteAll();
        drawGrid(e.canvas);
    };

    return { triggerRerender, selectedObject, setTool, tool, removeAllElements };
};

export default useControlPanel;
