import React, { useState } from 'react';

import { NUMBERS_AND_DECIMALS } from 'lib/src/constants/regex';
import { QuestionAnswerEnum } from 'lib/src/shared/enums/questions';
import { Question } from 'src/types/Question';

import useAnswerSupplierQuestion from './hooks/useAnswerSupplierQuestion';

import Checkbox from 'lib/src/components/form/Checkbox';
import MultiCheckbox from 'lib/src/components/form/MultiCheckbox';
import Form from 'lib/src/components/form/Form';
import Radio from 'lib/src/components/form/Radio';
import TextInput from 'lib/src/components/form/TextInput';
import TextArea from 'lib/src/components/form/TextArea';
import DeleteQuestionModal from './DeleteQuestionModal';

const SupplierQuestion: React.FC<SupplierQuestionProps> = ({ question }) => {
    const {
        form,
        isPosting,
        isQuestionPosting,
        handleChange,
        handleSubmit,
        isAnswerDifferent,
        options,
    } = useAnswerSupplierQuestion(question);

    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);

    const answered =
        question.answerBool ||
        question.answerOptionIDs?.length ||
        question.answerValue ||
        question.answerOptionID;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: '1' }}>
                <div className="form-group-wrap width-12">
                    <div className="form-group">
                        <p className="form-label">{question.questionText}</p>

                        <Form
                            isPosting={isQuestionPosting}
                            isDisabled={isPosting}
                            onSubmit={handleSubmit}
                            submitText="Save"
                            omitButtons={!isAnswerDifferent}
                            omitCancel
                            specificFieldErrors={[`question-${question.id}`]}
                            className="flex-row justify-between flex-column-sm"
                        >
                            {question.answerType === QuestionAnswerEnum.Checkbox ? (
                                <Checkbox
                                    name={`question-${question.id}`}
                                    value={form[`question-${question.id}`] as boolean}
                                    onChange={handleChange}
                                    disabled={question.answerBool !== null}
                                />
                            ) : question.answerType === QuestionAnswerEnum.CheckboxMulti ? (
                                <MultiCheckbox
                                    name={`question-${question.id}`}
                                    value={form[`question-${question.id}`] as number[]}
                                    onChange={handleChange}
                                    options={options}
                                    disabled={!!question.answerOptionIDs?.length}
                                />
                            ) : question.answerType === QuestionAnswerEnum.Radio ? (
                                <Radio
                                    name={`question-${question.id}`}
                                    value={form[`question-${question.id}`] as number}
                                    options={options}
                                    onChange={handleChange}
                                    disabled={!!question.answerOptionID}
                                />
                            ) : question.answerType === QuestionAnswerEnum.Numerical ? (
                                <TextInput
                                    name={`question-${question.id}`}
                                    value={form[`question-${question.id}`] as string}
                                    onChange={handleChange}
                                    validationRegExp={NUMBERS_AND_DECIMALS}
                                    disabled={!!question.answerValue}
                                />
                            ) : question.answerType === QuestionAnswerEnum.TextMultiLine ? (
                                <TextArea
                                    name={`question-${question.id}`}
                                    value={form[`question-${question.id}`] as string}
                                    onChange={handleChange}
                                    disabled={!!question.answerValue}
                                />
                            ) : (
                                <TextInput
                                    name={`question-${question.id}`}
                                    value={form[`question-${question.id}`] as string}
                                    onChange={handleChange}
                                    disabled={!!question.answerValue}
                                />
                            )}
                        </Form>
                    </div>
                </div>
                {question.isCustom && answered && (
                    <button
                        className="icon-button delete"
                        onClick={() => setShowDeleteQuestionModal(true)}
                        type="button"
                    >
                        <i className="fal fa-trash-alt"></i>
                    </button>
                )}
            </div>
            {showDeleteQuestionModal && (
                <DeleteQuestionModal
                    question={question}
                    closeModal={() => setShowDeleteQuestionModal(false)}
                />
            )}
        </>
    );
};

interface SupplierQuestionProps {
    question: Question;
}

export default SupplierQuestion;
