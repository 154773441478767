import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import Wedding from '../../types/Wedding';
import {
    fetchWeddingFailure,
    fetchWeddingRequest,
    fetchWeddingSuccess,
    postUpdateWeddingFailure,
    postUpdateWeddingRequest,
    postUpdateWeddingSuccess,
} from '@actions/weddings';

import {
    postAddPlannerFailure,
    postAddPlannerRequest,
    postAddPlannerSuccess,
} from '@actions/weddings/postAddPlanner';
import WeddingPlanner from '../../types/WeddingPlanner';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    fetchPlannersFailure,
    fetchPlannersRequest,
    fetchPlannersSuccess,
} from '@actions/weddings/fetchPlanners';
import {
    deletePlannerFailure,
    deletePlannerRequest,
    deletePlannerSuccess,
} from '@actions/weddings/deletePlanner';
import {
    fetchMyWeddingsFailure,
    fetchMyWeddingsRequest,
    fetchMyWeddingsSuccess,
} from '@actions/weddings/fetchMyWeddings';
import { logout } from '@actions/auth';

interface WeddingState {
    isPosting: boolean;
    postSuccess: boolean;
    isDeleting: boolean;
    deleteSuccess: boolean;
    isFetching: boolean;
    error: string | null;
    selectedWedding: Wedding | null;
    weddings: Record<number, Wedding>;
    planners: Record<number, WeddingPlanner>;
}

const initialState: WeddingState = {
    isPosting: false,
    postSuccess: false,
    isDeleting: false,
    deleteSuccess: false,
    isFetching: false,
    error: null,
    selectedWedding: null,
    planners: {},
    weddings: {},
};

export default createReducer(initialState, {
    [postUpdateWeddingRequest.type]: handlePostRequest,
    [postUpdateWeddingSuccess.type]: handlePostSuccess,
    [postUpdateWeddingFailure.type]: handleFailure,
    [postAddPlannerRequest.type]: handlePostRequest,
    [postAddPlannerSuccess.type]: handlePostWeddingPlannerSuccess,
    [postAddPlannerFailure.type]: handleFailure,
    [fetchWeddingRequest.type]: handleFetchRequest,
    [fetchWeddingSuccess.type]: handleFetchWeddingSuccess,
    [fetchWeddingFailure.type]: handleFetchFailure,
    [fetchPlannersRequest.type]: handleFetchRequest,
    [fetchPlannersSuccess.type]: handleFetchPlannersSuccess,
    [fetchPlannersFailure.type]: handleFetchFailure,
    [fetchMyWeddingsRequest.type]: handleFetchRequest,
    [fetchMyWeddingsFailure.type]: handleFetchFailure,
    [fetchMyWeddingsSuccess.type]: handleFetchWeddingsSuccess,
    [deletePlannerRequest.type]: handleDeleteRequest,
    [deletePlannerSuccess.type]: handleDeleteSuccess,
    [deletePlannerFailure.type]: handleDeleteFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: WeddingState) {
    state.isFetching = true;
}

function handleFetchWeddingSuccess(state: WeddingState, action: PayloadAction<Wedding>) {
    state.isFetching = false;
    state.selectedWedding = action.payload;
}

function handleFetchPlannersSuccess(state: WeddingState, action: PayloadAction<WeddingPlanner[]>) {
    state.isFetching = false;
    state.planners = convertArrToObj(action.payload, 'userID');
}

function handleFetchWeddingsSuccess(state: WeddingState, action: PayloadAction<Wedding[]>) {
    state.isFetching = false;
    state.weddings = convertArrToObj(action.payload);
}

function handleFetchFailure(state: WeddingState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state: WeddingState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state: WeddingState, action: PayloadAction<Wedding>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.selectedWedding = action.payload;
}

function handlePostWeddingPlannerSuccess(
    state: WeddingState,
    action: PayloadAction<WeddingPlanner>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.planners[action.payload.userID] = action.payload;
}

function handleFailure(state: WeddingState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postSuccess = false;
    state.error = action.payload;
}

function handleDeleteRequest(state: WeddingState) {
    state.isDeleting = true;
    state.deleteSuccess = false;
    state.error = null;
}
function handleDeleteSuccess(state: WeddingState, action: PayloadAction<number>) {
    state.isDeleting = false;
    state.deleteSuccess = true;
    delete state.planners[action.payload];
}
function handleDeleteFailure(state: WeddingState, action: PayloadAction<string>) {
    state.isDeleting = false;
    state.deleteSuccess = false;
    state.error = action.payload;
}
