import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';

import carouselPlaceholder1 from '@content/home/carousel-placeholder1.png';
import carouselPlaceholder2 from '@content/home/carousel-placeholder2.png';
import Title from 'lib/src/components/typography/Title';

const data = [
    {
        id: 1,
        name: 'Rebecca & Sean',
        location: 'The Cotswolds',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin enim quis nunc euismod accumsan.',
    },
    {
        id: 2,
        name: 'Jane & John',
        location: 'London',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin enim quis nunc euismod accumsan.',
    },
    {
        id: 3,
        name: 'Sarah & James',
        location: 'Manchester',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus viverra sapien vitae augue scelerisque, et vulputate ipsum egestas. Duis viverra tristique urna, gravida cursus diam mollis eget. Aenean sollicitudin enim quis nunc euismod accumsan.',
    },
];

const TestimonialsCarousel = () => {
    const [step, setStep] = useState(0);

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };

    return (
        <div className="testimonials">
            <div className="flex-row justify-center">
                <Title tag="h3">Happy customers</Title>
            </div>
            <Carousel
                className="testimonials-carousel"
                showIndicators={false}
                showStatus={false}
                onChange={newIndex => setStep(newIndex)}
                dynamicHeight
                selectedItem={step}
                renderArrowNext={(_, hasNext, label) =>
                    hasNext && (
                        <button
                            type="button"
                            onClick={handleNext}
                            title={label}
                            className="control-arrow control-next"
                        >
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    )
                }
                renderArrowPrev={(_, hasPrev, label) =>
                    hasPrev && (
                        <button
                            type="button"
                            onClick={handlePrev}
                            title={label}
                            className="control-arrow control-prev"
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    )
                }
            >
                {data.map(item => {
                    return (
                        <div
                            key={item.id}
                            className="testimonials-carousel-item flex-row align-center justify-center flex-column-reverse-pt"
                        >
                            <div className="flex-6 flex-column align-start justify-between testimonials-carousel-item-content">
                                <div>
                                    <h4>{item.name}</h4>
                                    <h5>{item.location}</h5>

                                    <div className="flex-row">
                                        <p>{item.description}</p>
                                    </div>
                                </div>

                                <div className="flex-row testimonials-carousel-item-indicators">
                                    {data.map((item, index) => {
                                        return (
                                            <button
                                                key={item.id}
                                                className={`testimonials-carousel-item-indicator ${
                                                    step === index ? 'active' : ''
                                                }`}
                                                onClick={() => setStep(index)}
                                            ></button>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="flex-6 testimonials-carousel-item-images flex-row justify-center display-none-pt">
                                <div className="image">
                                    <img
                                        src={carouselPlaceholder2}
                                        alt="Testimonial"
                                        className="image"
                                    />

                                    <div className="background-box" />
                                </div>

                                <div className="image">
                                    <img src={carouselPlaceholder1} alt="Testimonial" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default TestimonialsCarousel;
