import React from 'react';
import useForm from 'lib/src/hooks/useForm';
import FormRow from 'lib/src/components/form/FormRow';
import { fabric } from 'fabric';
import TextInput from 'lib/src/components/form/TextInput';
import { customFloatInputValidation } from 'lib/src/utils/generic';
import { onAddWall } from '@pages/tablePlanner/controls/controlPanel/WallControls';

const initialForm = {
    width: '1500',
    length: '1000',
};

const CustomRoomControls: React.FC<CustomRoomControlsProps> = ({ canvas }) => {
    const [form, onChange, resetData] = useForm(initialForm);
    const { length, width } = form;
    const clearForm = () => resetData(initialForm);
    const customChange = (name: keyof typeof form, str: string) =>
        onChange(name, customFloatInputValidation(str));
    const onSubmit = () => {
        if (!length || !width) return;
        const startX = 50;
        const startY = 50;
        const endX = startX + +width;
        const endY = startY + +length;
        onAddWall(canvas, null, +width, startX, startY);
        onAddWall(canvas, null, +width, startX, endY);
        onAddWall(canvas, null, +length, startX, startY, 90);
        onAddWall(canvas, null, +length, endX, startY, 90);
    };

    return (
        <>
            <strong>Build Room</strong>
            <FormRow>
                <TextInput name="width" value={width} onChange={customChange} label="Width (cm)" />
                <TextInput
                    name="length"
                    value={length}
                    onChange={customChange}
                    label="Length (cm)"
                />
            </FormRow>
            <div className="table-planner-menu-grid table-planner-menu-grid--2">
                <button className="button primary" onClick={clearForm}>
                    Clear
                </button>
                <button className="button primary" onClick={onSubmit}>
                    Add Room
                </button>
            </div>
        </>
    );
};

interface CustomRoomControlsProps {
    canvas: fabric.Canvas;
}

export default CustomRoomControls;
