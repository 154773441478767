import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generateSupplierGuestsPDF } from '@actions/guests';
import {
    selectSupplierGuestsGenerateError,
    selectSupplierGuestsIsGenerating,
} from '@selectors/guest';

import usePrevious from 'lib/src/hooks/usePrevious';

const useGenerateSupplierGuestsPDF = () => {
    const [showSupplierGuestsPDFError, setShowSupplierGuestsPDFError] = useState(false);

    const dispatch = useDispatch();
    const isGeneratingSupplierGuests = useSelector(selectSupplierGuestsIsGenerating);
    const generateSupplierGuestsError = useSelector(selectSupplierGuestsGenerateError);
    const prevGenerateSupplierGuestsError = usePrevious(generateSupplierGuestsError);

    useEffect(() => {
        if (generateSupplierGuestsError && !prevGenerateSupplierGuestsError) {
            setShowSupplierGuestsPDFError(true);
        }
    }, [generateSupplierGuestsError, prevGenerateSupplierGuestsError]);

    const handleGenerateSupplierGuestsPDF = () => {
        dispatch(generateSupplierGuestsPDF());
    };

    return {
        handleGenerateSupplierGuestsPDF,
        isGeneratingSupplierGuests,
        generateSupplierGuestsError,
        showSupplierGuestsPDFError,
        setShowSupplierGuestsPDFError,
    };
};

export default useGenerateSupplierGuestsPDF;
