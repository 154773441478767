import Description from 'lib/src/components/typography/Description';
import ActionButton from 'lib/src/components/button/ActionButton';
import { useState } from 'react';
import AddAdditionalProfileForm from '@pages/account/tabs/additionalProfiles/AddAdditionalProfileForm';
import useAdditionalProfiles from '@pages/account/tabs/additionalProfiles/hooks/useAdditionalProfiles';
import AdditionalProfileItem from '@pages/account/tabs/additionalProfiles/AdditionalProfileItem';
import WeddingPlanner from '../../../../types/WeddingPlanner';
import DeleteAdditionalProfileModal from '@pages/account/tabs/additionalProfiles/DeleteAdditionalProfileModal';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';

const AdditionalProfiles = () => {
    const { isWeddingOwner } = useIsLoggedIn();
    const { isFetching, profiles } = useAdditionalProfiles();
    const [showAddProfileModal, setShowAddProfileModal] = useState(false);
    const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false);
    const [focussedProfile, setFocussedProfile] = useState<WeddingPlanner | null>(null);
    return (
        <div className="flex flex-column">
            <Description>Additional Profiles</Description>
            <ActionButton
                source="secondary"
                icon="plus"
                onClick={() => setShowAddProfileModal(true)}
            >
                Add Profile
            </ActionButton>
            {isFetching && <Description>Loading...</Description>}
            {profiles.map(profile => (
                <AdditionalProfileItem
                    key={profile.id}
                    profile={profile}
                    isWeddingOwner={isWeddingOwner}
                    showDeleteProfileModal={() => {
                        if (isWeddingOwner) {
                            setShowDeleteProfileModal(true);
                            setFocussedProfile(profile);
                        }
                    }}
                />
            ))}
            {showAddProfileModal && (
                <AddAdditionalProfileForm hideModal={() => setShowAddProfileModal(false)} />
            )}
            {showDeleteProfileModal && !!focussedProfile && (
                <DeleteAdditionalProfileModal
                    profile={focussedProfile}
                    hideModal={() => {
                        setShowDeleteProfileModal(false);
                        setFocussedProfile(null);
                    }}
                />
            )}
        </div>
    );
};

export default AdditionalProfiles;
