import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import { addVectors, degToRad, getPositionFromAngledRadius, IVector2 } from '../../utils';
import Door from '../../../../types/Door';
import Description from 'lib/src/components/typography/Description';
import DraggableDoor from '@pages/tablePlanner/controls/controlPanel/components/DraggableDoor';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';

const DoorControls: React.FC<DoorControlsProps> = ({ setDropItem }) => {
    return (
        <div className="table-planner-menu-section">
            <Description>Doors</Description>
            <div className="table-planner-menu-grid">
                <DraggableDoor setDropItem={setDropItem} flip />
                <DraggableDoor setDropItem={setDropItem} />
                <DraggableDoor setDropItem={setDropItem} angle={180} />
                <DraggableDoor setDropItem={setDropItem} flip angle={180} />
            </div>
        </div>
    );
};

const startPos: IVector2 = { x: 100, y: 100 };
const pointsToGenerate = 20;

const generatePoints = (offset: IVector2, w: number): IVector2[] => {
    const points = new Array<IVector2>(pointsToGenerate + 1);
    const angle = degToRad(90);
    points[0] = offset;
    for (let i = 1; i < pointsToGenerate + 1; i++) {
        points[i] = addVectors(
            getPositionFromAngledRadius(
                (i / (pointsToGenerate - 1)) * angle + degToRad(265),
                Math.abs(w),
            ),
            offset,
        );
    }
    return points;
};

export const onAddDoor = (
    canvas: fabric.Canvas,
    door: Door | null = null,
    width = 75,
    height = 75,
    angle = 0,
    flip: boolean | null = null,
    left = 0,
    top = 0,
) => {
    const parsedWidth = door?.width ?? width;
    const parsedHeight = door?.height ?? height;
    const line = new fabric.Polyline(generatePoints(startPos, parsedWidth), {
        name: 'door',
        stroke: 'black',
        snapAngle: 90,
        snapThreshold: 10,
        strokeWidth: 3,
        flipX: flip === null ? parsedWidth < 0 : flip,
        flipY: parsedHeight < 0,
        fill: 'rgba(1,1,1,0)',
        data: {
            type: PlannerObjectType.Door,
            id: door?.id ?? v4(),
        },
        top: door?.top ?? top,
        left: door?.left ?? left,
        width: parsedWidth,
        height: door?.height,
        angle: door?.angle ?? angle,
    });
    // hide non-length controls
    line.setControlsVisibility({});
    // new fabric.Image.filters.BaseFilter().setOptions()
    canvas.add(line);
};

interface DoorControlsProps {
    setDropItem: (dropItem: DropItemConfig | null) => void;
}

export default DoorControls;
