import useDownloadPDF from './useDownloadPDF';

export const usePrintPanel = () => {
    const { handleDownload, handlePrint, isFetching } = useDownloadPDF();

    const onClickPrint = () => {
        handlePrint();
    };

    const onClickExport = () => {
        handleDownload();
    };

    return { onClickExport, onClickPrint, isFetching };
};

export default usePrintPanel;
