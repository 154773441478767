import React from 'react';
import { Link } from 'react-router-dom';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import BudgetTable from './BudgetTable';
import useFetchBudgetBreakdown from './hooks/useFetchBudgetBreakdown';

const BudgetBreakdown: React.FC = () => {
    const { budget, isFetching, budgetError } = useFetchBudgetBreakdown();

    return (
        <div className="page-padding section">
            <div className="section-inner">
                <CreateHeader>
                    <div>
                        <Title>Budget Breakdown</Title>
                        <p className="generic-text">
                            Budget numbers are added via the{' '}
                            <Link to="/wedding/suppliers">Suppliers</Link> page.
                        </p>
                    </div>
                </CreateHeader>

                <DataCheck isEmpty={!budget} isLoading={isFetching} error={budgetError}>
                    {budget && (
                        <BudgetTable
                            budget={budget}
                            budgetData={budget.budgetData}
                            isFetching={isFetching}
                        />
                    )}
                </DataCheck>
            </div>
        </div>
    );
};

export default BudgetBreakdown;
