import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Supplier } from 'src/types/Supplier';

export const fetchSupplierRequest = createAction('fetchSupplierRequest');
export const fetchSupplierSuccess = createAction<Supplier>('fetchSupplierSuccess');
export const fetchSupplierFailure = createAction('fetchSupplierFailure');

export const fetchSupplier =
    (supplierID: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSupplierRequest());
        try {
            const { data } = await api.get<Supplier>(`supplier/${supplierID}`);
            dispatch(fetchSupplierSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSupplierFailure, e as APIError);
        }
    };
