import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import fileDownload from 'js-file-download';

export const generateMealPDFRequest = createAction('generateMealPDFRequest');
export const generateMealPDFSuccess = createAction('generateMealPDFSuccess');
export const generateMealPDFFailure = createAction('generateMealPDFFailure');

export const generateMealPDF =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(generateMealPDFRequest());
        try {
            await api
                .get<Blob>('meal/pdf', 'blob')
                .then(res => new Blob([res.data], { type: 'application/pdf' }))
                .then(blob => fileDownload(blob, 'WeddingMeals.pdf'));
            dispatch(generateMealPDFSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, generateMealPDFFailure, e as APIError);
        }
    };
