import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Resource } from 'src/types/Resource';

export const fetchResourcesByTagRequest = createAction('fetchResourcesByTagRequest');
export const fetchResourcesByTagSuccess = createAction<Resource[]>('fetchResourcesByTagSuccess');
export const fetchResourcesByTagFailure = createAction('fetchResourcesByTagFailure');

export const fetchResourcesByTag =
    (categoriesIDs: number[]) =>
    async (dispatch: AppDispatch): Promise<void> => {
        const qs =
            '?' +
            categoriesIDs.map((item, i) => `tag=${encodeURIComponent(categoriesIDs[i])}`).join('&');

        dispatch(fetchResourcesByTagRequest());
        try {
            const { data } = await api.get<Resource[]>(`resource/bytag${qs}`);

            dispatch(fetchResourcesByTagSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchResourcesByTagFailure, e as APIError);
        }
    };
