import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    deleteTimingFailure,
    deleteTimingRequest,
    deleteTimingSuccess,
    fetchTimingsFailure,
    fetchTimingsRequest,
    fetchTimingsSuccess,
    generateTimingsPDFFailure,
    generateTimingsPDFRequest,
    generateTimingsPDFSuccess,
    patchTimingFailure,
    patchTimingRequest,
    patchTimingSuccess,
    postTimingFailure,
    postTimingRequest,
    postTimingSuccess,
} from '@actions/timings';
import { Timing } from 'src/types/Timing';
import { convertArrToObj } from 'lib/src/utils/generic';
import { logout } from '@actions/auth';

interface TimingsState {
    isFetching: boolean;
    fetchError: string | null;
    isPosting: boolean;
    postError: string | null;
    postSuccess: boolean;
    isGenerating: boolean;
    generateSuccess: boolean;
    generateError: string | null;
    timings: Record<number, Timing>;
}

const initialState: TimingsState = {
    isFetching: false,
    fetchError: null,
    isPosting: false,
    postError: null,
    postSuccess: false,
    isGenerating: false,
    generateSuccess: false,
    generateError: null,
    timings: {},
};

export default createReducer(initialState, {
    [fetchTimingsRequest.type]: handleFetchRequest,
    [fetchTimingsSuccess.type]: handleFetchTimingsSuccess,
    [fetchTimingsFailure.type]: handleFetchFailure,
    [postTimingRequest.type]: handlePostRequest,
    [postTimingSuccess.type]: handlePostTimingSuccess,
    [postTimingFailure.type]: handlePostFailure,
    [patchTimingRequest.type]: handlePostRequest,
    [patchTimingSuccess.type]: handlePostTimingSuccess,
    [patchTimingFailure.type]: handlePostFailure,
    [deleteTimingRequest.type]: handlePostRequest,
    [deleteTimingSuccess.type]: handleDeleteTimingSuccess,
    [deleteTimingFailure.type]: handlePostFailure,
    [generateTimingsPDFRequest.type]: handleGenerateRequest,
    [generateTimingsPDFSuccess.type]: handleGenerateSuccess,
    [generateTimingsPDFFailure.type]: handleGenerateFailure,
    [logout.type]: () => initialState,
});

function handleFetchRequest(state: TimingsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchFailure(state: TimingsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchTimingsSuccess(state: TimingsState, action: PayloadAction<Timing[]>) {
    state.isFetching = false;
    state.timings = convertArrToObj(action.payload);
}

function handlePostRequest(state: TimingsState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state: TimingsState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handlePostTimingSuccess(state: TimingsState, action: PayloadAction<Timing>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.timings[action.payload.id] = action.payload;
}

function handleDeleteTimingSuccess(state: TimingsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.timings[action.payload];
}

function handleGenerateRequest(state: TimingsState) {
    state.isGenerating = true;
    state.generateSuccess = false;
    state.generateError = null;
}

function handleGenerateSuccess(state: TimingsState) {
    state.isGenerating = false;
    state.generateSuccess = true;
}

function handleGenerateFailure(state: TimingsState, action: PayloadAction<string>) {
    state.isGenerating = false;
    state.generateError = action.payload;
}
