import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import fileDownload from 'js-file-download';

export const generateSupplierQuestionsPDFRequest = createAction(
    'generateSupplierQuestionsPDFRequest',
);
export const generateSupplierQuestionsPDFSuccess = createAction(
    'generateSupplierQuestionsPDFSuccess',
);
export const generateSupplierQuestionsPDFFailure = createAction(
    'generateSupplierQuestionsPDFFailure',
);

export const generateSupplierQuestionsPDF =
    (supplierID: number, supplierName: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(generateSupplierQuestionsPDFRequest());
        try {
            await api
                .get<Blob>(`question/pdf/${supplierID}`, 'blob')
                .then(res => new Blob([res.data], { type: 'application/pdf' }))
                .then(blob => fileDownload(blob, `${supplierName} Questions.pdf`));
            dispatch(generateSupplierQuestionsPDFSuccess());
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, generateSupplierQuestionsPDFFailure, e as APIError);
        }
    };
