import { useDispatch, useSelector } from 'react-redux';
import { selectGuestDeleteSuccess, selectGuestIsDeleting } from '@selectors/guest';
import Guest from '../../../../types/Guest';
import { deleteGuest } from '@actions/guests/deleteGuest';

const useDeleteGuest = (guest: Guest) => {
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectGuestIsDeleting);
    const deleteSuccess = useSelector(selectGuestDeleteSuccess);

    const handleSubmit = () => {
        dispatch(deleteGuest(guest.id));
    };

    return { isDeleting, deleteSuccess, handleSubmit };
};

export default useDeleteGuest;
