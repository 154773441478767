import { DropdownOption } from "../../types/shared/DropdownOption";

export enum ToolType {
    None = 0,
    StandardChair = 1,
    HighChair = 2,
    ChildChair = 3,
    WheelChair = 4,
}

export const toolTypeOptions: DropdownOption<ToolType>[] = [
    {
        value: ToolType.None,
        label: 'None'
    },
    {
        value: ToolType.StandardChair,
        label: 'Standard',
    },
    {
        value: ToolType.HighChair,
        label: 'High Chair',
    },
    {
        value: ToolType.ChildChair,
        label: 'Child Chair',
    },
    {
        value: ToolType.WheelChair,
        label: 'Wheelchair',
    }
];
