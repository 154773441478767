import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDecodedJwtToken } from 'lib/src/utils/jwt';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthPostSuccess } from '@selectors/auth';
import { logout } from '@actions/auth';

export default function useIsLoggedIn() {
    const dispatch = useDispatch();
    const [token, setToken] = useState(getDecodedJwtToken());
    const location = useLocation();
    const postSuccess = useSelector(selectAuthPostSuccess);

    useEffect(() => {
        const decoded = getDecodedJwtToken();
        if (!decoded) dispatch(logout);
        setToken(getDecodedJwtToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, postSuccess]);
    let weddingID = token ? parseInt(token.WeddingID ?? '') : null;
    if (Number.isNaN(weddingID)) weddingID = null;
    return {
        isLoggedIn: !!token,
        hasWedding: !!weddingID,
        weddingID,
        isWeddingOwner: token?.IsWeddingOwner === 'true',
    };
}
