import useForm from 'lib/src/hooks/useForm';
import { AddPlannerRequest, postAddPlanner } from '@actions/weddings/postAddPlanner';
import { useDispatch, useSelector } from 'react-redux';
import { selectWeddingIsPosting, selectWeddingPostSuccess } from '@selectors/wedding';

const useAddAdditionalProfile = () => {
    const dispatch = useDispatch();
    const isPosting = useSelector(selectWeddingIsPosting);
    const postSuccess = useSelector(selectWeddingPostSuccess);
    const [formData, handleChange] = useForm<AddPlannerRequest>({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
    });

    const handleSubmit = () => {
        dispatch(postAddPlanner(formData));
    };

    return { formData, handleChange, handleSubmit, isPosting, postSuccess };
};

export default useAddAdditionalProfile;
