import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '@reducers/index';
import { fetchSingleMeal } from '@actions/meal';
import { selectMeal, selectMealsIsFetching, selectMealTypes } from '@selectors/meal';
import MealPage from './MealPage';
import useAllMealsTypesFetch from '../meals/hooks/useAllMealsTypesFetch';
import UpdateMealModal from './UpdateMealModal';
import DeleteMealModal from './DeleteMealModal';
import useAllMealTimesFetch from '../hooks/useAllMealTimesFetch';

const MealContainer: React.FC = () => {
    useAllMealsTypesFetch();
    const { mealTimes } = useAllMealTimesFetch();

    const mealTypes = useSelector(selectMealTypes);

    const { id, action } = useParams<ParamTypes>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSingleMeal(+id));
    }, [id, dispatch]);

    const meal = useSelector((state: RootState) => selectMeal(state, +id));
    const isFetching = useSelector(selectMealsIsFetching);

    return (
        <>
            <MealPage
                meal={meal}
                mealTypes={mealTypes}
                mealTimes={mealTimes}
                isFetching={isFetching}
            />
            {action === 'delete' && <DeleteMealModal meal={meal} />}
            {!!meal && action === 'edit' && (
                <UpdateMealModal
                    id={meal.id}
                    name={meal.name}
                    mealType={meal.mealType}
                    mealTime={meal.mealTime}
                    costPerDish={meal.costPerDish}
                    isVegetarian={meal.isVegetarian}
                    isVegan={meal.isVegan}
                    isDairyFree={meal.isDairyFree}
                    isGlutenFree={meal.isGlutenFree}
                    isNutFree={meal.isNutFree}
                    notes={meal.notes}
                    mealTypes={mealTypes}
                    mealTimes={mealTimes}
                    isEggFree={meal.isEggFree}
                />
            )}
        </>
    );
};

interface ParamTypes {
    id: string;
    action: string;
}

export default MealContainer;
