import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Meal } from 'src/types/Meal';

export const fetchSingleMealRequest = createAction('fetchSingleMealRequest');
export const fetchSingleMealSuccess = createAction<Meal>('fetchSingleMealSuccess');
export const fetchSingleMealFailure = createAction('fetchSingleMealFailure');

export const fetchSingleMeal =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSingleMealRequest());
        try {
            const { data } = await api.get<Meal>(`meal/${id}`);

            dispatch(fetchSingleMealSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleMealFailure, e as APIError);
        }
    };
