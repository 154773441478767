import { selectProfile } from '@selectors/profile';
import { selectWedding } from '@selectors/wedding';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

export const formatNumber = (num: number | string | undefined) =>
    num ? Math.max(+num, 0)?.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) ?? '00' : '00';

const getCountdown = (deadline: dayjs.Dayjs) => {
    const now = dayjs();
    let finalDate = deadline;

    let diffDuration = dayjs.duration(finalDate.diff(now));
    const years = diffDuration.years();
    finalDate = finalDate.subtract(years, 'years');

    diffDuration = dayjs.duration(finalDate.diff(now));
    const months = diffDuration.months() + years * 12;
    finalDate = finalDate.subtract(diffDuration.months(), 'months');

    diffDuration = dayjs.duration(finalDate.diff(now));
    const weeks = diffDuration.weeks();
    finalDate = finalDate.subtract(weeks, 'weeks');

    diffDuration = dayjs.duration(finalDate.diff(now));
    const days = diffDuration.days();
    finalDate = finalDate.subtract(days, 'days');

    diffDuration = dayjs.duration(finalDate.diff(now));
    const hours = diffDuration.hours();

    return {
        months,
        weeks,
        days,
        hours,
    };
};

const useCountdownState = (eventDate: dayjs.Dayjs | null) => {
    const countdown = getCountdown(eventDate ?? dayjs());
    const [time, setTime] = useState(eventDate ? countdown : null);

    useEffect(() => {
        if (!eventDate) return;
        if (!time) setTime(countdown);
        const timerId = setInterval(() => {
            const newCountdown = getCountdown(eventDate);
            setTime(newCountdown);
        }, 1000 * 60); // Update every minute to see if theres a change

        return () => clearInterval(timerId);
    }, [countdown, eventDate, time]);

    return time;
};

const useCountdown = (date: Date | dayjs.Dayjs) => {
    const eventDate = dayjs(date);
    const countdown = useCountdownState(eventDate);

    return { eventDate, ...countdown };
};

export const useHeroCountdown = () => {
    const profile = useSelector(selectProfile);
    const wedding = useSelector(selectWedding);

    const firstName = profile?.firstName;
    const partnerFirstName = profile?.partnerFirstName;
    const dateOfWedding = wedding?.date ? dayjs(wedding?.date) : null;

    const countdown = useCountdownState(dateOfWedding);

    return {
        firstName,
        weddingDate: dateOfWedding,
        partnerFirstName,
        dateOfWedding: wedding?.date,
        ...countdown,
    };
};

export default useCountdown;
