import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    fetchSupplierTypesRequest,
    fetchSupplierTypesSuccess,
    fetchSupplierTypesFailure,
    fetchSupplierTypeRequest,
    fetchSupplierTypeSuccess,
    fetchSupplierTypeFailure,
} from '@actions/supplierTypes';
import { SupplierType } from 'src/types/SupplierType';
import { convertArrToObj } from 'lib/src/utils/generic';

interface SuppliersState {
    isFetching: boolean;
    fetchError: string | null;
    supplierTypes: Record<number, SupplierType>;
}

const initialState: SuppliersState = {
    isFetching: false,
    fetchError: null,
    supplierTypes: {},
};

export default createReducer(initialState, {
    [fetchSupplierTypesRequest.type]: handleFetchRequest,
    [fetchSupplierTypesSuccess.type]: handleFetchSupplierTypesSuccess,
    [fetchSupplierTypesFailure.type]: handleFetchFailure,
    [fetchSupplierTypeRequest.type]: handleFetchRequest,
    [fetchSupplierTypeSuccess.type]: handleFetchSupplierTypeSuccess,
    [fetchSupplierTypeFailure.type]: handleFetchFailure,
});

function handleFetchRequest(state: SuppliersState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchFailure(state: SuppliersState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchSupplierTypesSuccess(
    state: SuppliersState,
    action: PayloadAction<SupplierType[]>,
) {
    state.isFetching = false;
    state.supplierTypes = convertArrToObj(action.payload);
}

function handleFetchSupplierTypeSuccess(
    state: SuppliersState,
    action: PayloadAction<SupplierType>,
) {
    state.isFetching = false;
    state.supplierTypes[action.payload.id] = action.payload;
}
