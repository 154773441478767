import Resources from './Resources';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { Resource, ResourceCategory } from 'src/types/Resource';

const ResourcesCategory: React.FC<ResourceCategoryProps> = ({
    resourceCategoryId,
    resourcesByCategoryArr,
    resourcesCategory,
    isFetching,
    error,
}) => {
    return (
        <DataCheck isEmpty={!resourcesByCategoryArr.length} isLoading={isFetching} error={error}>
            <Resources
                resourceCategoryId={resourceCategoryId}
                resourcesByCategoryArr={resourcesByCategoryArr}
                resourcesCategory={resourcesCategory}
            />
        </DataCheck>
    );
};

interface ResourceCategoryProps {
    resourceCategoryId: number;
    resourcesByCategoryArr: Resource[];
    resourcesCategory: ResourceCategory;
    isFetching: boolean;
    error: string | null;
}

export default ResourcesCategory;
