import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import React from 'react';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';

const DraggableDoor: React.FC<DraggableDoorProps> = ({
    setDropItem,
    width = 75,
    caption = `${width}cm wide`,
    angle = 0,
    flip = false,
}) => {
    return (
        <div className="drag-container">
            <div
                className="draggable door"
                style={{
                    rotate: `${angle}deg`,
                    transform: `scaleX(${flip ? -1 : 1}) `,
                }}
                draggable={true}
                onDragStart={() => {
                    setDropItem({
                        type: PlannerObjectType.Door,
                        width,
                        angle,
                        flip,
                    });
                }}
                onDragEnd={() => {
                    setDropItem(null);
                }}
            />
            <span className="drag-caption">{caption}</span>
        </div>
    );
};

interface DraggableDoorProps {
    setDropItem: (item: DropItemConfig | null) => void;
    width?: number;
    caption?: string;
    angle?: number;
    flip?: boolean;
}

export default DraggableDoor;
