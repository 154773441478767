import { combineReducers } from 'redux';

import darkModeReducer from 'lib/src/redux/reducers/darkMode';
import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import authReducer from './auth';
import weddingReducer from './wedding';
import guestReducer from './guest';
import profileReducer from './profile';
import tablePlannerReducer from './tablePlanner';
import mealReducer from './meal';
import resourcesReducer from './resources';
import suppliersReducer from './suppliers';
import supplierTypesReducer from './supplierTypes';
import questionsReducer from './questions';
import timingsReducer from './timings';
import budgetReducer from './budget';

const rootReducer = combineReducers({
    authReducer,
    darkModeReducer,
    fieldErrorsReducer,
    guestReducer,
    mealReducer,
    profileReducer,
    questionsReducer,
    redirectsReducer,
    resourcesReducer,
    suppliersReducer,
    supplierTypesReducer,
    tablePlannerReducer,
    timingsReducer,
    weddingReducer,
    budgetReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
