import { ToolFunction } from './../../../pages/tablePlanner/controls/guestPanel/hooks/useGuestPanel';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/redux/store';
import Guest from 'src/types/Guest';

export const setSelectedGuestAction = createAction<Guest | null>('setSelectedGuestAction');

export const setSelectedGuest = (guest: Guest | null) => async (dispatch: AppDispatch) =>
    dispatch(setSelectedGuestAction(guest));

export const setToolFunctionAction = createAction<ToolFunction>('setToolFunctionAction');

export const setToolFunction = (tool: ToolFunction) => async (dispatch: AppDispatch) =>
    dispatch(setToolFunctionAction(tool));
