import React from 'react';
import TimePicker, { TimePickerValue } from 'react-time-picker';

import useFieldValidation from '../../hooks/useFieldValidation';
import { FormInputProps } from '../../types/shared/FormInputProps';
import FormField from './FormField';

const TimePickerInput: React.FC<TimePickerInputProps> = ({
    name,
    value,
    onChange,
    label = '',
    required = false,
    disabled,
    customValidate,
    extraClasses = '',
    disableClear = false,
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <TimePicker
                className={`form-input ${extraClasses}`}
                onChange={handleChange} 
                value={value || ''} 
                onClockClose={handleBlur} 
                disabled={disabled}
                disableClock
                hourAriaLabel="Hour"
                hourPlaceholder="HH"
                minutePlaceholder="MM"
                format='hh:mm a'
                clearIcon={disableClear ? null : undefined}
            />
        </FormField>
    );

    function handleBlur() {
        showError();
    }

    function handleChange(val: TimePickerValue) {
        const updatedVal = val ? val.toString() + ':00' : '';
        onChange(name, updatedVal);
        showError();
    }
};

interface TimePickerInputProps extends FormInputProps<string | null> {
    label?: string;
    extraClasses?: string;
    disableClear?: boolean;
}

export default TimePickerInput;
