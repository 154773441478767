import React from 'react';

import { TIMING_DAY_DROPDOWN_OPTIONS } from 'lib/src/constants/timings';

import useEditTiming from './hooks/useEditTiming';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';
import TimePickerInput from 'lib/src/components/form/TimePickerInput';

const EditTimingModal: React.FC<EditTimingModalProps> = ({ editTimingID, closeModal }) => {
    const { form, handleChange, handleSubmit, isPosting, supplierOptions } =
        useEditTiming(editTimingID);

    return (
        <Modal title="Edit Timing">
            <Form onSubmit={handleSubmit} onCancel={closeModal} isPosting={isPosting}>
                <TextInput
                    label="Name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                />
                <Select
                    label="Supplier"
                    name="supplierID"
                    value={form.supplierID}
                    options={supplierOptions}
                    onChange={handleChange}
                    omitRemoval
                    required
                />
                <Select
                    label="Day"
                    name="day"
                    value={form.day}
                    options={TIMING_DAY_DROPDOWN_OPTIONS}
                    onChange={handleChange}
                    omitRemoval
                    required
                />
                <TimePickerInput
                    label="Time"
                    name="time"
                    value={form.time}
                    onChange={handleChange}
                    required
                />
            </Form>
        </Modal>
    );
};

interface EditTimingModalProps {
    editTimingID: number;
    closeModal: () => void;
}

export default EditTimingModal;
