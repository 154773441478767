import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import Window from '../../../../types/Window';
import DraggableWindow from '@pages/tablePlanner/controls/controlPanel/components/DraggableWindow';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';
import Description from 'lib/src/components/typography/Description';

const WindowControls: React.FC<WindowControlsProps> = ({ setDropItem }) => {
    return (
        <div className="table-planner-menu-section">
            <Description>Windows</Description>
            <div className="table-planner-menu-grid">
                <DraggableWindow setDropItem={setDropItem} length={50} />
                <DraggableWindow setDropItem={setDropItem} length={100} />
            </div>
        </div>
    );
};

export const onAddWindow = (
    canvas: fabric.Canvas,
    window: Window | null = null,
    length = 100,
    left = 100,
    top = 100,
) => {
    const lineConfig = {
        stroke: 'black',
        snapAngle: 15,
        lockScalingFlip: true,
        strokeWidth: 2,
        left: window?.left ?? left,
        top: window?.top ?? top,
        width: window?.width ?? length,
        angle: window?.angle ?? 0,
        data: {
            type: PlannerObjectType.Window,
            id: window?.id ?? v4(),
        },
    };

    const line = new fabric.Line();
    line.set(lineConfig);
    // hide non-length controls
    line.setControlsVisibility({
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mt: false,
        mb: false,
    });
    canvas.add(line);
};

interface WindowControlsProps {
    setDropItem: (item: DropItemConfig | null) => void;
}

export default WindowControls;
