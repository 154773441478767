import { Resource } from 'src/types/Resource';
import config from '../../config';
import Description from 'lib/src/components/typography/Description';
const { S3_URL } = config;

const ResourcePage: React.FC<ResourceParams> = ({ resource }) => {
    return (
        <div>
            <div>
                <div className="hero resource">
                    <div
                        className="hero-upper"
                        style={{ backgroundImage: `url(${S3_URL}/${resource.image})` }}
                    ></div>

                    <div className="hero-lower">
                        <div className="section">
                            <div className="section-inner">
                                <h2>{resource.name}</h2>
                                <Description>{resource.description}</Description>
                                <p className="page-body">{resource.body}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ResourceParams {
    resource: Resource;
}

export default ResourcePage;
