import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import Guest from '../../../types/Guest';
import { AgeCategory } from 'lib/src/shared/enums/ageCategory';
import { AttendanceResponse } from 'lib/src/shared/enums/attendanceResponse';
import { GuestInvite } from 'lib/src/shared/enums/guestInvite';
import { GuestInviteRSVP } from 'lib/src/shared/enums/guestInviteRSVP';

export const patchGuestRequest = createAction('patchGuestRequest');
export const patchGuestSuccess = createAction<Guest>('patchGuestSuccess');
export const patchGuestFailure = createAction('patchGuestFailure');

export const patchGuest =
    (patchBody: UpdateGuestRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(patchGuestRequest());
        try {
            const { data } = await api.patch<UpdateGuestRequest, Guest>('guest', patchBody);
            dispatch(patchGuestSuccess(data));
        } catch (e) {
            console.log({ e });
            handleApiErrors(dispatch, patchGuestFailure, e as APIError);
        }
    };

export interface UpdateGuestRequest {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    nickname: string;
    email: string;
    address: string;
    phoneNumber: string;
    specialRelations: string;
    notes: string;
    ageCategory: AgeCategory;
    pictureS3Key: string | null;
    guestInvitedTo: GuestInvite;
    guestRSVP: GuestInviteRSVP;
    cateringAttendanceCanape: AttendanceResponse;
    cateringAttendanceWeddingBreakfast: AttendanceResponse;
    cateringAttendanceEveningMeal: AttendanceResponse;
    isVegan: boolean;
    isVegetarian: boolean;
    isGlutenFree: boolean;
    isDairyFree: boolean;
    isNutFree: boolean;
    isEggFree: boolean;
    isOther: boolean;
    otherDetails: string | null;
    isStayingAtWeddingVenue: boolean;
    accommodationAddress: string | null;
    numberOfNights: number | null;
}
