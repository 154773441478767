import { PlannerObjectType } from 'lib/src/shared/enums/plannerObjectType';
import React from 'react';
import { DropItemConfig } from '@pages/tablePlanner/TablePlanner';

const DraggableWindow: React.FC<DraggableWindowProps> = ({
    setDropItem,
    length,
    caption = `${length}cm long`,
}) => {
    return (
        <div className="drag-container">
            <div
                className="draggable window"
                draggable={true}
                onDragStart={() => {
                    setDropItem({
                        type: PlannerObjectType.Window,
                        height: length,
                    });
                }}
                onDragEnd={() => {
                    setDropItem(null);
                }}
            />
            <span className="drag-caption">{caption}</span>
        </div>
    );
};

interface DraggableWindowProps {
    setDropItem: (item: DropItemConfig | null) => void;
    length: number;
    caption?: string;
}

export default DraggableWindow;
