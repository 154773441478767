import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchGuests } from '@actions/guests/fetchGuests';
import { selectGuestError, selectGuestIsFetching, selectGuestsArr } from '@selectors/guest';

const useFetchGuests = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectGuestIsFetching);
    const error = useSelector(selectGuestError);
    const guests = useSelector(selectGuestsArr);
    useEffect(() => {
        dispatch(fetchGuests());
    }, [dispatch]);

    return {
        isFetching,
        error,
        guests: guests,
    };
};

export default useFetchGuests;
